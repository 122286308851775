import BlueAmbulance from '../facilitator/components/RaceScreen/assets/BlueAmbulance.png';
import GreenAmbulance from '../facilitator/components/RaceScreen/assets/GreenAmbulance.png';
import OrangeAmbulance from '../facilitator/components/RaceScreen/assets/OrangeAmbulance.png';
import PinkAmbulance from '../facilitator/components/RaceScreen/assets/PinkAmbulance.png';
import PurpleAmbulance from '../facilitator/components/RaceScreen/assets/PurpleAmbulance.png';
import RedAmbulance from '../facilitator/components/RaceScreen/assets/RedAmbulance.png';

// confetti needs color code in hex
const RED = '#FF465C';
const GREEN = '#00A949';
const BLUE = '#009AF9';
const ORANGE = '#E57200';
const PINK = '#FF2EAF';
const PURPLE = '#B366FD';
const defaultColors = [RED, GREEN, PINK, BLUE, ORANGE, PURPLE];
const defaultAmbulanceImages = [
  RedAmbulance,
  GreenAmbulance,
  PinkAmbulance,
  BlueAmbulance,
  OrangeAmbulance,
  PurpleAmbulance,
];

export const getTeamNumber = (teamName: string): number => {
  const numbersOnly = teamName.replace(/\D/g, '');
  const teamNumber = numbersOnly ? parseInt(numbersOnly) : 1;
  return teamNumber;
};

export const getTeamAmbulanceImage = (
  teamName: string,
  ambulanceImages = defaultAmbulanceImages,
): string => {
  const teamNumber = getTeamNumber(teamName);
  return ambulanceImages[teamNumber % ambulanceImages.length];
};

export const getTeamColor = (
  teamName: string,
  colors = defaultColors,
): string => {
  const teamNumber = getTeamNumber(teamName);
  return colors[teamNumber % colors.length];
};
