/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* TODO: Remove this */
import {
  BasicDropdown,
  DropdownItem,
  ModalContainer as InnerModalContainer,
  ModalBody as LifeModalBody,
  LoadingIndicator,
  Modal,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Size,
} from '@laerdal/life-react-components';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import checkmark from '../../assets/images/Checkmark.png';
import errorCheckmark from '../../assets/images/errorcheckmark.png';
import Pads from '../../assets/images/pads.png';
import constants from '../../constants/Constants';
import { useTheme } from '../../context';
import { defaultText } from '../../features/sanity';
import { AedPadHelpModal } from '../../features/sanity/sanityTypes/templates/aedDraggablePadPageTemplate';
import {
  SanityImage,
  SanityShareNotSupported,
} from '../../pageTemplates/types/sanityTypes';
import { LocalStorageService } from '../../services';
import LifeButton from '../Button';
import { focusOnElement } from '../UseFocus';

declare type ModalContainerProps = {
  showModal: boolean;
  closeModal: () => void;
  size?: string;
  children: React.ReactNode;
  modalHeight?: string;
  modalWidth?: string;
  modalOverflow?: string;
  id?: string;
};

const { pageMargin } = constants;

const InnerModalContainerOverride = styled(InnerModalContainer)``;

const lifeModalPadding = 24;

const ModalContainer = (props: ModalContainerProps) => {
  const [modalWidth, setModalWidth] = useState(constants.maxPageWidthMobile);
  const CalculateAndSetModalWidth = () => {
    setModalWidth(
      window.innerWidth < constants.maxPageWidthMobile
        ? window.innerWidth - (pageMargin.SMALL * 2 + lifeModalPadding * 2)
        : constants.maxPageWidthMobile -
            (pageMargin.SMALL * 2 + lifeModalPadding * 2),
    );
  };

  useEffect(() => {
    CalculateAndSetModalWidth();
    window.addEventListener('resize', CalculateAndSetModalWidth);
    window.addEventListener('orientationchange', CalculateAndSetModalWidth);

    return () => {
      window.removeEventListener('resize', CalculateAndSetModalWidth);
      window.removeEventListener(
        'orientationchange',
        CalculateAndSetModalWidth,
      );
    };
  }, []);

  return <InnerModalContainerOverride {...props} width={`${modalWidth}px`} />;
};

const LanguageModalContainer = (props: ModalContainerProps) => {
  return (
    <InnerModalContainerOverride {...props} width="350px" height="400px" />
  );
};

interface CommonModalProps {
  bodyText?: React.ReactNode;
  buttonText?: string;
  headerText?: string;
  image?: SanityImage;
  imageSource?: string;
  isButtonEnabled?: boolean;
  isVisible: boolean;
  toggleModal: (value?: string) => void;
}

type LanguageSelectionModalProps = {
  languageList: DropdownItem[];
} & CommonModalProps;

interface Call911ModalProps extends CommonModalProps {
  continueLaterClicked: () => void;
  imageSource?: string;
  imageAlt?: string;
  headerText: string;
  bodyText: string;
  primaryButtonText: string;
  secondaryButtonText: string;
}

interface ExtendedModalProps extends CommonModalProps {
  shareText?: string;
  copyTextPressed?: () => void;
  continueButtonPressed?: () => void;
}

interface ShareNotSupportedProps extends CommonModalProps {
  shareData?: SanityShareNotSupported;
  copyTextPressed?: () => void;
  continueButtonPressed?: () => void;
}

interface AedPadsHelpModalProps extends CommonModalProps {
  modalData?: AedPadHelpModal;
  whereToPlacePadsClicked: () => void;
  practiseNowClicked: () => void;
}

const Footer = styled(ModalFooter)`
  justify-content: center !important;
`;

const StyledForm = styled.form`
  padding: 10px;
`;

const StyledModalFooter = styled(ModalFooter)<{ verticalButtons?: boolean }>`
  display: flex;
  ${(props) =>
    props.verticalButtons
      ? `
  margin: 0px;
  flex-direction: column;
  button {
    margin: 0px;
    margin-bottom: 8px;
  }
  button:last-of-type {
    margin: 0px;
  }`
      : `     
  margin: 0 -5px;
  button {
    margin: 0 5px;
  }`}
  margin-bottom: 16px;
`;

const StyledModalBody = styled(LifeModalBody)`
  margin-bottom: 16px;
`;

const LanguageStyledModalFooter = styled(ModalFooter)`
  align-items: end;
  height: 65%;
  justify-content: center;
`;

const Header = styled(ModalHeader)`
  justify-content: flex-end !important;
  margin-bottom: 0px;
  text-align: center;
`;

const ModalBody = styled(LifeModalBody)<{ textAlignment?: string }>`
  display: flex;
  font-size: 2.6rem;
  font-weight: 700;
  text-align: ${(props) => props.textAlignment ?? props.theme.textAlignment};
  line-height: 31.2px;
  flex-direction: column;
  align-items: center;
`;

const CenteredFullButton = styled(LifeButton)`
  max-width: ${constants.maxPageWidthMobile - 100}px;
  background-color: inherit;
  flex: 1;
`;

const CopyButton = styled(LifeButton)<{
  color: string;
  backgroundColor?: string;
  disableHover?: boolean;
}>`
  .button-content {
    background-color: ${(props) => props.backgroundColor};
    border-radius: 100px !important;
    color: inherit;
  }
  padding: 7px;
  flex: 1;
  max-width: ${constants.maxPageWidthMobile - 100}px;
  color: white;
  border-width: 0px;
  font-weight: bold;
  font-size: 1.8rem;
`;

const setFocus = (node) => {
  if (node !== null) {
    setTimeout(() => {
      focusOnElement(node);
    }, 20);
  }
};

const DropDown = styled.div`
  width: 280px;
  margin-top: 10px;
`;

export const CameraPermissionModal: FunctionComponent<CommonModalProps> = ({
  isVisible,
  toggleModal,
}: CommonModalProps) => {
  const modalRef = useCallback(setFocus, []);
  return (
    <ModalContainer
      showModal={isVisible}
      closeModal={() => toggleModal()}
      modalHeight="auto"
    >
      <Modal>
        <StyledForm ref={modalRef} onSubmit={(event) => event.preventDefault()}>
          <Header>
            <ModalCloseButton onClick={() => toggleModal()} />
          </Header>
          <ModalBody tabIndex={-1}>
            Allow permissions to camera from your browser or system options
          </ModalBody>
          <Footer>
            <CenteredFullButton onClick={() => toggleModal()}>
              Ok
            </CenteredFullButton>
          </Footer>
        </StyledForm>
      </Modal>
    </ModalContainer>
  );
};

const GenericModalHeaderText = styled.h1`
  font-weight: 700;
  font-size: 2.6rem;
  text-align: center;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.neutral_800};
`;

const GenericModalBodyText = styled.p`
  font-size: 1.8rem;
  color: ${(props) => props.theme.colors.neutral_600};
  font-weight: 400;
`;

export const GenericModal: FunctionComponent<CommonModalProps> = ({
  isVisible,
  toggleModal,
  headerText,
  bodyText,
  buttonText,
}: CommonModalProps) => {
  const modalRef = useCallback(setFocus, []);
  return (
    <ModalContainer
      showModal={isVisible}
      closeModal={() => toggleModal()}
      modalHeight="auto"
    >
      <Modal>
        <StyledForm ref={modalRef} onSubmit={(event) => event.preventDefault()}>
          <Header>
            <div>
              <ModalCloseButton onClick={() => toggleModal()} />
            </div>
          </Header>
          <ModalBody>
            <GenericModalHeaderText tabIndex={-1}>
              {headerText}
            </GenericModalHeaderText>
            <GenericModalBodyText>{bodyText}</GenericModalBodyText>
          </ModalBody>

          <Footer>
            <CenteredFullButton onClick={() => toggleModal()}>
              {buttonText}
            </CenteredFullButton>
          </Footer>
        </StyledForm>
      </Modal>
    </ModalContainer>
  );
};

const EnableAudioModalText = styled.h1`
  margin-top: 5px;
  font-weight: 600;
  font-size: 2.6rem;
  text-align: center;
  color: ${(props) => props.theme.colors.neutral_800};
`;

const ModalTitle = styled.h1`
  margin-bottom: 8px;
  font-size: 2.6rem;
  line-height: 120%;

  text-align: center;

  color: ${(props) => props.theme.colors.neutral_800};
`;
const ModalParagraph = styled.p`
  font-size: 2rem;

  text-align: center;

  color: ${(props) => props.theme.colors.neutral_600};
`;

const ContinueLaterButton = ({
  continueLaterClicked,
  secondaryButtonText,
}: {
  continueLaterClicked: () => void;
  secondaryButtonText: string;
}) => {
  if (!continueLaterClicked || !secondaryButtonText) {
    return null;
  }

  return (
    <CenteredFullButton variant="secondary" onClick={continueLaterClicked}>
      {secondaryButtonText}
    </CenteredFullButton>
  );
};

export const Call911Modal: FunctionComponent<Call911ModalProps> = ({
  isVisible,
  toggleModal,
  bodyText,
  continueLaterClicked,
  imageSource,
  imageAlt,
  headerText,
  primaryButtonText,
  secondaryButtonText,
}: Call911ModalProps) => {
  const modalRef = useCallback(setFocus, []);
  return (
    <ModalContainer
      showModal={isVisible}
      closeModal={() => toggleModal()}
      modalHeight="auto"
    >
      <Modal>
        <StyledForm ref={modalRef} onSubmit={(event) => event.preventDefault()}>
          <Header>
            <ModalCloseButton onClick={() => toggleModal()} />
          </Header>
          <ModalBody>
            {imageSource && (
              <img width="102" height="102" src={imageSource} alt={imageAlt} />
            )}

            <EnableAudioModalText tabIndex={-1}>
              {headerText}
            </EnableAudioModalText>
            <GenericModalBodyText>{bodyText}</GenericModalBodyText>
          </ModalBody>

          <Footer style={{ flexDirection: 'column' }}>
            <CenteredFullButton
              onClick={() => toggleModal()}
              variant="primary"
              id="navigateToNextPage"
            >
              {primaryButtonText}
            </CenteredFullButton>
            <ContinueLaterButton
              continueLaterClicked={continueLaterClicked}
              secondaryButtonText={secondaryButtonText}
            />
          </Footer>
        </StyledForm>
      </Modal>
    </ModalContainer>
  );
};

export const ErrorModal: FunctionComponent<CommonModalProps> = ({
  isVisible,
  toggleModal,
}: CommonModalProps) => {
  const modalRef = useCallback(setFocus, []);
  return (
    <ModalContainer
      showModal={isVisible}
      closeModal={() => toggleModal()}
      modalHeight="auto"
    >
      <Modal>
        <StyledForm ref={modalRef} onSubmit={(event) => event.preventDefault()}>
          <Header>
            <ModalCloseButton onClick={() => toggleModal()} />
          </Header>
          <ModalBody>
            <img width="102" height="102" src={errorCheckmark} alt="Check" />

            <EnableAudioModalText tabIndex={-1}>
              Something went wrong.
              <br />
              Please try again.
            </EnableAudioModalText>
          </ModalBody>

          <Footer>
            <CenteredFullButton onClick={() => toggleModal()}>
              Ok
            </CenteredFullButton>
          </Footer>
        </StyledForm>
      </Modal>
    </ModalContainer>
  );
};

const CopyText = styled.p`
  border-radius: 4px;
  border: 1px solid #949494;
  color: ${(props) => props.theme.colors.neutral_600};
  text-align: left;
  padding: 15px;
  font-size: 1rem;
  font-style: italic;
  white-space: pre-line;
  margin-left: 10px;
  margin-right: 10px;
`;

const CopyTextHeading = styled.p`
  color: ${(props) => props.theme.colors.neutral_600};
  text-align: left;
  font-size: 0.75rem;
  margin-bottom: 5px;
  margin-left: 10px;
`;

const ShareTrainingHeaderText = styled(ModalBody)`
  font-weight: bold;
  font-size: 1.7rem;
  text-align: center;
  margin-bottom: 20px;
`;

export const ShareModal: FunctionComponent<ShareNotSupportedProps> = ({
  isVisible,
  toggleModal,
  shareData,
  copyTextPressed,
}: ShareNotSupportedProps) => {
  const [copyButtonText, setCopyButtonText] = useState<string | undefined>('');
  const [copyButtonColor, setCopyButtonColor] = useState<string>('');
  const modalRef = useCallback(setFocus, []);
  useEffect(() => {
    setCopyButtonText(shareData?.copyButtonText);
    setCopyButtonColor('');
  }, [isVisible]);
  return (
    <ModalContainer
      showModal={isVisible}
      closeModal={() => toggleModal()}
      modalHeight="auto"
    >
      <Modal>
        <form ref={modalRef} onSubmit={(event) => event.preventDefault()}>
          <Header>
            <ModalCloseButton
              onClick={() => {
                toggleModal();
              }}
            />
          </Header>
          <ModalBody>
            <ShareTrainingHeaderText tabIndex={-1}>
              {shareData?.header}
            </ShareTrainingHeaderText>
            <CopyTextHeading>{shareData?.subHeader}</CopyTextHeading>
            <CopyText>{shareData?.body}</CopyText>
          </ModalBody>

          <Footer>
            <CopyButton
              backgroundColor={copyButtonColor}
              color={copyButtonColor}
              disableHover={copyButtonText === shareData?.copiedButtonText}
              onClick={() => {
                if (copyTextPressed) copyTextPressed();
                setCopyButtonText(shareData?.copiedButtonText);
                setCopyButtonColor('#37a851');
              }}
            >
              {copyButtonText}
            </CopyButton>
          </Footer>
        </form>
      </Modal>
    </ModalContainer>
  );
};

const ContinueModalFooter = styled.section`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ContinueModalHeading = styled(ModalBody)`
  font-weight: bold;
  font-size: 1.7rem;
  text-align: center;
  margin-bottom: 0px;
`;

const ConituneModalSubHeading = styled.p`
  color: ${(props) => props.theme.colors.neutral_600};
  text-align: left;
  font-size: 1.25rem;
  margin-bottom: 20px;
`;

export const ContinueTrainingModal: FunctionComponent<ExtendedModalProps> = ({
  isVisible,
  toggleModal,
  continueButtonPressed,
}: ExtendedModalProps) => {
  const modalRef = useCallback(setFocus, []);
  return (
    <ModalContainer
      showModal={isVisible}
      closeModal={() => toggleModal()}
      modalHeight="auto"
    >
      <Modal>
        <form ref={modalRef} onSubmit={(event) => event.preventDefault()}>
          <Header>
            <ModalCloseButton
              onClick={() => {
                toggleModal();
              }}
            />
          </Header>
          <ModalBody>
            <ContinueModalHeading tabIndex={-1}>
              Camera could not be loaded
            </ContinueModalHeading>
            <ConituneModalSubHeading>
              Retry or restart your browser to fix the camera issue. If nothing
              works, you could continue without camera.
            </ConituneModalSubHeading>
          </ModalBody>

          <ContinueModalFooter>
            <LifeButton
              variant="primary"
              onClick={() => {
                if (continueButtonPressed) {
                  continueButtonPressed();
                }
              }}
            >
              Practise without camera
            </LifeButton>
          </ContinueModalFooter>
        </form>
      </Modal>
    </ModalContainer>
  );
};

interface TrainingOperatorModalProps extends CommonModalProps {
  onContinue: () => void;
  onRestart: () => void;
}

export const TrainingOperatorLoadSoundModal: FunctionComponent<
  TrainingOperatorModalProps
> = ({ isVisible, onContinue, onRestart }: TrainingOperatorModalProps) => {
  const modalRef = useCallback(setFocus, []);
  return (
    <ModalContainer
      showModal={isVisible}
      closeModal={() => {}}
      modalHeight="auto"
    >
      <Modal>
        <form ref={modalRef} onSubmit={(event) => event.preventDefault()}>
          <Header>
            <ModalCloseButton onClick={() => onContinue()} />
          </Header>
          <StyledModalBody>
            <ModalTitle tabIndex={-1}>Training paused</ModalTitle>
            <ModalParagraph>
              This portion of the training is playing automatically and it
              includes audio. You can continue where you left off or start over
              last practise section from the beginning.
            </ModalParagraph>
          </StyledModalBody>

          <StyledModalFooter verticalButtons>
            <LifeButton
              onClick={() => onContinue()}
              style={{ width: '100%' }}
              variant="primary"
            >
              Continue practise
            </LifeButton>
            <LifeButton
              onClick={() => onRestart()}
              style={{ width: '100%' }}
              variant="secondary"
            >
              Start over 999 call
            </LifeButton>
          </StyledModalFooter>
        </form>
      </Modal>
    </ModalContainer>
  );
};

const HelpModalHeading = styled.h1`
  font-weight: 700;
  font-style: normal;
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.neutral_800};
`;

const HelpModalSubHeading = styled.p`
  color: ${(props) => props.theme.colors.neutral_600};
  text-align: center;
  font-size: 1.9rem;
  margin-bottom: 20px;
  text-align: ${(props) => props.theme.textAlignment};
`;
const AedPadsHelpModalImg = styled.img`
  max-width: 182px;
  max-height: 121px;
`;

export const AedPadsHelpModal: FunctionComponent<AedPadsHelpModalProps> = ({
  isVisible,
  toggleModal,
  modalData,
  practiseNowClicked,
  whereToPlacePadsClicked,
}: AedPadsHelpModalProps) => {
  const modalRef = useCallback(setFocus, []);
  return (
    <ModalContainer
      id="AedPadsHelpModal"
      showModal={isVisible}
      closeModal={() => toggleModal()}
      modalHeight="auto"
    >
      <Modal>
        <StyledForm ref={modalRef} onSubmit={(event) => event.preventDefault()}>
          <Header>
            <ModalCloseButton onClick={() => toggleModal()} />
          </Header>
          <ModalBody>
            <AedPadsHelpModalImg
              src={modalData?.iconImage?.src ?? Pads}
              alt={modalData?.iconImage?.alt ?? 'Pads'}
            />
            <HelpModalHeading tabIndex={-1}>
              {modalData?.headingText}
            </HelpModalHeading>
            <HelpModalSubHeading>{modalData?.bodyText}</HelpModalSubHeading>
          </ModalBody>

          <Footer style={{ flexDirection: 'column' }}>
            <CenteredFullButton
              variant="primary"
              id="whereToPlacePadsButton"
              onClick={whereToPlacePadsClicked}
            >
              {modalData?.buttons.primaryButtonText ??
                defaultText.aedPadButtonText.padHelpModal.primary}
            </CenteredFullButton>
            <CenteredFullButton
              variant="secondary"
              onClick={practiseNowClicked}
            >
              {modalData?.buttons.secondaryButtonText ??
                defaultText.aedPadButtonText.padHelpModal.secondary}
            </CenteredFullButton>
          </Footer>
        </StyledForm>
      </Modal>
    </ModalContainer>
  );
};

export const RolloutPackageModal: FunctionComponent<CommonModalProps> = ({
  isVisible,
  toggleModal,
  headerText,
  bodyText,
  buttonText,
  isButtonEnabled = true,
}: CommonModalProps) => {
  const modalRef = useCallback(setFocus, []);
  const themeContext = useTheme();
  const colors = themeContext.getColors();
  return (
    <ModalContainer
      showModal={isVisible}
      closeModal={() => toggleModal()}
      modalHeight="auto"
    >
      <Modal>
        <StyledForm ref={modalRef} onSubmit={(event) => event.preventDefault()}>
          <Header>
            <ModalCloseButton onClick={() => toggleModal()} />
          </Header>
          <ModalBody>
            {isButtonEnabled ? (
              <img width="102" height="102" src={checkmark} alt="Check mark" />
            ) : (
              <LoadingIndicator size={Size.Medium} color={colors.neutral_500} />
            )}

            <ModalTitle tabIndex={-1}>{headerText}</ModalTitle>
            <ModalParagraph>{bodyText}</ModalParagraph>
          </ModalBody>

          <Footer>
            <CenteredFullButton
              disabled={!isButtonEnabled}
              onClick={() => toggleModal()}
            >
              {buttonText}
            </CenteredFullButton>
          </Footer>
        </StyledForm>
      </Modal>
    </ModalContainer>
  );
};

export const LanguageSelectionModal: FunctionComponent<
  LanguageSelectionModalProps
> = ({
  isVisible,
  toggleModal,
  headerText = 'Change language',
  buttonText = 'Continue',
  languageList,
}: LanguageSelectionModalProps) => {
  const modalRef = useCallback(setFocus, []);
  const [languageSelected, setLanguageSelected] = useState(false);
  const [SelectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    const language = LocalStorageService.getLanguageCodeFromLocalStorage();
    if (language) {
      setSelectedLanguage(language);
    }
  }, []);

  return (
    <LanguageModalContainer
      showModal={isVisible}
      closeModal={() => toggleModal()}
    >
      <Modal>
        <StyledForm ref={modalRef} onSubmit={(event) => event.preventDefault()}>
          <Header>
            <ModalCloseButton onClick={() => toggleModal()} />
          </Header>
          <ModalBody>
            <ModalTitle tabIndex={-1}>{headerText}</ModalTitle>
            <DropDown>
              <BasicDropdown
                id="LanguageSelectionBasicDropdown"
                list={languageList}
                size={Size.Medium}
                placeholder="Select here..."
                value={SelectedLanguage}
                onSelect={(value) => {
                  if (value !== SelectedLanguage) {
                    setSelectedLanguage(value);
                    setLanguageSelected(true);
                  }
                }}
                messageOnNoResults="No results found"
                minWidth="280px"
              />
            </DropDown>
          </ModalBody>
          <LanguageStyledModalFooter>
            <CenteredFullButton
              disabled={!languageSelected}
              onClick={() => toggleModal(SelectedLanguage)}
            >
              {buttonText}
            </CenteredFullButton>
          </LanguageStyledModalFooter>
        </StyledForm>
      </Modal>
    </LanguageModalContainer>
  );
};
