/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable max-classes-per-file */
import AdaptiveFilter from './AdaptiveFilter';
import Compression from './Compression';
import GlobalVariables from './GlobalVariables';

class ScoreFilter {
  private adaptiveFilter: AdaptiveFilter = new AdaptiveFilter();
  public allCompressions: Array<Array<Compression>> = new Array<
    Array<Compression>
  >();
  public currentSessionId = 0;
  private sequenceNumber = 0;

  constructor(sessionId: number) {
    this.currentSessionId = sessionId;
  }

  public filterAndSubmitAsync(rate: number, milliSecondsElapsed: number): void {
    try {
      const filteredFrequence = this.adaptiveFilter.filterPeaks(rate);
      this.adaptiveFilter.filterAdaptive();
      const result =
        this.adaptiveFilter.checkIfStableLowRate(filteredFrequence);
      // Sjekker om holding-frekvenser har vært under 60 i rate.
      if (!result.IsSingleFrequency) {
        if (result.HistoricalFrequencies.length < 10) {
          for (let i = 0; i < result.HistoricalFrequencies.length; i += 1) {
            if (result.HistoricalFrequencies[i] < 60) {
              result.HistoricalFrequencies[i] = -1;
            }
          }
        }
      }
      const compressions = new Array<Compression>();
      if (result.IsSingleFrequency) {
        if (!GlobalVariables.globalIsTrustingLowFrequency) {
          if (result.SingleFrequency < 70 && result.SingleFrequency > 0) {
            result.SingleFrequency = -1;
          }
        }
        compressions.push(
          this.createCompression(result.SingleFrequency, milliSecondsElapsed),
        );
        GlobalVariables.compressionRate = result.SingleFrequency;
      } else {
        GlobalVariables.compressionRate =
          result.HistoricalFrequencies[result.HistoricalFrequencies.length - 1];

        for (let i = 0; i < result.HistoricalFrequencies.length; i += 1) {
          compressions.push(
            this.createCompression(
              result.HistoricalFrequencies[i],
              milliSecondsElapsed,
            ),
          );
        }
      }

      this.allCompressions.push(compressions);
    } catch (exception: any) {
      console.error(
        'Failed while filtering and submitting compressions.',
        exception,
      );
    }
  }

  private createCompression(
    singleFrequency: number,
    milliSecondsElapsed: number,
  ): Compression {
    const compression = new Compression(
      singleFrequency,
      this.currentSessionId,
      (this.sequenceNumber += 1),
      milliSecondsElapsed,
      !GlobalVariables.globalScepticalFrequency,
    );

    return compression;
  }
}

export default ScoreFilter;
