import React, { useEffect, useRef, useState } from 'react';
import Ambulance from '../../components/AmbulanceAnimation';
import { ModalProps } from '../../components/Modals';
import ModalForGrid from '../../components/Modals/Modal';
import { ContinueTrainingModal } from '../../components/Modals/Modals';
import Lottie from '../../components/ReactLottie/index';
import { AnimationState } from '../../components/TopBars/types';
import constants from '../../constants/Constants';
import {
  useAudio,
  useCamera,
  useHeightViewport,
  useTcprLink,
  useViewport,
} from '../../context';
import {
  FireBaseCollections,
  useFirebase,
} from '../../context/FirebaseProvider';
import { useSanityCourse } from '../../context/sanity/useSanityCourse';
import animationData from '../../cpr-sideView.json';
import SessionResult from '../../features/cprAlgorithm/SessionResult';
import SanityModal from '../../features/sanity/sanityModal';
import {
  AllowCameraPermissionMessage,
  SanityLottieOptions,
  TrainingIntroMessages,
  TrainingPageBottomPart,
  TrainingPageCentralPart,
} from '../../features/sanity/types';
import { SessionResultExtended } from '../../helper';
import { useNavigation } from '../../hooks';
import useAudioAutoplay from '../../hooks/useAudioAutoplay';
import {
  AudioProps,
  ResultData,
  TrainingResultPages,
} from '../types/courseTypes';
import { TrainingStartSequence } from './Components';
import * as TrainingPageStyles from './StyledComponents';
import TrainingContent from './TrainingContent';
import { MainText, OperatorImage } from './TrainingWithCounterPage';

const {
  BottomPart,
  CenterOverlay,
  CenterPart,
  LottieContainer,
  PageGridStyle,
  ProgressbarContainer,
} = TrainingPageStyles;

interface TrainingPageProps {
  allowCameraPermissionMessage?: AllowCameraPermissionMessage;
  bottomPart?: TrainingPageBottomPart;
  centralPart?: TrainingPageCentralPart;
  audioProps?: AudioProps;
  introMessages?: TrainingIntroMessages;
  lottieOptions?: SanityLottieOptions;
  resultPages?: TrainingResultPages;
  showAmbulance?: boolean;
  showTrainingResult?: (resultData: ResultData) => void;
  trainingDurationInSeconds?: number;
}

const TrainingPage: React.FunctionComponent<TrainingPageProps> = ({
  allowCameraPermissionMessage,
  audioProps = { enableAudio: false, audioDelay: 2 },
  bottomPart,
  centralPart,
  introMessages,
  lottieOptions,
  resultPages,
  showAmbulance = true,
  showTrainingResult,
  trainingDurationInSeconds,
}: TrainingPageProps) => {
  const [receivedRate, setReceivedRate] = useState<boolean>(false);
  const [onRefreshModal, setOnRefreshModal] = useState<
    ModalProps | undefined
  >();
  const [showContinueModal, setShowContinueModal] = useState<boolean>(false);
  const { isLandscape } = useViewport();
  const { innerHeight } = useHeightViewport();
  const { storeTrainingData, storeGAEvent } = useFirebase();
  const courseContext = useSanityCourse();
  const ctx = useCamera();
  const navigationContext = useNavigation();
  const tcprLinkContext = useTcprLink();
  const audio = useAudio();
  const timerRefTraining = useRef<NodeJS.Timeout | null>(null);
  const timerRefNoRate = useRef<NodeJS.Timeout | null>(null);
  const trainingDurationInMs =
    trainingDurationInSeconds === undefined
      ? 60 * 1000
      : trainingDurationInSeconds * 1000;

  // This is needed since we do not check permission at the start of the course. Can be removed when that check is done
  useEffect(() => {
    if (!ctx?.hasCameraPermission) {
      ctx?.checkCameraPermissionAsync();
    }
  }, []);

  useEffect(() => {
    if (ctx?.hasCameraPermission) {
      tcprLinkContext?.load();
    }
  }, [ctx?.hasCameraPermission]);

  const [overlayTimeout, setOverlayTimeout] = useState(false);

  const OverlayTimeoutFunc = () => {
    setOverlayTimeout(true);
    tcprLinkContext?.start();
    timerRefTraining.current = setTimeout(() => {
      tcprLinkContext?.stop();
    }, trainingDurationInMs);

    // timeout that will display a error message if the camera algoritm have not received a rate
    timerRefNoRate.current = setTimeout(() => {
      if (ctx?.hasCameraPermission && !receivedRate) {
        setShowContinueModal(true);
        storeGAEvent({
          category: 'CameraLoading',
          action: 'NoRateReceived',
          label: window.location.pathname,
        });
      }
    }, constants.cameraLoadingTimeoutMs);
  };

  // clear timeout of the reset rate error message.
  useEffect(() => {
    if (receivedRate && timerRefNoRate.current) {
      clearTimeout(timerRefNoRate.current as NodeJS.Timeout);
    }
  }, [receivedRate, timerRefNoRate.current]);

  useEffect(() => {
    if (
      tcprLinkContext !== null &&
      tcprLinkContext?.rate >= -1 &&
      !receivedRate
    ) {
      setReceivedRate(true);
    }
  }, [tcprLinkContext?.rate, receivedRate]);

  // Modal Functions
  const dismissOnRefreshModal = () => {
    if (audioProps?.enableAudio) {
      audio?.initCurrentSection();
    }
    setOnRefreshModal(undefined);
  };
  const createOnRefreshModal = () => {
    if (
      (centralPart === TrainingPageCentralPart.CountdownWithSound ||
        audioProps?.enableAudio) &&
      navigationContext &&
      navigationContext.initialPage === window.location.pathname &&
      audio?.doesSectionContainAudio()
    ) {
      const newModal = SanityModal.createContinueCourseModal(
        courseContext,
        dismissOnRefreshModal,
        navigationContext,
      );
      setOnRefreshModal(newModal);
    }
  };

  useEffect(() => {
    if (audioProps?.enableAudio) {
      createOnRefreshModal();
    }
  }, []);

  const SetTrainingResults = (
    sessionResultParsed: SessionResult,
    hasCameraPermission?: boolean,
  ) => {
    if (resultPages && showTrainingResult) {
      if (hasCameraPermission === false) {
        showTrainingResult(resultPages.debriefWithNoCameraAccess);
      } else if (
        sessionResultParsed.totalTimeWithUnknownRate === null ||
        sessionResultParsed.averageCompressionRateOfRatesAboveZero === null ||
        sessionResultParsed.totalTimeWithUnknownRate > 30
      ) {
        showTrainingResult(resultPages.couldNotDetect);
      } else if (
        sessionResultParsed.averageCompressionRateOfRatesAboveZero > 130 ||
        sessionResultParsed.averageCompressionRateOfRatesAboveZero < 89
      ) {
        showTrainingResult(resultPages.badRate);
      } else if (
        sessionResultParsed.averageCompressionRateOfRatesAboveZero > 120
      ) {
        showTrainingResult(resultPages.compressSlower);
      } else if (
        sessionResultParsed.averageCompressionRateOfRatesAboveZero < 100
      ) {
        showTrainingResult(resultPages.compressFaster);
      } else {
        showTrainingResult(resultPages.goodRate);
      }
    }
  };

  const defaultOptions = {
    animationData: !lottieOptions?.path ? animationData : undefined,
    autoplay: lottieOptions?.autoplay ?? true,
    loop: lottieOptions?.loop ?? true,
    path: lottieOptions?.path,
    rendererSettings: {},
  };

  // If the camera does not load
  const continueButtonPressed = () => {
    clearTimeout(timerRefTraining.current as NodeJS.Timeout);
    clearTimeout(timerRefNoRate.current as NodeJS.Timeout);
    setShowContinueModal(false);
    setOverlayTimeout(false);
    storeGAEvent({
      event: {
        category: 'CameraLoading',
        action: 'TrainingWithoutCamera',
        label: window.location.pathname,
      },
    });
  };

  // Save score to firebase and navigate
  useEffect(() => {
    if (tcprLinkContext?.sessionResult === '') {
      return;
    }

    if (!ctx || ctx === null) {
      navigationContext?.navigateToUrl('/412');
    }

    if (!tcprLinkContext?.sessionResult || !overlayTimeout) {
      return;
    }

    const sessionResultParsed: SessionResultExtended = JSON.parse(
      tcprLinkContext?.sessionResult,
    );
    storeTrainingData(
      FireBaseCollections.sessionResults,
      sessionResultParsed,
      ctx?.hasCameraPermission,
    );

    SetTrainingResults(
      sessionResultParsed.sessionResult,
      ctx?.hasCameraPermission,
    );
  }, [tcprLinkContext?.sessionResult]);
  useAudioAutoplay(audioProps, audio);
  return (
    <PageGridStyle height={innerHeight}>
      {onRefreshModal && (
        <ModalForGrid
          bannerImage={onRefreshModal.bannerImage}
          buttons={onRefreshModal.buttons}
          onClose={onRefreshModal.onClose}
          text={onRefreshModal.text}
          verticalButtons
        />
      )}
      <ProgressbarContainer>
        {overlayTimeout && showAmbulance && (
          <Ambulance
            animationState={
              onRefreshModal ? AnimationState.paused : AnimationState.running
            }
            animationDurationInSeconds={trainingDurationInSeconds ?? 60}
            animationDelayInSeconds={0}
            start
          />
        )}
      </ProgressbarContainer>
      <CenterPart>
        {centralPart === TrainingPageCentralPart.CompressionAnimation && (
          <LottieContainer>
            <Lottie
              aria-label="Perform compressions animation"
              options={defaultOptions}
            />
          </LottieContainer>
        )}
        {centralPart === TrainingPageCentralPart.CountdownWithSound && (
          <>
            <OperatorImage
              src={introMessages?.emergencyOperatorImagePath} // TODO: Map image correctly.
              width={48}
              height={48}
              alt="Operator" // TODO: Get alt from Sanity.
            />
            <MainText>{introMessages?.emergencyOperatorHeading}</MainText>
          </>
        )}
      </CenterPart>
      <CenterOverlay>
        <ContinueTrainingModal // Displayed when Camera did not load
          isVisible={showContinueModal}
          toggleModal={() => setShowContinueModal(false)}
          continueButtonPressed={continueButtonPressed}
        />
        <TrainingStartSequence
          isLoaded={tcprLinkContext !== null}
          OverlayTimeout={OverlayTimeoutFunc}
          introMessages={introMessages?.messages}
          trainingStartsIn={introMessages?.trainingStartsIn}
        />
      </CenterOverlay>
      <BottomPart>
        <TrainingContent
          trainingWithSpeedometer={bottomPart?.left === 'speedometer'}
          displayVideoFeed={bottomPart?.right === 'videoFeed'}
          speedometer={bottomPart?.speedometer}
          feedbackTexts={bottomPart?.feedbackTexts}
          allowCameraPermissionMessage={allowCameraPermissionMessage}
          height={
            isLandscape
              ? constants.video.sizes.trainingVideoFrameSize.height
              : undefined
          }
        />
      </BottomPart>
    </PageGridStyle>
  );
};

export default TrainingPage;
