import { WithCauseError } from '../services/errorHandling/errorHelper';

class CameraDoesNotExistError extends WithCauseError {
  constructor(message: string, cause) {
    super(message, cause);
    this.name = 'CameraDoesNotExistError';
  }
}
class CameraPermissionDeniedError extends WithCauseError {
  constructor(message: string, cause) {
    super(message, cause);
    this.name = 'CameraPermissionDeniedError';
  }
}

export function translatePermissionDeniedErrorToCameraPermissionDeniedError(
  error: Error,
) {
  if (!error || !error.message) {
    throw new Error('Error is not valid');
  }
  if (
    error.message.includes('Requested device not found') || // Chromium
    error.message.includes('The object can not be found here.') // Firefox
  ) {
    const cameraDoesNotExistError = new CameraDoesNotExistError(
      'The user does not have a camera',
      error,
    );
    return cameraDoesNotExistError;
  }

  if (
    error.message.includes('Permission denied') || // Chromium
    error.message.includes('request is not allowed') // Safari and Firefox
  ) {
    const cameraPermissionDeniedError = new CameraPermissionDeniedError(
      'Camera permission denied by user or browser',
      error,
    );
    return cameraPermissionDeniedError;
  }

  return error;
}
