/* eslint-disable no-continue */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-classes-per-file */

import GlobalVariables from './GlobalVariables';

class FrequencyResult {
  public IsSingleFrequency: boolean;

  public SingleFrequency: number;

  public HistoricalFrequencies: Array<number>;

  constructor() {
    this.SingleFrequency = 0;
    this.IsSingleFrequency = false;
    this.HistoricalFrequencies = new Array<number>();
  }

  setHistoricalFrequencies(frequencies: Array<number>): void {
    this.IsSingleFrequency = false;
    this.HistoricalFrequencies = frequencies;
  }

  setFrequency(frequency: number): void {
    this.IsSingleFrequency = true;
    this.SingleFrequency = frequency;
  }
}

class AdaptiveFilter {
  lastDisplayFrequency: number;

  secondLastDisplayFrequency: number;

  thirdLastDisplatFrequency: number;

  holdingFrequencyNumber: number;

  isHolding: boolean;

  private lastTenFrequencies: Array<number>;

  private holdingFrequencies: Array<number>;

  private isTrustingLowFrequency: boolean;

  constructor() {
    this.holdingFrequencies = new Array<number>();
    this.lastTenFrequencies = new Array<number>();
    this.lastDisplayFrequency = -5;
    this.secondLastDisplayFrequency = -5;
    this.thirdLastDisplatFrequency = -5;
    this.holdingFrequencyNumber = 0;
    this.isHolding = false;
    this.isTrustingLowFrequency = false;
  }

  filterPeaks(newFrequency: number): FrequencyResult {
    if (this.lastDisplayFrequency === -5) {
      this.lastDisplayFrequency = -4;
      const ret: FrequencyResult = new FrequencyResult();
      ret.setFrequency(newFrequency);
      this.addToLastTenFrequencies(ret, this.lastTenFrequencies);
      return ret;
    }

    if (this.lastDisplayFrequency === -4) {
      this.secondLastDisplayFrequency = this.lastDisplayFrequency;
      this.lastDisplayFrequency = -3;
      const ret: FrequencyResult = new FrequencyResult();
      ret.setFrequency(newFrequency);
      this.addToLastTenFrequencies(ret, this.lastTenFrequencies);
      return ret;
    }

    if (this.lastDisplayFrequency === -3) {
      this.thirdLastDisplatFrequency = this.secondLastDisplayFrequency;
      this.secondLastDisplayFrequency = this.lastDisplayFrequency;
      this.lastDisplayFrequency = newFrequency;
      const ret: FrequencyResult = new FrequencyResult();
      ret.setFrequency(newFrequency);
      this.addToLastTenFrequencies(ret, this.lastTenFrequencies);
      return ret;
    }

    if (
      !this.isHolding &&
      Math.abs(this.lastDisplayFrequency - this.secondLastDisplayFrequency) <
        15 &&
      Math.abs(this.lastDisplayFrequency - this.thirdLastDisplatFrequency) <
        20 &&
      Math.abs(this.lastDisplayFrequency - newFrequency) > 30
    ) {
      this.holdingFrequencies.push(newFrequency);
      this.holdingFrequencyNumber += 1;
      this.isHolding = true;
      const ret: FrequencyResult = new FrequencyResult();
      ret.setFrequency(this.lastDisplayFrequency);
      this.addToLastTenFrequencies(ret, this.lastTenFrequencies);
      return ret;
    }

    if (
      this.isHolding &&
      Math.abs(this.lastDisplayFrequency - newFrequency) > 20 &&
      this.holdingFrequencyNumber < 3
    ) {
      this.holdingFrequencies.push(newFrequency);
      this.holdingFrequencyNumber += 1;
      const ret: FrequencyResult = new FrequencyResult();
      ret.setFrequency(this.lastDisplayFrequency);
      this.addToLastTenFrequencies(ret, this.lastTenFrequencies);
      return ret;
    }

    if (
      this.isHolding &&
      Math.abs(this.lastDisplayFrequency - newFrequency) > 20 &&
      this.holdingFrequencyNumber >= 3
    ) {
      this.isHolding = false;
      this.holdingFrequencies.push(newFrequency);
      const frequencyHistory = this.holdingFrequencies;

      this.holdingFrequencies.splice(0, this.holdingFrequencies.length);
      const ret: FrequencyResult = new FrequencyResult();
      ret.setHistoricalFrequencies(frequencyHistory);
      this.addToLastTenFrequencies(ret, this.lastTenFrequencies);
      this.thirdLastDisplatFrequency = this.secondLastDisplayFrequency;
      this.secondLastDisplayFrequency = this.lastDisplayFrequency;
      this.lastDisplayFrequency = newFrequency;
      this.holdingFrequencyNumber = 0;
      return ret;
    }

    this.holdingFrequencies.splice(0, this.holdingFrequencies.length);
    this.isHolding = false;
    this.thirdLastDisplatFrequency = this.secondLastDisplayFrequency;
    this.secondLastDisplayFrequency = this.lastDisplayFrequency;
    this.lastDisplayFrequency = newFrequency;
    this.holdingFrequencyNumber = 0;
    const result: FrequencyResult = new FrequencyResult();
    result.setFrequency(newFrequency);
    this.addToLastTenFrequencies(result, this.lastTenFrequencies);
    return result;
  }

  private addToLastTenFrequencies(
    result: FrequencyResult,
    listToUse: Array<number>,
  ): void {
    if (result.IsSingleFrequency) {
      listToUse.push(result.SingleFrequency);
    } else {
      let listToUseOffset = 2;
      for (let i = result.HistoricalFrequencies.length - 1; i >= 0; i -= 1) {
        if (i === result.HistoricalFrequencies.length - 1) {
          listToUse.push(
            result.HistoricalFrequencies[
              result.HistoricalFrequencies.length - 1
            ],
          );
          continue;
        }
        if (listToUse.length - 1 < 0) {
          listToUse.splice(0, 0, result.HistoricalFrequencies[i]);
        } else {
          listToUse[listToUse.length - listToUseOffset] =
            result.HistoricalFrequencies[i];
          listToUseOffset += 1;
        }
      }
    }

    while (listToUse.length > 10) {
      listToUse.splice(0, 1);
    }
  }

  public filterAdaptive(): void {
    if (this.lastTenFrequencies.length < 5) {
      return;
    }

    const listSize = this.lastTenFrequencies.length;
    const newFrequency = this.lastTenFrequencies[listSize - 1];
    const lastFrequencyReturned = this.lastTenFrequencies[listSize - 2];
    const secondToLastFrequencyReturned = this.lastTenFrequencies[listSize - 3];
    const thirdToLastFrequencyReturned = this.lastTenFrequencies[listSize - 4];

    let frequencyToReturn: number;

    if (
      Math.abs(lastFrequencyReturned - newFrequency) < 10 &&
      Math.abs(secondToLastFrequencyReturned - newFrequency) < 10 &&
      Math.abs(thirdToLastFrequencyReturned - newFrequency) < 10
    ) {
      frequencyToReturn = Math.trunc(
        newFrequency * 0.25 +
          lastFrequencyReturned * 0.25 +
          secondToLastFrequencyReturned * 0.25 +
          thirdToLastFrequencyReturned * 0.25,
      );
    } else if (
      Math.abs(lastFrequencyReturned - newFrequency) < 10 &&
      Math.abs(secondToLastFrequencyReturned - newFrequency) < 10
    ) {
      frequencyToReturn = Math.trunc(
        newFrequency * 0.34 +
          lastFrequencyReturned * 0.33 +
          secondToLastFrequencyReturned * 0.33,
      );
    } else if (Math.abs(lastFrequencyReturned - newFrequency) < 10) {
      frequencyToReturn = Math.trunc(
        newFrequency * 0.5 + lastFrequencyReturned * 0.5,
      );
    } else {
      frequencyToReturn = newFrequency;
    }
    this.lastTenFrequencies[this.lastTenFrequencies.length - 1] =
      frequencyToReturn;
  }

  public checkIfStableLowRate(input: FrequencyResult): FrequencyResult {
    const newFrequency = input.IsSingleFrequency
      ? input.SingleFrequency
      : input.HistoricalFrequencies[input.HistoricalFrequencies.length - 1];
    if (this.isTrustingLowFrequency) {
      if (
        Math.abs(
          this.lastTenFrequencies[this.lastTenFrequencies.length - 1] -
            newFrequency,
        ) < 15
      ) {
        input.IsSingleFrequency = true;
        input.SingleFrequency = newFrequency;

        if (newFrequency > 80 || newFrequency < 30) {
          this.isTrustingLowFrequency = false;
        }
      } else {
        this.isTrustingLowFrequency = false;
      }
    }

    if (newFrequency > 70 || newFrequency < 30) {
      return input;
    }

    let counter = 0;

    if (!this.isTrustingLowFrequency) {
      for (let i = 0; i < this.lastTenFrequencies.length; i += 1) {
        if (Math.abs(this.lastTenFrequencies[i] - newFrequency) < 15) {
          counter += 1;
        }
      }
    }

    if (counter === 10) {
      input.setHistoricalFrequencies(this.lastTenFrequencies);
      this.isTrustingLowFrequency = true;

      input.IsSingleFrequency = false;
    }

    GlobalVariables.globalIsTrustingLowFrequency = this.isTrustingLowFrequency;
    return input;
  }
}
export default AdaptiveFilter;
