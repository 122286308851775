import viablePaths from './viablePaths';

interface PathProps {
  firstPartOfPath: string;
  lastPartOfPath: string;
  mainPath: string;
}

const lastPartOfPath =
  window.location.pathname.split('/').length >= 3
    ? window.location.pathname.split('/').pop() ?? ''
    : window.location.pathname.slice(1);
const firstPartOfPath =
  window.location.pathname.split('/').length >= 3
    ? `${window.location.pathname.split('/')[1]}`
    : window.location.pathname.slice(1);
const mainPath =
  viablePaths().find((path) => path.slice(1) === firstPartOfPath) ?? '';

export const pathHelper: PathProps = {
  lastPartOfPath,
  firstPartOfPath,
  mainPath,
};
