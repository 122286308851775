/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { getQuerystringParameter } from './UrlHelper';
import checkForInsertedDevOpsVariable from './checkForInsertedDevOpsVariable';

/**
 * This is a helper class for the automatic navigation feature. It will check if the test mode is enabled, and if so, will return the value from the test mode config. Otherwise, it will return the fallback value
 */
const autoNavigationHelper = {
  isTestMode() {
    return !!window.config.automaticTestMode?.isTestMode;
  },

  defaultDelay: 10,

  /**
   *  This will check if the test mode is enabled, and if so, will return the value from the test mode config.
   *  Otherwise, it will return the fallback value or defaultDelay if the fallback value is null
   * @param fallbackValue A number or undefined, representing a fallback value.
   * @returns A number representing the auto play delay.
   */
  autoPlayDelay(fallbackValue: number | undefined) {
    if (this.isTestMode()) {
      return (
        window.config.automaticTestMode?.autoPlayDelay ??
        fallbackValue ??
        autoNavigationHelper.defaultDelay
      );
    }
    return fallbackValue || autoNavigationHelper.defaultDelay;
  },

  /**
   * This will check if the test mode is enabled, and if so, will return the value from the test mode config.
   * Otherwise, it will return the fallback value or defaultDelay if the fallback value is null
   * @param fallbackValue A number or undefined, representing a fallback value.
   * @returns A number representing the auto play duration.
   */
  autoPlayDuration(fallbackValue: number | undefined): number {
    if (this.isTestMode()) {
      return (
        window.config.automaticTestMode?.autoPlayDuration ??
        fallbackValue ??
        autoNavigationHelper.defaultDelay
      );
    }
    return fallbackValue || autoNavigationHelper.defaultDelay;
  },

  /**
   *  This will check if the test mode is enabled, and if so, will return the value from the test mode config.
   *  Otherwise, it will return the fallback value or defaultDelay if the fallback value is null
   * @param fallbackValue A number or undefined, representing a fallback value.
   * @returns A number representing the camera training duration.
   */
  cameraTrainingDuration(fallbackValue: number | undefined): number {
    if (this.isTestMode()) {
      return (
        window.config.automaticTestMode?.cameraTrainingDuration ??
        fallbackValue ??
        autoNavigationHelper.defaultDelay
      );
    }
    return fallbackValue || autoNavigationHelper.defaultDelay;
  },
  /**
   * This sets up the test mode config, which is used for testing purposes only. Its used to speed up the auto play duration dev ops variables are used to set the values for the test mode config
   *
   */
  setupTestMode() {
    // check if there is a querystring parameter for test mode is present isTestMode. does not need to be true, just not null
    const isTestMode = getQuerystringParameter('testMode') !== null;
    if (isTestMode) {
      window.config.automaticTestMode = {
        isTestMode: true,
        autoPlayDuration: checkForInsertedDevOpsVariable(
          `#{TESTMODE_AUTO_PLAY_DURATION}#`,
          1,
        ),
        autoPlayDelay: checkForInsertedDevOpsVariable(
          `#{TESTMODE_AUTO_PLAY_DELAY}#`,
          1,
        ),
        cameraTrainingDuration: checkForInsertedDevOpsVariable(
          `#{TESTMODE_CAMERA_DURATION}#`,
          1,
        ),
      };

      // eslint-disable-next-line no-console
      console.info('Automatic test mode is enabled');
    }
  },
};

export default autoNavigationHelper;
