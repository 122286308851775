import React, { FunctionComponent, useEffect, useState } from 'react';

import WithFadeIn from '../../../../../components/WithFadeIn';
import WithFocus from '../../../../../components/WithFocus';
import { useSanityCourse } from '../../../../../context/sanity/useSanityCourse';
import InfoPage from '../../../../../pageTemplates/Templates/InfoPage';
import {
  LearnerPageWithTemplate,
  LearnerShowMyTeamTemplate,
} from '../../../../../pageTemplates/types/courseTypes';
import { SanityText } from '../../../../../pageTemplates/types/sanityTypes';
import { UseAbly } from '../../../context/AblyContext';

type PageProps = {
  template?: LearnerShowMyTeamTemplate | undefined;
};

const LearnerShowMyTeamPage: FunctionComponent<PageProps> = ({ template }) => {
  const [currentPage, setCurrentPage] = useState<LearnerShowMyTeamTemplate>(
    {} as LearnerShowMyTeamTemplate,
  );
  const sanityCourse = useSanityCourse();
  const [text, setText] = useState<SanityText>();
  const { getLearnerTeam, learnerTeamName } = UseAbly();

  useEffect(() => {
    const sanityPage = sanityCourse.getCurrentPage() as LearnerPageWithTemplate;
    const tempPage =
      template !== undefined ? template : sanityPage.learnerShowMyTeamTemplate;
    if (tempPage !== undefined) {
      const team = getLearnerTeam() ?? '';
      const sanityText: SanityText = {
        header: tempPage.text?.header ?? 'You are in Team',
        body: tempPage.text?.body ?? 'Find your teammate',
      };
      sanityText.header += ` ${team}`;
      setText(sanityText);
      setCurrentPage(tempPage);
    }
  }, []);

  useEffect(() => {
    if (learnerTeamName) {
      const sanityText: SanityText = {
        header: currentPage?.text?.header ?? 'You are in Team',
        body: currentPage?.text?.body ?? 'Find your teammate',
      };
      sanityText.header += ` ${learnerTeamName}`;
      setText(sanityText);
    }
  }, [learnerTeamName, currentPage]);

  return (
    <InfoPage image={currentPage?.image} text={text} showTimeLeftBar={false} />
  );
};
export default WithFadeIn(WithFocus(LearnerShowMyTeamPage));
