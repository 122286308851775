import {
  Checkbox,
  InputLabel,
  TextField,
} from '@laerdal/life-react-components';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import LifeButton from '../../components/Button';
import { ModalProps } from '../../components/Modals';
import Modal from '../../components/Modals/Modal';
import {
  FireBaseCollections,
  useFirebase,
  useHeightViewport,
} from '../../context';
import { isEmailValid } from '../../helper/ValidationHelper';
import containsIllegalCharacters from '../../helper/containsIllegalCharactersHelper';
import {
  DiplomaData,
  DiplomaTextInput,
  SendDiplomaAsync,
} from '../../services';
import { logError } from '../../services/errorHandling';
import { TimeLeftBar } from '../Templates/InfoPage';
import {
  ButtonContainer,
  PageGridStyle,
  ProgressbarContainer,
} from '../Templates/InfoPageStyles';
import { Button } from '../Templates/InfoPageTypes';
import { MainContentWrapper } from '../Templates/SimulationPageStyles';
import {
  DiplomaTemplate,
  SanityPrivacyPolicyText,
} from '../types/diplomaTemplateTypes';
import { SanityModal } from '../types/sanityTypes';
import { DiplomaModalTypes } from './DiplomaTypes';

const StyledPrivacyPolicyText = styled.p`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.neutral_500};
`;

const StyledLink = styled.a`
  text-decoration-line: underline;
  color: ${(props) => props.theme.colors.primary_600};
  :hover {
    color: ${(props) => props.theme.colors.primary_800};
  }
`;

const CheckboxContainer = styled.div`
  align-self: flex-start;
  label {
    color: ${(props) => props.theme.colors.black};
    line-height: 2rem;
    font-size: 1.4rem;
    padding: 1.4rem 0px !important;
  }
`;

const LabelAndFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputLabelContainer = styled.div`
  label {
    font-size: 1.2rem;
    line-height: 16px;
  }
  align-self: flex-start;
  margin-top: 8px;
`;
const StyledText = styled.main`
  h1 {
    margin: 16px 0 8px 0;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    text-align: center;
    color: ${(props) => props.theme.colors.neutral_800};
  }
  p {
    margin: 0;
    font-size: 1.8rem;
    line-height: 120%;
    text-align: center;
    color: ${(props) => props.theme.colors.neutral_600};
    margin-bottom: 8px;
  }
`;

const MainContentWrapperInGrid = styled(MainContentWrapper)`
  grid-column: 2 / -2;
  grid-row: 2 / 3;
  width: 100%;
  justify-content: flex-start;
`;
const PrivacyPolicyLink: FunctionComponent<{
  url: string;
  linkText: string;
}> = ({ url, linkText }) => {
  return (
    <StyledLink href={url} target="_blank">
      {linkText}
    </StyledLink>
  );
};

const PrivacyPolicyText = (privacyPolicyText: SanityPrivacyPolicyText) => {
  const { privacyText, url, linkText } = privacyPolicyText;
  if (!privacyText) {
    return null;
  }
  if (privacyText.indexOf('[LINK]') !== -1) {
    return (
      <StyledPrivacyPolicyText>
        {privacyText.substring(0, privacyText.indexOf('[LINK]'))}
        &nbsp;
        <PrivacyPolicyLink url={url} linkText={linkText} />
        &nbsp;
        {privacyText.split('[LINK]').pop()}
      </StyledPrivacyPolicyText>
    );
  }
  return <StyledPrivacyPolicyText>{privacyText}</StyledPrivacyPolicyText>;
};

interface CommunicationData {
  receiveTrainingReminders: boolean;
  receiveResearchCommunication: boolean;
}

type DiplomaTemplateProps = Omit<
  DiplomaTemplate,
  'successModal' | 'errorModal'
> & {
  modal?: ModalProps;
  showModal?: (
    modalType?: DiplomaModalTypes,
    sanityModal?: SanityModal,
  ) => void;
};

const validateFormField = (
  formTextField: DiplomaTextInput,
  newText: string,
) => {
  if (formTextField.isRequired && (!newText || newText.length === 0)) {
    return formTextField.validationErrorMessage;
  }
  if (
    formTextField.minimumLengthValidation &&
    (!newText || newText.length < formTextField.minimumLengthValidation)
  ) {
    return formTextField.validationErrorMessage;
  }
  if (
    formTextField.maximumLengthValidation &&
    newText.length > formTextField.maximumLengthValidation
  ) {
    return formTextField.validationErrorMessage;
  }
  if (containsIllegalCharacters(newText)) {
    return formTextField.validationErrorMessage;
  }
  if (formTextField.enableEmailValidation && !isEmailValid(newText)) {
    return formTextField.validationErrorMessage;
  }
  return '';
};

const DisplayModal: FunctionComponent<{
  modal: ModalProps | undefined;
}> = ({ modal }) => {
  if (modal) {
    return (
      <Modal
        bannerImage={modal.bannerImage}
        buttons={modal.buttons}
        image={modal.image}
        onClose={modal.onClose}
        text={modal.text}
        verticalButtons={modal.verticalButtons}
      />
    );
  }
  return null;
};

const DiplomaPageTemplate: FunctionComponent<DiplomaTemplateProps> = ({
  documentId,
  buttons,
  diplomaData,
  email,
  modal,
  privacyPolicyText = {
    privacyText: '',
    url: '',
    linkText: '',
  },
  receiveResearchCommunication,
  showModal,
  showTimeLeftBar = true,
  text = {
    header: '',
    body: '',
  },
  verticalButtons = false,
}) => {
  const { innerHeight } = useHeightViewport();
  const { storeCollectionNonTrackable } = useFirebase();
  const [isBusy, setIsBusy] = useState(false);
  const [errorString, setErrorString] = useState('');
  const [dynamicDiplomaData, setDynamicDiplomaData] = useState<
    DiplomaData | undefined
  >();
  const [communicationData, setCommunicationData] = useState<CommunicationData>(
    {
      receiveTrainingReminders: false,
      receiveResearchCommunication: false,
    },
  );

  const [dynamicDiplomaDataFocusedId, setDynamicDiplomaDataFocusedId] =
    useState<string | undefined>();

  useEffect(() => {
    setDynamicDiplomaData(diplomaData);
  }, [diplomaData]);

  const onSendDiploma = async () => {
    dynamicDiplomaData?.diplomaTexts?.forEach((dt) => {
      if (dt.todaysDate) {
        dt.text = moment().format(dt.dateFormat ?? 'DD.MM.YYYY');
      }
    });
    await SendDiplomaAsync(documentId, dynamicDiplomaData, email?.company);

    const data = dynamicDiplomaData?.diplomaTexts?.reduce((acc, dt) => {
      if (dt.formTextField) {
        acc[dt.formTextField.id] = dt.formTextField.value;
      }
      return acc;
    }, {});
    if (
      communicationData.receiveResearchCommunication ||
      communicationData.receiveTrainingReminders
    ) {
      const emailCollection = {
        ...communicationData,
        ...data,
      };
      storeCollectionNonTrackable(FireBaseCollections.email, emailCollection);
    }
  };

  const validateFields = (): boolean => {
    let allFieldsAreValid = true;
    if (!dynamicDiplomaData?.diplomaTexts) {
      return true;
    }
    const newDiplomaTexts = dynamicDiplomaData.diplomaTexts.map((dt) => {
      if (!dt.formTextField) {
        return dt;
      }

      const error = validateFormField(dt.formTextField, dt.formTextField.value);

      if (error) {
        allFieldsAreValid = false;
        return {
          ...dt,
          formTextField: {
            ...dt.formTextField,
            currentErrorMessage: error,
          },
        };
      }

      return dt;
    });

    setDynamicDiplomaData({
      ...dynamicDiplomaData,
      diplomaTexts: newDiplomaTexts,
    });

    return allFieldsAreValid;
  };

  return (
    <PageGridStyle height={innerHeight}>
      <ProgressbarContainer>
        <TimeLeftBar showTimeLeftBar={showTimeLeftBar} />
      </ProgressbarContainer>
      <MainContentWrapperInGrid>
        <StyledText>
          <h1>{text.header}</h1>
          <p>{text.body}</p>
        </StyledText>

        {dynamicDiplomaData?.diplomaTexts?.map((diplomaText) => {
          if (
            !diplomaText.formTextField ||
            !diplomaText.formTextField.addFieldToForm
          ) {
            return null;
          }
          return (
            <LabelAndFieldContainer key={diplomaText.formTextField.id}>
              <InputLabelContainer>
                <InputLabel
                  inputId={diplomaText.formTextField.id}
                  text={diplomaText.formTextField.label ?? ''}
                />
              </InputLabelContainer>
              <TextField
                id={diplomaText.formTextField.id}
                placeholder={diplomaText.formTextField.placeholder}
                value={diplomaText.formTextField.value}
                onFocus={(focused) => {
                  setDynamicDiplomaDataFocusedId(focused.currentTarget.id);
                }}
                onChange={(newText) => {
                  setDynamicDiplomaData({
                    ...dynamicDiplomaData,
                    diplomaTexts: dynamicDiplomaData?.diplomaTexts?.map(
                      (dt) => {
                        if (
                          dt.formTextField &&
                          dt.formTextField.id === dynamicDiplomaDataFocusedId
                        ) {
                          return {
                            ...dt,
                            text: newText,
                            formTextField: {
                              ...dt.formTextField,
                              value: newText,
                              currentErrorMessage: validateFormField(
                                dt.formTextField,
                                newText,
                              ),
                            },
                          };
                        }
                        return dt;
                      },
                    ),
                  });
                }}
                className={
                  diplomaText.formTextField.currentErrorMessage && errorString
                }
                validationMessage={
                  diplomaText.formTextField.currentErrorMessage
                }
              />
            </LabelAndFieldContainer>
          );
        })}

        {receiveResearchCommunication &&
          receiveResearchCommunication.displayReceiveResearchCommunicationCheckbox && (
            <CheckboxContainer>
              <Checkbox
                id="ReceiveResearchCommunication"
                selected={communicationData.receiveResearchCommunication}
                select={(selected) => {
                  setCommunicationData({
                    ...communicationData,
                    receiveResearchCommunication: selected,
                  });
                }}
                label={receiveResearchCommunication?.label}
              />
            </CheckboxContainer>
          )}

        {PrivacyPolicyText(privacyPolicyText)}
      </MainContentWrapperInGrid>
      <ButtonContainer
        role="navigation"
        aria-label="Main"
        verticalButtons={verticalButtons}
      >
        {buttons ? (
          buttons.map((button: Button, index: number) => {
            if (index === 0) {
              return (
                <LifeButton
                  loading={isBusy}
                  className=""
                  style={{ width: '100%' }}
                  variant={button.buttonType}
                  onClick={async () => {
                    if (!validateFields()) {
                      return;
                    }
                    setIsBusy(false);
                    try {
                      setIsBusy(true);
                      await onSendDiploma();
                      if (showModal) {
                        showModal('success');
                      }
                    } catch (exception: any) {
                      logError(exception);
                      if (showModal) {
                        showModal('error');
                      }
                    } finally {
                      setIsBusy(false);
                    }
                  }}
                >
                  {button.text}
                  {button.icon ? <button.icon size="18px" /> : ''}
                </LifeButton>
              );
            }
            return (
              <LifeButton
                key={`button_${button.buttonType}_${button.text}`}
                variant={button.buttonType}
                disabled={isBusy}
                onClick={async () => {
                  button.onClick();
                }}
              >
                {button.text}
                {button.icon ? <button.icon size="18px" /> : ''}
              </LifeButton>
            );
          })
        ) : (
          <p>please add buttons</p>
        )}
      </ButtonContainer>
      <DisplayModal modal={modal} />
    </PageGridStyle>
  );
};

export default DiplomaPageTemplate;
