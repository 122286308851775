/* eslint-disable import/prefer-default-export */

export function findIndexOfMax(
  values: number[],
  startPosition: number,
  endPosition: number,
): number {
  if (startPosition < 0) {
    startPosition = 0;
  }
  let currentMax = values[startPosition];
  let currentMaxIndex = startPosition;
  if (endPosition > values.length) {
    endPosition = values.length;
  }
  for (let i = 1 + startPosition; i <= endPosition; i += 1) {
    if (values[i] > currentMax) {
      currentMax = values[i];
      currentMaxIndex = i;
    }
  }
  return currentMaxIndex;
}

export function frequencyMagnitudeFromFFT(values: number[]): number[] {
  const absoluteValues: number[] = new Array(values.length / 2);

  for (
    let i = 0, j = 0;
    i < values.length && j < absoluteValues.length;
    i += 2, j += 1
  ) {
    absoluteValues[j] = Math.sqrt(
      values[i] * values[i] + values[i + 1] * values[i + 1],
    );
  }
  return absoluteValues;
}

export function firstIndexOf(
  valuesToSearch: number[],
  valueToSearchFor: number,
): number {
  for (let i = 0; i < valuesToSearch.length; i += 1) {
    if (valuesToSearch[i] === valueToSearchFor) {
      return i;
    }
  }
  return -1;
}

export function lastIndexOf(
  valuesToSearch: number[],
  valueToSearchFor: number,
): number {
  for (let i = valuesToSearch.length - 1; i >= 0; i -= 1) {
    if (valuesToSearch[i] === valueToSearchFor) {
      return i;
    }
  }
  return -1;
}

export function hanningWindow(length: number): number[] {
  const hanningWindowArray: number[] = [];
  const hanningWindowArrayLength = length;
  for (let i = 0; i < hanningWindowArrayLength; i += 1) {
    hanningWindowArray[i] =
      0.5 * (1 - Math.cos((2 * Math.PI * i) / (length - 1)));
  }
  return hanningWindowArray;
}

export function multiplyElementWise(a: number[], b: number[]): number[] {
  const result: number[] = [];
  for (let i = 0; i < a.length; i += 1) {
    result[i] = a[i] * b[i];
  }
  return result;
}

export function subtract(values: number[], toSubtract: number): number[] {
  for (let i = 0; i < values.length; i += 1) {
    values[i] -= toSubtract;
  }
  return values;
}

export function average(values: number[]): number {
  let addition = 0;
  for (let i = 0; i < values.length; i += 1) {
    addition += values[i];
  }
  return addition / values.length;
}

export function averageFromTo(
  values: number[],
  from: number,
  to: number,
): number {
  let addition = 0;
  for (let i = from; i < to; i += 1) {
    addition += values[i];
  }
  return addition / (to - from);
}

export function findPeakIndexes(
  values: number[],
  startIndex: number,
  endIndex: number,
  peakThreshold: number,
): number[] {
  const peaks: number[] = [];
  startIndex = Math.floor(startIndex);
  endIndex = Math.floor(endIndex);
  peakThreshold = Math.floor(peakThreshold);

  if (startIndex < 0 || endIndex > values.length) {
    return peaks;
  }
  for (let i = startIndex + 1; i <= endIndex - 1; i += 1) {
    if (
      values[i] > values[i - 1] &&
      values[i] > values[i + 1] &&
      values[i] > peakThreshold
    ) {
      peaks.push(i);
    }
  }
  return peaks;
}

export function pickClosest(peaksInRange: number[], index: number): number {
  let currentClosest = peaksInRange[0];
  for (let i = 1; i < peaksInRange.length; i += 1) {
    if (Math.abs(peaksInRange[i] - index) < Math.abs(currentClosest - index)) {
      currentClosest = peaksInRange[i];
    }
  }
  return currentClosest;
}

export function zeroPadToNewArray(
  values: number[],
  newLength: number,
): number[] {
  const result: number[] = new Array(newLength).fill(0);
  for (let i = 0; i < values.length; i += 1) {
    result[i] = values[i];
  }
  return result;
}

export function getFrequencyFromCurveInterpolation(
  frequencyMagnitudes: number[],
  peakIndex: number,
  nyquistFrequency: number,
  numberOfSamplesInFFTinput: number,
): number {
  const freqLeft = frequencyMagnitudes[peakIndex - 1];
  const freqMiddle = frequencyMagnitudes[peakIndex];
  const freqRight = frequencyMagnitudes[peakIndex + 1];

  const deltaM =
    (freqRight - freqLeft) / (2 * (2 * freqMiddle - freqRight - freqLeft));
  return Math.trunc(
    ((peakIndex + deltaM) /
      (numberOfSamplesInFFTinput / 2 / nyquistFrequency)) *
      60,
  );
}

export function absSum(sumOfChanges: number[]): number {
  let addition = 0;
  for (let i = 0; i < sumOfChanges.length; i += 1) {
    addition += Math.abs(sumOfChanges[i]);
  }
  return addition;
}
