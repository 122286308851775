/* eslint-disable no-underscore-dangle */
import learnerTemplateTypes from '../groupRevivr/learner/templates/types/learnerTemplateTypes';
import { LearnerNavigation } from './sanityTypes/navigationTypes';

export const findTargetLearnerNavigation = (
  learnerNavigation: LearnerNavigation,
  learnerPagesWithRoleSplitTemplate: LearnerNavigation | LearnerNavigation[],
): LearnerNavigation => {
  const isRoleSplitTemplate =
    learnerNavigation.template ===
    learnerTemplateTypes.LearnerRoleSplitTemplate;
  const isRoleSplitArray = Array.isArray(learnerPagesWithRoleSplitTemplate);
  const learnerNavigationWithLearnerRoleSplitTemplate =
    isRoleSplitTemplate && isRoleSplitArray
      ? learnerPagesWithRoleSplitTemplate.find(
          (item) => item._id === learnerNavigation._id,
        )
      : learnerPagesWithRoleSplitTemplate;
  return isRoleSplitTemplate
    ? (learnerNavigationWithLearnerRoleSplitTemplate as LearnerNavigation)
    : learnerNavigation;
};
