/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */

import {
  ButtonProps,
  Button as LifeButton,
  Size,
} from '@laerdal/life-react-components';
import React from 'react';
import styled, { css } from 'styled-components';
import { useFirebase, useTheme } from '../context';
import mediaMedium from '../pageTemplates/Templates/MediaMedium';

const OverrideButton = styled(LifeButton)<{
  font?: string;
  width?: string;
  backgroundColor?: string;
}>`
  border-radius: 100px;
  width: ${(props) => props.width || '100%'};
  ${(props) =>
    (props.variant === 'primary' || !props.variant) &&
    css`
      && .button-content {
        align-self: center;
        font-family: ${props.font};
        border-radius: 100px;
        background-color: ${props.backgroundColor || ''};
        border-color: ${props.backgroundColor || ''};
        font-size: ${props.size === Size.Large
          ? '2.2rem'
          : props.size === Size.Small
          ? '1.6rem'
          : '1.9rem'};
      }
      &:hover > div.button-content,
      &.hover-state > div.button-content {
        background-color: ${props.colorTheme === 'teal'
          ? props.theme.colors.accent1_700
          : props.colorTheme === 'dark'
          ? props.theme.colors.primary_100
          : props.theme.colors.primary_700};
        border-color: ${props.colorTheme === 'teal'
          ? props.theme.colors.accent1_700
          : props.colorTheme === 'dark'
          ? props.theme.colors.primary_100
          : props.theme.colors.primary_700};
        color: ${props.colorTheme === 'dark'
          ? props.theme.colors.primary_600
          : props.theme.colors.white};
      }
    `}

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      div.button-content {
        align-self: center;
        font-family: ${props.font};
        outline: none;
        color: ${props.colorTheme === 'teal'
          ? props.theme.colors.accent1
          : props.colorTheme === 'dark'
          ? props.theme.colors.white
          : props.theme.colors.primary};
        background-color: transparent;
        border-radius: 100px;
        box-shadow: inset 0 0 0 2px
          ${props.colorTheme === 'teal'
            ? props.theme.colors.accent1
            : props?.colorTheme === 'dark'
            ? props.theme.colors.white
            : props.theme.colors.primary};
        div svg path {
          fill: ${props.colorTheme === 'teal'
            ? props.theme.colors.accent1
            : props.theme.colors.primary};
        }
      }

      &:hover > div.button-content,
      &.hover-state > div.button-content {
        outline: none;
        color: ${props.colorTheme === 'teal'
          ? props.theme.colors.accent1_700
          : props.colorTheme === 'dark'
          ? props.theme.colors.primary_200
          : props.theme.colors.primary_700};
        box-shadow: inset 0 0 0 2px
          ${props.colorTheme === 'teal'
            ? props.theme.colors.accent1_700
            : props?.colorTheme === 'dark'
            ? props.theme.colors.primary_200
            : props.theme.colors.primary_700};
        background-color: transparent;
        div {
          svg {
            path {
              fill: ${props.colorTheme === 'teal'
                ? props.theme.colors.accent1_700
                : props.theme.colors.primary_700};
            }
          }
        }
      }
      &:active > div.button-content,
      &.active-state > div.button-content {
        color: ${props.colorTheme === 'teal'
          ? props.theme.colors.accent1_800
          : props.colorTheme === 'dark'
          ? props.theme.colors.primary_300
          : props.theme.colors.primary_800};
        border-color: ${props.colorTheme === 'teal'
          ? props.theme.colors.accent1_800
          : props.colorTheme === 'dark'
          ? props.theme.colors.primary_300
          : props.theme.colors.primary_800};
        background-color: transparent;
        div {
          svg {
            path {
              fill: ${props.colorTheme === 'teal'
                ? props.theme.colors.accent1_800
                : props?.colorTheme === 'dark'
                ? props.theme.colors.white
                : props.theme.colors.primary_800};
            }
          }
        }
      }

      &&:disabled > div.button-content,
      &&.disabled-state > div.button-content {
        background-color: transparent !important;
        color: ${props?.colorTheme === 'dark'
          ? props.theme.colors.primary_700
          : props.theme.colors.neutral_300};
        border-color: ${props?.colorTheme === 'dark'
          ? props.theme.colors.primary_700
          : props.theme.colors.neutral_100};
      }
    `}
  

    
  && .label {
    display: flex;
  }
  && svg {
    align-self: center;
    margin: 0px 2px;
  }

  &:disabled > div.button-content,
  &.disabled-state > div.button-content {
    pointer-events: none;
    background-color: ${(props) =>
      props.colorTheme === 'dark'
        ? props.theme.colors.primary_700
        : props.theme.colors.neutral_100};
    color: ${(props) =>
      props.colorTheme === 'dark'
        ? props.theme.colors.primary_800
        : props.theme.colors.neutral_300};
    border-color: ${(props) =>
      props.colorTheme === 'dark'
        ? props.theme.colors.primary_700
        : props.theme.colors.neutral_100};
  }
  ${(props) =>
    !props.width && // If width is not set, then we are using the media queries to determine the size of the button
    mediaMedium(css`
      width: min(22%, 200px);
      && .button-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `)}
`;

// Override of life button so it is responsive of the font size
const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const themeContext = useTheme();
  const { storeButtonClick } = useFirebase();
  const { primaryFont } = themeContext.getFonts();
  const { onClick, disabled, ...restProps } = props;

  const buttonOnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    storeButtonClick(event.currentTarget.innerText, event.currentTarget.id);
    if (onClick) onClick(event);
  };

  return (
    <OverrideButton
      {...restProps}
      backgroundColor={themeContext.getColors().primary}
      font={primaryFont}
      onClick={buttonOnClick}
      disabled={disabled}
      role="button"
    />
  );
};

export default Button;
