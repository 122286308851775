import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useUser } from '../../../context/UserProvider';
import { SanityCourseContextProps } from '../../../context/sanity/sanityCourseContextProps';
import {
  FacilitatorPageWithTemplate,
  Favicon,
  LearnerPageWithTemplate,
  SanityGroupSettings,
  SanitySettings,
} from '../../../pageTemplates/types/courseTypes';
import { SanityService } from '../../sanity';
import { fetchProject } from '../../sanity/SanityFetch';

type Error = {
  errorCode?: 'ERRORLOADINGCOURSE';
  errorMessage?: string;
};

export const GroupCourseContext = React.createContext<SanityCourseContextProps>(
  {} as SanityCourseContextProps,
);

const handleFavicon = (faviconUrl?: string) => {
  const favicon = document.getElementById('favicon') as Favicon;

  if (favicon && faviconUrl !== undefined) {
    favicon.href = faviconUrl;
  }
};

const handleSanitySettings = (sanitySettings: SanitySettings) => {
  handleFavicon(sanitySettings.brandLogoUrl);
};

const GroupSanityProvider = ({ children }: { children: ReactNode }) => {
  const [course, setCourse] = useState<
    LearnerPageWithTemplate[] | FacilitatorPageWithTemplate[]
  >([]);
  const [settings, setSettings] = useState<SanityGroupSettings>(
    {} as SanityGroupSettings,
  );

  const userContext = useUser();

  const [projectPages, setProjectPages] = useState({});
  const [error, setError] = useState<Error | undefined>();
  const [supportedLanguages, setSupportedLanguages] = useState<string[]>([]);
  const loadContent = async () => {
    try {
      const content = await SanityService.getProject(
        fetchProject(userContext.sanityProjectId),
      );

      handleSanitySettings(content.settings);
      setProjectPages(content.projectPages);
      setSettings(content.settings as SanityGroupSettings);
      setCourse(
        content.course as
          | LearnerPageWithTemplate[]
          | FacilitatorPageWithTemplate[],
      );
      setSupportedLanguages(content.supportedLanguages);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setError({
        errorCode: 'ERRORLOADINGCOURSE',
        errorMessage: e,
      } as Error);
    }
  };

  // load data
  useEffect(() => {
    loadContent();
  }, []);

  const getCurrentPage = () => {
    const currentPath = window.location.pathname;
    const currentPageIndex = course.findIndex((a) => a.url === currentPath);
    return course[currentPageIndex];
  };

  const getProjectPage = () => {
    return undefined; // Not implemented for group
  };
  const value = useMemo(
    () => ({
      course,
      settings,
      error,
      supportedLanguages,
      projectPages,
      getCurrentPage,
      getProjectPage,
    }),
    [course, settings, error, projectPages],
  );
  return (
    <GroupCourseContext.Provider value={value}>
      {children}
    </GroupCourseContext.Provider>
  );
};

export default GroupSanityProvider;

export const UseSanityGroupCourse = (): SanityCourseContextProps => {
  const sanityCourse = useContext(GroupCourseContext);
  return sanityCourse;
};
