/* eslint-disable no-underscore-dangle */
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { ModalProps } from '../../components/Modals';
import { ShareModal } from '../../components/Modals/Modals';
import { AnimationState } from '../../components/TopBars/types';
import WithFadeIn from '../../components/WithFadeIn';
import WithFocus from '../../components/WithFocus';
import constants from '../../constants/Constants';
import { useAudio, useCamera, useSanityCourse } from '../../context';
import useAskForCameraPermission from '../../features/navigation/useAskForCameraPermission';
import { sanityFunctions } from '../../features/sanity';
import { createTextAndImageBody } from '../../features/sanity/SanityServiceMapper';
import { CustomButton } from '../../features/sanity/sanityTypes/customButton';
import {
  convertShareNotSupportedData,
  copiedShareText,
  getBodyText,
  useShowSharePopup,
} from '../../features/socialShare/socialShare';
import { translatePermissionDeniedErrorToCameraPermissionDeniedError } from '../../helper';
import { useNavigation } from '../../hooks';
import { logError } from '../../services/errorHandling';
import InfoPage, { InfoPageDialog } from '../Templates/InfoPage';
import { Button, CountDown, ImageTypes } from '../Templates/InfoPageTypes';
import {
  FacilitatorTextAndImageTemplate,
  LearnerTextAndImageTemplate,
  TextAndImageTemplate,
  isFacilitatorTextAndImageTemplate,
  isLearnerTextAndImageTemplate,
} from '../types/courseTypes';
import {
  SanityButton,
  SanityCameraPermission,
  SanityModal,
  SanityShare,
  SanityShareNotSupported,
} from '../types/sanityTypes';
import { showModal } from './utils';

type DynamicTextAndImagePageProps = {
  template?: TextAndImageTemplate | FacilitatorTextAndImageTemplate;
};

const DynamicTextAndImagePage: FunctionComponent<
  DynamicTextAndImagePageProps
> = ({ template }) => {
  const [currentPage, setCurrentPage] = useState<
    | TextAndImageTemplate
    | FacilitatorTextAndImageTemplate
    | LearnerTextAndImageTemplate
  >({});
  const sanityCourse = useSanityCourse();
  const [buttons, setButtons] = useState<Button[] | undefined>(undefined);

  const animationRef = useRef<HTMLDivElement>(null);
  const [anim, setAnim] = useState<AnimationState>(AnimationState.paused);
  const [countDown, setCountDown] = useState<CountDown | undefined>({
    duration: 10,
    delay: 1,
    animationState: AnimationState.paused,
    setAnimRef: animationRef,
  });
  const [modal, setModal] = useState<ModalProps | undefined>();
  const navigationContext = useNavigation();
  const cameraContext = useCamera();
  const audio = useAudio();
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [copyTextData, setCopyTextData] = useState<
    SanityShareNotSupported | undefined
  >();
  const [bodyText, setBodyText] = useState<string>('');
  const showSharePopup = useShowSharePopup();
  const askForCameraPermission = useAskForCameraPermission();

  const dialogText = useRef<InfoPageDialog | undefined>(undefined);
  const videoRef = useRef<HTMLVideoElement>(null);

  const showModalPrefilled = (innerButtonModal: SanityModal | undefined) => {
    showModal(innerButtonModal, setModal, navigationContext);
}

  let cameraStream: any = null;
  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: constants.video.sizes.mediaStream.width,
          height: constants.video.sizes.mediaStream.height,
          facingMode: 'user',
        },
      })
      .then((stream) => {
        const video = videoRef.current;
        if (video !== null) {
          video.setAttribute('autoplay', '');
          video.setAttribute('muted', '');
          video.setAttribute('playsinline', '');
          video.srcObject = stream;
          video.play();
          cameraStream = stream;
        }
      })
      .catch((error) => {
        error =
          translatePermissionDeniedErrorToCameraPermissionDeniedError(error);
        logError(error);
      });
  };

  const showOpenInBrowserModal = (browserModal: SanityModal) => {
    const newModal: ModalProps = {
      onClose: () => {
        setModal(undefined);
      },
      text: {
        header: browserModal.text?.header,
        body: createTextAndImageBody(
          browserModal?.text?.body,
          browserModal?.text?.bulletPoints,
        ),
      },
      bannerImage: {
        src: browserModal.bannerImage?.src,
        alt: browserModal.bannerImage?.alt,
        fullWidth: true,
      },
      buttons: sanityFunctions.createButtonsFromSanityButtons(
        browserModal.buttons,
        navigationContext,
        showModalPrefilled,
      ),
      verticalButtons: true,
    };
    setModal(newModal);
  };

  const showCameraPermission = (
    cameraPermission: SanityCameraPermission,
    activatedButton: Button,
    allButtons: SanityButton[] | CustomButton[],
  ) => {
    if (allButtons === undefined || activatedButton === undefined) {
      return;
    }

    // show a loading icon for the button
    const newAllButtons: Button[] = allButtons.map((btn) => {
      if (btn === activatedButton) {
        btn.loading = true;
      }
      return btn;
    });

    if (newAllButtons === undefined || newAllButtons.length === 0) {
      return;
    }

    setButtons(newAllButtons);
    askForCameraPermission(
      navigationContext,
      cameraContext,
      cameraPermission,
      showOpenInBrowserModal,
    );
  };

  const showShare = (shareData: SanityShare) => {
    const webShare: any = window.navigator;
    if (webShare.share) {
      showSharePopup(shareData);
    } else {
      setCopyTextData(convertShareNotSupportedData(shareData?.copyTextModal));
      setBodyText(getBodyText(shareData?.copyTextModal?.body));
    }
  };

  useEffect(() => {
    if (copyTextData !== undefined) {
      setShowShareModal(true);
    }
  }, [copyTextData]);

  const createInfoPageDialog = (page): InfoPageDialog => {
    const replyButton =
      page.dialog?.answerDialog?.button !== undefined
        ? sanityFunctions.createButtonsFromSanityButtons(
            [page.dialog?.answerDialog?.button],
            navigationContext,
            showModalPrefilled,
            showShare,
            showCameraPermission,
          )
        : undefined;
    return {
      showInitialDialog: page.dialog?.showInitialDialog,
      initialDialog: {
        text: page.dialog?.initialDialog?.text,
        textAlignCenter: page.dialog?.initialDialog?.textAlignCenter,
        pointerAlignLeft: page.dialog?.initialDialog?.pointerAlignLeft,
      },
      showAnswerDialog: page.dialog?.showAnswerDialog,
      answerDialog: {
        text: page.dialog?.answerDialog?.text,
        textAlignCenter: page.dialog?.answerDialog?.textAlignCenter,
        pointerAlignLeft: page.dialog?.answerDialog?.pointerAlignLeft,
        button: replyButton && replyButton[0],
      },
    };
  };
  useEffect(() => {
    const sanityPage =
      sanityCourse.getCurrentPage()?.textAndImageTemplate ??
      sanityCourse.getProjectPage()?.textAndImageTemplate ??
      undefined;
    const tempPage = template !== undefined ? template : sanityPage;

    if (tempPage !== undefined) {
      if (tempPage.dialog !== undefined) {
        dialogText.current = createInfoPageDialog(tempPage);
      }

      setCurrentPage(tempPage);
      if (tempPage.enableAutoplay && tempPage.countDown) {
        setButtons(
          sanityFunctions.createAutoplayButtonsFromSanityCountDown(
            tempPage.countDown,
            navigationContext,
            audio,
            anim,
            setAnim,
          ),
        );
      } else {
        setButtons(
          sanityFunctions.createButtonsFromSanityButtons(
            tempPage?.buttons,
            navigationContext,
            showModalPrefilled,
            showShare,
            showCameraPermission,
          ),
        );
      }
      setAnim(AnimationState.running);
    }
  }, []);

  const onAnimated = () => {
    navigationContext?.navigateToNextPage();
  };

  useEffect(() => {
    let someRef: HTMLDivElement | null = null;
    if (currentPage.enableAutoplay && currentPage.countDown) {
      setButtons(
        sanityFunctions.createAutoplayButtonsFromSanityCountDown(
          currentPage.countDown,
          navigationContext,
          audio,
          anim,
          setAnim,
        ),
      );

      setCountDown({
        duration: currentPage.countDown.duration,
        delay: currentPage.countDown.delay,
        animationState: anim,
        setAnimRef: animationRef,
      });
      if (animationRef && animationRef.current) {
        someRef = animationRef.current;
        animationRef.current.addEventListener('animationend', onAnimated, {
          once: true,
        });
      }
    }
    return () => {
      if (someRef) {
        someRef.removeEventListener('animationend', onAnimated);
      }
    };
  }, [anim]);

  // For handling Webcam
  useEffect(() => {
    if (currentPage.image?.imgType === ImageTypes.webCam) {
      getVideo();
    }

    return () => {
      if (cameraStream !== null && !isIOS) {
        cameraStream.getTracks().forEach(function (track) {
          // Need to stop the stream
          track.stop();
        });
      }
    };
  }, [currentPage.image?.imgType]);

  const CopiedText = () => {
    copiedShareText(bodyText);
  };

  const imageType = currentPage.image?.imgType;
  let isWebCam = false;
  let isFullWidthIconQRCodeLottie = false;

  if (imageType !== undefined) {
    isWebCam = imageType === ImageTypes.webCam;
    isFullWidthIconQRCodeLottie = [
      ImageTypes.FullWidth,
      ImageTypes.Icon,
      ImageTypes.QRCode,
      ImageTypes.Lottie,
    ].includes(imageType);
  }

  if (isFacilitatorTextAndImageTemplate(currentPage)) {
    return (
      <InfoPage
        buttons={buttons}
        completedText={currentPage.completedText}
        countDown={currentPage.enableAutoplay ? countDown : undefined}
        dialogText={dialogText.current}
        displayCompletedLearners={currentPage.displayCompletedLearners}
        enableAudio={currentPage.enableAudio}
        enableDialogText={currentPage.enableDialogText}
        enableInputField={currentPage.enableInputField}
        image={currentPage.image}
        inputField={currentPage.inputField}
        modal={modal}
        showTimeLeftBar={currentPage.showTimeLeftBar}
        text={currentPage.text}
        verticalButtons={currentPage.verticalButtons}
      />
    );
  }

  if (isLearnerTextAndImageTemplate(currentPage)) {
    return (
      <>
        {isWebCam || isFullWidthIconQRCodeLottie ? (
          <ShareModal
            isVisible={showShareModal}
            toggleModal={() => setShowShareModal(false)}
            shareData={copyTextData}
            copyTextPressed={CopiedText}
          />
        ) : null}
        <InfoPage
          buttons={isFullWidthIconQRCodeLottie ? buttons : currentPage.buttons}
          countDown={currentPage.enableAutoplay ? countDown : undefined}
          dialogText={dialogText.current}
          displayTeamName={currentPage.displayTeamName}
          enableAudio={currentPage.enableAudio}
          enableDialogText={currentPage.enableDialogText}
          enableInputField={currentPage.enableInputField}
          image={currentPage.image}
          inputField={currentPage.inputField}
          modal={modal}
          showTimeLeftBar={currentPage.showTimeLeftBar}
          text={currentPage.text}
          verticalButtons={currentPage.verticalButtons}
          video={isWebCam ? { ref: videoRef } : undefined}
        />
      </>
    );
  }

  return (
    <>
      {isWebCam || isFullWidthIconQRCodeLottie ? (
        <ShareModal
          isVisible={showShareModal}
          toggleModal={() => setShowShareModal(false)}
          shareData={copyTextData}
          copyTextPressed={CopiedText}
        />
      ) : null}
      <InfoPage
        buttons={buttons}
        countDown={currentPage.enableAutoplay ? countDown : undefined}
        dialogText={dialogText.current}
        enableAudio={currentPage.enableAudio}
        enableDialogText={currentPage.enableDialogText}
        image={currentPage.image}
        inputField={currentPage.inputField}
        enableInputField={currentPage.enableInputField}
        modal={modal}
        showTimeLeftBar={currentPage.showTimeLeftBar}
        text={currentPage.text}
        verticalButtons={currentPage.verticalButtons}
        video={isWebCam ? { ref: videoRef } : undefined}
      />
    </>
  );
};

export default WithFadeIn(WithFocus(DynamicTextAndImagePage));
