import React, { FunctionComponent, useEffect, useState } from 'react';
import WithFadeIn from '../../components/WithFadeIn';
import WithFocus from '../../components/WithFocus';
import { useSanityCourse } from '../../context';
import { EmailReminderTemplate } from '../types/courseTypes';
import DynamicEmailReminderPage from './DynamicEmailReminderPage';

const DynamicEmailReminderTemplate: FunctionComponent = () => {
  const [currentPage, setCurrentPage] = useState<EmailReminderTemplate>({
    image: undefined,
    mainText: '',
    emailConfirmationText: '',
    inputField: {
      label: '',
      minimumCharsToValidate: 0,
      placeholder: '',
      validationText: '',
    },
    emailContent: {
      subject: '',
      heading: '',
      body: '',
      buttonText: '',
    },
    sendEmailButtonText: '',
  });

  const pageContext = useSanityCourse();

  useEffect(() => {
    const tempPage =
      pageContext.projectPages.emailReminderPage?.emailReminderTemplate;
    if (tempPage !== undefined) {
      setCurrentPage(tempPage);
    }
  }, []);

  return (
    <DynamicEmailReminderPage
      image={currentPage.image}
      emailSubjectText={currentPage.emailContent.subject}
      emailHeadingText={currentPage.emailContent.heading}
      emailBodyText={currentPage.emailContent.body}
      emailButtonText={currentPage.emailContent.buttonText}
      mainText={currentPage.mainText}
      inputLabelText={currentPage.inputField.label}
      placeholderText={currentPage.inputField.placeholder}
      validationMessage={currentPage.inputField.validationText}
      confirmationText={currentPage.emailConfirmationText}
      sendEmailButtonText={currentPage.sendEmailButtonText}
    />
  );
};

export default WithFadeIn(WithFocus(DynamicEmailReminderTemplate));
