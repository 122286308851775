import styled from 'styled-components';
import constants from '../../constants/Constants';
import { PageGridStyle } from '../Templates/InfoPageStyles';
import { ImageTypes } from '../Templates/InfoPageTypes';

const { media } = constants;
const ExtendedPageGridStyle = styled(PageGridStyle)`
  @media screen and (min-height: ${media.MEDIUM}px) {
    grid-template-rows:
      minmax(${constants.headerHeight.SMALL}px, max-content)
      minmax(max-content, 1fr)
      ${(props) => (props.imgType === ImageTypes.Icon ? '2fr' : '4fr')}
      max-content
      minmax(${constants.pageMargin.SMALL}px, max-content);
  }
`;

export default ExtendedPageGridStyle;
