import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AudioTypes, PageAudio } from '../../pageTemplates/types/courseTypes';
import {
  LoadToAudioLibraryError,
  PlayAudioError,
  WithCauseError,
  logError,
} from '../../services/errorHandling/errorHelper';
import { useSanityCourse } from '../sanity/useSanityCourse';
import { AudioContextProps } from './audioContextProps';

class PlayAudioOnPageError extends WithCauseError {
  constructor(message: string, cause) {
    super(message, cause);
    this.name = 'PlayAudioOnPageError';
  }
}

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

/**
 * Safari and apple devices, only play sounds onButtonClicks (user interraction).
 * Purpose of this AudioContext is to work around those limitations.
 */

const AudioContext = React.createContext<AudioContextProps | null>(null);

const playAudioErrorString = 'Failed to play audio from library';
const loadToAudioLibraryErrorString = 'Failed to load audio to library';

const AudioProvider: FunctionComponent<Props> = ({ children }: Props) => {
  const myAudioLibrary = new Map<string, HTMLAudioElement>();
  const [sectionsLoaded, setSectionsLoaded] = useState<string[]>([]);
  const courseContext = useSanityCourse();

  // Helper functions
  const getCurrentPath = (): string => {
    return window.location.pathname;
  };
  const getCurrentCourseIndex = (): number => {
    const currentPath = getCurrentPath();
    const currentIndex = courseContext.course.findIndex(
      (page) => page.url === currentPath,
    );
    return currentIndex;
  };
  const createSoundKey = (url: string, index?: number) => {
    return index === undefined ? `${url}` : `${url}_${index}`;
  };
  const isPartofASection = (): boolean => {
    const currentPath = getCurrentPath();
    const currentIndex = courseContext.course.findIndex(
      (page) => page.url === currentPath,
    );

    if (currentIndex < 0) {
      return false;
    }

    if (courseContext.course[currentIndex].section !== undefined) {
      return true;
    }
    return false;
  };
  const isCurrentSectionLoaded = () => {
    const currentPath = getCurrentPath();
    const currentIndex = courseContext.course.findIndex(
      (page) => page.url === currentPath,
    );

    if (currentIndex < 0) {
      return false;
    }

    if (courseContext.course[currentIndex].section !== undefined) {
      return sectionsLoaded.includes(
        courseContext.course[currentIndex].section!.title, // needed to use ! to stop annoying ts error that section.title could be undefined
      );
    }
    return false;
  };

  /**
   * loadToAudioLibrary, let us load an audio on an onClick event, to be used later.
   * Check this link before you do any changes here: https://laerdal.atlassian.net/l/cp/1ravDU81
   * @param src sources of the audio file
   * @param key key to access the audio with.
   */
  const loadToAudioLibrary = (src: string, key: string, loop?: boolean) => {
    const newAudio = new Audio(src);
    if (loop !== undefined) {
      newAudio.loop = loop;
    }
    myAudioLibrary.set(key, newAudio);
    try {
      const audio = myAudioLibrary.get(key);

      if (audio) {
        audio.preload = 'auto';
        audio.load();
      }
    } catch (error) {
      throw new LoadToAudioLibraryError(loadToAudioLibraryErrorString, error);
    }
  };
  const playFromAudioLibrary = (key: string) => {
    const myAudio = myAudioLibrary.get(key);
    if (myAudio && !myAudio.ended) {
      try {
        myAudio.play();
      } catch (error: unknown) {
        throw new PlayAudioError(playAudioErrorString, error);
      }
    }
  };
  const playPauseResetFromAudioLibrary = (key: string) => {
    const myAudio = myAudioLibrary.get(key);
    if (myAudio) {
      try {
        myAudio.play();
      } catch (error: unknown) {
        throw new PlayAudioError(playAudioErrorString, error);
      }
      myAudio.onended = () => {
        myAudio.pause();
        myAudio.currentTime = 0;
      };
    }
  };

  const pauseAudio = (key: string) => {
    myAudioLibrary.get(key)?.pause();
  };

  const pauseFromAudioLibrary = (key: string) => {
    const currentIndex = courseContext.course.findIndex(
      (page) => page.url === key,
    );
    const { audio } = courseContext.course[currentIndex];
    if (audio) {
      if (audio?.length === 1) {
        pauseAudio(key);
      } else {
        audio.forEach((sound, index) => {
          pauseAudio(createSoundKey(key, index));
        });
      }
    }
  };
  const pauseAudioOnPage = () => {
    const currentIndex = getCurrentCourseIndex();
    const { audio } = courseContext.course[currentIndex];
    if (audio) {
      if (audio?.length === 1) {
        pauseAudio(getCurrentPath());
      } else {
        audio.forEach((sound, index) => {
          pauseAudio(createSoundKey(getCurrentPath(), index));
        });
      }
    }
  };

  const removeAudioFromLibrary = (key: string) => {
    myAudioLibrary.delete(key);
  };
  const onEnded = (cb: () => void, key?: string) => {
    if (key) {
      const myAudio = myAudioLibrary.get(key);
      if (myAudio) {
        myAudio.onended = cb;
      }
    } else {
      const myAudio = myAudioLibrary.get(getCurrentPath());
      if (myAudio) {
        myAudio.onended = cb;
      }
    }
  };

  const reset = (key: string) => {
    if (myAudioLibrary.get(key) !== undefined) {
      const myAudio = myAudioLibrary.get(key);
      if (myAudio?.currentTime !== undefined) {
        myAudio.currentTime = 0;
      }
    }
  };

  const resetAudio = (key: string) => {
    const currentIndex = courseContext.course.findIndex(
      (page) => page.url === key,
    );
    const { audio } = courseContext.course[currentIndex];
    if (audio) {
      if (audio?.length === 1) {
        reset(key);
      } else {
        audio.forEach((sound, index) => {
          reset(createSoundKey(key, index));
        });
      }
    }
  };

  const resetAudioOnPage = () => {
    const currentIndex = getCurrentCourseIndex();
    const { audio } = courseContext.course[currentIndex];
    if (audio) {
      if (audio?.length === 1) {
        reset(getCurrentPath());
      } else {
        audio.forEach((sound, index) => {
          reset(createSoundKey(getCurrentPath(), index));
        });
      }
    }
  };

  // Preloads audio in the background., so that it can be played later.
  const preloadAllAudio = async () => {
    const reorderPagesWhereCurrentPageIsFirst = () => {
      const pagesWithAudio = courseContext.course.filter(
        (page) => page?.audio !== undefined && page?.audio?.length > 0,
      );
      const indexOfCurrentPage = pagesWithAudio.findIndex(
        (page) => page.url === getCurrentPath(),
      );

      const pagesBeforeCurrentPage = pagesWithAudio.slice(
        0,
        indexOfCurrentPage,
      );
      const currentPageIncludingPagesAfterCurrentPage =
        pagesWithAudio.slice(indexOfCurrentPage);

      return [
        ...currentPageIncludingPagesAfterCurrentPage,
        ...pagesBeforeCurrentPage,
      ];
    };

    const pagesToLoadInOrder = reorderPagesWhereCurrentPageIsFirst();

    const filterOutPagesThatDoesHaveEmptyArrayOfAudio = (): PageAudio[] => {
      let audio: PageAudio[] = [];
      pagesToLoadInOrder.forEach((page) => {
        if (page.audio === undefined) return;
        audio = audio.concat(page.audio);
      });
      return audio;
    };

    const audioArray = filterOutPagesThatDoesHaveEmptyArrayOfAudio();

    let audioIndex = 0;
    const loadNextAudio = () => {
      if (audioIndex >= audioArray.length) {
        return; // All audio files have been loaded
      }

      const newAudio = new Audio(audioArray[audioIndex].src);
      newAudio.addEventListener('canplaythrough', loadNextAudio, {
        once: true,
      }); // Add an event listener to load the next audio file when this one has loaded

      newAudio.load();
      audioIndex += 1;
    };

    loadNextAudio();
  };

  /**
   * Initializes the sounds for current section
   */
  const initCurrentSection = () => {
    // Makes sure to not initialize something thats already loaded
    if (!isCurrentSectionLoaded() && isPartofASection()) {
      const currentIndex = getCurrentCourseIndex();
      const currentSection = courseContext.course[currentIndex].section;
      if (currentSection) {
        const pagesToLoad = courseContext.course.filter(
          (page) =>
            page?.section?.title === currentSection?.title && page?.audio,
        );
        pagesToLoad.forEach((page) => {
          if (page.audio?.length === 1) {
            loadToAudioLibrary(page.audio[0].src, page.url);
          } else {
            page.audio?.forEach((audio, index) => {
              loadToAudioLibrary(audio.src, createSoundKey(page.url, index));
            });
          }
        });
        setSectionsLoaded((sections) => {
          const returnSections = sections;
          if (returnSections.includes(currentSection.title)) {
            return returnSections;
          }
          returnSections.push(currentSection.title);
          return returnSections;
        });
      } else {
        const error = new Error('UseAudio: Current section contains no Audio');
        logError(error, 'UseAudio: Current section contains no Audio', {
          currentSection,
        });
      }
    }
  };

  /**
   * Checks if any pages in the current section contain any audio.
   *
   * @returns bool
   */
  const doesSectionContainAudio = (): boolean => {
    const currentIndex = getCurrentCourseIndex();
    const currentSection = courseContext.course[currentIndex]?.section;
    if (!currentSection) {
      return false;
    }

    const pagesInSectionWithAudio = courseContext.course.filter(
      (page) =>
        page.section?.title === currentSection?.title &&
        page.audio?.length !== 0,
    );

    if (pagesInSectionWithAudio.length > 0) {
      return true;
    }
    return false;
  };

  const initializeTrainingOperatorAudio = () => {
    const currentPath = window.location.pathname;
    const currentIndex = courseContext.course.findIndex(
      (page) => page.url === currentPath,
    );
    const trainingOperatorPage = courseContext.course[currentIndex];
    const currentSection = courseContext.course[currentIndex].section;
    if (currentSection) {
      setSectionsLoaded((sections) => {
        const returnSections = sections;
        if (returnSections.includes(currentSection.title)) {
          return returnSections;
        }
        returnSections.push(currentSection.title);
        return returnSections;
      });
      trainingOperatorPage.audio?.forEach(async (audio, index) => {
        await loadToAudioLibrary(
          audio.src,
          createSoundKey(trainingOperatorPage.url, index),
        );
      });
    }
  };

  const playAudioOnPage = (type?: AudioTypes, audioIndex?: number) => {
    try {
      if (!isCurrentSectionLoaded() && isPartofASection()) {
        initCurrentSection();
      }
      const currentIndex = getCurrentCourseIndex();
      const { audio } = courseContext.course[currentIndex];
      if (audio && type === undefined) {
        if (audio?.length === 1) {
          playFromAudioLibrary(getCurrentPath());
        } else {
          audio.forEach((sound, index) => {
            playFromAudioLibrary(createSoundKey(getCurrentPath(), index));
          });
        }
      } else if (audio) {
        if (audio?.length === 1) {
          if (audio[0].audioType === type) {
            playFromAudioLibrary(getCurrentPath());
          }
        } else if (
          audioIndex !== undefined &&
          audio[audioIndex].audioType === type
        ) {
          playFromAudioLibrary(createSoundKey(getCurrentPath(), audioIndex));
        } else {
          audio.forEach((sound, index) => {
            if (sound.audioType === type) {
              playFromAudioLibrary(createSoundKey(getCurrentPath(), index));
            }
          });
        }
      }
    } catch (error) {
      const errorMessage = 'Could not play audio on page.';
      const playAudioError = new PlayAudioOnPageError(errorMessage, error);
      logError(playAudioError, errorMessage, {
        audioIndex,
        currentPath: getCurrentPath(),
        type,
      });
    }
  };
  const playPauseResetAudioOnPage = (type: AudioTypes, audioIndex: number) => {
    if (!isCurrentSectionLoaded() && isPartofASection()) {
      initCurrentSection();
    }
    const currentIndex = getCurrentCourseIndex();
    const { audio } = courseContext.course[currentIndex];
    if (audio) {
      if (audio?.length === 1) {
        if (audio[0].audioType === type) {
          playPauseResetFromAudioLibrary(getCurrentPath());
        }
      } else if (
        audioIndex !== undefined &&
        audio[audioIndex].audioType === type
      ) {
        playPauseResetFromAudioLibrary(
          createSoundKey(getCurrentPath(), audioIndex),
        );
      } else {
        audio.forEach((sound, index) => {
          if (sound.audioType === type) {
            playPauseResetFromAudioLibrary(
              createSoundKey(getCurrentPath(), index),
            );
          }
        });
      }
    }
  };

  // Preloads audio so that its already loaded when we need it on every section.
  // Added a 5 seconds delay so it does not stop apge
  useEffect(() => {
    if (courseContext.course && courseContext.course.length > 0) {
      setTimeout(() => {
        preloadAllAudio();
      }, 5000);
    }
  }, [courseContext.course]);

  return (
    <AudioContext.Provider
      value={{
        doesSectionContainAudio,
        loadToAudioLibrary,
        playFromAudioLibrary,
        pauseFromAudioLibrary,
        removeAudioFromLibrary,
        resetAudio,
        onEnded,
        initCurrentSection,
        resetAudioOnPage,
        pauseAudioOnPage,
        playAudioOnPage,
        playPauseResetAudioOnPage,
        isCurrentSectionLoaded,
        initializeTrainingOperatorAudio,
        preloadAllAudio,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};
export default AudioProvider;

export const useAudio = (): AudioContextProps => {
  return useContext(AudioContext) as AudioContextProps;
};
