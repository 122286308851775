import { FunctionComponent, useEffect, useState } from 'react';
import { LaerdalFooter } from '../../components';
import { ShareModal } from '../../components/Modals/Modals';
import WithFocus from '../../components/WithFocus';
import { useCamera, useSanityCourse } from '../../context';
import useAskForCameraPermission from '../../features/navigation/useAskForCameraPermission';
import { sanityFunctions } from '../../features/sanity';
import { createTextAndImageBody } from '../../features/sanity/SanityServiceMapper';
import {
  convertShareNotSupportedData,
  copiedShareText,
  getBodyText,
  useShowSharePopup,
} from '../../features/socialShare/socialShare';
import { FrontPageTemplate } from '../types/courseTypes';
import {
  SanityCameraPermission,
  SanityModal,
  SanityShare,
  SanityShareNotSupported,
} from '../types/sanityTypes';

import { ModalProps } from '../../components/Modals';
import WithFadeIn from '../../components/WithFadeIn';
import { useNavigation } from '../../hooks';
import { Button } from '../Templates/InfoPageTypes';
import FrontPage, { FrontPageProps } from './FrontPage';

const DynamicFrontPage: FunctionComponent<FrontPageProps> = () => {
  const [bodyText, setBodyText] = useState<string>('');
  const [buttons, setButtons] = useState<Button[] | undefined>(undefined);
  const [copyTextData, setCopyTextData] = useState<
    SanityShareNotSupported | undefined
  >();
  const [currentPage, setCurrentPage] = useState<FrontPageTemplate>({});
  const [modal, setModal] = useState<ModalProps | undefined>();
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const cameraContext = useCamera();
  const navigationContext = useNavigation();
  const pageContext = useSanityCourse();
  const askForCameraPermission = useAskForCameraPermission();
  const showSharePopup = useShowSharePopup();

  const showModal = (buttonModal: SanityModal | undefined) => {
    if (buttonModal === undefined) {
      setModal(undefined);
      return;
    }
    const newModal: ModalProps = {
      onClose: () => {
        setModal(undefined);
      },
      text: {
        header: buttonModal.text?.header,
        header2: buttonModal.text?.header2,
        body: createTextAndImageBody(
          buttonModal?.text?.body,
          buttonModal?.text?.bulletPoints,
        ),
      },
      bannerImage: buttonModal.bannerImage,
      buttons: sanityFunctions.createButtonsFromSanityButtons(
        buttonModal.buttons,
        navigationContext,
        showModal,
      ),
      verticalButtons: buttonModal.verticalButtons,
    };
    setModal(newModal);
  };
  const showShare = (shareData: SanityShare) => {
    const webShare: any = window.navigator;
    if (webShare.share) {
      showSharePopup(shareData);
    } else {
      setCopyTextData(convertShareNotSupportedData(shareData?.copyTextModal));
      setBodyText(getBodyText(shareData?.copyTextModal?.body));
    }
  };
  useEffect(() => {
    if (copyTextData !== undefined) {
      setShowShareModal(true);
    }
  }, [copyTextData]);
  const showOpenInBrowserModal = (browserModal: SanityModal) => {
    const newModal: ModalProps = {
      onClose: () => {
        setModal(undefined);
      },
      text: {
        header: browserModal.text?.header,
        body: createTextAndImageBody(
          browserModal?.text?.body,
          browserModal?.text?.bulletPoints,
        ),
      },
      bannerImage: {
        src: browserModal.bannerImage?.src,
        alt: browserModal.bannerImage?.alt,
        fullWidth: true,
      },
      buttons: sanityFunctions.createButtonsFromSanityButtons(
        browserModal.buttons,
        navigationContext,
        showModal,
      ),
      verticalButtons: true,
    };
    setModal(newModal);
  };
  const showCameraPermission = (
    cameraPermission: SanityCameraPermission,
    activatedButton: Button,
    allButtons: Button[],
  ) => {
    if (allButtons === undefined || activatedButton === undefined) {
      return;
    }

    // show a loading icon for the button
    const newAllButtons = allButtons.map((btn) => {
      if (btn === activatedButton) {
        btn.loading = true;
      }
      return btn;
    });

    if (newAllButtons === undefined || newAllButtons.length === 0) {
      return;
    }

    setButtons(newAllButtons);
    askForCameraPermission(
      navigationContext,
      cameraContext,
      cameraPermission,
      showOpenInBrowserModal,
    );
  };

  useEffect(() => {
    const tempPage =
      pageContext.getCurrentPage()?.frontPageTemplate ??
      pageContext.getProjectPage()?.frontPageTemplate ??
      undefined;
    if (tempPage !== undefined) {
      setCurrentPage(tempPage);
      setButtons(
        sanityFunctions.createButtonsFromSanityButtons(
          tempPage?.buttons,
          navigationContext,
          showModal,
          showShare,
          showCameraPermission,
        ),
      );
    }
  }, []);
  const CopiedText = () => {
    copiedShareText(bodyText);
  };

  return (
    <>
      <ShareModal
        copyTextPressed={CopiedText}
        isVisible={showShareModal}
        shareData={copyTextData}
        toggleModal={() => setShowShareModal(false)}
      />
      <FrontPage
        banner={currentPage?.banner}
        bottomContent={
          <LaerdalFooter
            displayVersionNumber={currentPage?.showVersionNumber}
          />
        }
        buttons={buttons}
        image={currentPage?.image}
        modal={modal}
        text={currentPage?.text}
        verticalButtons={currentPage?.verticalButtons}
      />
    </>
  );
};

export default WithFadeIn(WithFocus(DynamicFrontPage));
