import * as Ably from 'ably';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import constants from '../../../constants/Constants';
import { useFirebase } from '../../../context';
import { LocalStorageService } from '../../../services';
import { createRealtimeChannel } from '../../realtimeCommunication/ablyInstanceFactory';
import {
  ToasterType,
  useToasterFactory,
} from '../../toaster/factory/useToasterFactory';
import {
  AblyNavigateToNextPageCommand,
  AblyTeamUpCommand,
  AblyWaitingCommand,
  Channel,
  LearnerTeam,
} from '../types/AblyCommandType';
import { ConnectionStates } from '../types/AblyConnectionTypes';
import {
  LearnerData,
  addNewLearnerInTeam,
  createTeams,
  getLearnerData,
  setLearnerTeam,
  setLearnerTeamRole,
} from './TeamUp';

type AblyContextProps = {
  connectedUsers: Ably.Types.PresenceMessage[];
  connectFacilitator: (classroomCode: number) => Promise<void>;
  connectionStatusChanged: Ably.Types.ConnectionState | undefined;
  connectLearner: (classroomCode: number) => Promise<void>;
  getLearnerTeam: () => string | null;
  getLearnerTeamRole: () => string | null;
  learnerTeamName: string;
  disconnectLearner: () => void;
  navigateToNextPageCommand: AblyNavigateToNextPageCommand | undefined;
  onLeftWaitingPage: () => void;
  onWaitingPage: () => void;
  publishNavigateToNextPageCommand: (
    command: AblyNavigateToNextPageCommand,
  ) => void;
  resetWaitingLearners: () => void;
  subscribeToChannel: () => Promise<void>;
  waitingUsers: string[];
  teamUpLearners: () => void;
};

const waitingCommandMessages = {
  waiting: 'Waiting',
  left: 'Not waiting',
};

const AblyContext = React.createContext<AblyContextProps>(
  {} as AblyContextProps,
);

const getClassroomChannelAsync = async (
  ablyRealtime: Ably.Realtime,
  classroomCode: number,
) => {
  const channel = ablyRealtime.channels.get(classroomCode.toString(), {
    params: { rewind: '1' },
  });
  return channel;
};

const AblyProvider: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }) => {
  const [connectionStatusChanged, setConnectionStatusChanged] = useState<
    Ably.Types.ConnectionState | undefined
  >();
  const [connectedUsers, setConnectedUsers] = useState<
    Ably.Types.PresenceMessage[]
  >([]);
  const [waitingUsers, setWaitingUsers] = useState<string[]>([]);
  const [navigateToNextPageCommand, setNavigateToNextPageCommand] =
    useState<AblyNavigateToNextPageCommand>();
  const { storeAblyConnectionEvent } = useFirebase();
  const connectionState = useRef<ConnectionStates | null>(null);
  const channel = useRef<Ably.Types.RealtimeChannelCallbacks | null>(null);
  const ably = useRef<Ably.Realtime | null>(null);
  const { createToast, connectionStateChanged } = useToasterFactory();
  const subscribeToChannel = async (): Promise<void> => {
    channel.current?.unsubscribe('navigateToNextPage');
    channel.current?.subscribe('navigateToNextPage', (message) => {
      setNavigateToNextPageCommand(
        JSON.parse(message.data) as AblyNavigateToNextPageCommand,
      );
    });
  };
  const [learnerTeams, setLearnerTeams] = useState<LearnerTeam[]>([]);
  const [learnerTeamName, setLearnerTeamName] = useState<string>('');

  const setToaster = (
    currentState: Ably.Types.ConnectionState,
    previousState: Ably.Types.ConnectionState,
    retryDurationInMs: number | undefined,
  ) => {
    if (
      connectionState.current !== null &&
      previousState === ConnectionStates.connecting &&
      currentState === ConnectionStates.connected
    ) {
      createToast(ToasterType.success, 'Connected!');

      connectionState.current = ConnectionStates.connected;
    } else if (
      previousState === ConnectionStates.connecting &&
      currentState === ConnectionStates.disconnected
    ) {
      createToast(
        ToasterType.Warning,
        `Check your internet connection. Trying to reconnect in ${
          retryDurationInMs && Math.ceil(retryDurationInMs / 1000)
        } seconds`,
      );
    } else if (
      previousState === ConnectionStates.disconnected &&
      currentState === ConnectionStates.connecting &&
      connectionState.current !== ConnectionStates.reconnecting
    ) {
      createToast(ToasterType.Warning, `Disconnected. Trying to reconnect...`);
      connectionState.current = ConnectionStates.reconnecting;
    }
  };

  const publishTeamUpCommand = (commandData: AblyTeamUpCommand) => {
    channel.current?.publish(
      Channel.TeamUpCommand,
      JSON.stringify(commandData),
    );
  };

  const addNewLearner = (learnerId: string) => {
    const team = addNewLearnerInTeam(learnerId);
    publishTeamUpCommand({ teamMessage: [team] });
  };

  /**
   * Make a teamUp session by making a team of 2 learners in each team and assign each learner a role (exp: A or B)
   * Also publish teams data to all learners.
   * @returns void
   */
  const teamUpLearners = () => {
    const teams = createTeams(connectedUsers);
    if (teams.length > 0) {
      publishTeamUpCommand({ teamMessage: teams });
    }
  };

  /**
   * Get learner team (exp. 1 or 2 or 3) in TeamUp session.
   * @returns string learner team name
   */
  const getLearnerTeam = (): string | null => {
    const existingTeam = LocalStorageService.getLearnerTeamFromLocalStorage();
    return existingTeam;
  };

  /**
   * Get learner role (exp. A or B) in TeamUp session.
   * @returns string learner role name
   */
  const getLearnerTeamRole = (): string | null => {
    const existingRole = LocalStorageService.getLearnerRoleFromLocalStorage();
    return existingRole;
  };

  const InitiateAblyConnectionAsync = async (
    classroomCode: number,
    name: string,
  ) => {
    ably.current = createRealtimeChannel(name);

    ably.current.connection.on((stateChanged) => {
      connectionStateChanged(stateChanged.current);
      if (stateChanged.current === ConnectionStates.closed) {
        setConnectionStatusChanged(ConnectionStates.closed);
      } else if (stateChanged.current === ConnectionStates.connected) {
        setConnectionStatusChanged(ConnectionStates.connected);
      }

      setToaster(
        stateChanged.current,
        stateChanged.previous,
        stateChanged.retryIn,
      );
    });

    channel.current = await getClassroomChannelAsync(
      ably.current,
      classroomCode,
    );
  };

  /**
   * Add a user to the list of waiting users.
   * @param clientId - an Ably client ID. Usually presence.clientId or message.clientId
   * @returns void
   */
  const addWaitingUser = (clientId: string) => {
    // If user is already waiting, return immediately
    if (waitingUsers.includes(clientId)) {
      return;
    }
    // Add user to list of waiting users.
    setWaitingUsers((waitUsers) => waitUsers.concat(clientId));
  };

  /**
   * Remove a user from the list of waiting users.
   * @param clientId - an Ably client ID. Usually presence.clientId or message.clientId
   * @returns void
   */
  const removeWaitingUser = (clientId: string) => {
    // remove user from list of waiting users
    setWaitingUsers((waitUsers) => {
      return waitUsers.filter((e) => e !== clientId);
    });
  };

  const publishNavigateToNextPageCommand = (
    commandData: AblyNavigateToNextPageCommand,
  ) => {
    channel.current?.publish(
      Channel.NavigationCommand,
      JSON.stringify(commandData),
    );
  };

  const setConnectedUsersFacilitator: Ably.Types.realtimePresenceGetCallback = (
    error: Ably.Types.ErrorInfo | null,
    presenceArray: Ably.Types.PresenceMessage[] | undefined,
  ) => {
    if (presenceArray !== undefined) {
      setConnectedUsers(presenceArray);
    }
  };

  const connectFacilitator = async (classroomCode: number) => {
    await InitiateAblyConnectionAsync(classroomCode, `Facilitator`);
    channel.current?.presence.subscribe((presence) => {
      channel.current?.presence.get(undefined, setConnectedUsersFacilitator);
      const JoiningOrLeavingLearner = presence.clientId;
      if (presence.action === 'leave') {
        storeAblyConnectionEvent(
          ConnectionStates.disconnected,
          JoiningOrLeavingLearner,
        );
        // Also remove disconnected users from the list of waiting users
        if (!waitingUsers) return;
        setWaitingUsers((waitUsers) => {
          return waitUsers.filter((e) => e !== presence.clientId);
        });
      }
      if (presence.action === 'enter') {
        storeAblyConnectionEvent(
          ConnectionStates.connected,
          JoiningOrLeavingLearner,
        );
        if (
          LocalStorageService.getTrainingTypeFromLocalStorage() ===
          constants.trainingMode.TEAMUP
        ) {
          const learnerData = JSON.parse(presence.data) as LearnerData;
          if (!learnerData.team) {
            addNewLearner(presence.clientId);
          }
        }
        // When a new user enters a class in progress, the facilitator has to publish
        // the current path such that the new user can be moved to the current page.
        publishNavigateToNextPageCommand({
          currentUrl: window.location.pathname,
        });
      }
    });
    channel.current?.subscribe(Channel.WaitingCommand, (message) => {
      const jsonData = JSON.parse(message.data) as AblyWaitingCommand;
      switch (jsonData.message) {
        case waitingCommandMessages.waiting:
          addWaitingUser(message.clientId);
          break;
        case waitingCommandMessages.left:
          removeWaitingUser(message.clientId);
          break;
        default:
          break;
      }
    });
  };

  const setTeamUpLearner = () => {
    if (!getLearnerTeam()) {
      setLearnerTeam(learnerTeams);
      setLearnerTeamRole(learnerTeams);
      const clientId = window.revivr.learnerId ?? '';
      channel.current?.presence.updateClient(
        clientId,
        getLearnerData(),
        (err) => {
          if (err) {
            console.error(`Error updating client presence: ${err.message}`);
            createToast(ToasterType.Warning, 'Learner presence not updated');
          }
        },
      );
    }
  };

  useEffect(() => {
    if (learnerTeams.length > 0) {
      setTeamUpLearner();
      setLearnerTeamName(
        LocalStorageService.getLearnerTeamFromLocalStorage() ?? '',
      );
    }
  }, [learnerTeams]);

  const disconnectLearner = () => {
    if (ably.current) {
      setNavigateToNextPageCommand(undefined);
      ably.current.close();
    }
  };

  const connectLearner = async (classroomCode: number) => {
    await InitiateAblyConnectionAsync(
      classroomCode,
      window.revivr.learnerId as string,
    );
    // The check is done to stop the preview from navigating away from the page the user would like to preview
    if (!window.revivr.isPreviewMode) {
      channel.current?.subscribe(Channel.NavigationCommand, (message) => {
        setNavigateToNextPageCommand(
          JSON.parse(message.data) as AblyNavigateToNextPageCommand,
        );
      });
      channel.current?.subscribe(Channel.TeamUpCommand, (message) => {
        const teams = JSON.parse(message.data) as AblyTeamUpCommand;
        setLearnerTeams(teams.teamMessage);
      });
      const learnerData = getLearnerData();
      channel.current?.presence.enter(learnerData, (err) => {
        if (err) {
          console.error('Error entering presence');
        }
      });
    }
  };

  const publishWaitingCommand = (commandData: AblyWaitingCommand) => {
    channel.current?.publish(
      Channel.WaitingCommand,
      JSON.stringify(commandData),
    );
  };
  const resetWaitingLearners = () => {
    setWaitingUsers([]);
  };
  const onWaitingPage = () => {
    publishWaitingCommand({ message: waitingCommandMessages.waiting });
  };
  const onLeftWaitingPage = () => {
    publishWaitingCommand({ message: waitingCommandMessages.left });
  };

  return (
    <AblyContext.Provider
      value={{
        connectFacilitator,
        connectLearner,
        connectedUsers,
        connectionStatusChanged,
        disconnectLearner,
        navigateToNextPageCommand,
        onWaitingPage,
        onLeftWaitingPage,
        publishNavigateToNextPageCommand,
        resetWaitingLearners,
        subscribeToChannel,
        waitingUsers,
        teamUpLearners,
        getLearnerTeam,
        getLearnerTeamRole,
        learnerTeamName,
      }}
    >
      {children}
    </AblyContext.Provider>
  );
};

export const UseAbly = (): AblyContextProps => {
  const context = useContext(AblyContext);
  return context;
};

export default AblyProvider;
