import { Size } from '@laerdal/life-react-components';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import constants from '../../constants/Constants';
import { defaultText } from '../../features/sanity';
import { useNavigation } from '../../hooks';
import {
  DraggableArea,
  HorizontalBottomContent,
  ImageStyle,
  LeftPadImageStyle,
  MainContent,
  MainContentWrapper,
  MainTextTopMargin,
  RightPadImageStyle,
  SubText,
} from '../Templates/SimulationPageStyles';
import { AEDDraggablePadPageTemplate } from '../types/courseTypes';
import {
  AedPadRelativeWidth,
  PadPosition,
  calculateHeight,
  calculateWidth,
} from './AedPadUtil';

const LeftPadCorrectPositionStyle = styled.img<{
  leftPosition: number;
  topPosition: number;
}>`
  position: absolute;
  left: ${(props) => props.leftPosition}px;
  top: ${(props) => props.topPosition}px;
`;

const RightPadCorrectPositionStyle = styled.img<{
  leftPosition: number;
  topPosition: number;
}>`
  position: absolute;
  left: ${(props) => props.leftPosition}px;
  top: ${(props) => props.topPosition}px;
`;

interface AedPadsDataProps {
  aedPadSize: AedPadRelativeWidth;
  HelpClicked: () => void;
  leftPadCorrectPosition: PadPosition;
  leftPadPosition: PadPosition;
  padPlacementWithinRange: boolean;
  pageData: AEDDraggablePadPageTemplate;
  practiseAgainClicked: () => void;
  rightPadCorrectPosition: PadPosition;
  rightPadImage: string;
  rightPadPosition: PadPosition;
}

const AedResult: FunctionComponent<AedPadsDataProps> = ({
  pageData,
  aedPadSize,
  padPlacementWithinRange,
  leftPadPosition,
  rightPadPosition,
  leftPadCorrectPosition,
  rightPadCorrectPosition,
  practiseAgainClicked,
  HelpClicked,
  rightPadImage,
}: AedPadsDataProps) => {
  const navigationContext = useNavigation();
  const {
    aedPadCorrectPosition,
    aedPadInCorrectPosition,
    aedPadPlacementValues,
    useOnlyOnePad,
  } = pageData;

  const calculateLeftPadWidth = () => {
    if (useOnlyOnePad) {
      return '0%'; // This hides the box
    }
    return aedPadPlacementValues?.leftPadAreaWidth
      ? `${aedPadPlacementValues?.leftPadAreaWidth}px`
      : '32%';
  };
  return (
    <>
      <MainContentWrapper>
        <MainContent>
          <DraggableArea>
            <ImageStyle
              src={pageData.image?.src}
              width={calculateWidth()}
              height={calculateHeight()}
              alt="practiceSpace"
            />
            <LeftPadCorrectPositionStyle
              src={pageData.aedPadPlacementValues?.leftPadAreaImage.src}
              leftPosition={leftPadCorrectPosition?.x}
              topPosition={leftPadCorrectPosition?.y}
              id="leftArea"
              width={calculateLeftPadWidth()}
              height={
                aedPadPlacementValues?.leftPadAreaHeight
                  ? `${aedPadPlacementValues?.leftPadAreaHeight}px`
                  : `${constants.padAreaDefaultValues.LeftAreaDefaultValues.height}px`
              }
              alt={
                defaultText.aedDraggablePad.images.rightCorrectPlacementArea.alt
              }
            />
            <RightPadCorrectPositionStyle
              src={pageData.aedPadPlacementValues?.rightPadAreaImage.src}
              leftPosition={rightPadCorrectPosition?.x}
              topPosition={rightPadCorrectPosition?.y}
              id="rightArea"
              width={
                aedPadPlacementValues?.rightPadAreaWidth
                  ? `${aedPadPlacementValues.rightPadAreaWidth}px`
                  : '30%'
              }
              height={
                aedPadPlacementValues?.rightPadAreaHeight
                  ? `${aedPadPlacementValues?.rightPadAreaHeight}px`
                  : `${constants.padAreaDefaultValues.RightAreaDefaultValues.height}px`
              }
              alt={
                defaultText.aedDraggablePad.images.leftCorrectPlacementArea.alt
              }
            />
            <LeftPadImageStyle
              src={pageData.aedLeftPad?.src}
              leftPosition={leftPadPosition?.x}
              topPosition={leftPadPosition?.y}
              width={aedPadSize.leftPadWidth}
              alt={defaultText.aedDraggablePad.images.leftPad.alt}
            />
            <RightPadImageStyle
              src={rightPadImage}
              leftPosition={rightPadPosition?.x}
              topPosition={rightPadPosition?.y}
              width={aedPadSize.rightPadWidth}
              alt={defaultText.aedDraggablePad.images.rightPad.alt}
            />
          </DraggableArea>
          <MainTextTopMargin>
            {padPlacementWithinRange
              ? aedPadCorrectPosition?.headingText
              : aedPadInCorrectPosition?.headingText}
          </MainTextTopMargin>
          <SubText>
            {padPlacementWithinRange
              ? aedPadCorrectPosition?.bodyText
              : aedPadInCorrectPosition?.bodyText}
          </SubText>
        </MainContent>
      </MainContentWrapper>
      {padPlacementWithinRange ? (
        <HorizontalBottomContent>
          <Button
            size={window.innerWidth <= 320 ? Size.Small : Size.Medium}
            style={{ width: '100%' }}
            variant="secondary"
            onClick={() => {
              practiseAgainClicked();
            }}
          >
            {aedPadCorrectPosition?.buttons?.secondaryButtonText ??
              defaultText.aedPadButtonText.padCorrectPosition.secondary}
          </Button>
          <Button
            size={window.innerWidth <= 320 ? Size.Small : Size.Medium}
            style={{ width: '100%' }}
            variant="primary"
            onClick={() => {
              navigationContext?.navigateToNextPageWithSleepCheck();
            }}
          >
            {aedPadCorrectPosition?.buttons?.primaryButtonText ??
              defaultText.aedPadButtonText.padCorrectPosition.primary}
          </Button>
        </HorizontalBottomContent>
      ) : (
        <HorizontalBottomContent>
          <Button
            size={window.innerWidth <= 320 ? Size.Small : Size.Medium}
            style={{ width: '100%' }}
            variant="secondary"
            onClick={() => {
              HelpClicked();
            }}
          >
            {aedPadInCorrectPosition?.buttons?.secondaryButtonText ??
              defaultText.aedPadButtonText.padInCorrectPosition.secondary}
          </Button>
          <Button
            size={window.innerWidth <= 320 ? Size.Small : Size.Medium}
            style={{ width: '100%' }}
            variant="primary"
            onClick={() => {
              practiseAgainClicked();
            }}
          >
            {aedPadInCorrectPosition?.buttons?.primaryButtonText ??
              defaultText.aedPadButtonText.padInCorrectPosition.primary}
          </Button>
        </HorizontalBottomContent>
      )}
    </>
  );
};

export default AedResult;
