import React, { useState, FunctionComponent, useEffect } from 'react';
import { PageGridStyle } from './InfoPageStyles';

type PageGridProps = {
  children: any;
};

const PageGrid: FunctionComponent<PageGridProps> = ({
  children,
}: PageGridProps) => {
  const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
  useEffect(() => {
    const setInnerHeightCallback = () => {
      setInnerHeight(window.innerHeight);
    };

    window.addEventListener('resize', setInnerHeightCallback);
    window.addEventListener('orientationchange', setInnerHeightCallback);

    return () => {
      window.removeEventListener('resize', setInnerHeightCallback);
      window.removeEventListener('orientationchange', setInnerHeightCallback);
    };
  }, []);

  return <PageGridStyle height={innerHeight}>{children}</PageGridStyle>;
};

export default PageGrid;
