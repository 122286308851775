import React from 'react';
import styled from 'styled-components';
import BrandIcon from '../../../../components/TopBars/BrandIcon';
import { useSanityCourse } from '../../../../context';
import { SanityGroupSettings } from '../../../../pageTemplates/types/courseTypes';
import { LocalStorageService } from '../../../../services';

const TeamNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1/2;
  grid-column: 1/-1;
`;

const TeamNameFontStyling = styled.h2`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  font-size: 2rem;
  line-height: 120%;
  font-weight: 400;
  color: ${(props) => props.theme.colors.neutral_800};
`;

interface DisplayTeamNameProps {
  enabled: boolean | undefined;
}

const DisplayTeamName: React.FunctionComponent<DisplayTeamNameProps> = ({
  enabled,
}) => {
  const teamName = LocalStorageService.getLearnerTeamFromLocalStorage();
  const { settings } = useSanityCourse();
  const groupSettings = settings as SanityGroupSettings;
  const teamText = groupSettings?.learnerTeamText ?? 'Team';
  if (!enabled || !teamName || !window.revivr.isLearner) {
    return <> </>;
  }

  return (
    <TeamNameContainer>
      <BrandIcon />
      <TeamNameFontStyling>{`${teamText} ${teamName}`}</TeamNameFontStyling>
    </TeamNameContainer>
  );
};

export default DisplayTeamName;
