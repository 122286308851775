const windowNavigator: any = window.navigator;

export enum BrowserTypes {
  Safari = 'SAFARI',
  iOSWebview = 'IOSWEBVIEW',
  AndroidWebview = 'ANDROIDWEBVIEW',
  Chrome = 'CHROMe',
}

export const browserCheck = (
  isStandalone: boolean = windowNavigator.standalone,
  nameOfAgent: string = window.navigator.userAgent.toLowerCase(),
  isSafari: boolean = /safari/.test(nameOfAgent),
  isios: boolean = /iphone|ipod|ipad/.test(nameOfAgent),
): string => {
  // Input parameters are for unit testing and are not necessary.

  const navStandalone = isStandalone;
  const userAgent = nameOfAgent;
  const safari = isSafari;
  const ios = isios;

  if (ios) {
    if (!navStandalone && safari) {
      return BrowserTypes.Safari; // Safari
    }
    if (!navStandalone && !safari) {
      return BrowserTypes.iOSWebview; // iOS webview
    }
  }
  if (userAgent.includes('wv')) {
    return BrowserTypes.AndroidWebview; // Android webview
  }
  return BrowserTypes.Chrome; // Chrome
};

export const isAndroidWebView = (): boolean => {
  return browserCheck() === BrowserTypes.AndroidWebview;
};
export const isiOSWebview = (): boolean => {
  return browserCheck() === BrowserTypes.iOSWebview;
};
