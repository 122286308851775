import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { isIOS } from 'react-device-detect';
import constants from '../constants/Constants';
import { translatePermissionDeniedErrorToCameraPermissionDeniedError } from '../helper/CameraHelper';
import { logError } from '../services/errorHandling';

export type CameraContextProps = {
  hasCameraPermission: boolean;
  checkCameraPermissionAsync: () => Promise<boolean>;
};

type Props = {
  children: ReactNode;
};

const CameraContext = React.createContext<CameraContextProps | null>(null);

const CameraProvider: React.FunctionComponent<Props> = ({
  children,
}: Props) => {
  const [hasCameraPermission, setHasCameraPermission] =
    useState<boolean>(false);

  const checkCameraPermissionAsync = (): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      const vgaConstraints = {
        video: {
          width: constants.video.sizes.mediaStream.width,
          height: constants.video.sizes.mediaStream.height,
        },
      };

      navigator.mediaDevices
        .getUserMedia(vgaConstraints)
        .then(function (stream) {
          // Android requires that we close, or we will select the wrong camera
          if (stream !== null && !isIOS) {
            stream.getTracks().forEach(function (track) {
              // Need to stop the stream
              track.stop();
            });
          }

          setHasCameraPermission(true);
          resolve(true);
        })
        .catch(function (error) {
          error =
            translatePermissionDeniedErrorToCameraPermissionDeniedError(error);
          logError(error);
          setHasCameraPermission(false);
          resolve(false);
        });
    });
  };
  const value = useMemo(
    () => ({
      hasCameraPermission,
      checkCameraPermissionAsync,
    }),
    [hasCameraPermission, checkCameraPermissionAsync],
  );

  return (
    <CameraContext.Provider value={value}>{children}</CameraContext.Provider>
  );
};

export const useCamera = (): CameraContextProps => {
  return useContext(CameraContext) as CameraContextProps;
};

export default CameraProvider;
