import React, { ReactNode } from 'react';
import { createGlobalStyle, css, ThemeProvider } from 'styled-components';
import { useTheme } from '../context';
import mediaMedium from '../pageTemplates/Templates/MediaMedium';

const ThemeCreator = ({
  children,
}: {
  children: ReactNode;
}): React.JSX.Element => {
  const themeContext = useTheme();
  const { primaryFont, secondaryFont } = themeContext.getFonts();
  const GlobalStyle = createGlobalStyle`


  html {
    font-size: 62.5%;
  } /* =10px */
  body {
    margin: 0;
    font-family: ${secondaryFont};
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.6em;
  }

  p {
    margin: 0px;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    font-size: 2rem;
    ${mediaMedium(
      css`
        font-size: 2.6rem;
      `,
    )}

      
  }
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 140%;
  }

  h1 {
    font-size: 2.6rem;
    font-family: ${primaryFont};
    ${mediaMedium(
      css`
        font-size: 3.6rem;
      `,
    )}
  }

  h1:focus {
    outline: 0;
  }

  h2:focus {
    outline: 0;
  }

  h3:focus {
    outline: 0;
  }

`;

  return (
    <ThemeProvider theme={themeContext.theme}>
      <>
        <GlobalStyle />
        {children}
      </>
    </ThemeProvider>
  );
};

export default ThemeCreator;
