/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable class-methods-use-this */

import Analyzer from './Analyzer';
import GlobalVariables from './GlobalVariables';
import ImageblockManager from './ImageblockManager';
import Result from './Result';
import { absSum } from './Util';

/* eslint-disable no-console */

class FrequencyFinder {
  private width: number;
  private height: number;
  private previousLuminanceValues: number[] = [];
  private differenceImagesArray: number[][] = [];
  private differenceImagesArraySize = 20;
  private imageNumberCounter = 0;
  private absoluteDifferenceThreshold = 20;
  // added RoiBlockSize because it is used in construct of ImageblockManager
  // we can remove it in future
  private RoiBlockSize = 50;
  private startTime = 0;
  private numberOfTimesRun = 0;

  private imageBlockManager: ImageblockManager;
  private analyzer: Analyzer;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;

    this.imageBlockManager = new ImageblockManager(
      width,
      height,
      this.RoiBlockSize,
    );

    this.analyzer = new Analyzer();

    for (let i = 0; i < this.differenceImagesArraySize; i++) {
      this.differenceImagesArray[i] = new Array<number>(width * height).fill(0);
    }

    this.previousLuminanceValues = new Array<number>(width * height).fill(0);
  }

  processImage(image: Uint8ClampedArray | undefined): void {
    if (this.startTime === 0) {
      this.startTime = Date.now();
    }

    if (typeof image !== undefined && image !== undefined && image.length > 0) {
      this.calculateDifferenceImage(image);

      const timeSinceLastCalculation = Date.now() - this.startTime;

      if (timeSinceLastCalculation >= 500) {
        // Reset time. This is used to calculate FPS, so needs to be reset BEFORE doing calc
        this.startTime = Date.now();
        // Try to find current frequency

        const sumOfChanges = this.imageBlockManager.calculateSumOfChanges(
          this.differenceImagesArray,
          timeSinceLastCalculation,
          this.imageNumberCounter - 1,
        );

        const newFrequency = this.findFrequency(sumOfChanges);
        // Invoke event
        document.dispatchEvent(
          new CustomEvent('compressionRateUpdated', {
            detail: { compressionRate: newFrequency },
          }),
        );
        // Reset image counter
        this.imageNumberCounter = 0;
      }
    }
  }

  calculateDifferenceImage(data: Uint8ClampedArray): void {
    const changes = this.differenceImagesArray[this.imageNumberCounter];
    let difference = 0;
    let luminance = 0;

    for (let i = 0; i < changes.length; i += 1) {
      luminance = data[i] & 0xff;
      difference = luminance - this.previousLuminanceValues[i];
      this.previousLuminanceValues[i] = luminance;
      if (Math.abs(difference) > this.absoluteDifferenceThreshold) {
        changes[i] = difference;
      } else {
        changes[i] = 0;
      }
    }

    if (this.imageNumberCounter < 19) {
      this.imageNumberCounter++;
    } else {
      console.debug(
        'Warning. Images are coming in faster than we are processing them...',
      );
    }
  }

  findFrequency(sumOfChanges: number[]): number {
    try {
      this.numberOfTimesRun++;

      if (absSum(sumOfChanges) < sumOfChanges.length * 200) {
        return 0;
      }

      if (this.numberOfTimesRun < 6) {
        // stop if not reached window length yet (this only happens in the beginning of a session)
        // this is done to avoid potential unexpected results when using a smaller window.
        return 0;
      }
      /**
       * This is usually dynamic based on frames/(date.now() - startTime, it's set to a constant here for performance reasons.
       * The author recommended 15fps. Anything more will just be unnecessary and lead to poor performance for weak phones.
       * Setting this to zero will disable the lower and upper frequency threshold leading to aliasing and the algorithm picking up wrong frequencies.
       */
      const fps = GlobalVariables.globalFPS;
      document.dispatchEvent(
        new CustomEvent('framesPerSecond', {
          detail: { frameRate: Math.trunc(fps) },
        }),
      );
      const analyzerResult: Result = this.analyzer.EstimateFrequencyByUsingFFT(
        sumOfChanges,
        fps,
      );
      const frequencyInIntFromFFT = analyzerResult.frequency;
      if (analyzerResult.noDistinctFrequencyFound) {
        return -1;
      }
      if (analyzerResult.frequency <= 0) {
        return -1;
      }
      return frequencyInIntFromFFT;
    } catch (e) {
      console.log('Freq analysis failed', e, 'CAMFA01');
      return -1;
    }
  }
}

export default FrequencyFinder;
