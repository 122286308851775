import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import constants from '../constants/Constants';

type ViewportContextProps = {
  currentOrientation: string;
  isLandscape: boolean;
  isMediumWidth: boolean;
};
type Props = {
  children: any;
};

const initSize = {
  currentOrientation: '',
  isLandscape: true,
  isMediumWidth: false,
};

const ViewportContext = React.createContext<ViewportContextProps>(initSize);

export const ViewportProvider: FunctionComponent<Props> = ({
  children,
}: Props) => {
  const [isLandscape, setIsLandscape] = useState<boolean>(
    window.innerWidth > window.innerHeight,
  );
  const [isMediumWidth, setMediumWidth] = useState<boolean>(false);
  const [currentOrientation, setCurrentOrientation] = useState<string>('');
  const { media } = constants;
  const mediaOrientationQuery = window.matchMedia(`(orientation:landscape)`);
  const mediaMediumQuery = window.matchMedia(`(min-width: ${media.MEDIUM}px)`);

  const mediaOrientationQueryListener = (e) => {
    if (e.matches) {
      setCurrentOrientation(constants.deviceOrientation.LANDSCAPE);
      setIsLandscape(true);
    } else {
      setCurrentOrientation(constants.deviceOrientation.PORTRAIT);
      setIsLandscape(false);
    }
  };

  const mediaMediumQueryListener = (e) => {
    if (e.matches && !window.revivr.isLearner) {
      setMediumWidth(true);
    } else {
      setMediumWidth(false);
    }
  };

  useEffect(() => {
    mediaOrientationQueryListener(mediaOrientationQuery);
    mediaOrientationQuery.addEventListener(
      'change',
      mediaOrientationQueryListener,
    );

    mediaMediumQueryListener(mediaMediumQuery);
    mediaMediumQuery.addEventListener('change', mediaMediumQueryListener);

    return () => {
      mediaOrientationQuery.removeEventListener(
        'change',
        mediaOrientationQueryListener,
      );
      mediaMediumQuery.removeEventListener('change', mediaMediumQueryListener);
    };
  }, []);

  return (
    <ViewportContext.Provider
      value={{
        currentOrientation,
        isLandscape,
        isMediumWidth,
      }}
    >
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = (): ViewportContextProps => {
  const { currentOrientation, isLandscape, isMediumWidth } =
    useContext(ViewportContext);
  return {
    currentOrientation,
    isLandscape,
    isMediumWidth,
  };
};
