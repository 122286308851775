import React from 'react';
import ThemeProvider from '../../../context/ThemeProvider';
import { AudioProvider, FirebaseProvider } from '../../../context';

import LoadingPage from '../../../pages/LoadingContentPage';
import GlobalStyle from '../../../theme/globalStyles';
import AblyProvider from '../context/AblyContext';
import GroupSanityProvider from '../context/GroupSanityContext';

import FacilitatorRouting from './FacilitatorRouting';

const FacilitatorApp = (props) => {
  return (
    <GroupSanityProvider>
      <FirebaseProvider>
        <AblyProvider>
          <ThemeProvider>
            <AudioProvider>
              <LoadingPage>
                <GlobalStyle>
                  <FacilitatorRouting />
                </GlobalStyle>
              </LoadingPage>
            </AudioProvider>
          </ThemeProvider>
        </AblyProvider>
      </FirebaseProvider>
    </GroupSanityProvider>
  );
};
FacilitatorApp.propTypes = {};

export default FacilitatorApp;
