import {
  ToastColor,
  ToastEntryDirection,
  ToastPosition,
  useToast,
} from '@laerdal/life-react-components';
import * as Ably from 'ably';
import { useEffect } from 'react';
import useStateRef from 'react-usestateref';
import { ConnectionStates } from '../../groupRevivr/types/AblyConnectionTypes';

export enum ToasterType {
  Error = 0,
  Warning = 1,
  Information = 2,
  success = 3,
}

export const useToasterFactory = () => {
  const { addToast } = useToast();
  const [visible, setVisible, visibleRef] = useStateRef<boolean>(false);
  const [
    pendingConnectedToaster,
    setPendingConnectedToaster,
    pendingConnectedToasterRef,
  ] = useStateRef<boolean>(false);
  const [connectionState, setConnectionState, connectionStateRef] =
    useStateRef<Ably.Types.ConnectionState>(ConnectionStates.closed);

  const toasterDisplayDurationInMs = 5000;

  const createToast = (
    toasterType: ToasterType,
    text: string,
    delay = toasterDisplayDurationInMs,
    autoClose = true,
    showCloseButton = true,
  ) => {
    let color;

    switch (toasterType) {
      case ToasterType.Error:
        color = ToastColor.RED;
        break;
      case ToasterType.Warning:
        color = ToastColor.ORANGE;
        break;
      case ToasterType.Information:
        color = ToastColor.BLUE;
        break;
      default:
        color = ToastColor.GREEN;
        break;
    }

    if (visibleRef.current && toasterType === ToasterType.success) {
      setPendingConnectedToaster(true);
    }

    if (!visibleRef.current) {
      addToast(text, {
        color,
        showCloseButton,
        autoClose,
        position: ToastPosition.TOPMIDDLE,
        enterFrom: ToastEntryDirection.TOP,
        delay,
      });
      setVisible(true);
    }
  };

  const connectionStateChanged = (state: Ably.Types.ConnectionState) => {
    setConnectionState(state);
  };

  const ShowPendingConnectedToaster = () => {
    if (connectionStateRef.current === ConnectionStates.connected) {
      createToast(
        ToasterType.success,
        'Connected!',
        toasterDisplayDurationInMs,
      );
    }
  };

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
        if (pendingConnectedToasterRef.current) {
          ShowPendingConnectedToaster();
          setPendingConnectedToaster(false);
        }
      }, toasterDisplayDurationInMs);
    }
  }, [visible]);

  return {
    createToast,
    connectionStateChanged,
  };
};

export {};
