import groq from 'groq';
import _ from 'lodash';
import checkForInsertedDevOpsVariable from '../../helper/checkForInsertedDevOpsVariable';

/* eslint-disable @typescript-eslint/no-use-before-define */
const iconImageQueryString = (): string => {
  return `
    "iconImage": iconImage{
      ...,
      "src": asset->url,                           
    },
  `;
};
// Query for lottie.js in Sanity
const lottieQueryString = (): string => {
  return groq`"lottie": lottie {
    ...,
      "src": lottieFile.asset->url
  },`;
};

// for querying { name: image, type: customImage } type from sanity
const customImageQueryString = (): string => {
  return `"image": image{...,
    ${iconImageQueryString()}
    ${lottieQueryString()}
    "fullWidthImage": fullWidthImage{ ...,
      "src": asset->url,
    },
  },`;
};

// For querying the sanityType 'banner' from sanity
const bannerQueryString = (): string => {
  return `"banner": banner{...,
    "backImage":backImage{ ...,
      "src": asset->url,
    },
    "frontImage":frontImage{ ...,
      "src": asset->url,
    },
  },`;
};

const continueCourseModal = (): string => {
  return `
  "continueCourseModal": continueCourseModal {
    ...,
    "url":  startOverPage -> slug.current,
  },`;
};
/**
 *
 * @param number recursion stopper, should be 0 or undefined. (Is used internally within the function)
 * @returns a GROQ query string for buttons, with 3 levels of modals. (The modal contains buttons)
 * number is used internally within the function to stop recursion to run too many times.
 */
const modalQueryString = (
  recursionStopper = 0,
  modalName = 'modal',
): string => {
  return `
  "${modalName}": ${modalName}{
    ...,
    "text": text {
      ...,
      "bulletPoints": bulletPoints[] {
        ...,
        ${iconImageQueryString()}                          
      },             
    },
    "bannerImage": bannerImage{ ...,
      "src": asset->url,
    },
    ${buttonsQueryString(recursionStopper + 1)}
  },`;
};

/**
 *
 * @param number recursion stopper, should be 0 or undefined. (Is used internally within the function)
 * @returns a GROQ query string for buttons, with 3 levels of modals. (The modal contains buttons)
 * number is used internally within the function to stop recursion to run too many times.
 */
const buttonsQueryString = (number = 0): string => {
  if (number > 3) {
    return ``;
  }

  return `
  "buttons": buttons[] {
    ..., 
    "url": navigateToPage ->slug.current,
    ${modalQueryString(number)}
    "cameraPermission": cameraPermission{
      ...,
      "navigateOnPermission": navigateOnPermission-> slug.current,
      "navigateOnPermissionDenied": navigateOnPermissionDenied-> slug.current,
      ${modalQueryString(number)}
    },
  },
  `;
};

const cardsQueryString = (): string => {
  return `
  "cards": cards[] {
    ..., 
    ${iconImageQueryString()}
  },
  `;
};

const trainingResultPageQueryString = () => {
  return `
    ...,
    ${iconImageQueryString()}  
    ${buttonsQueryString()}`;
};

const frontPageTemplateQueryString = (): string => {
  return `
  frontPageTemplate{
    ..., 
    ${bannerQueryString()}
    ${customImageQueryString()}
    ${buttonsQueryString()}
    "text": text {
      ...,
      "bulletPoints": bulletPoints[] {
        ...,
        ${iconImageQueryString()}                           
      },             
    },
  },`;
};

const learnerFrontPageTemplateQueryString = (): string => {
  return `
  learnerFrontPageTemplate{
    ..., 
    ${bannerQueryString()}
    ${customImageQueryString()}
    ${buttonsQueryString()}
    "text": text {
      ...,
      "bulletPoints": bulletPoints[] {
        ...,
        ${iconImageQueryString()}                           
      },             
    },
  },`;
};

const learnerTeamUpPageTemplateQueryString = (): string => {
  return `
  showMyTeamTemplate{
    ..., 
    ${customImageQueryString()}
    "text": text {
      ...,
      "bulletPoints": bulletPoints[] {
        ...,
        ${iconImageQueryString()}                           
      },             
    },
  },`;
};

const dialogModalImage = () => {
  return `
  "dialog": dialog {
    ...,
    "answerDialog": answerDialog {
      ...,
      "button": button {
        ...,
        "modal": modal {
          ...,
          "bannerImage": bannerImage{ ...,
            "src": asset->url,
          },
        }, 
      },
    },
  },
  `;
};

const dialogPageTemplateQueryString = (): string => {
  return `
  dialogPageTemplate{
    ..., 
    ${continueCourseModal()}
    "mainImage": mainImage {
      ...,
      "src": asset->url,
    },
  },`;
};

const aedTemplateQueryString = (): string => {
  return `
  aedTemplate{
    ..., 
    ${customImageQueryString()}
    "volumeDialog": volumeDialog {
      ...,
      "image": image {
        ...,
        "customSizeImage": customSizeImage{
          ...,
          "src": asset->url,
        },
      }
    },
    ${continueCourseModal()}
  },`;
};

const aedDraggableTemplateQueryString = (): string => {
  return `
  aedDraggablePadPageTemplate{
    ...,
    "image": image {
      ...,
      "src": asset->url,
    },
    "Correct Placement Image": image {
      ...,
      "src": asset->url,
    },
    "rightPadAlternateImageGroup": rightPadAlternateImageGroup {
      ...,
      "alternateRightPadImage": alternateRightPadImage {
        ...,
        "src": asset->url,
      }
    },
    aedPadHelpModal{
      ...,
      ${iconImageQueryString()}
    }
  },`;
};

const aedBubbleTemplateQueryString = (): string => {
  return `
  aedBubbleTemplate{
    ...,
    "fullWidthImage": fullWidthImage{ ...,
      "src": asset->url,
    },
    ${continueCourseModal()}
    ${lottieQueryString()}
  },`;
};

const textAndImageTemplateQueryString = (): string => {
  return `
  textAndImageTemplate{
    ..., 
    ${customImageQueryString()} 
    ${buttonsQueryString()}
    ${dialogModalImage()}
    "text": text {
      ...,
      "bulletPoints": bulletPoints[] {
        ...,
        ${iconImageQueryString()}                          
      },             
    },
  },`;
};
const imageTemplateQueryString = (): string => {
  return `
  imageTemplate{
    ..., 
    ${customImageQueryString()} 
    ${buttonsQueryString()}
  },`;
};

const learnerTeamSplitQuery = (): string => {
  return `
  learnerRoleSplitTemplate{
    ..., 
    "roleA": roleA {
      ...,  
    }, 
    "roleB": roleB {
      ...,
    },
  },`;
};

const teamUpTheLearnersTemplateQueryString = (): string => {
  return `
  teamUpTheLearnersTemplate{
    ...,
    ${customImageQueryString()}
    ${buttonsQueryString()}
    ${continueCourseModal()}
    "text": text {
      ...,
      "bulletPoints": bulletPoints[] {
        ...,
        ${iconImageQueryString()}
      },
    },
  },`;
};

const diplomaTemplateQueryString = (): string => {
  return `
  diplomaTemplate{
    ..., 
    ${buttonsQueryString()}
    "text": text {
      ...,
      "bulletPoints": bulletPoints[] {
        ...,
        ${iconImageQueryString()}                          
      },             
    },
    "diplomaImage": diplomaImage {
      ...,
      "src": asset->url
    },
    ${modalQueryString(0, 'successModal')}
    ${modalQueryString(0, 'errorModal')}
  },`;
};

const inputTemplateQueryString = (): string => {
  return `
  inputTemplate{
    ..., 
    ${buttonsQueryString()}
    "text": text {
      ...,
      "bulletPoints": bulletPoints[] {
        ...,
        ${iconImageQueryString()}                         
      },             
    },
  },`;
};

const emailReminderTemplateQueryString = (): string => {
  return `
  emailReminderTemplate{
    ...,
    ${customImageQueryString()}
  },`;
};

const clickableCardsTemplateQueryString = (): string => {
  return `
  clickableCardsTemplate{
    ...,
    ${bannerQueryString()}
    ${cardsQueryString()}
  },`;
};

const emergencyCallTemplateQueryString = (): string => {
  return `
  emergencyCallTemplate{
    ..., 
    "emergencyCallModal": emergencyCallModal{
      ...,
      "emergencyCallModalHeaderImage": emergencyCallModalHeaderImage {
        ...,
        ${iconImageQueryString()}
        "fullWidthImage": fullWidthImage{ ...,
          "src": asset->url,
        },
      },
    },
  },`;
};

const trainingTemplateQueryString = (): string => {
  return `
  trainingTemplate{
    ...,
    "trainingPage": trainingPage{
      ...,
      "lottie": lottie {
        ...,
        "path": lottie.asset->url,
      },
      "trainingIntro": trainingIntro{
        ...,
        "emergencyOperatorImagePath": emergencyOperatorImage.asset->url,
      },
    },
    "resultPages": resultPages{
      ...,
      "compressFaster": compressFaster{
        ${trainingResultPageQueryString()}        
      },
      "compressSlower": compressSlower{
        ${trainingResultPageQueryString()}
      },
      "goodRate": goodRate{
        ${trainingResultPageQueryString()}
      },
      "badRate": badRate{
        ${trainingResultPageQueryString()}
      },
      "couldNotDetect": couldNotDetect{
        ${trainingResultPageQueryString()}
      },
      "debriefWithNoCameraAccess": debriefWithNoCameraAccess{
        ${trainingResultPageQueryString()}
      },
    },
  },`;
};

const connectedLearnersTemplateQueryString = (): string => {
  return `
  connectedLearnersTemplate{
    ..., 
    ${bannerQueryString()}      
  },`;
};

const templatesQueryString = (): string => {
  return `
    ${aedBubbleTemplateQueryString()}
    ${aedTemplateQueryString()}
    ${aedDraggableTemplateQueryString()}
    ${dialogPageTemplateQueryString()}
    ${diplomaTemplateQueryString()}
    ${emergencyCallTemplateQueryString()}
    ${frontPageTemplateQueryString()}
    ${inputTemplateQueryString()}
    ${textAndImageTemplateQueryString()}
    ${trainingTemplateQueryString()}
    ${emailReminderTemplateQueryString()}
    ${teamUpTheLearnersTemplateQueryString()}
  `;
};

const projectPagesQueryString = (fieldName: string): string => {
  return `${fieldName}{
  ...coalesce(
    *[_id == 'drafts.' + ^._ref][0],
    *[_id == ^._ref][0]
  ) {
  ...,
  ${textAndImageTemplateQueryString()}
  ${frontPageTemplateQueryString()}
  ${clickableCardsTemplateQueryString()}
  }
},        
`;
};

const facilitatorTemplatesQueryString = (): string => {
  return `
  ${connectedLearnersTemplateQueryString()}
  ${imageTemplateQueryString()}
  ${templatesQueryString()}
  ${teamUpTheLearnersTemplateQueryString()}
  `;
};

const projectSettingsGROQuery = (language?: string) => {
  if (!language) {
    return `projectSettings -> {...,},`;
  }
  return `
  projectSettings -> {
    "translation":
    *[_type == "translation.metadata" && references(^._id)] {
      "translations":
        translations[_key == '${language}'] {
          value -> {
            ...,
          }  
        }
    }
  },`;
};

export const projectWithIdGROQuery = (projectId: string): string => {
  return `*[ _type == "project"] [_id == "${projectId}" ] { 
    organization -> { ..., brandLogo{..., "src": asset->url } },
    ${projectSettingsGROQuery()}
    projectPages{...,
      ${projectPagesQueryString('browserNotSupported')}
      ${projectPagesQueryString('landingPage')}
      ${projectPagesQueryString('pageForPcUsers')}
      emailReminderPage{
        ...coalesce(
          *[_id == 'drafts.' + ^._ref][0],
          *[_id == ^._ref][0]
        ) {
        ...,
        ${emailReminderTemplateQueryString()}
        }        
      },
      ${projectPagesQueryString('privacyPolicy')}
      ${projectPagesQueryString('termsAndConditions')}
    },    
  } [0]`;
};

const projectPagesTranslationGROQuery = (language: string): string => {
  return `
    "translation":
      *[_type == "translation.metadata" && references(^._id)] {
        "translations":
          translations[_key == '${language}'] {
            value -> {
              ...,
              ${textAndImageTemplateQueryString()}
              ${frontPageTemplateQueryString()}
            }  
          }
      }`;
};

const getSupportedLanguages = (): string => {
  return `
    "translation":
      *[_type == "translation.metadata" && references(^._id)] {
        "translations":
          translations[] {
            value -> {
              language,
            }  
          }
      }`;
};

const emailprojectPageTranslationGROQuery = (language: string): string => {
  return `
    "translation":
      *[_type == "translation.metadata" && references(^._id)] {
        "translations": 
          translations[_key == '${language}'] {
            value -> {
              ...,
              ${emailReminderTemplateQueryString()}
            }  
          }
      }`;
};

export const getProjectSupportedLanguagesGROQQuery = (
  projectId: string,
): string => {
  return `*[ _type == "project"] [_id == "${projectId}" ] { 
    projectPages{
      landingPage{
        ...coalesce(
          *[_id == 'drafts.' + ^._ref][0],
          *[_id == ^._ref][0]
        ) {
          ${getSupportedLanguages()}
        }     
      },
    },
  }`;
};

export const projectWithIdAndLocalizationGROQuery = (
  projectId: string,
  language: string,
): string => {
  return `*[ _type == "project"] [_id == "${projectId}" ] { 
    ... ,
    organization -> { ..., brandLogo{..., "src": asset->url } },
    ${projectSettingsGROQuery(language)}
    projectPages{...,
      browserNotSupported{
        ...coalesce(
          *[_id == 'drafts.' + ^._ref][0],
          *[_id == ^._ref][0]
        ) {
          ${projectPagesTranslationGROQuery(language)}
        }     
      },
      landingPage{
        ...coalesce(
          *[_id == 'drafts.' + ^._ref][0],
          *[_id == ^._ref][0]
        ) {
          ${projectPagesTranslationGROQuery(language)}
        }     
      },
      pageForPcUsers{
        ...coalesce(
          *[_id == 'drafts.' + ^._ref][0],
          *[_id == ^._ref][0]
        ) {
          ${projectPagesTranslationGROQuery(language)}
        }     
      },
      emailReminderPage{
        ...coalesce(
          *[_id == 'drafts.' + ^._ref][0],
          *[_id == ^._ref][0]
        ) {
          ${emailprojectPageTranslationGROQuery(language)}
        }     
      },
      privacyPolicy{
        ...coalesce(
          *[_id == 'drafts.' + ^._ref][0],
          *[_id == ^._ref][0]
        ) {
          ${projectPagesTranslationGROQuery(language)}
        }     
      },
      termsAndConditions{
        ...coalesce(
          *[_id == 'drafts.' + ^._ref][0],
          *[_id == ^._ref][0]
        ) {
          ${projectPagesTranslationGROQuery(language)}
        }     
      },
    },    
  } [0]`;
};

export const navigationWithIdGROQuery = (projectId: string): string => {
  return `*[ _type == "project"] [_id == "${projectId}" ] { 
    navigation []  {
      ...coalesce(
        *[_id == 'drafts.' + ^._ref][0],
        *[_id == ^._ref][0]
      ) {
        ...,
        ${templatesQueryString()}
        sounds [] -> { 
          ..., 
          audioType -> {...} 
        },
        section -> {
          ...,
          ${continueCourseModal()}
        }, 
      }
    }     
  } [0]`;
};
const sectionTranslationGroqQuery = (language: string) => {
  return `
  section{
    ...coalesce(
      *[_id == 'drafts.' + ^._ref][0],
      *[_id == ^._ref][0]
    ) {
      "translation":
      *[_type == "translation.metadata" && references(^._id)] {
        "translations":
          translations[_key == '${language}'] {
            value -> {
              ...,
              ${continueCourseModal()}              
            }  
          }
      }
    }  
  },`;
};

const navigationPagesTranslationGROQuery = (language: string): string => {
  return `
    "translation":
      *[_type == "translation.metadata" && references(^._id)] {
        "translations":
          translations[_key == '${language}'] {
            value -> {
              ...,
                ${templatesQueryString()}
                sounds [] -> { 
                  ..., 
                  audioType -> {...} 
                },
                ${sectionTranslationGroqQuery(language)}
            }
          }
      }`;
};

export const navigationWithIdAndLocalizationGROQuery = (
  projectId: string,
  language: string,
): string => {
  return `*[ _type == "project"] [_id == "${projectId}" ] { 
    navigation []  {
      ...coalesce(
        *[_id == 'drafts.' + ^._ref][0],
        *[_id == ^._ref][0]
      ) {
        ${navigationPagesTranslationGROQuery(language)}
      }
    }     
  } [0]`;
};

export const learnerGroupProjectWithIdGROQuery = (
  projectId: string,
): string => {
  return `*[ _type == "groupProject"] [_id == "${projectId}" ] { 
    ... ,
    organization -> { ..., brandLogo{..., "src": asset->url } },
    ${groupProjectSettingsGROQuery()}
    projectPages {...,
      facilitatorLandingPage{
          ...coalesce(
             *[_id == 'drafts.' + ^._ref][0],
             *[_id == ^._ref][0]
           ) {
          ...,
          ${textAndImageTemplateQueryString()}
          ${frontPageTemplateQueryString()}
          }
      },
      learnerLandingPage{
        ...coalesce(
           *[_id == 'drafts.' + ^._ref][0],
           *[_id == ^._ref][0]
         ) {
        ...,
        ${learnerFrontPageTemplateQueryString()}
        }
      },
      waitingPage{
        ...coalesce(
           *[_id == 'drafts.' + ^._ref][0],
           *[_id == ^._ref][0]
         ) {
        ...,
        ${textAndImageTemplateQueryString()}
        ${frontPageTemplateQueryString()}
        }
      },
    },  
  } [0]`;
};

export const learnerGroupProjectWithIdAndLocalizationGROQuery = (
  projectId: string,
  language: string,
): string => {
  return `
  *[ _type == "groupProject"] [_id == "${projectId}" ] { 
    ... ,
    organization -> { ..., brandLogo{..., "src": asset->url } },
    ${groupProjectSettingsGROQuery(language)}
    projectPages {...,
      facilitatorLandingPage{
          ...coalesce(
             *[_id == 'drafts.' + ^._ref][0],
             *[_id == ^._ref][0]
           ) {
          ${projectPagesTranslationGROQuery(language)}
          }
      },
      learnerLandingPage{
        ...coalesce(
           *[_id == 'drafts.' + ^._ref][0],
           *[_id == ^._ref][0]
         ) {
        ${learnerProjectPagesTranslationGROQuery(language)}
        }
      },
      waitingPage{
        ...coalesce(
           *[_id == 'drafts.' + ^._ref][0],
           *[_id == ^._ref][0]
         ) {
          ${projectPagesTranslationGROQuery(language)}
        }
      },
    },  
  } [0]`;
};
export const learnerGroupNavigationWithIdGROQuery = (
  projectId: string,
): string => {
  return `*[ _type == "groupProject"] [_id == "${projectId}" ] { 
    navigation []  {
      ...coalesce(
        *[_id == 'drafts.' + ^._ref][0],
        *[_id == ^._ref][0]
      ) {
        ...,
        learnerNavigation {
          ...coalesce(
            *[_id == 'drafts.' + ^._ref][0],
            *[_id == ^._ref][0]
          ) {
            ...,
            ${learnerTeamUpPageTemplateQueryString()}
            ${templatesQueryString()}
            ${learnerTeamSplitQuery()}
            sounds [] -> { 
              ..., 
              audioType -> {...} 
            },
            section -> {
              ...,
              ${continueCourseModal()}
            },
          }  
        },
        template {
          ...coalesce(
            *[_id == 'drafts.' + ^._ref][0],
            *[_id == ^._ref][0]
          ) {
            ...,
          }
        },       
        section -> {
          ...,
          ${continueCourseModal()}
        }, 
      }
    }     
  } [0]`;
};
const learnerNavigationPagesTranslationGROQuery = (
  language: string,
): string => {
  return `
    "translation":
      *[_type == "translation.metadata" && references(^._id)] {
        "translations":  
          translations[_key == '${language}'] {
            value -> {
              ...,       
              ${learnerTeamUpPageTemplateQueryString()}
              ${templatesQueryString()}
              ${learnerTeamSplitQuery()}
              sounds [] -> { 
                ..., 
                audioType -> {...} 
              },
              ${sectionTranslationGroqQuery(language)}
            }
          }
      }`;
};
export const learnerGroupNavigationWithIdAndLocalizationGROQuery = (
  projectId: string,
  language: string,
): string => {
  return `
  *[ _type == "groupProject"] [_id == "${projectId}" ] { 
    navigation []  {
      ...coalesce(
        *[_id == 'drafts.' + ^._ref][0],
        *[_id == ^._ref][0]
      ) {
        ...,
        learnerNavigation {
          ...coalesce(
            *[_id == 'drafts.' + ^._ref][0],
            *[_id == ^._ref][0]
          ) {
            ${learnerNavigationPagesTranslationGROQuery(language)}
          }  
        },
        ${sectionTranslationGroqQuery(language)} 
      }
    }     
  } [0]
  `;
};

export const facilitatorGroupProjectWithIdGROQuery = (
  projectId: string,
): string => {
  return `*[ _type == "groupProject"] [_id == "${projectId}" ] { 
    ... ,
    organization -> { ..., brandLogo{..., "src": asset->url } },
    ${groupProjectSettingsGROQuery()}
    projectPages {...,
      facilitatorLandingPage{
          ...coalesce(
             *[_id == 'drafts.' + ^._ref][0],
             *[_id == ^._ref][0]
           ) {
          ...,
          ${textAndImageTemplateQueryString()}
          ${frontPageTemplateQueryString()}
          }
      },
      learnerLandingPage{
        ...coalesce(
           *[_id == 'drafts.' + ^._ref][0],
           *[_id == ^._ref][0]
         ) {
        ...,
        ${learnerFrontPageTemplateQueryString()}
        }
      },
      waitingPage{
        ...coalesce(
           *[_id == 'drafts.' + ^._ref][0],
           *[_id == ^._ref][0]
         ) {
        ...,
        ${textAndImageTemplateQueryString()}
        ${frontPageTemplateQueryString()}
        }
      },  
    },
  } [0]`;
};
const learnerProjectPagesTranslationGROQuery = (language: string): string => {
  return `
    "translation":
      *[_type == "translation.metadata" && references(^._id)] {
        "translations":
          translations[_key == '${language}'] {
            value -> {
              ...,
              ${learnerFrontPageTemplateQueryString()}
            }  
          }
      }`;
};
const groupProjectSettingsGROQuery = (language?: string) => {
  if (!language) {
    return `groupProjectSettings -> {...,},`;
  }
  return `
  groupProjectSettings -> {
    "translation":
    *[_type == "translation.metadata" && references(^._id)] {
      "translations":
        translations[_key == '${language}'] {
          value -> {
            ...,
          }  
        }
    }
  },`;
};

export const getGroupProjectSupportedLanguagesGROQQuery = (
  projectId: string,
): string => {
  return `*[ _type == "groupProject"] [_id == "${projectId}" ] { 
    projectPages{
      facilitatorLandingPage{
        ...coalesce(
          *[_id == 'drafts.' + ^._ref][0],
          *[_id == ^._ref][0]
        ) {
          ${getSupportedLanguages()}
        }     
      },
    },
  }`;
};

export const facilitatorGroupProjectWithIdAndLocalizationGROQuery = (
  projectId: string,
  language: string,
): string => {
  return `
  *[ _type == "groupProject"] [_id == "${projectId}" ] { 
    ... ,
    organization -> { ..., brandLogo{..., "src": asset->url } },
    ${groupProjectSettingsGROQuery(language)}
    projectPages {...,
      facilitatorLandingPage{
          ...coalesce(
             *[_id == 'drafts.' + ^._ref][0],
             *[_id == ^._ref][0]
           ) {
            ${projectPagesTranslationGROQuery(language)}
          }
      },
      learnerLandingPage{
        ...coalesce(
           *[_id == 'drafts.' + ^._ref][0],
           *[_id == ^._ref][0]
         ) {
          ${learnerProjectPagesTranslationGROQuery(language)}
        }
      },
      waitingPage{
        ...coalesce(
           *[_id == 'drafts.' + ^._ref][0],
           *[_id == ^._ref][0]
         ) {
          ${projectPagesTranslationGROQuery(language)}
        }
      },
    },
  } [0]`;
};
export const facilitatorGroupNavigationWithIdGROQuery = (
  projectId: string,
): string => {
  return `*[ _type == "groupProject"] [_id == "${projectId}" ] {
    navigation []  {
      ...coalesce(
        *[_id == 'drafts.' + ^._ref][0],
        *[_id == ^._ref][0]
      ) {
        ...,
        facilitatorNavigation {
          ...coalesce(
            *[_id == 'drafts.' + ^._ref][0],
            *[_id == ^._ref][0]
          ) {
            ...,       
            ${facilitatorTemplatesQueryString()}
            sounds [] -> { 
              ..., 
              audioType -> {...} 
            },
            section -> {
              ...,
              ${continueCourseModal()}
            },
          }  
        },
        template {
          ...coalesce(
            *[_id == 'drafts.' + ^._ref][0],
            *[_id == ^._ref][0]
          ) {
            ...,
          }
        },       
        section -> {
          ...,
          ${continueCourseModal()}
        },  
      }
    }     
  } [0]`;
};
const facilitatorNavigationPagesTranslationGROQuery = (
  language: string,
): string => {
  return `
    "translation":
      *[_type == "translation.metadata" && references(^._id)] {
        "translations":   
          translations[_key == '${language}'] {
            value -> {
              ...,       
              ${facilitatorTemplatesQueryString()}
              sounds [] -> { 
                ..., 
                audioType -> {...} 
              },
              ${sectionTranslationGroqQuery(language)}
            }
          }
      }`;
};
export const facilitatorGroupNavigationWithIdAndLocalizationGROQuery = (
  projectId: string,
  language: string,
): string => {
  return `
  *[ _type == "groupProject"] [_id == "${projectId}" ] {
    navigation []  {
      ...coalesce(
        *[_id == 'drafts.' + ^._ref][0],
        *[_id == ^._ref][0]
      ) {
        ...,
        facilitatorNavigation {
          ...coalesce(
            *[_id == 'drafts.' + ^._ref][0],
            *[_id == ^._ref][0]
          ) {
            ${facilitatorNavigationPagesTranslationGROQuery(language)}
          }  
        },        
        ${sectionTranslationGroqQuery(language)}  
      }
    }     
  } [0]
  `;
};
export const settingsWithIdGROQuery = (organizationId: string): string => {
  return `*[ _type == "organizationSettings"] [_id == "${organizationId}_settings" ] { 
    ... ,
    theme -> {...,
    }
  } [0]`;
};

const roleTemplateTypesQueryString = (): string => {
  return `
    ${aedDraggableTemplateQueryString()}
    ${aedTemplateQueryString()}
    ${emergencyCallTemplateQueryString()}
    ${teamUpTheLearnersTemplateQueryString()}
    ${textAndImageTemplateQueryString()}
    ${trainingTemplateQueryString()}
  `;
};

const learnerTeamSplitFullQuery = (): string => {
  return `
  learnerRoleSplitTemplate{
    ..., 
    "roleA": roleA {
      ...,
      ${roleTemplateTypesQueryString()}    
    }, 
    "roleB": roleB {
      ...,
      ${roleTemplateTypesQueryString()}
    },
  },`;
};

/**
 *
 * @param learnerNavIds an array of learnerNavigation ids
 * @param chunkSize the size of the chunks to split the query into. Defaults to 10. This is to reduce the size of the individual queries.
 * @returns an array of GROQ query strings for split template. The query strings are split into chunks of 10 ids.
 */
export const learnerNavigationWithLearnerRoleSplitTemplate = (
  learnerNavIds: string | string[],
  chunkSize = checkForInsertedDevOpsVariable(
    '#{SplitTemplateQueryChunkSize}#',
    '10',
  ) as unknown as number,
): string | string[] => {
  const idQuery = Array.isArray(learnerNavIds)
    ? _.chunk(learnerNavIds, chunkSize).map(
        (chunk) => `[_id in ["${chunk.join('","')}"]]`,
      )
    : `[_id == "${learnerNavIds}" ]]`;
  const fullQuery = (id) => {
    return `*[ _type == "learnerNavigation"] ${id} {
    ...,
    ${learnerTeamSplitFullQuery()}
    sounds [] -> { 
      ..., 
      audioType -> {...} 
    },
    section -> {
      ...,
      ${continueCourseModal()}
    },
  }`;
  };
  return Array.isArray(idQuery)
    ? idQuery.map((idChunk) => fullQuery(idChunk))
    : fullQuery(idQuery);
};
