/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import styled from 'styled-components';
import CameraFilter from '../../components/CameraFilter/cameraFilter';
import constants from '../../constants/Constants';
import { useTcprLink, useViewport } from '../../context';
import FrequencyFinder from '../../features/cprAlgorithm/FrequencyFinder';
import { translatePermissionDeniedErrorToCameraPermissionDeniedError } from '../../helper/CameraHelper';
import { logError } from '../../services/errorHandling';

const Video = styled.video<{ width: number; height: number }>`
  background-color: 'grey';
  border-radius: 8px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;
const VideoContainer = styled.div`
  background-color: 'grey';
  z-index: 6;
`;

interface ITcprLinkFrameProps {
  displayVideoFeed?: boolean;
  stopStreamOnTermination?: boolean; // Indicates if we shall terminate the video stream. Usually after the course end and we do not need the video camera to be active
}

const yImage = new Uint8ClampedArray(
  constants.video.sizes.algorithm.height *
    constants.video.sizes.algorithm.width,
);

const ConvertRGBAtoYUV888 = (
  image: Uint8ClampedArray,
): Uint8ClampedArray | undefined => {
  let j = 0;

  // convert from RGBA to YUV888
  for (let i = 0; i < image.length; i += 4) {
    const r = image[i];
    const g = image[i + 1];
    const b = image[i + 2];

    const y = r * 0.299 + g * 0.587 + b * 0.114;
    j += 3;

    yImage[j / 3] = y;
  }
  return yImage;
};

const frequenyFinder = new FrequencyFinder(
  constants.video.sizes.algorithm.height,
  constants.video.sizes.algorithm.width,
);

const TcprLinkFrame: React.FunctionComponent<ITcprLinkFrameProps> = ({
  stopStreamOnTermination = false,
  displayVideoFeed = true,
}: ITcprLinkFrameProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const tcprLinkContext = useTcprLink();
  let cameraStream: MediaStream | null = null;
  const [postImage, setPostImage] = useState(false);
  const { isLandscape } = useViewport();

  const getAndPostImage = () => {
    const canvas = document.createElement('canvas');
    // const canvas = document.getElementById('testCanvas');

    // scale the canvas to fit the requirements for the camera algorithm
    canvas.width = constants.video.sizes.algorithm.width;
    canvas.height = constants.video.sizes.algorithm.height;
    const context = canvas.getContext('2d');

    if (context === null || videoRef.current == null) {
      return;
    }

    context.drawImage(
      videoRef.current,
      0,
      0,
      constants.video.sizes.algorithm.width,
      constants.video.sizes.algorithm.height,
    );

    // Should consider blob  https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
    // DataURL (BASE64) is imageData compressed to JPG or PNG, then converted to string, and this string is larger by 37% (info) than BLOB.

    const rpgImage = context.getImageData(
      0,
      0,
      constants.video.sizes.algorithm.width,
      constants.video.sizes.algorithm.height,
    ).data;

    const convertedImage = ConvertRGBAtoYUV888(rpgImage);

    if (convertedImage !== undefined) {
      frequenyFinder.processImage(convertedImage);
    }
  };

  const getVideo = (testVideo: string | null) => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          // Messy check because mobile phones don't work as you expect..
          width: isLandscape
            ? constants.video.sizes.mediaStream.width
            : isMobile
            ? constants.video.sizes.mediaStream.width
            : constants.video.sizes.mediaStream.height,
          height: isLandscape
            ? constants.video.sizes.mediaStream.height
            : isMobile
            ? constants.video.sizes.mediaStream.height
            : constants.video.sizes.mediaStream.width,
        },
      })
      .then((stream) => {
        const video = videoRef.current;
        if (video !== null) {
          video.setAttribute('autoplay', '');
          video.setAttribute('muted', ''); // Need to have this on the <Video> element as well
          video.setAttribute('playsinline', '');

          // feature to show a premade video instead of the web camera
          if (testVideo && testVideo.length > 0) {
            video.setAttribute('loop', 'true');
            video.src = `/video/${testVideo}`;
          } else {
            video.srcObject = stream;
          }

          video.play();
          cameraStream = stream;
        }
      })
      .catch((error) => {
        error =
          translatePermissionDeniedErrorToCameraPermissionDeniedError(error);
        logError(error);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (tcprLinkContext?.isStarted) {
        getAndPostImage();
        setPostImage(true);
      }
    }, constants.algorithmSendTimeoutMs);
    return () => {
      clearInterval(interval);
    };
  }, [postImage, tcprLinkContext?.isStarted]);

  useEffect(() => {
    if (postImage === true) {
      setPostImage(false);
    }
  }, [tcprLinkContext?.isStarted]);

  useEffect(() => {
    // added a feature so we can add a premade video and test the algorithm
    // ?testvideo=stfc_2_tct_60_ttwua_4_acr_112_tnoc_113.mp4
    const params = new URLSearchParams(window.location.search);
    const videoName = params.get('testvideo');
    getVideo(videoName);
  }, [videoRef, isLandscape]);

  useEffect(() => {
    return () => {
      if (
        (cameraStream !== null && !isIOS) ||
        (cameraStream !== null && stopStreamOnTermination) // stopStreamOnTermination is used on the last camera page on ios
      ) {
        cameraStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  const width = isLandscape
    ? constants.video.sizes.trainingVideoFrameSize.width
    : constants.video.sizes.trainingVideoFrameSize.height;
  const height = isLandscape
    ? constants.video.sizes.trainingVideoFrameSize.height
    : constants.video.sizes.trainingVideoFrameSize.width;

  return (
    <VideoContainer>
      <CameraFilter>
        <Video
          hidden={!displayVideoFeed}
          muted
          ref={videoRef}
          width={width}
          height={height}
        />
      </CameraFilter>
    </VideoContainer>
  );
};

export default TcprLinkFrame;
