import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import Lottie from '../../../../../components/ReactLottie/index';
import constants from '../../../../../constants/Constants';
import {
  useCamera,
  useHeightViewport,
  useTcprLink,
  useViewport,
} from '../../../../../context';
import { useFirebase } from '../../../../../context/FirebaseProvider';
import * as TrainingPageStyles from '../../../../../pageTemplates/trainingTemplate/StyledComponents';
import TrainingContent from '../../../../../pageTemplates/trainingTemplate/TrainingContent';
import { CountDownLottie } from '../../../components';
import { calculateScoreBasedOnRate } from '../../../helpers';
import { GameChannelStates } from '../../../providers/AblyRaceEvents';
import LearnerStyles from '../../learner.styles';
import TopBar from '../TopBar';
import Styles from './CprPage.styles';
import CprPageTypes from './CprPage.types';
import { getCprHint, getLottieOptions } from './helpers';
import DebugView from '../../../facilitator/components/Debug/DebugView';
import { useRaceLearner } from '../../../providers/RaceLearnerProvider';

const { BottomPart, CenterPart, LottieContainer } = TrainingPageStyles;

const CenterTopPart = CenterPart;

const CenterBottomPart = styled(CenterPart)`
  grid-row: 3;
`;
const CenterOverlay = styled(TrainingPageStyles.CenterOverlay)`
  grid-row: 2 / 4;
  justify-content: center;
  align-content: center;
`;

const CprPage: React.FunctionComponent<CprPageTypes.Props> = ({
  allowCameraPermissionMessage,
  backgroundLottieOptions,
  bottomPart,
  countDownLottieOptions,
  learnerTeam,
  Vehicle,
}: CprPageTypes.Props) => {
  const { isDebugMode, learnerId, gameState } = useRaceLearner();
  const [receivedRate, setReceivedRate] = useState<boolean>(false);
  const { isLandscape } = useViewport();
  const { innerHeight } = useHeightViewport();
  const { storeGAEvent } = useFirebase();
  const ctx = useCamera();
  const tcprLinkContext = useTcprLink();
  const [countDownComplete, setCountDownComplete] = useState(false);
  const [receivedCprScoreAboveZero, setReceivedCprScoreAboveZero] =
    useState(false);
  const isRunning = useRef(false);
  const timerRefNoRate = useRef<NodeJS.Timeout | null>(null);

  // This is needed since we do not check permission at the start of the course. Can be removed when that check is done
  useEffect(() => {
    if (!ctx?.hasCameraPermission) {
      ctx?.checkCameraPermissionAsync();
    }
  }, []);

  useEffect(() => {
    if (ctx?.hasCameraPermission && !tcprLinkContext.isLoaded) {
      tcprLinkContext.load();
    }
  }, [ctx?.hasCameraPermission, tcprLinkContext.isLoaded]);

  const StartTraining = useCallback(() => {
    tcprLinkContext.start();

    // timeout that will display an error message if the camera algorithm have not received a rate
    timerRefNoRate.current = setTimeout(() => {
      if (ctx?.hasCameraPermission && !receivedRate) {
        storeGAEvent({
          category: 'CameraLoading',
          action: 'NoRateReceived',
          label: window.location.pathname,
        });
      }
    }, constants.cameraLoadingTimeoutMs);
  }, [timerRefNoRate, ctx, receivedRate, storeGAEvent]);

  // clear timeout of the reset rate error message.
  useEffect(() => {
    if (receivedRate && timerRefNoRate.current) {
      clearTimeout(timerRefNoRate.current);
    }
  }, [receivedRate, timerRefNoRate.current]);

  useEffect(() => {
    if (
      tcprLinkContext !== null &&
      tcprLinkContext?.rate >= -1 &&
      !receivedRate
    ) {
      setReceivedRate(true);
    }

    if ((tcprLinkContext?.rate ?? 0) > 0 && !receivedCprScoreAboveZero) {
      setReceivedCprScoreAboveZero(true);
    }
  }, [tcprLinkContext?.rate, receivedRate]);

  const cprHint = useMemo(
    () => getCprHint(receivedCprScoreAboveZero, tcprLinkContext, bottomPart),
    [receivedCprScoreAboveZero, tcprLinkContext, bottomPart],
  );

  useEffect(() => {
    switch (gameState) {
      case GameChannelStates.race_start:
        StartTraining();
        break;
      case GameChannelStates.race_lobby:
      case GameChannelStates.race_countdown:
      case GameChannelStates.race_finished:
      default:
        break;
    }
    return () => {
      // Had issued with tcprLinkContext.isRunning in cleanup, so had to make this useRef value.
      if (isRunning.current) {
        tcprLinkContext.stop(); // Make sure to stop the training when the game is finished
      }
    };
  }, [gameState]);

  useEffect(() => {
    isRunning.current = tcprLinkContext.isRunning;
  }, [tcprLinkContext.isRunning]);

  // Will stop the training if the learner is in the final position
  useEffect(() => {
    if (learnerTeam.isFinalPosition && isRunning.current) {
      tcprLinkContext?.stop();
    }
  }, [learnerTeam.isFinalPosition]);

  const onCountDownComplete = () => {
    setCountDownComplete(true);
  };

  return (
    <>
      {isDebugMode && (
        <DebugView
          learnerTeam={learnerTeam}
          learnerId={learnerId}
          receivedRate={receivedRate}
          currentRate={tcprLinkContext?.rate}
          currentState={gameState}
        />
      )}

      <LearnerStyles.PageGrid height={innerHeight}>
        <TopBar learnerTeam={learnerTeam} />
        <CenterTopPart fullWidth>
          <LottieContainer>
            <Lottie
              aria-label="Moving road animation"
              isClickToPauseDisabled
              options={getLottieOptions(backgroundLottieOptions)}
              speed={
                calculateScoreBasedOnRate(tcprLinkContext?.rate ?? 0) / 100
              }
            />
          </LottieContainer>
          <Styles.VehicleWrapper color={learnerTeam.color}>
            {Vehicle ? <Vehicle /> : null}
          </Styles.VehicleWrapper>
        </CenterTopPart>
        <CenterBottomPart>
          <Styles.FeedbackText aria-live="polite">
            {gameState === GameChannelStates.race_lobby
              ? 'Waiting for the race to start...'
              : cprHint()}
          </Styles.FeedbackText>
        </CenterBottomPart>
        <CenterOverlay>
          {gameState === GameChannelStates.race_countdown ? (
            <CountDownLottie
              countDownComplete={countDownComplete}
              countDownLottieOptions={countDownLottieOptions}
              onComplete={onCountDownComplete}
            />
          ) : null}
        </CenterOverlay>
        <BottomPart>
          <TrainingContent
            trainingWithSpeedometer={bottomPart?.left === 'speedometer'}
            displayVideoFeed={bottomPart?.right === 'videoFeed'}
            speedometer={bottomPart?.speedometer}
            allowCameraPermissionMessage={allowCameraPermissionMessage}
            height={
              isLandscape
                ? constants.video.sizes.trainingVideoFrameSize.height
                : undefined
            }
          />
        </BottomPart>
      </LearnerStyles.PageGrid>
    </>
  );
};

export default CprPage;
