export enum ConnectorType {
  Facilitator = 'facilitator',
  Learner = 'learner',
}

export enum ConnectionStates {
  closed = 'closed',
  connected = 'connected',
  connecting = 'connecting',
  disconnected = 'disconnected',
  reconnecting = 'reconnecting',
}
