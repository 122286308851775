import React, { FunctionComponent, useState } from 'react';

import {
  BasicDropdown,
  DropdownItem,
  InputLabel,
} from '@laerdal/life-react-components';
import styled from 'styled-components';
import LifeButton from '../../components/Button';
import { TimeLeftWithText } from '../../components/TopBars';
import { useUser } from '../../context';
import {
  FireBaseCollections,
  useFirebase,
} from '../../context/FirebaseProvider';
import { LocalStorageService } from '../../services';
import {
  ButtonContainer,
  MainContent,
  ProgressbarContainer,
  StyledText,
} from '../Templates/InfoPageStyles';
import { Button } from '../Templates/InfoPageTypes';
import PageGrid from '../Templates/PageGrid';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

interface SelectionPageProps {
  buttons: Button[];
  dropdownMenuItems: string[];
  dropdownMenuLabel: string;
  dropdownMenuPlaceholder: string;
  pageHeader: string;
  showTimeLeftBar?: boolean;
  utmCampaignQueryString: boolean;
}
const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .dropdown:hover:not(.action-within) input::placeholder {
    color: ${(props) => props.theme.colors.neutral_500} !important;
  }
  .dropdown-arrow {
    color: ${(props) => props.theme.colors.neutral_500} !important;
  }

  .dropdown:hover {
    color: ${(props) => props.theme.colors.neutral_500};
    background: ${(props) => props.theme.colors.neutral_20};
    box-shadow: inset 0px 0px 0px 1px
      ${(props) => props.theme.colors.neutral_400};
  }
  .value:hover:not(.action-within)::placeholder {
    color: ${(props) => props.theme.colors.neutral_500};
  }
  [role='option']:hover:not(.active):not(:disabled):not(.disabled-state) {
    background: ${(props) => props.theme.colors.neutral_20};
  }
`;
const SelectionPageTemplate: FunctionComponent<SelectionPageProps> = ({
  buttons,
  dropdownMenuItems,
  dropdownMenuLabel,
  dropdownMenuPlaceholder,
  pageHeader,
  showTimeLeftBar,
  utmCampaignQueryString,
}) => {
  const [answerSelected, setAnswerSelected] = useState('');
  const { storeCollection } = useFirebase();
  const { setUtmCampaign } = useUser();

  const buttonList: DropdownItem[] = [];
  const header = pageHeader;
  const selectableItems = dropdownMenuItems;
  selectableItems?.forEach((element) => {
    buttonList.push({ value: element, displayLabel: element });
  });
  const selectionPageData = {
    answerSelected,
    isCorrectAnswer: null,
  };

  return (
    <PageGrid>
      <ProgressbarContainer>
        <TimeLeftWithText enabled={showTimeLeftBar} />
      </ProgressbarContainer>
      <MainContent row={2}>
        <ContentContainer>
          <StyledText>
            <h1>{header}</h1>
          </StyledText>
          <DropdownContainer className="dropdownContainer">
            <InputLabel
              inputId={dropdownMenuLabel ?? ''}
              text={dropdownMenuLabel ?? ''}
            />
            <BasicDropdown
              className="dropdown"
              minWidth="0px"
              id="dropdown"
              placeholder={dropdownMenuPlaceholder}
              onSelect={(value) => {
                setAnswerSelected(value);
                if (utmCampaignQueryString) {
                  setUtmCampaign(value);
                  LocalStorageService.setUtmCampaign(value);
                }
              }}
              list={buttonList}
            />
          </DropdownContainer>
        </ContentContainer>
      </MainContent>
      <ButtonContainer role="navigation" aria-label="Main">
        {buttons
          ? buttons.map((button: Button) => {
              return (
                <LifeButton
                  disabled={!answerSelected}
                  key={`button_${button.buttonType}_${button.text}`}
                  variant={button.buttonType}
                  onClick={() => {
                    storeCollection(FireBaseCollections.quizQuestion, {
                      ...selectionPageData,
                    }).finally(() => button.onClick());
                  }}
                >
                  {button.text}
                  {button.icon ? <button.icon size="18px" /> : ''}
                </LifeButton>
              );
            })
          : null}
      </ButtonContainer>
    </PageGrid>
  );
};

export default SelectionPageTemplate;
