import { NavigationContextProps } from '../../context/navigation/navigationContextProps';
import { SanityCourseContextProps } from '../../context/sanity/sanityCourseContextProps';
import {
  PageWithTemplate,
  ProjectPages,
  SanitySettings,
} from '../../pageTemplates/types/courseTypes';

const createNavigationProviderMock = (): NavigationContextProps => {
  return {
    currentPageDuration: () => {
      return 0;
    },
    initialRender: true,
    initialPage: '',
    navigateToAedPractisePage: () => {},
    navigateToCheckAnswerPage: () => {},
    navigateToDefaultAndroidBrowser: () => {},
    navigateToFirstPage: () => {},
    navigateToNextPage: () => {},
    navigateToNextPageWithSleepCheck: () => {},
    navigateToPreviousPage: () => {},
    navigateToThePageAfterNext: () => {},
    navigateToUrl: () => {},
    numbPagesLeft: 0,
    preloadImagesOnNextPage: () => {},
    timeLeftInSeconds: 130,
    totalTimeInSeconds: 300,
  };
};

const createSanityProvider = (): SanityCourseContextProps => {
  return {
    course: [],
    getCurrentPage: () => {
      return {} as PageWithTemplate;
    },
    getProjectPage: () => {
      return {} as PageWithTemplate;
    },
    projectPages: {} as ProjectPages,
    settings: {
      organizationSettings: {},
    } as SanitySettings,
    supportedLanguages: [],
  };
};

export const createDefaultWindowMock = (): void => {
  window.mock = {
    navigationProvider: createNavigationProviderMock(),
    sanityProvider: createSanityProvider(),
  };

  window.revivr = {
    isGroupRevivr: false,
    isLearner: true,
    isTeamedUpSession: false,
  };
};

export const isSanityProviderMockDefined = ():
  | SanityCourseContextProps
  | undefined => {
  return window.mock?.sanityProvider;
};

export const isNavigationProviderMockDefined = ():
  | NavigationContextProps
  | undefined => {
  return window.mock?.navigationProvider;
};
