/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import React, { FunctionComponent, useEffect, useState } from 'react';
import WithFadeIn from '../../components/WithFadeIn';
import WithFocus from '../../components/WithFocus';
import { useSanityCourse } from '../../context/sanity/useSanityCourse';
import PhoneCallPageTemplate from '../../pages/EmergencyCallPage/PhoneCallPageTemplate';
import { EmergencyCallTemplateProps } from '../types/courseTypes';

type ComponentProps = {
  template?: EmergencyCallTemplateProps | undefined;
};

const Template: FunctionComponent<ComponentProps> = ({ template }) => {
  const [currentPage, setCurrentPage] = useState<EmergencyCallTemplateProps>({
    numberToCall: '',
    whyTrainButton: '',
    headerText: '',
    infoHeaderText: '',
    practiceCallingText: '',
    whyTrainModalHeaderText: '',
    whyTrainModalBodyText: '',
    whyTrainModalButtonText: '',
    showCall911Modal: true,
    call911ModalImage: undefined,
    call911ModalHeaderText: '',
    call911ModalBodyText: '',
    call911ModalPrimaryButton: '',
    call911ModalSecondaryButton: '',
    showTimeLeftBar: true,
    displayTeamName: false,
  });
  const pageContext = useSanityCourse();

  useEffect(() => {
    const tempPage =
      template !== undefined
        ? template
        : pageContext.getCurrentPage().emergencyCallTemplate;
    if (tempPage !== undefined) {
      setCurrentPage(tempPage);
    }
  }, []);

  return (
    <PhoneCallPageTemplate
      whyTrainButton={currentPage.whyTrainButton}
      headerText={currentPage.headerText}
      infoHeaderText={currentPage.infoHeaderText}
      numberToCall={currentPage.numberToCall.toString()}
      practiceCallingText={currentPage.practiceCallingText}
      whyTrainModalHeaderText={currentPage.whyTrainModalHeaderText}
      whyTrainModalBodyText={currentPage.whyTrainModalBodyText}
      whyTrainModalButtonText={currentPage.whyTrainModalButtonText}
      showCall911Modal={currentPage.showCall911Modal}
      call911ModalImage={currentPage.call911ModalImage}
      call911ModalHeaderText={currentPage.call911ModalHeaderText}
      call911ModalBodyText={currentPage.call911ModalBodyText}
      call911ModalPrimaryButton={currentPage.call911ModalPrimaryButton}
      call911ModalSecondaryButton={currentPage.call911ModalSecondaryButton}
      showTimeLeftBar={currentPage.showTimeLeftBar}
      displayTeamName={currentPage.displayTeamName}
      bottomText={currentPage.bottomText}
      showBottomButton={currentPage.showBottomButton}
      showBottomText={currentPage.showBottomText}
    />
  );
};

export default WithFadeIn(WithFocus(Template));
