import styled, { css } from 'styled-components';
import constants from '../../../constants/Constants';
import { PageGridStyle } from '../../Templates/InfoPageStyles';
import mediaMedium from '../../Templates/MediaMedium';

const { pageMargin, headerHeight } = constants;

/**
 * Use this page grid wherever possible
 * so we can reuse components more easily.
 */
const StyledPageGrid = styled(PageGridStyle)`
  grid-template-rows:
    minmax(32px, max-content)
    minmax(0, max-content)
    1fr
    minmax(0, max-content)
    16px;

  ${mediaMedium(css`
    grid-template-rows:
      minmax(${headerHeight.MEDIUM}px, max-content)
      1fr
      minmax(0, max-content)
      ${pageMargin.SMALL}px;
    grid-template-columns: ${pageMargin.MEDIUM}px 1fr 1fr 1fr 1fr ${pageMargin.MEDIUM}px;
    max-width: ${constants.maxPageWidth}px;
  `)}
`;

export default StyledPageGrid;
