import React, { useCallback, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { Team } from '../../../providers/Types';
import { LearnerTeam } from '../../../learner/learner.types';

const RaceDebugViewContainer = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
  display: flex;
  background-color: red;
`;

const ShowDebug = styled.button`
  justify-content: center;
  align-items: center;
  height: 25px;
  margin: 5px;
`;

const DebugViewContainer = styled.div`
  position: absolute;
  background-color: grey;
  color: white;
  opacity: 0.9;
  top: 50px;
  width: 90%;
  height: 70%;
  z-index: 100;
  margin-left: auto;
  margin-right: auto;
  height: 100xp;
  overflow-y: scroll;
`;

const FormattedText = styled.pre`
  background-color: black;
  color: white;
`;

const TeamContainer = styled.div`
  background-color: black;
  color: white;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px; // Optional: Adds space between items
`;

const LearnerContainer = styled.div`
  background-color: black;
  color: white;
  padding: 10px;
`;

const IndividualTeamContainer = styled.div`
  background-color: white;
  color: black;
  width: 30%;
  padding: 10px;
`;

const IndividualPlayerInTeamContainer = styled.div`
  margin: 5px;
`;

type DebugMessage = {
  timestamp: string;
  formattedTimestamp: string;
  message: string;
  data?: any;
};

const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
  return `${hours}:${minutes}:${seconds}:${milliseconds}`;
};

export default function RaceDebugView({
  teams,
  currentState,
  learnerTeam,
  receivedRate,
  currentRate,
  learnerId,
}: {
  teams?: Team[] | undefined;
  currentState?: any;
  learnerTeam?: LearnerTeam | undefined;
  currentRate?: number;
  receivedRate?: boolean;
  learnerId?: string;
}) {
  const [showDebug, setShowDebug] = useState(false);

  const [debugLogs, setDebugLogs] = useState<DebugMessage[]>([]);

  const [visibleDetails, setVisibleDetails] = useState({});
  const toggleDetails = (index) => {
    setVisibleDetails((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleRaceDebugMessage = useCallback((event: CustomEvent) => {
    const date = new Date();
    const timestamp = date.getTime();
    setDebugLogs((prevLogs) => {
      const newLog: DebugMessage = {
        timestamp: date.toLocaleString(),
        formattedTimestamp: formatTimestamp(timestamp),
        message: event.detail.message,
        data: event.detail.data,
      };

      return [...prevLogs, newLog];
    });
  }, []);

  useEffect(() => {
    window.addEventListener('raceDebugMessage', handleRaceDebugMessage);

    setDebugLogs(JSON.parse(localStorage.getItem('debugLogs') || '[]'));

    return () => {
      // Cleanup: remove the event listener when the component unmounts or when showDebug changes
      window.removeEventListener('raceDebugMessage', handleRaceDebugMessage);
      console.log('Debug view unmounted');
    };
  }, []);

  console.log('Learner team: ', learnerTeam);

  return (
    <RaceDebugViewContainer>
      <ShowDebug onClick={() => setShowDebug(!showDebug)}>
        Show Debug View
      </ShowDebug>

      {showDebug && (
        <DebugViewContainer>
          <h1>Race Debug</h1>
          <h3>Current State: {currentState}</h3>

          <button
            onClick={() => {
              localStorage.setItem('debugLogs', '');
              setDebugLogs([]);
              setVisibleDetails({});
            }}
          >
            Clear data
          </button>

          {learnerTeam && (
            <>
              <h2>Player</h2>
              <LearnerContainer>
                <div>Id: {learnerId}</div>
                <div>team: {learnerTeam.name}</div>
                <div>Position: {learnerTeam.position}</div>
                <div>Is Final Position: {learnerTeam.isFinalPosition}</div>
                <div>CurrentRate Rate: {currentRate}</div>
              </LearnerContainer>
            </>
          )}

          {teams && (
            <>
              <h2>Teams</h2>

              <TeamContainer>
                {teams
                  .slice()
                  .sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  })
                  ?.map((team) => {
                    return (
                      <IndividualTeamContainer key={team.name}>
                        <div>
                          Name: <b>{team.name}</b>{' '}
                        </div>
                        <div>FinalPosition: {team.finalPosition}</div>
                        <div>Team Score: {team.score?.toFixed(2)}</div>
                        <br />
                        <b>Learner 1</b>
                        {team.learner1?.id && (
                          <IndividualPlayerInTeamContainer>
                            <div>Id: {team.learner1?.id}</div>
                            <div>rate: {team.learner1?.rate}</div>
                            <div>score: {team.learner1?.score?.toFixed(2)}</div>
                            <div>
                              updated:
                              {!!team?.learner1?.lastRateUpdateTimestamp &&
                                formatTimestamp(
                                  team.learner1.lastRateUpdateTimestamp,
                                )}
                            </div>
                          </IndividualPlayerInTeamContainer>
                        )}

                        {team.learner2?.id && (
                          <IndividualPlayerInTeamContainer>
                            <br />
                            <b>Learner 2</b>
                            <div>Id: {team.learner2?.id}</div>
                            <div>rate: {team.learner2?.rate}</div>
                            <div>score: {team.learner2?.score?.toFixed(2)}</div>
                            <div>
                              updated:
                              {!!team?.learner2?.lastRateUpdateTimestamp &&
                                formatTimestamp(
                                  team.learner2.lastRateUpdateTimestamp,
                                )}
                            </div>
                          </IndividualPlayerInTeamContainer>
                        )}
                      </IndividualTeamContainer>
                    );
                  })}
              </TeamContainer>
            </>
          )}

          <h2>Log</h2>

          <ul>
            {debugLogs?.map((log, index) => (
              <li key={index}>
                {log.formattedTimestamp} - {log.message}
                <button onClick={() => toggleDetails(index)}>
                  {visibleDetails[index] ? '-' : '+'}
                </button>
                {visibleDetails[index] && log.data && (
                  <FormattedText>
                    {JSON.stringify(log.data, null, 2)}
                  </FormattedText>
                )}
              </li>
            ))}
          </ul>
        </DebugViewContainer>
      )}
    </RaceDebugViewContainer>
  );
}
