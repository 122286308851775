import React from 'react';
import {
  BannerBackImg,
  BannerFrontImg,
} from '../../pageTemplates/Templates/InfoPageStyles';
import { Image } from '../../pageTemplates/Templates/InfoPageTypes';
import { HorizontalAlignment } from '../../pageTemplates/types/courseTypes';

export type Banner = {
  frontImage?: Image;
  frontImageAlignment?: HorizontalAlignment;
  backImage?: Image;
};

type BannerImageProps = {
  banner?: Banner;
};

const BannerImage: React.FunctionComponent<BannerImageProps> = ({ banner }) => {
  if (banner && banner.frontImage?.src) {
    return banner.backImage?.src ? (
      <>
        <BannerFrontImg
          src={banner.frontImage.src}
          alt={banner.frontImage.alt ? banner.frontImage.alt : 'Banner'}
          imgType={banner.frontImage.imgType}
          alignment={banner.frontImageAlignment}
        />
        <BannerBackImg
          src={banner.backImage.src}
          alt={banner.backImage.alt ? banner.backImage.alt : 'Banner'}
        />
      </>
    ) : (
      <BannerFrontImg
        src={banner.frontImage.src}
        alt={banner.frontImage.alt ? banner.frontImage.alt : 'Banner'}
        imgType={banner.frontImage.imgType}
        alignment={banner.frontImageAlignment}
      />
    );
  }
  return null;
};
export default BannerImage;
