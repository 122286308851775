import confetti from 'canvas-confetti';
import { useEffect, useRef } from 'react';
import consoleDebug from '../../../helper/consoleDebug';
import type { AwardPodiumTypes } from '../facilitator/components/AwardPodium';

type FacilitatorConfettiEffectProps = {
  players: AwardPodiumTypes.PlayerInfo[];
};

export const showLearnerConfetti = () => {
  const commonConfettiOptions = {
    particleCount: 100,
    startVelocity: 30,
    disableForReducedMotion: true,
    spread: 360,
  };
  const interval = setInterval(() => {
    const x = Math.random();
    const y = Math.random();
    if (document.hidden) {
      return;
    }
    confetti({
      commonConfettiOptions,
      origin: {
        x: 0.5 + 0.5 * x,
        // since they fall down, start a bit higher than random
        y: 0.5 + 0.5 * y - 0.2,
      },
    });
    confetti({
      commonConfettiOptions,
      origin: {
        x: 0.5 * y,
        // since they fall down, start a bit higher than random
        y: 0.5 * x - 0.2,
      },
    });
  }, 2000);

  return () => {
    confetti.reset();
    consoleDebug('clearing interval confetti');
    clearInterval(interval);
  };
};

export const useLearnerConfettiEffect = (position: number) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (position > 3) {
      return;
    }

    const commonConfettiOptions = {
      particleCount: 100,
      startVelocity: 30,
      disableForReducedMotion: true,
      spread: 360,
    };

    (function frame() {
      if (!document.hidden) {
        const x = Math.random();
        const y = Math.random();

        confetti({
          commonConfettiOptions,
          origin: {
            x: 0.5 + 0.5 * x,
            // since they fall down, start a bit higher than random
            y: 0.5 + 0.5 * y - 0.2,
          },
        });
        confetti({
          commonConfettiOptions,
          origin: {
            x: 0.5 * y,
            // since they fall down, start a bit higher than random
            y: 0.5 * x - 0.2,
          },
        });
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(frame, 2000);
    })();

    return () => {
      confetti.reset();

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [position]);
};

export const useFacilitatorConfettiEffect = ({
  players,
}: FacilitatorConfettiEffectProps) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!Array.isArray(players)) {
      return null;
    }

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }
    const winnerColors = [
      players[0]?.color,
      players[0]?.color,
      players[0]?.color,
      players[1]?.color,
      players[2]?.color,
    ];
    (function frame() {
      if (!document.hidden) {
        confetti({
          disableForReducedMotion: true,
          particleCount: 5,
          startVelocity: 10,
          spread: 360,
          origin: {
            x: Math.random(),
            y: Math.random() * 0.8 - 0.2,
          },
          colors: winnerColors,
          shapes: ['circle'],
          gravity: randomInRange(0.4, 0.6),
          scalar: randomInRange(0.4, 0.8),
          drift: randomInRange(-0.4, 0.4),
        });
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(frame, 30);
    })();

    return () => {
      confetti.reset();

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [players]);
};
