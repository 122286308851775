import React, { useEffect } from 'react';

export default function usePreloadImage(imageSrc: string | undefined) {
  useEffect(() => {
    if (imageSrc) {
      const preloadImage = new Image();
      preloadImage.src = imageSrc;
    }
  }, [imageSrc]);
}
