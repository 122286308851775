import { groupPaths, soloPaths } from './projectPathsHelper';

/**
 *  We want to check with DevOps which paths are viable for the current environment.
 * As these paths can't be hard coded, we have some logic to check for this.
 * Group paths and solo paths are both returned here.
 * @returns An array of paths used for routing.
 */
const viablePaths = (): string[] => {
  const allViablePathsJSON = { ...soloPaths, ...groupPaths };
  const paths = ['/'].concat(Object.keys(allViablePathsJSON));
  return paths;
};

export default viablePaths;
