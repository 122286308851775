import NoSleep from 'nosleep.js';
import { useEffect } from 'react';
import {
  NoSleepError,
  logWarning,
} from '../services/errorHandling/errorHelper';

const noSleep = new NoSleep();
const errorMessage = 'Error while trying to enable NoSleep.';

// Hook to enable NoSleep.js
// Prevents the screen from going to sleep on mobile devices
const UseNoSleep = (): void => {
  useEffect(() => {
    document.addEventListener(
      'click',
      function enableNoSleep() {
        try {
          document.removeEventListener('click', enableNoSleep, false); // only run nosleep.enable() once.
          noSleep.enable();
        } catch (error: unknown) {
          const noSleepError = new NoSleepError(errorMessage, error);
          logWarning(errorMessage, {}, noSleepError);
        }
      },
      false,
    );
  }, []);
};

export default UseNoSleep;
