import * as Ably from 'ably';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import consoleDebug from '../../../helper/consoleDebug';
import { LocalStorageService } from '../../../services';
import { createRealtimeChannel } from '../../realtimeCommunication/ablyInstanceFactory';
import { calculateScoreBasedOnRate } from '../helpers';
import {
  getTeamAmbulanceImage,
  getTeamColor,
} from '../helpers/teamAssetSelectors';
import AblyDebugLogger from './AblyLogger';
import {
  GameChannelStates,
  RaceGameChannelEvents,
  TeamChannelEventPositionMessage,
  TeamChannelEvents,
} from './AblyRaceEvents';
import {
  GameChannelGameMessage,
  HandleLearnerRateUpdateMessage,
  RaceFacilitatorContextProps,
  SetupLearnerConnectedEventMessage,
  Team,
} from './Types';
import { TEAM_NAME_PREFIX } from './constants';
import { createLearner, useTeams } from './useTeams';

const RaceFacilitatorContext = React.createContext<RaceFacilitatorContextProps>(
  {} as RaceFacilitatorContextProps,
);

const RaceFacilitatorProvider = ({ children }) => {
  const location = useLocation();
  const gameChannelName = useRef<string | null>(null);
  const { getTeams, setTeams, createTeam, initiateTeams } = useTeams();

  const ably = useRef<Ably.Types.RealtimePromise>(createRealtimeChannel());
  const [lastGameState, setLastGameState] = useState<GameChannelStates>(
    GameChannelStates.race_lobby,
  );

  const gameChannel = useRef<Ably.Types.RealtimeChannelCallbacks | undefined>(
    undefined,
  );

  // When we navigate away from a race page, we want to reset the states.
  useEffect(() => {
    if (lastGameState !== GameChannelStates.race_lobby) {
      setLastGameState(GameChannelStates.race_lobby);
    }
  }, [location.pathname]);

  const sendGameStates = (state: GameChannelStates, content?: string) => {
    const data = {
      state,
      content,
      isDebugMode: window.config.debugMode,
    } as GameChannelGameMessage;

    if (gameChannel.current) {
      AblyDebugLogger.AblyDebugEventPublishedLogger(
        RaceGameChannelEvents.game_message,
        data,
      );
      gameChannel.current.publish(RaceGameChannelEvents.game_message, data);

      setLastGameState(state);
    }
  };

  const sendTeamEvents = (
    team: string,
    event: TeamChannelEvents,
    message: TeamChannelEventPositionMessage,
  ) => {
    const data = {
      event,
      message,
    };

    AblyDebugLogger.AblyDebugEventPublishedLogger(event, data);

    const teamChannelName = `${gameChannelName.current}-${team}`;
    const teamChannel = ably.current.channels.get(teamChannelName);

    teamChannel.publish(event, data);
  };

  const updateLearnerAndTeamRate = (team, learnerId, rate, rateTimestamp) => {
    const currentTeam = getTeams()[team];

    if (currentTeam) {
      const currentLearner =
        currentTeam.learner1?.id === learnerId
          ? currentTeam.learner1
          : currentTeam.learner2;

      if (currentLearner === null) {
        consoleDebug('learner not found');
        return;
      }

      if (
        currentLearner.lastRateUpdateTimestamp != null &&
        rateTimestamp < currentLearner.lastRateUpdateTimestamp
      ) {
        consoleDebug('old message');
        return;
      }

      currentLearner.lastRateUpdateTimestamp = rateTimestamp;
      currentLearner.rate = rate;
      currentLearner.score = calculateScoreBasedOnRate(rate);

      const divideBy = currentTeam.learner2 !== null ? 2 : 1;

      const newAverageScore =
        ((currentTeam.learner1 != null ? currentTeam.learner1.score : 0) +
          (currentTeam.learner2 != null ? currentTeam.learner2.score : 0)) /
        divideBy;

      const updatedTeam = {
        ...currentTeam,
        score: newAverageScore,
      };

      const newTeams = {
        ...getTeams(),
        [team]: updatedTeam,
      };

      setTeams(newTeams);
    }
  };

  const updateRateMessageTimestamp = useRef<number | null>(null);
  const handleLearnerRateUpdate = (message: HandleLearnerRateUpdateMessage) => {
    AblyDebugLogger.AblyDebugEventReceivedLogger(
      TeamChannelEvents.rate_update,
      message,
    );
    const { team, learnerId, rate } = message.data;
    const { timestamp } = message;

    updateRateMessageTimestamp.current = timestamp;
    updateLearnerAndTeamRate(team, learnerId, rate, timestamp);
  };
  const handleLearnerRateUpdateRef = useRef(handleLearnerRateUpdate);

  const handleLearnerConnected = (
    message: SetupLearnerConnectedEventMessage,
  ) => {
    AblyDebugLogger.AblyDebugEventReceivedLogger(
      RaceGameChannelEvents.learner_connected,
      message,
    );

    // Republish the last game event command to the new learner when it connects. Because of the history is not always available
    sendGameStates(lastGameState);

    const { team, learnerId } = message.data;
    const newLearner = createLearner(learnerId);
    let existingTeam = getTeams()[team];

    if (existingTeam === undefined) {
      existingTeam = createTeam(team);
    }

    if (
      existingTeam.learner1 === null ||
      existingTeam.learner1.id === learnerId
    ) {
      existingTeam.learner1 = existingTeam.learner1 ?? newLearner;
    } else if (existingTeam.learner2 === null) {
      existingTeam.learner2 = newLearner;
    }
    const updatedTeam: Team = {
      ...existingTeam,
      color: getTeamColor(existingTeam.name),
      ambulanceImage: getTeamAmbulanceImage(existingTeam.name),
      score: 0,
    };

    const newTeams = {
      ...getTeams(),
      [team]: updatedTeam,
    };

    if (lastGameState === GameChannelStates.race_finished) {
      sendTeamEvents(updatedTeam.name, TeamChannelEvents.position_update, {
        position: updatedTeam.finalPosition,
        isFinalPosition: true,
      });
    }

    setTeams(newTeams);
  };

  const handleLearnerConnectedRef = useRef(handleLearnerConnected);

  const cleanup = () => {
    if (gameChannel.current) {
      consoleDebug('Unsubscribing from game channel, learner_connected event');
      gameChannel.current.unsubscribe(RaceGameChannelEvents.learner_connected);
    }

    Object.values(getTeams()).forEach((team) => {
      const teamChannelName = `${gameChannelName.current}-${team.name}`;
      const teamChannel = ably.current.channels.get(teamChannelName);

      consoleDebug(
        `Unsubscribing from team channel, rate_update and position_update for team: ${team.name}`,
      );
      teamChannel.unsubscribe(TeamChannelEvents.rate_update);
      teamChannel.unsubscribe(TeamChannelEvents.position_update);
    });
  };

  const connect = (existingTeams: Record<string, Team> | null) => {
    gameChannelName.current = `game-${LocalStorageService.getClassroomCodeFromLocalStorage()}`;
    if (!ably.current) {
      return;
    }
    if (gameChannelName.current === null) {
      consoleDebug(' !gameChannelName.current ||');
      return;
    }

    gameChannel.current = ably.current.channels.get(gameChannelName.current);
    gameChannel.current.unsubscribe(RaceGameChannelEvents.learner_connected);

    const setupLearnerConnectedEvent = (
      message: SetupLearnerConnectedEventMessage,
    ) => {
      const { data } = message;
      const connectedLearnerTeam = data.team;

      const teamChannelName = `${gameChannelName.current}-${connectedLearnerTeam}`;
      const teamChannel = ably.current.channels.get(teamChannelName);

      const learnerRateUpdateCallback = (learnerRateUpdateCallbackMessage) =>
        handleLearnerRateUpdateRef.current(learnerRateUpdateCallbackMessage);
      teamChannel.unsubscribe(TeamChannelEvents.rate_update);
      teamChannel.subscribe(
        TeamChannelEvents.rate_update,
        learnerRateUpdateCallback,
      );

      handleLearnerConnectedRef.current(message);
    };

    gameChannel.current.subscribe(
      RaceGameChannelEvents.learner_connected,
      setupLearnerConnectedEvent,
    );

    if (existingTeams) {
      Object.values(existingTeams).forEach((team) => {
        if (team.learner1 !== null) {
          setupLearnerConnectedEvent({
            data: { team: team.name, learnerId: team.learner1.id },
          } as SetupLearnerConnectedEventMessage);
        }

        if (team.learner2 !== null) {
          setupLearnerConnectedEvent({
            data: { team: team.name, learnerId: team.learner2.id },
          });
        }
      });
    }
  };

  const onTeamCompleted = (teamNumber: number) => {
    const currentTeam = getTeams()[TEAM_NAME_PREFIX + teamNumber];

    const teamWithPosition = Object.values(getTeams())
      .filter((t) => t.finalPosition !== undefined)
      .sort((a, b) => a.finalPosition - b.finalPosition);

    currentTeam.finalPosition = teamWithPosition.length + 1;

    const updatedTeam = {
      ...currentTeam,
      finalPosition: currentTeam.finalPosition,
    };

    const newTeams = {
      ...getTeams(),
      [TEAM_NAME_PREFIX + teamNumber]: updatedTeam,
    };

    setTeams(newTeams);

    sendTeamEvents(currentTeam.name, TeamChannelEvents.position_update, {
      position: currentTeam.finalPosition,
      isFinalPosition: true,
    });
  };

  useEffect(() => {
    if (lastGameState === GameChannelStates.race_lobby) {
      // Reset the current position
      const teamsCopy = JSON.parse(JSON.stringify(getTeams())) as Record<
        string,
        Team
      >;

      const teamWithPosition = [...Object.values(teamsCopy)] as Team[];
      teamWithPosition.forEach((team) => {
        team.finalPosition = undefined;
        team.score = 0;
      });

      consoleDebug('reset current position', teamsCopy);
      setTeams(teamsCopy);
    }
    consoleDebug('last game state has been updated to: ', lastGameState);
  }, [lastGameState]);

  useEffect(() => {
    handleLearnerConnectedRef.current = handleLearnerConnected;
  }, [handleLearnerConnected]); // Re-run if handleLearnerConnected changes

  useEffect(() => {
    handleLearnerRateUpdateRef.current = handleLearnerRateUpdate;
  }, [handleLearnerRateUpdate]); // Re-run if handleLearnerConnected changes

  useEffect(() => {
    consoleDebug('RaceFacilitatorProvider connect, initiateTeams');
    const teams = initiateTeams();

    connect(teams);
    return () => {
      cleanup();
    };
  }, []);

  const value = useMemo(
    () => ({
      cleanup,
      connect,
      getTeams,
      lastGameState,
      sendGameStates,
      sendTeamEvents,
      onTeamCompleted,
    }),
    [
      cleanup,
      connect,
      getTeams,
      lastGameState,
      sendGameStates,
      sendTeamEvents,
      onTeamCompleted,
    ],
  );

  return (
    <RaceFacilitatorContext.Provider value={value}>
      {children}
    </RaceFacilitatorContext.Provider>
  );
};

export const useRaceFacilitator = (): RaceFacilitatorContextProps => {
  const context = useContext(RaceFacilitatorContext);
  return context;
};

const withRaceFacilitatorProvider =
  (Component: React.ComponentType) => (props) => {
    return (
      <RaceFacilitatorProvider>
        <Component {...props} />
      </RaceFacilitatorProvider>
    );
  };

export default withRaceFacilitatorProvider;
