import { Options as LottieOptions } from '../../components/ReactLottie/index';
/**
 * This matches the types in our Sanity project.
 * customButton.js
 */
export const buttonNavigationTypes = {
  nextPage: 'nextPage',
  previousPage: 'previousPage',
  urlPage: 'urlPage',
  navToModal: 'navToModal',
  dismissModal: 'dismissModal',
  cameraPermission: 'cameraPermission',
  socialMediaShare: 'socialMediaShare',
  practiseAgain: 'practiseAgain',
};

export const cardNavigationTypes = {
  nextPage: 'nextPage',
  urlPage: 'urlPage',
};

/**
 * Types for the speedometer.js in Sanity.
 */
interface Range {
  max?: number;
  min?: number;
}
export interface SpeedometerRanges {
  bounds?: Range;
  accepted?: Range;
}
export interface SpeedometerHints {
  cprTooSlow?: string;
  cprTooFast?: string;
  cprGood?: string;
  cprNoDetect?: string;
}
export interface Speedometer {
  hints?: SpeedometerHints;
  ranges?: SpeedometerRanges;
}

export interface AllowCameraPermissionMessage {
  allowCameraPermissionText?: string;
  allowCameraPermissionButtonText?: string;
}
export interface FeedbackTexts {
  compressionDetected?: string;
  compressionNotDetected?: string;
}
export interface SanityLottieOptions extends LottieOptions {
  path: string;
}
export const trainingPageLayout = {
  central: {
    compressionAnimation: 'compressionAnimation',
    countdownWithSound: 'countdownWithSound',
  },
  bottom: {
    left: {
      speedometer: 'sppedometer',
      feedbackTextBox: 'feedbackTextBox',
    },
    right: {
      videoFeed: 'videoFeed',
    },
  },
  top: {
    ambulance: 'ambulance',
  },
};
export interface TrainingPageBottomPart {
  speedometer?: Speedometer;
  feedbackTexts?: FeedbackTexts;
  right: string;
  left: string;
}
export enum TrainingPageCentralPart {
  CountdownWithSound = 'countdownWithSound',
  CompressionAnimation = 'compressionAnimation',
}
export interface MessageWithDuration {
  message: string;
  duration: number;
}
export interface TrainingIntroMessages {
  enableCountDown?: boolean;
  messages?: MessageWithDuration[];
  trainingStartsIn?: MessageWithDuration;
  emergencyOperatorHeading?: string;
  emergencyOperatorImagePath?: string;
}
export interface QuizButtonText {
  showAnswerButtonText: string;
  tryAgainButtonText: string;
  okButtonText: string;
  continueButtonText: string;
}

export interface QuizAnswerLabel {
  selectAnswerLabelText: string;
  wrongAnswerLabelText: string;
  correctAnswerLabelText: string;
}
export default buttonNavigationTypes;
