import Bowser from 'bowser';
import SessionResult from '../features/cprAlgorithm/SessionResult';
import FpsCalculation from './FpsCalculations';

class SessionResultExtended {
  public sessionResult: SessionResult;

  public averageFps: number;

  public averageFpsStandardDeviation: number;

  public browser: Bowser.Parser.ParsedResult;

  public maxFps: number;

  public minFps: number;

  constructor(sessionResult: SessionResult, fpsData: Array<number>) {
    this.sessionResult = sessionResult;
    const fpsCalculation = new FpsCalculation(fpsData);
    this.averageFps = fpsCalculation.getAverageFps();
    this.averageFpsStandardDeviation = fpsCalculation.getStandardDeviationFps();
    this.maxFps = fpsCalculation.getMaximumFps();
    this.minFps = fpsCalculation.getMinimumFps();
    this.browser = Bowser.parse(window.navigator.userAgent);
  }
}
export default SessionResultExtended;
