import { useEffect, useRef } from 'react';

/*
 * This function is intended to be used as a replacement for setInterval. *
 * The issue with setInterval in react, is that it is difficult to mange the state,
 * and the state intrnally is usually the initial state only.
 */
const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const id = setInterval(() => {
      const call = savedCallback.current as any;
      call();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
