const consoleDebug = (message: string, data?: any) => {
  const query = new URLSearchParams(window.location.search);

  if (window.config.debugMode) {
    console.log(message, data);

    console.log('raceDebugMessage', data);
    // Create and dispatch a custom event with the message and data
    const event = new CustomEvent('raceDebugMessage', {
      detail: { message, data },
    });
    window.dispatchEvent(event);

    // Save the message and data to local storage and include a timestamp
    const timestamp = Date.now();
    const formattedTimestamp = new Date(timestamp).toLocaleString();
    const debugLog = {
      timestamp,
      formattedTimestamp,
      message,
      data,
    };
    const debugLogs = JSON.parse(localStorage.getItem('debugLogs') || '[]');
    debugLogs.push(debugLog);
    localStorage.setItem('debugLogs', JSON.stringify(debugLogs));
  }
};

export default consoleDebug;
