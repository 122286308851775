import React, { FunctionComponent, useEffect, useState } from 'react';

import WithFocus from '../../components/WithFocus';
import { useSanityCourse } from '../../context/sanity/useSanityCourse';
import { VisuallyHidden } from '../Templates/InfoPageStyles';
import { VideoPageTemplate } from '../types/courseTypes';

import WithFadeIn from '../../components/WithFadeIn';
import VimeoPage from './VimeoPage';
import { makeVimeoUrlPrivacyFriendly } from './vimeoHelper';

type PageProps = {
  template?: VideoPageTemplate | undefined;
};

const DynamicVideoPage: FunctionComponent<PageProps> = ({ template }) => {
  const [currentPage, setCurrentPage] = useState<VideoPageTemplate>({
    videoUrl: '',
    addContinueButton: false,
    addBackButton: false,
    navigateOnVideoComplete: false,
    videoIntroText: '',
  });
  const pageContext = useSanityCourse();

  useEffect(() => {
    const tempPage =
      template !== undefined
        ? template
        : pageContext.getCurrentPage().videoPageTemplate;

    if (tempPage !== undefined) {
      tempPage.videoUrl = makeVimeoUrlPrivacyFriendly(tempPage.videoUrl);
      setCurrentPage(tempPage);
    }
  }, []);

  if (currentPage.videoUrl !== '') {
    return (
      <>
        <VisuallyHidden tabIndex={-1}>
          {currentPage.videoIntroText}
        </VisuallyHidden>

        <VimeoPage
          video={currentPage.videoUrl}
          containContinueButton={currentPage.addContinueButton}
          continueButtonText={currentPage.continueButtonText}
          navigateOnVideoEnd={currentPage.navigateOnVideoComplete}
          continueButtonRevealTime={currentPage.continueButtonRevealTime}
          containBackButton={currentPage.addBackButton}
          backButtonText={currentPage.backButtonText}
        />
      </>
    );
  }
  return null;
};

export default WithFadeIn(WithFocus(DynamicVideoPage));
