/**
 * if the rate is between 100 and 120, the score is 100.
 * The score increases linearly from 0 to 100, where the max score of 100 is reaced when the rate is between 100 and 120.
 * The score decreases linearly down to 0, when the rate is from 120 to infinite
 * @param rate
 * @returns score
 */
const calculateScoreBasedOnRate = (rate: number): number => {
  const score = Math.max(
    Math.min(
      100 - ((100 - rate) * 100) / 45,
      100 - ((rate - 120) * 100) / 45,
      100,
    ),
    0,
  );

  return score;
};

export default calculateScoreBasedOnRate;
