import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { UseAbly } from '../../context/AblyContext';

const PrimaryColorSpan = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

interface CompletedLearnersProps {
  completedText?: string;
  displayCompletedLearners?: boolean;
}

const CompletedLearners: FunctionComponent<CompletedLearnersProps> = ({
  displayCompletedLearners = false,
  completedText,
}) => {
  const { connectedUsers, waitingUsers } = UseAbly();

  return displayCompletedLearners ? (
    <h1>
      <PrimaryColorSpan>{waitingUsers?.length}</PrimaryColorSpan>
      {`/${connectedUsers?.length} ${completedText ?? 'completed'}`}
    </h1>
  ) : null;
};

export default CompletedLearners;
