import { ToastProvider } from '@laerdal/life-react-components';
import React from 'react';
import CameraProvider from '../../context/CameraProvider';
import TcprLinkProvider from '../../context/TcprLinkProvider';
import UserProvider from '../../context/UserProvider';
import { ViewportHeightProvider } from '../../context/ViewportHeightProvider';
import { ViewportProvider } from '../../context/ViewportProvider';
import { ViewportWidthProvider } from '../../context/ViewportWidthProvider';
import FacilitatorApp from './facilitator/FacilitatorApp';
import LearnerApp from './learner/LearnerApp';

const GroupApp = () => {
  return (
    <ToastProvider>
      <CameraProvider>
        <TcprLinkProvider>
          <ViewportProvider>
            <ViewportWidthProvider>
              <ViewportHeightProvider>
                <UserProvider>
                  {window.revivr.isLearner ? (
                    <LearnerApp />
                  ) : (
                    <FacilitatorApp />
                  )}
                </UserProvider>
              </ViewportHeightProvider>
            </ViewportWidthProvider>
          </ViewportProvider>
        </TcprLinkProvider>
      </CameraProvider>
    </ToastProvider>
  );
};

export default GroupApp;
