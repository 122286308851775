/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import constants from '../constants/Constants';
import { getLaunchDarklyContext } from '../helper/launchDarklyHelper';
import { determineOrganizationProjectId } from '../helper/projectIdFinder';
import { LocalStorageService } from '../services';
import { logError } from '../services/errorHandling';

/**
 * Get Sanity Organization project id either from the config file or from the querystring
 * This updates on every hard refresh of the page
 */

const getSanityProjectId = (): string => {
  const ORGANIZATION_PROJECT_ID = determineOrganizationProjectId();

  const params = new URLSearchParams(window.location.search);
  const projectId = params.get(constants.queryKeys.SANITY_PROJECT_KEY);
  if (projectId && projectId.length > 1) {
    LocalStorageService.setProjectId(projectId);
    return projectId;
  }

  LocalStorageService.setProjectId(ORGANIZATION_PROJECT_ID);

  return ORGANIZATION_PROJECT_ID;
};

const getLocaleCode = (): string => {
  const params = new URLSearchParams(window.location.search);
  const localeCodeInURL = params.get(constants.queryKeys.LANGUAGE_CODE_KEY);
  if (localeCodeInURL) {
    LocalStorageService.setLanguageCodeToLocalStorage(localeCodeInURL);
    return localeCodeInURL;
  }
  const languageCodeFromLocalStorage =
    LocalStorageService.getLanguageCodeFromLocalStorage();
  if (languageCodeFromLocalStorage) {
    return languageCodeFromLocalStorage;
  }
  return '';
};

export type UserContextProps = {
  queryStrings: QueryStrings;
  queryStringChecked?: boolean;
  sanityProjectId: string;
  launchDarklyClientIsInitialised: boolean;
  localeCode: string;
  setProjectId: (projectId: string) => void;
  setUtmCampaign: (utmCampaign: string | null) => void;
  updateQueryStrings: () => void;
};

type Props = {
  children: any;
};

const {
  DISTRIBUTION_KEY,
  ORGANIZATION_KEY,
  PORTAL_ID_KEY,
  SHARE_LEVEL_KEY,
  START_OF_JOURNEY_KEY,
  TAG_KEY,
  UTM_CAMPAIGN,
  UTM_MEDIUM,
  UTM_SOURCE,
} = constants.queryKeys;

type QueryStrings = {
  distribution: string | null;
  organization: string | null;
  portalId: string | null;
  shareLevel: string | null;
  startOfJourney: string | null;
  tag: string | null;
  utm_campaign: string | null;
  utm_medium: string | null;
  utm_source: string | null;
};

type UpdateValueParams = {
  queryKey: string;
  localStorageServiceGetter: () => string | null;
  localStorageServiceSetter: (value: string | null) => void;
  localStorageKey: keyof QueryStrings;
};

const UserContext = React.createContext<UserContextProps>(
  {} as UserContextProps,
);

const UserProvider: FunctionComponent<Props> = ({ children }: Props) => {
  const [queryStrings, setQueryStrings] = useState<QueryStrings>(
    {} as QueryStrings,
  );
  const [queryStringChecked, setQueryStringChecked] = useState<boolean>(false);
  const [launchDarklyClientIsInitialised, setLaunchDarklyClientIsInitialised] =
    useState<boolean>(false);
  const [sanityProjectId, setSanityProjectId] = useState(getSanityProjectId());

  const setProjectId = (projectId: string) => {
    setSanityProjectId(projectId);
    LocalStorageService.setProjectId(projectId);
  };

  const localeCode = getLocaleCode();
  const ldClient = useLDClient();

  // We need to initialise LaunchDarkly and wait because the flags received from LD are not set properly immediately
  useEffect(() => {
    const initialiseLdUser = async () => {
      const launchDarklyContext = getLaunchDarklyContext(sanityProjectId);
      try {
        await ldClient.identify(launchDarklyContext);
        setLaunchDarklyClientIsInitialised(true);
      } catch (error) {
        logError(error, 'Error identifying user in LaunchDarkly');
      }
    };
    if (ldClient) {
      initialiseLdUser();
    }
    window.ldClient = ldClient;
  }, [ldClient]);

  /**
   * Checks if any of the specified querystring parameters have changed, and clears
   * the localstorage for all the specied values
   */
  const clearLocalStorageBasedOnQuerystring = () => {
    const params = new URLSearchParams(window.location.search);
    const distributionKey = params.get(DISTRIBUTION_KEY);
    const organization = params.get(ORGANIZATION_KEY);
    const portalId = params.get(PORTAL_ID_KEY);
    const shareLevel = params.get(SHARE_LEVEL_KEY);
    const startOfJourneyKey = params.get(START_OF_JOURNEY_KEY);
    const tagData = params.get(TAG_KEY);
    const utmCampaign = params.get(UTM_CAMPAIGN);
    const utmMedium = params.get(UTM_MEDIUM);
    const utmSource = params.get(UTM_SOURCE);

    if (
      distributionKey !== null ||
      organization !== null ||
      portalId !== null ||
      shareLevel !== null ||
      startOfJourneyKey !== null ||
      tagData !== null ||
      utmCampaign !== null ||
      utmMedium !== null ||
      utmSource !== null
    ) {
      LocalStorageService.clearDistribution();
      LocalStorageService.clearOrganization();
      LocalStorageService.clearPortalId();
      LocalStorageService.clearShareLevel();
      LocalStorageService.clearStartOfJourney();
      LocalStorageService.clearTag();
      LocalStorageService.clearUtmCampaign();
      LocalStorageService.clearUtmMedium();
      LocalStorageService.clearUtmSource();
    }
  };

  /**
   * Updates the given key in the current query string
   */
  const updateQueryString = (
    ObjectKey: keyof QueryStrings,
    value: string | null,
  ) => {
    setQueryStrings((currQueryString) => {
      currQueryString[ObjectKey] = value;
      return currQueryString;
    });
  };

  /**
   * Gets value for the given key from query string and updates localStorage.
   * If value is not present in querystring, try to get it from localStorage
   * @param queryKey matches key used in query string
   * @param localStorageServiceGetter A function for getting the value from localStorage
   * @param localStorageServiceSetter A function for setting the value in localStorage
   * @param localStorageKey [Optional] only used if different from querystring key. Eg: portalId
   */
  const updateValue = (params: UpdateValueParams) => {
    const {
      queryKey,
      localStorageServiceGetter,
      localStorageServiceSetter,
      localStorageKey,
    } = params;
    const searchParams = new URLSearchParams(window.location.search);
    const value = searchParams.get(queryKey) || localStorageServiceGetter();

    if (value) {
      updateQueryString(localStorageKey, value);
      localStorageServiceSetter(value);
    }
  };

  const updateUtm = (key: string) => {
    const params = new URLSearchParams(window.location.search);
    const currQueryStrings = params.get(key);

    // Function to update the state of query string item
    const changeQueryStringState = (value) => {
      setQueryStrings((currQueryString) => {
        currQueryString[key] = value;
        return currQueryString;
      });
    };

    if (currQueryStrings) {
      changeQueryStringState(currQueryStrings);
      localStorage.setItem(key, currQueryStrings);
    } else if (localStorage.getItem(key) !== null) {
      changeQueryStringState(localStorage.getItem(key));
    }
  };

  const updateUtmSources = () => {
    updateUtm(UTM_CAMPAIGN);
    updateUtm(UTM_MEDIUM);
    updateUtm(UTM_SOURCE);
  };

  const setUtmCampaign = (utmCampaign: string | null) => {
    setQueryStrings((currQueryString) => {
      currQueryString.utm_campaign = utmCampaign;
      return currQueryString;
    });
  };

  const updateQueryStrings = () => {
    clearLocalStorageBasedOnQuerystring();

    const paramsArray: UpdateValueParams[] = [
      {
        queryKey: DISTRIBUTION_KEY,
        localStorageServiceGetter: LocalStorageService.getDistribution,
        localStorageServiceSetter: LocalStorageService.setDistribution,
        localStorageKey: 'distribution',
      },
      {
        queryKey: ORGANIZATION_KEY,
        localStorageServiceGetter: LocalStorageService.getOrganization,
        localStorageServiceSetter: LocalStorageService.setOrganization,
        localStorageKey: 'organization',
      },
      {
        queryKey: SHARE_LEVEL_KEY,
        localStorageServiceGetter: LocalStorageService.getShareLevel,
        localStorageServiceSetter: LocalStorageService.setShareLevel,
        localStorageKey: 'shareLevel',
      },
      {
        queryKey: START_OF_JOURNEY_KEY,
        localStorageServiceGetter: LocalStorageService.getStartOfJourney,
        localStorageServiceSetter: LocalStorageService.setStartOfJourney,
        localStorageKey: 'startOfJourney',
      },
      {
        queryKey: TAG_KEY,
        localStorageServiceGetter: LocalStorageService.getTag,
        localStorageServiceSetter: LocalStorageService.setTag,
        localStorageKey: 'tag',
      },
      {
        queryKey: PORTAL_ID_KEY,
        localStorageServiceGetter: LocalStorageService.getPortalId,
        localStorageServiceSetter: LocalStorageService.setPortalId,
        localStorageKey: 'portalId',
      },
    ];
    paramsArray.forEach((params) => {
      updateValue(params);
    });

    updateUtmSources();
    setQueryStringChecked(true);
  };

  useEffect(() => {
    if (!queryStringChecked) {
      updateQueryStrings();
    }
  }, []);

  return (
    <UserContext.Provider
      value={useMemo(
        () => ({
          queryStringChecked,
          queryStrings,
          launchDarklyClientIsInitialised,
          sanityProjectId,
          localeCode,
          setProjectId,
          setUtmCampaign,
          updateQueryStrings,
        }),
        [
          queryStringChecked,
          queryStrings,
          launchDarklyClientIsInitialised,
          sanityProjectId,
          localeCode,
        ],
      )}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  return useContext(UserContext);
};

export default UserProvider;
