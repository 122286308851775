import { ModalDialog, Size } from '@laerdal/life-react-components';
import Vimeo from '@u-wave/react-vimeo';
import React, { FunctionComponent, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import LifeButton from '../../components/Button';
import { ClassroomCodeAndUrl } from '../../components/TopBars';
import constants from '../../constants/Constants';
import { useHeightViewport } from '../../context';
import { useNavigation } from '../../hooks';
import { logError } from '../../services/errorHandling';
import { ButtonContainer, PageGridStyle } from '../Templates/InfoPageStyles';
import mediaMedium from '../Templates/MediaMedium';
import './VimeoPage.css';

const { media, headerHeight, pageMargin } = constants;

const StyledPageGrid = styled(PageGridStyle)`
  max-width: 100%;

  ${mediaMedium(css`
    grid-template-rows:
      minmax(${headerHeight.MEDIUM}px, max-content)
      1fr
      ${pageMargin.SMALL}px
      minmax(0, max-content)
      ${pageMargin.SMALL}px;
  `)}

  @media (min-width: ${constants.maxPageWidth}px) {
    grid-template-columns:
      auto
      ${constants.pageMargin.MEDIUM}px
      ${constants.maxPageWidth / 4 - constants.pageMargin.MEDIUM}px
      ${constants.maxPageWidth / 4}px
      ${constants.maxPageWidth / 4}px
      ${constants.maxPageWidth / 4 - constants.pageMargin.MEDIUM}px
      ${constants.pageMargin.MEDIUM}px
      auto;
  }
`;
const StyledButtonContainer = styled(ButtonContainer)`
  @media (min-width: ${constants.maxPageWidth}px) {
    grid-column: 3 / -3;
  }

  grid-row: 2 / 3;
  margin-top: -16px;
  align-self: flex-start;
  justify-self: flex-end;
  width: 100%;

  ${mediaMedium(css`
    margin-top: 0px;
    grid-column: 2 / -2;
    grid-row: -3;
  `)}
`;

const VideoFrame = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  display: flex;

  .vimeoPlayer {
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: black;
    align-content: center;
    div:first-of-type {
      padding: 0 !important;
      position: unset !important;
      width: 100%;
      height: 100%;
    }
  }

  ${mediaMedium(css`
    grid-row: 2 / -4;
  `)}
`;

const StyledLifeButton = styled(LifeButton)<{ displayButton: boolean }>`
  visibility: ${(props) => (props.displayButton ? 'visible' : 'collapse')};
  // To make sure the button is always on top of the video player
  z-index: 10;

  @media only screen and (min-width: ${media.SMALL}px) {
    max-width: 123px;
  }
`;

interface VimeoPageProps {
  /**
   * A Vimeo video ID or URL.
   */
  video: number | string;
  /**
   * If true, automatically navigates to next page when Video ends
   */
  navigateOnVideoEnd?: boolean;
  /**
   * If true, displays a continue button the last 15sek
   */
  containContinueButton?: boolean;
  /**
   * If true, show a back button.
   */
  containBackButton?: boolean;
  continueButtonText?: string;
  backButtonText?: string;
  /**
   * If defined, the continue button will be displayed for the first x seconds of the video.
   * Default is 0 seconds.
   */
  continueButtonRevealTime?: number;
}

const VimeoPage: FunctionComponent<VimeoPageProps> = ({
  continueButtonText,
  containContinueButton = true,
  containBackButton = false,
  backButtonText,
  navigateOnVideoEnd = true,
  video,
  continueButtonRevealTime,
}: VimeoPageProps) => {
  const navigationContext = useNavigation();
  const [displayButton, setDisplayButton] = useState(false);
  const countDownRef = useRef<number>();
  const [playbackError, setPlaybackError] = useState(false);
  const { innerHeight } = useHeightViewport();

  const setContinueButtonVisibility = (event) => {
    const currTime = event.seconds;
    const totalDuration = event.duration;
    const timeLeft = totalDuration - currTime;
    clearTimeout(countDownRef.current);
    if (continueButtonRevealTime === undefined) {
      continueButtonRevealTime = 0;
    }

    if (timeLeft > totalDuration - continueButtonRevealTime && !displayButton) {
      setDisplayButton(false);
      countDownRef.current = window.setTimeout(() => {
        setDisplayButton(true);
      }, continueButtonRevealTime * 1000);
    } else if (!displayButton) {
      setDisplayButton(true);
    }
  };

  // Reveals the continue button immediately if the reveal time is 0 or undefined.
  if (!continueButtonRevealTime) {
    continueButtonRevealTime = 0;
    setContinueButtonVisibility({ seconds: 0, duration: 999 });
  }

  const modalOnClose = () => {
    setPlaybackError(false);
  };

  /**
   *
   * @param event (onPlay)
   * Initializing a countdown that will display the continue button when there is 15sek left of the video.
   * Else if - time left is less than 15sek, and the button is not yet displayed, make sure to display it.
   */
  const onPlay = (event) => {
    setContinueButtonVisibility(event);
  };

  /**
   * Stopping the countdown, to make sure it's not still running if a user pauses the video.
   */
  const onPause = () => {
    clearTimeout(countDownRef.current);
  };

  /**
   * @param event (onSeeked)
   * this callback is triggered when user scroll video progress bar and
   * parameter event has properties to give video information like duration and current elapsed time.
   */
  const onSeeked = (event) => {
    setContinueButtonVisibility(event);
  };

  // fullscreen feature is disabled in the vimeo dashboard
  return (
    <StyledPageGrid height={innerHeight}>
      <ClassroomCodeAndUrl />
      <VideoFrame>
        <Vimeo
          className="vimeoPlayer"
          showPortrait={false}
          responsive
          video={video}
          onError={(error: Error) => {
            logError(error);
            setPlaybackError(true);
          }}
          onPlay={(event) => {
            onPlay(event);
          }}
          onPause={() => {
            onPause();
          }}
          onSeeked={(event) => {
            onSeeked(event);
          }}
          onEnd={() => {
            if (navigateOnVideoEnd) {
              navigationContext?.navigateToNextPage();
            }
          }}
        />
      </VideoFrame>
      <StyledButtonContainer>
        <StyledLifeButton
          id="backButton"
          testId="backButton"
          invertFocus
          key={`button_${'secondary'}_${'Back'}`}
          style={{ width: '100%', fontSize: '200px' }}
          variant="secondary"
          onClick={() => {
            navigationContext?.navigateToPreviousPage();
          }}
          displayButton={
            window.innerWidth > constants.media.MEDIUM && containBackButton
          }
        >
          {backButtonText ?? 'Back'}
        </StyledLifeButton>
        <StyledLifeButton
          id="continueButton"
          testId="continueButton"
          invertFocus
          key={`button_${'primary'}_${'Continue'}`}
          style={{ width: '100%', fontSize: '200px' }}
          variant="primary"
          onClick={() => {
            navigationContext?.navigateToNextPageWithSleepCheck();
          }}
          displayButton={
            (!continueButtonRevealTime || displayButton) &&
            containContinueButton
          }
        >
          {continueButtonText ?? 'Continue'}
        </StyledLifeButton>
      </StyledButtonContainer>

      <ModalDialog
        key="smallModalWithoutImage"
        title="Playback error"
        size={Size.Small}
        isModalOpen={playbackError}
        submitAction={() => {}}
        buttons={[
          {
            action: () => {
              // reload current page
              window.location.reload();
            },
            text: 'Reload video',
            variant: 'tertiary',
          },
          {
            action: () => {
              navigationContext?.navigateToNextPageWithSleepCheck();
            },
            text: 'Skip video',
            variant: 'tertiary',
          },
        ]}
        closeModalAndClearInput={modalOnClose}
        closeAction={modalOnClose}
      />
    </StyledPageGrid>
  );
};
export default VimeoPage;
