import { useEffect } from 'react';

const GlobalUnhandledErrorHandlingHook = (): void => {
  const errorEventListener = (event) => {
    const currentTime = new Date();
    if (!window.revivr.errorMessages) {
      window.revivr.errorMessages = [];
    }

    const errorDetail = event.detail || {};
    const errorTimestamp = errorDetail.timestamp || currentTime;

    window.revivr.errorMessages.push({
      event,
      message:
        errorDetail.message ||
        errorDetail?.error?.message ||
        event.error?.message ||
        event.reason?.message,
      stack: errorDetail?.error?.stack || event.error?.stack,
      timestamp: errorTimestamp,
    });
  };

  useEffect(() => {
    window.addEventListener('customError', errorEventListener);
    window.addEventListener('error', errorEventListener);
    window.addEventListener('unhandledrejection', errorEventListener);

    return () => {
      window.removeEventListener('customError', errorEventListener);
      window.removeEventListener('error', errorEventListener);
      window.removeEventListener('unhandledrejection', errorEventListener);
    };
  }, []);
};

export default GlobalUnhandledErrorHandlingHook;
