/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  CameraFilter,
  FilterSkipButtonForPortrait,
  InputField,
  Speechbubble,
} from '../../components';
import { Modal, ModalProps } from '../../components/Modals';
import {
  BarWithIconWrapper,
  ClassroomCodeAndUrl,
  ProgressBar,
  TimeLeftBarWithoutText,
  TimeLeftWithText,
} from '../../components/TopBars';
import { AnimationState } from '../../components/TopBars/types';
import {
  useAudio,
  useHeightViewport,
  useSanityCourse,
  useViewport,
} from '../../context';
import {
  CompletedLearners,
  DisplayTeamName,
} from '../../features/groupRevivr/components';
import SanityModal from '../../features/sanity/sanityModal';
import { DynamicInputField } from '../../features/sanity/sanityTypes/templates/textAndImageTemplate';
import { useNavigation } from '../../hooks';
import ReplyBox from '../dialogPageTemplate/ReplyBox';
import { ContinueCourseModal } from '../dialogPageTemplate/types/ContinueCourseModal';
import { AudioTypes } from '../types/courseTypes';
import { SanityImage } from '../types/sanityTypes';
import ImagePart from './InfoPageImage';
import {
  BottomContainer,
  FooterTextStyle,
  PageGridStyle,
  ProgressbarContainer,
  StyledText,
  VideoContainer,
  VideoStyle,
} from './InfoPageStyles';
import { Button, CountDown, Video } from './InfoPageTypes';
import { Text } from './Text';

interface TimeLeftBarProps {
  showTimeLeftBar?: boolean;
  showTimeLeftBarWithoutText?: boolean;
}

export interface InfoPageDialog {
  showInitialDialog?: boolean;
  initialDialog?: InfoPageDialogText;
  showAnswerDialog?: boolean;
  answerDialog?: InfoPageDialogText;
}

interface InfoPageDialogText extends Text {
  text?: ReactNode;
  textAlignCenter?: boolean;
  pointerAlignLeft?: boolean;
  button?: Button;
}

export type PageProps = {
  bottomContent?: React.ReactNode;
  buttons?: Button[];
  completedText?: string;
  continueCourseModal?: ContinueCourseModal;
  countDown?: CountDown;
  dialogText?: InfoPageDialog;
  displayCompletedLearners?: boolean;
  displayTeamName?: boolean;
  enableAudio?: boolean;
  enableDialogText?: boolean;
  enableInputField?: boolean;
  image?: SanityImage;
  inputField?: DynamicInputField;
  modal?: ModalProps;
  showTimeLeftBar?: boolean;
  text?: Text;
  verticalButtons?: boolean;
  video?: Video;
};

type MainContentProps = {
  completedText?: string;
  dialogText?: InfoPageDialog;
  displayCompletedLearners?: boolean;
  enableDialogText: boolean;
  enableInputField: boolean;
  inputField?: DynamicInputField;
  setDisablePrimaryButtons?: React.Dispatch<React.SetStateAction<boolean>>;
  text?: Text;
};

const MainContent: FunctionComponent<MainContentProps> = ({
  completedText,
  dialogText,
  displayCompletedLearners,
  enableDialogText,
  enableInputField,
  inputField,
  setDisablePrimaryButtons,
  text,
}) => {
  return (
    <StyledText>
      {enableDialogText && dialogText !== undefined ? (
        <>
          {dialogText && dialogText.showInitialDialog && (
            <Speechbubble
              tabIndex={-1}
              pointerLeft={dialogText.initialDialog?.pointerAlignLeft}
              textAlignCenter={dialogText.initialDialog?.textAlignCenter}
            >
              {dialogText.initialDialog?.text}
            </Speechbubble>
          )}
          {dialogText &&
            dialogText.showAnswerDialog &&
            dialogText.answerDialog && (
              <ReplyBox
                showOnlyOnMobileDevices
                text={dialogText.answerDialog.text}
                button={dialogText.answerDialog.button}
                textAlignCenter={dialogText.answerDialog?.textAlignCenter}
              />
            )}
        </>
      ) : (
        text && (
          <h1 tabIndex={-1}>
            {text.header}
            {text.header2 ? (
              <>
                <br />
                {text.header2}
              </>
            ) : null}
          </h1>
        )
      )}

      {text &&
        (typeof text.body === 'string' ? (
          <p>
            {text.body}
            <strong>{text.emphasized}</strong>
          </p>
        ) : (
          text.body
        ))}

      {inputField && enableInputField && (
        <InputField
          alt={inputField.alt}
          dataType={inputField.dataType}
          errorMessage={inputField.errorMessage}
          placeholder={inputField.placeholder}
          range={inputField.range}
          setDisablePrimaryButtons={setDisablePrimaryButtons}
        />
      )}
      {text && text.footerText && (
        <FooterTextStyle>{text.footerText}</FooterTextStyle>
      )}

      <CompletedLearners
        displayCompletedLearners={displayCompletedLearners}
        completedText={completedText}
      />
    </StyledText>
  );
};

interface TimeLeftBarProps {
  showTimeLeftBar?: boolean;
  showTimeLeftBarWithoutText?: boolean;
}

export const TimeLeftBar: FunctionComponent<TimeLeftBarProps> = ({
  showTimeLeftBar = true,
  showTimeLeftBarWithoutText = false,
}) => {
  const navigationContext = useNavigation();
  if (navigationContext && showTimeLeftBar) {
    if (showTimeLeftBarWithoutText)
      return (
        <TimeLeftBarWithoutText
          timeLeftInSeconds={navigationContext.timeLeftInSeconds}
          totalTimeInSeconds={navigationContext.totalTimeInSeconds}
        />
      );
    return <TimeLeftWithText enabled />;
  }
  return null;
};

interface VideoPartProps {
  video?: Video;
}

const VideoPart: FunctionComponent<VideoPartProps> = ({ video }) => {
  const { isLandscape } = useViewport();

  return video && video.ref ? (
    <VideoContainer>
      <CameraFilter>
        <VideoStyle isLandscape={isLandscape} ref={video.ref}></VideoStyle>
      </CameraFilter>
    </VideoContainer>
  ) : null;
};

const InfoPage: FunctionComponent<PageProps> = ({
  bottomContent,
  buttons,
  completedText,
  continueCourseModal,
  countDown,
  dialogText,
  displayCompletedLearners,
  displayTeamName,
  enableAudio,
  enableDialogText = false,
  enableInputField,
  image,
  inputField,
  modal,
  showTimeLeftBar = true,
  text,
  verticalButtons = false,
  video,
}: PageProps) => {
  const navigationContext = useNavigation();
  const audio = useAudio();
  const pageContext = useSanityCourse();
  const [onRefreshModal, setOnRefreshModal] = useState<
    // onRefreshModal is used to show a modal when the user refreshes the page. If this value is not undefined, the audio and autonavigation is disabled
    ModalProps | undefined
  >();
  const [disablePrimaryButtons, setDisablePrimaryButtons] = useState(false);
  const { innerHeight } = useHeightViewport();
  const { isMediumWidth } = useViewport();

  const dismissOnRefreshModal = () => {
    if (!audio) return;

    audio.initCurrentSection();
    if (enableAudio) {
      audio.playAudioOnPage(AudioTypes.OnLoad);
    }
    setOnRefreshModal(undefined);
  };
  const createOnRefreshModal = () => {
    if (
      navigationContext &&
      navigationContext.initialPage === window.location.pathname &&
      audio?.doesSectionContainAudio()
    ) {
      const newModal = SanityModal.createContinueCourseModal(
        pageContext,
        dismissOnRefreshModal,
        navigationContext,
      );
      setOnRefreshModal(newModal);
    }
  };

  useEffect(() => {
    if (
      enableAudio &&
      navigationContext &&
      navigationContext.initialPage !== window.location.pathname
    ) {
      audio?.playAudioOnPage(AudioTypes.OnLoad);
    }
    const locationPathname = window.location.pathname;

    return () => {
      if (enableAudio) {
        audio?.pauseFromAudioLibrary(locationPathname);
        audio?.resetAudio(locationPathname);
      }
    };
  }, [enableAudio]);

  useEffect(() => {
    if (continueCourseModal !== undefined) {
      // Learner in group should not see the refresh modal
      if (!(window.revivr.isGroupRevivr && window.revivr.isLearner)) {
        createOnRefreshModal();
      }
    }
  }, [navigationContext.initialPage]);

  const countDownDuration = (): number => {
    if (countDown) {
      return countDown.duration;
    }
    if (navigationContext) {
      return navigationContext.currentPageDuration();
    }

    return 10;
  };

  return (
    <PageGridStyle height={innerHeight} imgType={image?.imgType}>
      <ClassroomCodeAndUrl />
      <DisplayTeamName enabled={displayTeamName} />
      {modal && !Array.isArray(modal) && (
        <Modal
          buttons={modal.buttons}
          onClose={modal.onClose}
          text={modal.text}
          image={modal.image}
          bannerImage={modal.bannerImage}
          verticalButtons={modal.verticalButtons}
        />
      )}
      {onRefreshModal && (
        // onRefreshModal is controlling if the audio, progressbar and autonavigation.
        <Modal
          bannerImage={onRefreshModal.bannerImage}
          buttons={onRefreshModal.buttons}
          onClose={onRefreshModal.onClose}
          text={onRefreshModal.text}
          verticalButtons
        />
      )}
      <ProgressbarContainer>
        {countDown ? (
          <BarWithIconWrapper hideIcon={isMediumWidth}>
            <ProgressBar
              animationDurationInSeconds={countDownDuration()}
              animationDelayInSeconds={countDown.delay}
              animationState={
                onRefreshModal
                  ? AnimationState.paused
                  : countDown?.animationState
              }
              setAnimRef={countDown.setAnimRef}
            />
          </BarWithIconWrapper>
        ) : (
          <TimeLeftWithText enabled={showTimeLeftBar} />
        )}
      </ProgressbarContainer>

      <ImagePart image={image} />
      <VideoPart video={video} />
      <MainContent
        completedText={completedText}
        dialogText={dialogText}
        displayCompletedLearners={displayCompletedLearners}
        enableDialogText={enableDialogText}
        inputField={inputField}
        enableInputField={enableInputField ?? false}
        text={text}
        setDisablePrimaryButtons={setDisablePrimaryButtons}
      />

      {FilterSkipButtonForPortrait(
        buttons,
        verticalButtons,
        disablePrimaryButtons,
      )}

      <BottomContainer>{bottomContent}</BottomContainer>
    </PageGridStyle>
  );
};

export default InfoPage;
