/* eslint-disable react/destructuring-assignment */
import { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PatientAssessment from '../../assets/images/PatientAssessment.svg';
import LifeButton from '../../components/Button';
import QuizButtonNew, {
  QuizButtonProps,
  SubmittedAnswer,
} from '../../components/Quiz/Button';
import { TimeLeftWithText } from '../../components/TopBars';
import { focusElementWithTabIndexMinusOne } from '../../components/UseFocus';
import constants from '../../constants/Constants';
import { useHeightViewport } from '../../context';
import {
  FireBaseCollections,
  useFirebase,
} from '../../context/FirebaseProvider';
import { DisplayTeamName } from '../../features/groupRevivr/components';
import { defaultText } from '../../features/sanity';
import { QuizAnswerLabel, QuizButtonText } from '../../features/sanity/types';
import ImagePart from '../Templates/InfoPageImage';
import {
  ButtonContainer,
  Img,
  PageGridStyle,
  ProgressbarContainer,
  StyledText,
} from '../Templates/InfoPageStyles';
import { Button } from '../Templates/InfoPageTypes';
import { SanityImage } from '../types/sanityTypes';
import mediaMedium from './../Templates/MediaMedium';
import Styles from './QuizPage.styles';

interface QuizAnswer extends QuizButtonProps {
  answerString: string;
}

const AnswerContainer = styled.div`
  display: flex;
  height: fit-content;
  grid-row: 2 / 3;
  flex-direction: column;
  grid-row: 2;
  ${mediaMedium(css`
    grid-row: 2;
    grid-column: 2 / 3;
    justify-content: center;
    margin: 0 0 0 ${constants.pageMargin.MEDIUM}px;
  `)}
`;

const MainContentContainer = styled.div`
  display: grid;
  grid-row: 2 / 3;
  grid-column: 2 / -2;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
  ${mediaMedium(css`
    grid-row: 2 / 3;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 2fr;
    justify-content: center;
    align-content: center;
  `)};
`;

const BodySmall = styled.p`
  /* Body / Small */
  font-size: 1.6rem;
  line-height: 140%;
  margin: 8px 0;

  color: ${(props) => props.theme.colors.neutral_600};
`;
const StyledAnswerText = styled.div`
  text-align: ${(props) => props.theme.textAlignment};
  h4 {
    margin: 8px 0;
    color: ${(props) => props.theme.colors.neutral_800};
  }
`;

const ImageContainer = styled.div`
  display: none;
  ${mediaMedium(css`
    display: flex;
    grid-column: 1/2;
    grid-row: 1/3;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
  `)}
`;

const IMAGE_MARGIN = '128px';
const IMAGE_MAX_WIDTH = '200px';

const StyledImg = styled(Img)`
  display: flex;
  width: 100%;
  height: 100%;
  margin: ${IMAGE_MARGIN};
  max-width: ${IMAGE_MAX_WIDTH};
`;

const DisplayImage = (image: SanityImage) => {
  if (image?.src) {
    return <ImagePart image={image} />;
  }
  return (
    <StyledImg
      src={PatientAssessment}
      alt="A person assessing the status of a patient"
    />
  );
};

interface ButtonProps {
  selectedAnswers?: string[];
  buttons?: Button[];
  numberOfSelected: number;
  isSurvey: boolean;
  setCorrectAnswer: () => void;
  correctAnswerSelected?: boolean;
  updateSubmittedAnswer: (input: SubmittedAnswer) => void;
  quizButtonText?: QuizButtonText;
}
const Buttons: FunctionComponent<ButtonProps> = ({
  buttons,
  selectedAnswers = [],
  numberOfSelected,
  isSurvey,
  setCorrectAnswer,
  correctAnswerSelected = false,
  updateSubmittedAnswer,
  quizButtonText,
}: ButtonProps) => {
  const { storeCollection } = useFirebase();
  const [answerSubmitted, setAnswerSubmitted] = useState(false);

  const storeAnswerToFireBase = (
    answerSelected: string,
    isCorrect: boolean,
  ) => {
    const quizCollection = {
      answerSelected,
      isCorrectAnswer: isCorrect,
    };
    storeCollection(FireBaseCollections.quizQuestion, quizCollection);
  };
  const handleSubmitAnswerClick = () => {
    storeAnswerToFireBase(selectedAnswers[0], correctAnswerSelected);
    setAnswerSubmitted(true);
    updateSubmittedAnswer({
      isSubmitted: true,
      isCorrect: correctAnswerSelected,
    });
  };
  const handleShowAnswerClick = () => {
    setCorrectAnswer();
  };
  const handleTryAgainClick = () => {
    setAnswerSubmitted(false);
    updateSubmittedAnswer({
      isSubmitted: false,
      isCorrect: undefined,
    });
  };

  if (!isSurvey) {
    if (!answerSubmitted) {
      const buttonText = 'OK';
      return (
        <Styles.StyledButtonContainer role="presentation">
          <LifeButton
            key={`button_primary_${buttonText}`}
            variant="primary"
            onClick={handleSubmitAnswerClick}
            disabled={!numberOfSelected}
          >
            {quizButtonText?.okButtonText || defaultText.quizPage.buttonText.ok}
          </LifeButton>
        </Styles.StyledButtonContainer>
      );
    }
    if (!correctAnswerSelected && numberOfSelected > 0) {
      return (
        <Styles.StyledButtonContainer role="presentation">
          <LifeButton
            key="button_secondary_Show answer"
            variant="secondary"
            onClick={handleShowAnswerClick}
          >
            {quizButtonText?.showAnswerButtonText ||
              defaultText.quizPage.buttonText.showAnswer}
          </LifeButton>
          <LifeButton
            key="button_primary_Try again"
            variant="primary"
            onClick={handleTryAgainClick}
          >
            {quizButtonText?.tryAgainButtonText ||
              defaultText.quizPage.buttonText.tryAgain}
          </LifeButton>
        </Styles.StyledButtonContainer>
      );
    }
  }
  // if correct answer is selected and submitted or if it is a survey
  return (
    <Styles.StyledButtonContainer role="navigation" aria-label="Main">
      {buttons && numberOfSelected > 0
        ? buttons.map((button: Button) => {
            return (
              <LifeButton
                className={button.buttonType}
                key={`button_${button.buttonType}_${button.text}`}
                variant={button.buttonType}
                onClick={() => {
                  if (isSurvey) {
                    storeCollection(FireBaseCollections.selectedAnswers, {
                      ...selectedAnswers,
                    });
                  }
                  button.onClick();
                }}
              >
                {button.text || defaultText.quizPage.buttonText.continue}
              </LifeButton>
            );
          })
        : null}
    </Styles.StyledButtonContainer>
  );
};
const MappedAnswers = (
  quizAnswers: QuizAnswer[],
  AnswerClicked: (answerText: string, isCorrect?: boolean) => void,
  setCorrectAnswerSelected: (isCorrect: boolean) => void,
  isSurvey: boolean,
  selectedAnswers: string[],
  submittedAnswer?: SubmittedAnswer,
): JSX.Element | null => {
  if (quizAnswers) {
    const returnAnswers = quizAnswers.map((answer: QuizAnswer) => {
      if (
        !(
          submittedAnswer?.isSubmitted &&
          submittedAnswer.isCorrect &&
          !answer.isCorrect
        )
      ) {
        return (
          <QuizButtonNew
            key={`button_${answer.answerString}`}
            isSurvey={isSurvey}
            submittedAnswer={submittedAnswer}
            isSelected={selectedAnswers.includes(answer.answerString)}
            onClick={() => {
              AnswerClicked(answer.answerString, answer.isCorrect);
              if (typeof answer.onClick === 'function') {
                answer.onClick();
              }
              if (!isSurvey) {
                setCorrectAnswerSelected(answer.isCorrect);
              }
            }}
          >
            {answer.answerString}
          </QuizButtonNew>
        );
      }
      return null;
    });
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{returnAnswers}</>;
  }
  return null;
};
const Answers = (
  answers: QuizAnswer[],
  AnswerClicked: (clickedAnswerText: string, isCorrect?: boolean) => void,
  setCorrectAnswerSelected: (isCorrect: boolean) => void,
  isSurvey: boolean,
  selectedAnswers: string[],
  quizAnswerLabel: QuizAnswerLabel,
  answerText?: string,
  submittedAnswer?: SubmittedAnswer,
): JSX.Element | null => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    const selectAnswerLabelText = quizAnswerLabel.selectAnswerLabelText;
    const correctAnswerLabelText = quizAnswerLabel.correctAnswerLabelText;
    const wrongAnswerLabelText = quizAnswerLabel.wrongAnswerLabelText;

    const isAnswerSubmitted = submittedAnswer?.isSubmitted ?? false;
    const isAnswerCorrect = submittedAnswer?.isCorrect ?? false;

    if (isSurvey || !isAnswerSubmitted) {
      setDisplayText(selectAnswerLabelText);
    } else {
      setDisplayText(
        isAnswerCorrect ? correctAnswerLabelText : wrongAnswerLabelText,
      );
    }
  }, [isSurvey, submittedAnswer, quizAnswerLabel]);

  if (answers) {
    return (
      <AnswerContainer role="radiogroup" aria-labelledby="group_label_1">
        <BodySmall id="group_label_1">{displayText}</BodySmall>

        {MappedAnswers(
          answers,
          AnswerClicked,
          setCorrectAnswerSelected,
          isSurvey,
          selectedAnswers,
          submittedAnswer,
        )}

        <StyledAnswerText>
          <h4>
            <b>
              {submittedAnswer &&
              submittedAnswer.isSubmitted &&
              submittedAnswer.isCorrect
                ? answerText
                : ''}
            </b>
          </h4>
        </StyledAnswerText>
      </AnswerContainer>
    );
  }
  return null;
};

type TimeLeftBarProps = {
  showTimeLeftBar: boolean;
};
const TimeLeftBar: FunctionComponent<TimeLeftBarProps> = ({
  showTimeLeftBar = true,
}) => {
  if (showTimeLeftBar) {
    return (
      <ProgressbarContainer>
        <TimeLeftWithText enabled={showTimeLeftBar} />
      </ProgressbarContainer>
    );
  }
  return null;
};

type QuestionProps = {
  question: string | null;
};

const QUIZ_TEXT_PADDING_BOTTOM = '25px';

const QuestionComponent: FunctionComponent<QuestionProps> = ({ question }) => {
  const StyledQuizText = styled(StyledText)`
    grid-row: 2;
    display: flex;
    height: fit-content;
    grid-row: 1 / 2;

    h1 {
      margin: 16px 0;
    }
    ${mediaMedium(css`
      align-self: flex-end;
      padding-bottom: ${QUIZ_TEXT_PADDING_BOTTOM};
      grid-row: 1;
      grid-column: 2;
      justify-content: center;
      align-content: center;
      margin: 0 0 0 ${constants.pageMargin.MEDIUM}px;
    `)}
  `;
  if (question) {
    return (
      <StyledQuizText>
        <h1 tabIndex={-1}>{question}</h1>
      </StyledQuizText>
    );
  }
  return null;
};

export type QuizPageProps = {
  answers: QuizAnswer[];
  answerText: string;
  buttons: Button[];
  displayTeamName: boolean;
  image: SanityImage;
  isSurvey: boolean;
  question: string;
  quizAnswerLabel: QuizAnswerLabel;
  quizButtonText: QuizButtonText;
  showTimeLeftBar: boolean;
};
const QuizPage: FunctionComponent<QuizPageProps> = ({
  answers,
  answerText,
  buttons,
  displayTeamName,
  image,
  isSurvey,
  question,
  quizAnswerLabel,
  quizButtonText,
  showTimeLeftBar,
}: QuizPageProps) => {
  const { innerHeight } = useHeightViewport();
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const [numberOfSelected, setNumberOfSelected] = useState<number>(0);
  const [correctAnswerSelected, setCorrectAnswerSelected] =
    useState<boolean>(false);
  const [submittedAnswer, setSubmittedAnswer] = useState<SubmittedAnswer>({
    isSubmitted: false,
    isCorrect: false,
  });

  const updateCorrectAnswerSelected = (isCorrect = false) => {
    setCorrectAnswerSelected(isCorrect);
  };
  const updateSubmittedAnswer = (input: SubmittedAnswer) => {
    setSubmittedAnswer(input);
  };

  // when a user click on the answer selection
  const AnswerClicked = (clickedAnswerText: string, isCorrect?: boolean) => {
    const manipulatedAnswers = [...selectedAnswers];
    const index = manipulatedAnswers.indexOf(clickedAnswerText);

    if (isSurvey) {
      if (index >= 0) {
        manipulatedAnswers.splice(index, 1);
      } else {
        manipulatedAnswers.push(clickedAnswerText);
      }
      setSelectedAnswers(manipulatedAnswers);
      setNumberOfSelected(manipulatedAnswers.length);
    } else {
      manipulatedAnswers[0] = clickedAnswerText;
      setSelectedAnswers(manipulatedAnswers);
      setNumberOfSelected(manipulatedAnswers.length);
    }
  };

  const setCorrectAnswer = () => {
    updateCorrectAnswerSelected(true);
    updateSubmittedAnswer({
      isSubmitted: true,
      isCorrect: true,
    });
    answers.forEach((answer) => {
      if (answer.isCorrect) {
        AnswerClicked(answer.answerString);
      }
    });
  };

  useEffect(() => {
    focusElementWithTabIndexMinusOne();
  }, [submittedAnswer]);
  return (
    <PageGridStyle height={innerHeight}>
      <DisplayTeamName enabled={displayTeamName} />
      <TimeLeftBar showTimeLeftBar={showTimeLeftBar} />
      <MainContentContainer>
        <ImageContainer>{DisplayImage(image)}</ImageContainer>
        <QuestionComponent question={question} />

        {Answers(
          answers,
          AnswerClicked,
          updateCorrectAnswerSelected,
          isSurvey,
          selectedAnswers,
          quizAnswerLabel,
          answerText,
          submittedAnswer,
        )}
      </MainContentContainer>
      <Buttons
        buttons={buttons}
        selectedAnswers={selectedAnswers}
        numberOfSelected={numberOfSelected}
        isSurvey={isSurvey}
        correctAnswerSelected={correctAnswerSelected}
        setCorrectAnswer={setCorrectAnswer}
        updateSubmittedAnswer={updateSubmittedAnswer}
        quizButtonText={quizButtonText}
      />
    </PageGridStyle>
  );
};

export default QuizPage;
