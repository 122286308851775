import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import BrandIcon from './BrandIcon';

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(1px, max-content) 1fr;
  align-content: center;
  justify-content: center;
`;
const BarAligner = styled.div`
  grid-column: -2;
  height: max-content;
  align-self: center;
`;

interface Props {
  children?: ReactNode;
  hideIcon?: boolean;
}

const BarWithIconWrapper: FunctionComponent<Props> = ({
  children,
  hideIcon = false,
}) => {
  return (
    <Grid>
      {!hideIcon && <BrandIcon />}
      <BarAligner>{children}</BarAligner>
    </Grid>
  );
};

export default BarWithIconWrapper;
