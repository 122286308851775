/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@laerdal/life-react-components';
import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import constants from '../../constants/Constants';
import { Img } from '../../pageTemplates/Templates/InfoPageStyles';
import { Button } from '../../pageTemplates/Templates/InfoPageTypes';
import LifeButton from '../Button';
import { focusOnElement } from '../UseFocus';
import ModalContainer from './ModalContainer';
import ModalProps from './ModalTypes';

const { pageMargin } = constants;
const StyledLifeModal = styled.div`
  width: calc(100vw - ${2 * pageMargin.SMALL}px);
  max-width: ${constants.maxPageWidthMobile - 2 * pageMargin.SMALL}px;
  display: grid;
  grid-template-columns: ${pageMargin.SMALL}px 1fr ${pageMargin.SMALL}px;
  grid-template-rows:
    ${pageMargin.SMALL}px
    minmax(32px, max-content)
    minmax(max-content, 1fr)
    minmax(max-content, 1fr)
    minmax(0, max-content)
    ${pageMargin.SMALL}px;
`;

const StyledModalHeader = styled(ModalHeader)`
  grid-column: 1 / -1;
  grid-row: 2;
  display: grid !important;
  grid-template-columns: ${pageMargin.SMALL}px 1fr ${pageMargin.SMALL}px;
  grid-template-rows:
    minmax(0, max-content)
    minmax(0, max-content);
  margin-bottom: 16px;
`;
const ModalCloseButtonAligner = styled.div`
  align-self: flex-start;
  justify-self: flex-end;
  grid-column: 2 / -2;
  grid-row: 1;
  z-index: 2;
`;
const ImgWithSize = styled(Img)<{
  maxHeight?: string;
  fullWidth?: boolean;
}>`
  ${(props) =>
    props.fullWidth
      ? `
    grid-column: 1 / -1;
    grid-row: 2;
    `
      : `
    max-height: ${props.maxHeight ? props.maxHeight : '102px'};
    grid-column: 2 / -2;
    grid-row: 1;
    `}
`;
const BannerImg = styled(ImgWithSize)`
  align-self: center;
  justify-self: center;
`;

const StyledModalBody = styled(ModalBody)`
  grid-column: 2 / -2;
  grid-row: 3 / -3;
  margin-bottom: 16px;
`;
const StyledModalFooter = styled(ModalFooter)<{ verticalButtons?: boolean }>`
  grid-column: 2 / -2;
  grid-row: -3;
  display: flex;
  ${(props) =>
    props.verticalButtons
      ? `
  margin: 0px;
  flex-direction: column;
  button {
    margin: 0px;
    margin-bottom: 8px;
  }
  button:last-of-type {
    margin: 0px;
  }`
      : `
      
  margin: 0 -5px;

  button {
    margin: 0 5px;
  }`}
  margin-bottom: 16px;
`;

const StyledText = styled.div`
  text-align: ${(props) => props.theme.textAlignment};
  h1,
  h2 {
    text-align: center;
    margin: 0;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: bold;
    line-height: 120%;
    /* or 31px */

    /* Neutrals / 800 shade */

    color: ${(props) => props.theme.colors.neutral_800};
  }
  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 120%;
    /* or 24px */

    /* Neutrals / 700 shade */

    color: ${(props) => props.theme.colors.neutral_600};
  }
`;

const Modal: FunctionComponent<ModalProps> = ({
  image,
  buttons,
  text,
  bannerImage,
  verticalButtons = false,
  onClose,
}: ModalProps) => {
  const modalRef = useCallback((node) => {
    if (node !== null) {
      setTimeout(() => {
        focusOnElement(node);
      }, 20);
    }
  }, []);

  return (
    <ModalContainer showModal closeModal={onClose}>
      <StyledLifeModal ref={modalRef}>
        <StyledModalHeader>
          <ModalCloseButtonAligner>
            <ModalCloseButton
              onClick={() => {
                onClose();
              }}
            />
          </ModalCloseButtonAligner>
          {bannerImage && (
            <BannerImg
              src={bannerImage.src}
              alt={bannerImage.alt ? bannerImage.alt : 'Banner'}
              maxHeight={bannerImage.size}
              onClick={bannerImage.onClick}
              fullWidth={bannerImage.fullWidth}
            />
          )}
        </StyledModalHeader>

        <StyledModalBody>
          {text && (
            <StyledText>
              <h1 tabIndex={-1}>{text.header}</h1>
              <h2>{text.header2}</h2>
              <p>
                {text.body}
                <strong>{text.emphasized}</strong>
              </p>
            </StyledText>
          )}
        </StyledModalBody>
        <StyledModalFooter verticalButtons={verticalButtons}>
          {buttons
            ? buttons.map((button: Button) => {
                return (
                  <LifeButton
                    width="100%"
                    tabIndex={0}
                    key={`button_${button.buttonType}_${button.text}`}
                    variant={button.buttonType}
                    onClick={() => {
                      if (button.onClick !== undefined) {
                        button.onClick();
                      }
                    }}
                  >
                    {button.text}
                  </LifeButton>
                );
              })
            : null}
        </StyledModalFooter>
      </StyledLifeModal>
    </ModalContainer>
  );
};

export default Modal;
