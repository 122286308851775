import { LoadingIndicator } from '@laerdal/life-react-components';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useSanityCourse } from '../context/sanity/useSanityCourse';
import ErrorPage from './ErrorPage';

// styled component that is centered in the middle of the screen
const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LoadingPage: React.FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const sanityContext = useSanityCourse();
  if (sanityContext.error) {
    return <ErrorPage error={sanityContext.error} />;
  }

  const courseNotLoadedForGroup = () => {
    return (window.revivr.isGroupRevivr && (!sanityContext.course || sanityContext.course.length === 0))
  }

  const landingPageNotLoadedForSolo = () => {
    return (!window.revivr.isGroupRevivr && !sanityContext.projectPages.landingPage);
  }

  if (courseNotLoadedForGroup() || landingPageNotLoadedForSolo()) {
    return (
      <Centered>
        <LoadingIndicator />
      </Centered>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default LoadingPage;
