import { Size } from '@laerdal/life-react-components';
import { FunctionComponent } from 'react';
import Button from '../../components/Button';
import { defaultText } from '../../features/sanity';
import { useNavigation } from '../../hooks';
import {
  DraggableArea,
  HorizontalBottomContent,
  ImageStyle,
  MainContent,
  MainContentWrapper,
  MainTextTopMargin,
  SubText,
} from '../Templates/SimulationPageStyles';
import { AEDDraggablePadPageTemplate } from '../types/courseTypes';
import { calculateHeight, calculateWidth } from './AedPadUtil';

interface AedHelpProps {
  pageData?: AEDDraggablePadPageTemplate;
  practiseAgainClicked: () => void;
}

const AedHelp: FunctionComponent<AedHelpProps> = ({
  pageData,
  practiseAgainClicked,
}: AedHelpProps) => {
  const navigationContext = useNavigation();
  return (
    <>
      <MainContentWrapper>
        <MainContent>
          <DraggableArea>
            <ImageStyle
              src={pageData?.correctImage?.src}
              width={calculateWidth()}
              height={calculateHeight()}
              alt={
                defaultText.aedDraggablePad.images.womanWithCorrectPadPlacement
                  .alt
              }
            />
          </DraggableArea>
          <MainTextTopMargin>
            {pageData?.aedPadHelp?.headingText}
          </MainTextTopMargin>
          <SubText>{pageData?.aedPadHelp?.bodyText}</SubText>
        </MainContent>
      </MainContentWrapper>
      <HorizontalBottomContent>
        <Button
          size={window.innerWidth <= 320 ? Size.Small : Size.Medium}
          style={{ width: '100%' }}
          variant="secondary"
          onClick={() => {
            practiseAgainClicked();
          }}
        >
          {pageData?.aedPadHelp?.buttons?.secondaryButtonText ??
            defaultText.aedPadButtonText.padHelpModal.secondary}
        </Button>
        <Button
          id="continueToNextPageButton"
          size={window.innerWidth <= 320 ? Size.Small : Size.Medium}
          style={{ width: '100%' }}
          variant="primary"
          onClick={() => {
            navigationContext?.navigateToNextPageWithSleepCheck();
          }}
        >
          {pageData?.aedPadHelp?.buttons?.primaryButtonText ??
            defaultText.aedPadButtonText.padHelpModal.primary}
        </Button>
      </HorizontalBottomContent>
    </>
  );
};
export default AedHelp;
