import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import constants from '../../constants/Constants';
import { useHeightViewport } from '../../context';

const { pageMargin } = constants;

// eslint-disable-next-line import/prefer-default-export
const Wrapper = styled.div<{ height: number; relative?: boolean }>`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  max-width: ${constants.maxPageWidthMobile}px;
  height: ${(props) => props.height}px;
  position: ${(props) => (props.relative === true ? 'relative' : 'auto')};
`;

export const MainContent = styled.div`
  display: flex;
  justify-content: Center;
  align-items: center;
  flex-direction: column;
  text-align: ${(props) => props.theme.textAlignment};
`;

export const MainContentWrapper = styled.main`
  flex: 1;
  display: flex;
  justify-content: Center;
  align-items: center;
  flex-direction: column;
`;

export const MainText = styled.h1`
  font-weight: bold;
  line-height: 120%;
  text-align: center;
  color: ${(props) => props.theme.colors.neutral_800};
  margin: 0px ${constants.pageMargin.SMALL}px ${pageMargin.SMALL}px
    ${pageMargin.SMALL}px;
`;

export const MainTextTopMargin = styled(MainText)`
  margin: ${constants.pageMarginTop}px ${pageMargin.SMALL}px
    ${pageMargin.SMALL}px ${pageMargin.SMALL}px;
`;

export const MainTextNoVerticalMargin = styled.h1`
  font-weight: bold;
  color: ${(props) => props.theme.colors.neutral_800};
  margin-right: ${pageMargin.SMALL}px;
  margin-left: ${pageMargin.SMALL}px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SubText = styled.p`
  font-size: 2rem;
  color: ${(props) => props.theme.colors.neutral_600};
  margin: 0px 15px 15px 15px;
`;

export const SubTextNoVerticalMargin = styled.p`
  font-size: 2rem;
  color: ${(props) => props.theme.colors.neutral_600};
  margin-right: ${pageMargin.SMALL}px;
  margin-left: ${pageMargin.SMALL}px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const HeaderImage = styled.img<{
  maxWidth?: string;
  maxHeight?: string;
}>`
  display: block;
  align-self: center;
  margin-top: 32px;
  margin-bottom: 32px;
  ${(props) =>
    props.maxWidth
      ? `max-width: ${props.maxWidth};
`
      : ''};
  ${(props) =>
    props.maxHeight
      ? `max-height: ${props.maxHeight};
    `
      : ''};
`;

export const HeaderContentWrapper = styled.div`
  margin-top: ${constants.pageMarginTop}px;
  margin-left: ${pageMargin.SMALL}px;
  margin-right: ${pageMargin.SMALL}px;
`;

export const HorizontalBottomContent = styled.div`
  margin: 0px ${pageMargin.SMALL}px ${constants.pageMarginBottom}px
    ${pageMargin.SMALL}px;
  display: flex;
  flex-direction: row;

  Button {
    margin: 0px;
  }
  Button:not(:first-child) {
    margin: 0 5px;
  }
  Button:last-child {
    margin-right: 0px;
  }
`;

export const VerticalBottomContent = styled.div`
  margin: 0px ${pageMargin.SMALL}px ${constants.pageMarginBottom}px
    ${pageMargin.SMALL}px;
`;

export const BottomContentWithHeight = styled.div`
  height: 80px;
  margin: 0px ${pageMargin.SMALL}px 0px ${pageMargin.SMALL}px;
  display: flex;
  flex-direction: row;
`;

export const ImageStyle = styled.img`
  display: block;
  margin: auto;
`;

export const LeftPadImageStyle = styled.img<{
  leftPosition: number;
  topPosition: number;
}>`
  position: absolute;
  left: ${(props) => props.leftPosition}px;
  top: ${(props) => props.topPosition}px;
`;

export const RightPadImageStyle = styled.img<{
  leftPosition: number;
  topPosition: number;
}>`
  position: absolute;
  left: ${(props) => props.leftPosition}px;
  top: ${(props) => props.topPosition}px;
`;

export const HeaderContentWrapperExtended = styled(HeaderContentWrapper)`
  margin-bottom: 12px;
`;

export const DraggableArea = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: auto;
`;

interface MyProps {
  children?: React.ReactNode;
  relative?: boolean;
}

const PageWrapper: FunctionComponent<MyProps> = ({
  relative,
  children,
}: MyProps) => {
  const { innerHeight } = useHeightViewport();
  // eslint-disable-next-line react/destructuring-assignment
  return (
    <Wrapper relative={relative} height={innerHeight}>
      {children}
    </Wrapper>
  );
};

export default PageWrapper;
