/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from 'react';
import focusFirstTag from './UseFocus';

const WithFocus = (WrappedComponent) => {
  const NewComponent = (props) => {
    useEffect(() => {
      focusFirstTag();
    }, []);

    return <WrappedComponent {...props} />;
  };

  return NewComponent;
};

export default WithFocus;
