import { DropdownItem } from '@laerdal/life-react-components';
import constants from '../../constants/Constants';
import { LocalStorageService } from '../../services';

export const getLocaleCodeForSanity = (): string => {
  let userLanguage = navigator.language;
  const languageCodeFromLocalStorage =
    LocalStorageService.getLanguageCodeFromLocalStorage();
  if (languageCodeFromLocalStorage) {
    userLanguage = languageCodeFromLocalStorage;
  }

  // We give all Norwegian languages bokmål
  if (
    userLanguage.startsWith('nb') ||
    userLanguage.startsWith('no') ||
    userLanguage.startsWith('nn')
  ) {
    return constants.localeCode.NB_NO;
  }
  if (userLanguage.startsWith('fr')) {
    return constants.localeCode.FR_FR;
  }
  if (userLanguage.startsWith('cy')) {
    return constants.localeCode.CY_GB;
  }
  if (userLanguage.startsWith('es')) {
    return constants.localeCode.ES_ES;
  }
  return constants.localeCode.EN;
};

export const getLanguageName = (code: string): string => {
  // We give all Norwegian languages bokmål
  if (code.startsWith('nb') || code.startsWith('no') || code.startsWith('nn')) {
    return constants.localeLanguageName.NORWEGIAN;
  }
  if (code.startsWith('fr')) {
    return constants.localeLanguageName.FRENCH;
  }
  if (code.startsWith('cy')) {
    return constants.localeLanguageName.WELSH;
  }
  if (code.startsWith('es')) {
    return constants.localeLanguageName.SPANISH;
  }
  return constants.localeLanguageName.ENGLISH;
};

export const getLanguagesList = (
  supportedLanguages: string[] | undefined,
): DropdownItem[] => {
  let list: DropdownItem[] = [];
  if (supportedLanguages && supportedLanguages.length > 0) {
    list = supportedLanguages?.map((lang) => {
      return { value: lang, displayLabel: getLanguageName(lang) };
    });
  } else {
    list = [
      {
        value: constants.localeCode.EN,
        displayLabel: getLanguageName(constants.localeCode.EN),
      },
    ];
  }
  return list;
};

export const updateScreenWithNewSelectedLanguage = (
  selectedLanguage: string | undefined,
): void => {
  if (
    selectedLanguage &&
    selectedLanguage !== LocalStorageService.getLanguageCodeFromLocalStorage()
  ) {
    LocalStorageService.setLanguageCodeToLocalStorage(selectedLanguage);
    window.location.reload();
  }
};
