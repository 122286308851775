import { CustomError, logError } from '../services/errorHandling/errorHelper';

class AccessibilityError extends CustomError {
  constructor(message: string) {
    super(message);
    this.name = 'AccessibilityError';
  }
}

export const focusOnElement = (
  element?: HTMLElement | null,
  template?: string,
): void => {
  const root = element || document.getElementById('root');
  if (!root) return;
  const mainElements = root.querySelectorAll('[tabindex="-1"]');

  const arrayOfTags = [] as any;
  arrayOfTags
    .concat(root.getElementsByTagName('<h1>'))
    .concat(root.getElementsByTagName('<h2>'))
    .concat(root.getElementsByTagName('<h3>'))
    .concat(root.getElementsByTagName('p'));

  if (mainElements && mainElements.length > 0) {
    const mainElement = mainElements[0] as HTMLHeadingElement;

    mainElement.focus();
  } else if (arrayOfTags && arrayOfTags.length > 0) {
    (arrayOfTags[0] as HTMLHeadingElement).focus();
  } else {
    logError(
      new AccessibilityError(
        'Could not find a focusable header element for accessability',
      ),
      undefined,
      {
        location: window.location.pathname,
        template, // Help us figure out which pages tha fail on accessibility.
      },
    );
  }
};

export const focusElementWithTabIndexMinusOne = () => {
  setTimeout(() => {
    focusOnElement();
  }, 10);
};

export default focusElementWithTabIndexMinusOne;
