import React, { useState } from 'react';
import { isAndroid } from 'react-device-detect';
import styled from 'styled-components';
import Button from '../../components/Button';
import { CameraPermissionModal } from '../../components/Modals/Modals';
import SpeedometerWithHint from '../../components/Speedometer/SpeedometerWithHint';
import { useCamera, useTcprLink } from '../../context';
import { CameraContextProps } from '../../context/CameraProvider';
import { useFirebase } from '../../context/FirebaseProvider';
import { TcprLinkContextProps } from '../../context/TcprLinkProvider';
import {
  AllowCameraPermissionMessage,
  FeedbackTexts,
  Speedometer as SpeedometerType,
} from '../../features/sanity/types';
import TCPRLinkDebugging from './TCPRLinkDebugging';
import TcprLinkFrame from './TcprLinkFrame';

const AllowAccessContainer = styled.div``;
const AllowAccessContent = styled.div`
  align-self: flex-end;
  margin: 20px;
`;

const CenteredText = styled.p`
  text-align: center;
  color: ${(props) => props.theme.colors.neutral_800};
`;

const VideoSpeedometerWrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  flex: 1;
`;

const CompressionStatus = styled.div<{ cprDetected: boolean }>`
  display: flex;
  text-align: center;
  align-items: center;
  background-color: ${(props) =>
    props.cprDetected
      ? props.theme.colors.green_100
      : props.theme.colors.orange_100};
  color: ${(props) =>
    props.cprDetected
      ? props.theme.colors.green_600
      : props.theme.colors.warning_500};
  min-height: 150px;
  padding: 14px;
  font-size: 2.2rem;
  margin: 10px;
  border-radius: 8px;
`;

interface TrainingContentProps {
  startTraining?: boolean;
  displayVideoFeed?: boolean;
  speedometer?: SpeedometerType;
  feedbackTexts?: FeedbackTexts;
  trainingWithSpeedometer?: boolean;
  height?: number;
  allowCameraPermissionMessage?: AllowCameraPermissionMessage;
}

const NeedToAllowAccessToCameraView = ({
  cameraContext,
  showModal,
  buttonClick,
  toggleModal,
  text,
}: {
  cameraContext: CameraContextProps | null;
  showModal: boolean;
  buttonClick: () => void;
  toggleModal: () => void;
  text: AllowCameraPermissionMessage | undefined;
}) => {
  if (!cameraContext?.hasCameraPermission) {
    return (
      <AllowAccessContainer>
        <AllowAccessContent>
          <CenteredText>
            {text?.allowCameraPermissionText ??
              'Allow access to the camera to receive feedback on your compressions'}
          </CenteredText>
          <Button
            onClick={buttonClick}
            variant="primary"
            style={{ width: '100%' }}
          >
            {text?.allowCameraPermissionButtonText ?? 'Allow'}
          </Button>
        </AllowAccessContent>
        <CameraPermissionModal
          isVisible={showModal}
          toggleModal={toggleModal}
        />
      </AllowAccessContainer>
    );
  }
  return null;
};

const TrainWithCameraAndWithoutSpeedometer = ({
  cameraContext,
  tcprLinkContext,
  trainingWithSpeedometer,
  startTraining,
  displayVideoFeed,
  feedbackTexts,
}: {
  cameraContext: CameraContextProps | null;
  tcprLinkContext: TcprLinkContextProps | null;
  trainingWithSpeedometer: boolean;
  startTraining: boolean;
  displayVideoFeed: boolean | undefined;
  feedbackTexts: FeedbackTexts | undefined;
}) => {
  if (cameraContext?.hasCameraPermission && !trainingWithSpeedometer) {
    return (
      <VideoSpeedometerWrapper>
        {startTraining && (
          <CompressionStatus
            cprDetected={tcprLinkContext !== null && tcprLinkContext.rate > 0}
          >
            {tcprLinkContext !== null && tcprLinkContext.rate > 0
              ? feedbackTexts?.compressionDetected // TODO Default non-sanity value if we accept value that can be undefined
              : feedbackTexts?.compressionNotDetected}
          </CompressionStatus>
        )}

        <TcprLinkFrame displayVideoFeed={displayVideoFeed} />
      </VideoSpeedometerWrapper>
    );
  }
  return null;
};

const TrainWithSpeedometerAndCamera = ({
  cameraContext,
  tcprLinkContext,
  trainingWithSpeedometer,
  speedometer,
  height,
  showDebugInfo,
}: {
  cameraContext: CameraContextProps | null;
  tcprLinkContext: TcprLinkContextProps | null;
  trainingWithSpeedometer: boolean;
  speedometer: SpeedometerType | undefined;
  height: number | undefined;
  showDebugInfo: boolean;
}) => {
  if (
    cameraContext?.hasCameraPermission &&
    trainingWithSpeedometer &&
    speedometer !== undefined
  )
    return (
      <>
        <VideoSpeedometerWrapper>
          <SpeedometerWithHint
            cpr={tcprLinkContext?.rate}
            height={height ?? 150}
            acceptedCpr={{
              min: speedometer?.ranges?.accepted?.min ?? 100,
              max: speedometer?.ranges?.accepted?.max ?? 120,
            }}
            cprRange={{
              min: speedometer?.ranges?.bounds?.min ?? 20,
              max: speedometer?.ranges?.bounds?.max ?? 200,
            }}
            hints={speedometer?.hints}
          />
          <TcprLinkFrame />
        </VideoSpeedometerWrapper>
        <TCPRLinkDebugging show={showDebugInfo} />
      </>
    );

  return null;
};

const TrainingContent: React.FunctionComponent<TrainingContentProps> = ({
  allowCameraPermissionMessage,
  displayVideoFeed,
  feedbackTexts,
  height,
  speedometer,
  startTraining = true,
  trainingWithSpeedometer = false,
}: TrainingContentProps) => {
  const ctx = useCamera();
  const [showModal, setShowModal] = useState(false);
  const tcprLinkContext = useTcprLink();
  const { storeGAEvent } = useFirebase();

  // check if debug is set in the query string
  const urlParams = new URLSearchParams(window.location.search);
  const showDebugInfo = urlParams.get('showDebugInfo') === 'true';

  const buttonClick = async () => {
    const result = await ctx?.checkCameraPermissionAsync();
    let gaEvent = {
      category: '',
      action: '',
      label: '',
    };
    if (result) {
      gaEvent = {
        category: 'CameraPermission',
        action: 'Accepted',
        label: window.location.pathname,
      };
      storeGAEvent({ ...gaEvent }).finally(window.location.reload); // Reload the page when the user have set permission.
    } else if (isAndroid) {
      setShowModal(true);
    } else {
      gaEvent = {
        category: 'CameraPermission',
        action: 'Declined',
        label: window.location.pathname,
      };
      storeGAEvent({ ...gaEvent });
    }
  };
  return (
    <>
      <NeedToAllowAccessToCameraView
        cameraContext={ctx}
        buttonClick={buttonClick}
        showModal={showModal}
        text={allowCameraPermissionMessage}
        toggleModal={() => setShowModal(false)}
      />

      <TrainWithSpeedometerAndCamera
        cameraContext={ctx}
        height={height}
        showDebugInfo={showDebugInfo}
        tcprLinkContext={tcprLinkContext}
        trainingWithSpeedometer={trainingWithSpeedometer}
        speedometer={speedometer}
      />
      <TrainWithCameraAndWithoutSpeedometer
        cameraContext={ctx}
        tcprLinkContext={tcprLinkContext}
        displayVideoFeed={displayVideoFeed}
        trainingWithSpeedometer={trainingWithSpeedometer}
        startTraining={startTraining}
        feedbackTexts={feedbackTexts}
      />
    </>
  );
};
export default TrainingContent;
