import { useContext } from 'react';
import { CommonNavigationContext } from './CommonNavigationProvider';
import { NavigationContextProps } from './navigationContextProps';

const useCommonNavigation = (): NavigationContextProps => {
  const navigation = useContext(CommonNavigationContext);
  // if `undefined`, throw an error
  if (navigation === undefined) {
    throw new Error('useCommonNavigation was used outside of its Provider');
  }

  return navigation;
};

export default useCommonNavigation;
