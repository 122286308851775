import * as Ably from 'ably';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LocalStorageService } from '../../../services';
import {
  createRealtimeChannel,
  getOrCreateAblyClientId,
} from '../../realtimeCommunication/ablyInstanceFactory';
import AblyDebugLogger from './AblyLogger';
import {
  GameChannelStates,
  RaceGameChannelEvents,
  TeamChannelEventPositionMessage,
  TeamChannelEvents,
} from './AblyRaceEvents';
import {
  GameChannelGameMessage,
  LearnerPosition,
  RaceLearnerContextProps,
  SendLearnerConnectedData,
  SendRateData,
} from './Types';
import { TEAM_NAME_PREFIX } from './constants';

const RaceLearnerContext = createContext<RaceLearnerContextProps>(
  {} as RaceLearnerContextProps,
);

const RaceLearnerProvider = ({ children }) => {
  const gameChannelName = useRef<string | null>(null);
  const learnerId = useRef<string | null>(null);
  const teamChannelName = useRef<string | null>(null);
  const teamName = useRef<string | null>(null);

  const ably = useRef<Ably.Types.RealtimePromise>(createRealtimeChannel());

  const [gameState, setGameState] = useState(GameChannelStates.race_lobby);
  const [isDebugMode, setIsDebugMode] = useState(false);
  const [position, setPosition] = useState<LearnerPosition>({
    current: null,
    isFinal: false,
  });

  const gameChannel = useRef<Ably.Types.RealtimeChannelCallbacks | undefined>(
    undefined,
  );

  const teamChannel = useRef<Ably.Types.RealtimeChannelCallbacks | undefined>(
    undefined,
  );

  const handleTeamPositionUpdate = (message: Ably.Types.Message) => {
    AblyDebugLogger.AblyDebugEventReceivedLogger(
      TeamChannelEvents.position_update,
      message,
    );
    if (!message) return;
    const eventData = message.data;
    const eventPositionMessage =
      eventData.message as TeamChannelEventPositionMessage;

    setPosition({
      current: eventPositionMessage.position,
      isFinal: eventPositionMessage.isFinalPosition,
    });
  };

  useEffect(() => {
    if (gameState === GameChannelStates.race_lobby) {
      setPosition({ current: null, isFinal: false }); // reset position
    }
  }, [gameState]);

  const handleRaceGameChannelEvents = (message: Ably.Types.Message) => {
    AblyDebugLogger.AblyDebugEventReceivedLogger(
      RaceGameChannelEvents.game_message,
      message,
    );
    if (!message) return;
    const eventData = message.data as GameChannelGameMessage;
    if (eventData.isDebugMode !== undefined) {
      window.config.debugMode = eventData.isDebugMode; // overrides the debug mode that is set in config earlier, with the debug mode value from the facilitator
      setIsDebugMode(eventData.isDebugMode);
    }
    setGameState(eventData.state);
  };

  const cleanup = () => {
    if (gameChannel.current) {
      gameChannel.current.unsubscribe(RaceGameChannelEvents.game_message);
    }
    if (teamChannel.current) {
      teamChannel.current.unsubscribe(TeamChannelEvents.position_update);
    }
  };
  // Learner connects to the game channel and team channel
  // Learner sends a message to the game channel to notify that they have connected
  // Learner subscribes to the game channel to listen for game state changes
  const connect = () => {
    teamName.current = `${TEAM_NAME_PREFIX}${LocalStorageService.getLearnerTeamFromLocalStorage()}`;
    gameChannelName.current = `game-${LocalStorageService.getClassroomCodeFromLocalStorage()}`;
    learnerId.current = getOrCreateAblyClientId();
    teamChannelName.current = `${gameChannelName.current}-${teamName.current}`;

    if (ably.current) {
      gameChannel.current = ably.current.channels.get(gameChannelName.current);
      teamChannel.current = ably.current.channels.get(teamChannelName.current);

      const connectionData = {
        team: teamName.current,
        learnerId: learnerId.current,
      } as SendLearnerConnectedData;

      gameChannel.current.unsubscribe(RaceGameChannelEvents.game_message);

      gameChannel.current.subscribe(
        RaceGameChannelEvents.game_message,
        (message) => {
          handleRaceGameChannelEvents(message);
        },
      );

      gameChannel.current.unsubscribe(TeamChannelEvents.position_update);

      teamChannel.current.subscribe(
        TeamChannelEvents.position_update,
        (message) => {
          handleTeamPositionUpdate(message);
        },
      );
      gameChannel.current.publish(
        RaceGameChannelEvents.learner_connected,
        connectionData,
      );
    }
  };

  const sendRate = (rate: number) => {
    if (teamChannel.current) {
      const data = {
        team: teamName.current,
        learnerId: learnerId.current,
        rate,
      } as SendRateData;

      AblyDebugLogger.AblyDebugEventPublishedLogger(
        TeamChannelEvents.rate_update,
        data,
      );
      teamChannel.current.publish(TeamChannelEvents.rate_update, data);
    }
  };

  const value = useMemo(
    () => ({
      connect,
      cleanup,
      position,
      gameState,
      sendRate,
      teamName: teamName.current,
      isDebugMode,
      learnerId: learnerId.current,
    }),
    [connect, cleanup, position, gameState, sendRate, teamName.current],
  );

  return (
    <RaceLearnerContext.Provider value={value}>
      {children}
    </RaceLearnerContext.Provider>
  );
};

export const useRaceLearner = () => {
  return useContext(RaceLearnerContext);
};

const withRaceLearnerProvider = (Component) => (props) => {
  return (
    <RaceLearnerProvider>
      <Component {...props} />
    </RaceLearnerProvider>
  );
};

export default withRaceLearnerProvider;
