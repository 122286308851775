import React from 'react';
import { FooterText } from './FooterStyles';

const LaerdalFooter: React.FunctionComponent<{
  displayVersionNumber: undefined | boolean;
}> = ({ displayVersionNumber }) => {
  return (
    <>
      {displayVersionNumber && (
        <FooterText>{`v.${
          import.meta.env.VITE_APP_VERSION
        } Learning Technology by Laerdal`}</FooterText>
      )}
    </>
  );
};
export default LaerdalFooter;
