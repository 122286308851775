/* eslint-disable @typescript-eslint/lines-between-class-members */
class Compression {
  sessionId: number;
  pd: number;
  rd: number;
  s2pt: number;
  p2et: number;
  pp2pt: number;
  rate: number;
  sequence: number;
  mse: number;
  lowNoise: boolean;

  constructor(
    rate: number,
    currentSessionId: number,
    sequenceNumber: number,
    milliSecondsElapses: number,
    lowNoise: boolean,
  ) {
    this.rate = rate;
    this.sessionId = currentSessionId;
    this.mse = milliSecondsElapses;
    this.sequence = sequenceNumber;

    this.p2et = 0;
    this.pd = 1;
    this.rd = 0;
    this.s2pt = 0;
    this.pp2pt = 0;
    this.lowNoise = lowNoise;

    if (rate <= 0) this.lowNoise = false;
  }
}

export default Compression;
