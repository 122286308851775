import React, { FunctionComponent } from 'react';
import FooterText from '../../components/FooterText';
import ModalProps from '../../components/Modals/ModalTypes';
import WithFadeIn from '../../components/WithFadeIn';
import WithFocus from '../../components/WithFocus';
import InfoPage, { PageProps } from '../Templates/InfoPage';
import { Button, Image, ImageTypes } from '../Templates/InfoPageTypes';

interface TrainingResultPageProps {
  heading?: string;
  subHeading?: string;
  iconImage?: Image;
  footerText?: string;
  modal?: ModalProps;
  buttons?: Button[];
}

const TrainingResultPage: FunctionComponent<TrainingResultPageProps> = ({
  heading,
  subHeading,
  iconImage,
  footerText,
  modal,
  buttons,
}: TrainingResultPageProps) => {
  const page: PageProps = {
    image: {
      src: iconImage?.src,
      alt: iconImage?.alt,
      imgType: ImageTypes.Icon,
    },
    buttons,
    text: {
      header: heading,
      body: subHeading,
    },
    bottomContent: <FooterText>{footerText}</FooterText>,
  };
  return (
    <InfoPage
      image={page.image}
      buttons={buttons}
      text={page.text}
      modal={modal}
      verticalButtons
      bottomContent={page.bottomContent}
    />
  );
};

export default WithFadeIn(WithFocus(TrainingResultPage));
