import {
  ErrorMessage,
  InputLabel,
  SystemIcons,
  TextField,
} from '@laerdal/life-react-components';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FireBaseCollections, useFirebase } from '../../context';
import { DynamicInputField } from '../../features/sanity/sanityTypes/templates/textAndImageTemplate';

const InputBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 96px;
`;

const ValidationError = styled(ErrorMessage)`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.critical_400};
`;

type DynamicInputFieldsProps = DynamicInputField & {
  setDisablePrimaryButtons?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const InputField: FunctionComponent<DynamicInputFieldsProps> = ({
  alt,
  dataType,
  placeholder,
  errorMessage,
  setDisablePrimaryButtons,
  range,
}) => {
  const [inputFieldValue, setInputFieldValue] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [validationFails, setValidationFails] = useState(false);
  const validationFailsRef = useRef(validationFails);
  const fieldValueRef = useRef('');
  const firebaseContext = useFirebase();
  const firebasePropsRef = useRef(firebaseContext.getGeneralProps());

  useEffect(() => {
    validateInputField();
  }, [inputFieldValue]);

  useEffect(() => {
    validationFailsRef.current = validationFails;
  }, [validationFails]);

  useEffect(() => {
    return () => {
      if (fieldValueRef.current.length === 0 || validationFailsRef.current) {
        return;
      }
      const firebaseData = {
        value: fieldValueRef.current,
        dataType,
        min: range?.min ?? null,
        max: range?.max ?? null,
      };
      firebaseContext.storeCollectionBarebones(
        FireBaseCollections.inputField,
        {
          ...firebaseData,
        },
        firebasePropsRef.current,
      );
    };
  }, []);

  useEffect(() => {
    // We would like the user to not continue unless they have filled in valid info
    if (setDisablePrimaryButtons) {
      setDisablePrimaryButtons(inputFieldValue.length === 0 || validationFails);
    }
  }, [inputFieldValue, validationFails]);

  const validateInputField = (): void => {
    if (!inputFieldValue || dataType === 'text') {
      setValidationFails(false);
      setValidationErrorMessage('');
      return;
    }
    const numericRegex = /^[-]?\d+([.,]\d+)?$/g;
    if (!numericRegex.test(inputFieldValue)) {
      setValidationFails(true);
      setValidationErrorMessage(errorMessage);
      return;
    }

    if (!range) {
      return;
    }
    const value = inputFieldValue as unknown as number;
    if (value < range.min) {
      setValidationFails(true);
      setValidationErrorMessage(range.minErrorMessage);
      return;
    }
    if (value > range.max) {
      setValidationFails(true);
      setValidationErrorMessage(range.maxErrorMessage);
      return;
    }
    setValidationFails(false);
    setValidationErrorMessage('');
  };

  const handleOnChange = (fieldValue: string) => {
    setInputFieldValue(fieldValue);
    fieldValueRef.current = fieldValue;
  };
  return (
    <InputBody>
      <InputLabel inputId="InputField" text={placeholder} />
      <TextField
        value={inputFieldValue}
        onChange={handleOnChange}
        alt={alt}
        id="inputField"
        inputMode={dataType}
        placeholder={placeholder}
      />

      {validationFails && (
        <ValidationError aria-live="polite" id="validationError">
          <SystemIcons.Attention size="20px" />
          <span>{validationErrorMessage}</span>
        </ValidationError>
      )}
    </InputBody>
  );
};

export default InputField;
