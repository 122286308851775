import { TrophyBronze, TrophyGold, TrophySilver } from '../../../assets';
import Styles from './AwardPodium.styles';

export const Trophies = [
  {
    src: TrophyGold,
    alt: 'First place',
  },
  {
    src: TrophySilver,
    alt: 'Second place',
  },
  {
    src: TrophyBronze,
    alt: 'Third place',
  },
];
const trophyUiGenerator = (src, alt) => {
  return <Styles.Trophy src={src} alt={alt} />;
};

export default trophyUiGenerator;
