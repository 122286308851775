import styled from 'styled-components';
import Types from './AwardPodium.types';

const CONSTANTS: Types.CONSTANTS = {
  Podium: {
    firstHeight: 215,
    height: 150,
    gap: 16,
    width: 176,
    color: '#f6f6f6',
    bottomPadding: 116,
  },
  Ambulance: {
    width: 60,
    height: 48,
  },
  TeamNameContainer: {
    width: 135,
    height: 32,
    borderRadius: 16,
    marginTop: 42,
  },
  Trophy: {
    marginBottom: -10,
  },
  AwardPodiumGrid: {
    sideMargin: 22,
    gap: 128,
  },
};

const PodiumContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  grid-column: 2/-2;
  grid-row: 1/4;
  justify-content: center;
`;

const RotatingImage = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin 1s linear infinite;
`;

const BouncingImage = styled.div`
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(120%);
      animation-timing-function: cubic-bezier(0.33333, 0.66667, 0.66667, 1);
    }
    50% {
      transform: translateY(0%);
      animation-timing-function: cubic-bezier(0.33333, 0, 0.66667, 0.33333);
    }
  }

  animation: bounce 2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) infinite;
`;

const Ambulance = styled.div<{ ambulanceColor: string }>`
  display: flex;
  grid-row: 1/2;
  justify-self: center;
  align-self: center;
  .colored-path {
    fill: ${(props) => props.ambulanceColor};
  }
  svg {
    height: ${CONSTANTS.Ambulance.height}px;
    width: ${CONSTANTS.Ambulance.width}px;
  }
`;

const TeamName = styled.h2`
  display: flex;
  font-size: 1.8rem;
  color: white;
  justify-content: center;
  align-content: center;
  z-index: 2;
`;

const Trophy = styled.img`
  display: flex;
  grid-row: 2/3;
  margin-bottom: ${CONSTANTS.Trophy.marginBottom}px;
  justify-self: center;
  align-self: end;
  z-index: 2;
`;

const PodiumGrid = styled.div<{ is1stPlace: boolean }>`
  display: grid;
  margin: 0px 4px;
  height: auto;
  padding-bottom: ${CONSTANTS.Podium.bottomPadding}px;
  width: ${CONSTANTS.Podium.width}px;
  grid-template-columns: 1fr;
  justify-items: center;
  align-self: end;
  grid-template-rows: ${(props) =>
    `min(20dvh,300px) 135px ${
      props.is1stPlace
        ? `${CONSTANTS.Podium.firstHeight}px`
        : `${CONSTANTS.Podium.height}px`
    }`};
`;
const Podium = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${CONSTANTS.Podium.color};
  flex-direction: column;
  place-self: center;
  border-radius: 16px 16px 0 0;
  grid-row: -2/-1;
  grid-column: 2/-2;
`;

const TeamNameContainer = styled.div<{
  color: string;
}>`
  display: flex;
  grid-row: -2/-1;
  grid-column: 2/-2;
  z-index: 2;
  width: ${CONSTANTS.TeamNameContainer.width}px;
  height: ${CONSTANTS.TeamNameContainer.height}px;
  border-radius: ${CONSTANTS.TeamNameContainer.borderRadius}px;
  margin-top: ${CONSTANTS.TeamNameContainer.marginTop}px;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
`;

const AwardGrid = styled.div`
  display: grid;
  width: 100dvw;
  height: 100dvh;
  grid-template-columns: ${CONSTANTS.AwardPodiumGrid.sideMargin}px 1fr ${CONSTANTS
      .AwardPodiumGrid.sideMargin}px;
  grid-template-rows: minmax(500px, auto) minmax(
      40px,
      ${CONSTANTS.AwardPodiumGrid.gap}px
    );
`;
export default {
  AwardGrid,
  Ambulance,
  BouncingImage,
  Podium,
  PodiumContainer,
  PodiumGrid,
  RotatingImage,
  TeamName,
  TeamNameContainer,
  Trophy,
};
