/* eslint-disable default-case */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { AudioProvider, FirebaseProvider } from '../../../context';
import ThemeProvider from '../../../context/ThemeProvider';
import LoadingPage from '../../../pages/LoadingContentPage';
import GlobalStyle from '../../../theme/globalStyles';
import AblyProvider from '../context/AblyContext';

import GroupSanityProvider from '../context/GroupSanityContext';
import LearnerRouting from './LearnerRouting';

const LearnerApp = (props) => {
  return (
    <GroupSanityProvider>
      <FirebaseProvider>
        <AblyProvider>
          <ThemeProvider>
            <AudioProvider>
              <LoadingPage>
                <GlobalStyle>
                  <LearnerRouting />
                </GlobalStyle>
              </LoadingPage>
            </AudioProvider>
          </ThemeProvider>
        </AblyProvider>
      </FirebaseProvider>
    </GroupSanityProvider>
  );
};

export default LearnerApp;
