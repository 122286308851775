/* eslint-disable no-nested-ternary */
import { useContext } from 'react';
import { NavigationContextMock } from '../context/mock/NavigationProviderMock';
import { SoloNavigationContext } from '../context/navigation/SoloNavigationProvider';
import { NavigationContextProps } from '../context/navigation/navigationContextProps';
import { FacilitatorNavigationContext } from '../features/groupRevivr/facilitator/context/FacilitatorNavigationContext';
import { LearnerNavigationContext } from '../features/groupRevivr/learner/context/LearnerNavigationContext';
import { isNavigationProviderMockDefined } from '../features/mock/mockHelper';

const contextResolver = () => {
  if (isNavigationProviderMockDefined()) {
    return NavigationContextMock;
  }

  if (!window.revivr.isGroupRevivr) {
    return SoloNavigationContext;
  }

  if (window.revivr.isLearner) {
    return LearnerNavigationContext;
  }

  return FacilitatorNavigationContext;
};

const useNavigation = (): NavigationContextProps => {
  const navigation = useContext(contextResolver());
  if (navigation === undefined) {
    throw new Error('useNavigation was used outside of its Provider');
  }
  return navigation;
};

export default useNavigation;
