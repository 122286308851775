/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

// TO USE THIS IN YOUR URL, DO THIS:
// If there is already a ? in the URL, use &debug or &isDebug. Otherwise, use ?isDebug. or ?isDebug.

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function DebugButton({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <button
      type="button"
      onClick={onClick}
      style={{ position: 'absolute', top: 0, zIndex: 9999 }}
    >
      Debug
    </button>
  );
}

function DebugView({ hideDebug }: { hideDebug: () => void }): JSX.Element {
  const pages = window.sanity?.sanityContent?.course || [];
  const projectId =
    localStorage.getItem('projectId') || '#{SANITY_BHF_PROJECT_ID}#'; // Replace 'defaultProjectId' with a suitable default or leave it empty

  return (
    <>
      <div
        onClick={hideDebug}
        style={{
          position: 'absolute',
          backgroundColor: 'black',
          opacity: 0.5,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
        }}
      />

      <div
        style={{
          margin: '30px',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'white',
          overflowY: 'auto',
          zIndex: 1001,
        }}
      >
        {pages.map((page, index) => (
          <a
            key={index}
            href={`${page.url}?projectId=${projectId}`}
            style={{ display: 'block', margin: '10px 0' }}
          >
            {page.url} -{page.template}
          </a>
        ))}
      </div>
    </>
  );
}

function Debug(): JSX.Element {
  const query = useQuery();
  const debugQueryParam =
    query.get('isDebug') !== null || query.get('debug') !== null;

  const [showDebugButton, setShowDebugButton] = useState(debugQueryParam);
  const [showDebugView, setShowDebugView] = useState(false);

  const handleDebugButtonClick = () => {
    setShowDebugView(true);
  };

  const hideDebug = () => {
    setShowDebugView(false);
    setShowDebugButton(false);
  };

  return (
    <>
      {showDebugButton && <DebugButton onClick={handleDebugButtonClick} />}
      {showDebugView && <DebugView hideDebug={hideDebug} />}
    </>
  );
}

export default Debug;
