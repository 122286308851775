import * as Ably from 'ably';
import constants from '../../../constants/Constants';
import { LocalStorageService } from '../../../services';
import { LearnerTeam } from '../types/AblyCommandType';

export type LearnerData = {
  team: string;
  role: string;
  id: string;
};

const addLearnerToRoleA = (
  teams: LearnerTeam[],
  teamName: string,
  learnerId: string,
) => {
  teams.push({
    teamName,
    roleA: {
      roleName: constants.learnerRole.ROLEA,
      learnerId,
    },
    roleB: {
      roleName: '',
      learnerId: '',
    },
  });
  return teams;
};

const addLearnerToRoleB = (teams: LearnerTeam[], learnerId: string) => {
  teams[teams.length - 1].roleB = {
    roleName: constants.learnerRole.ROLEB,
    learnerId,
  };
  return teams;
};

export const setTeamUp = (teams: LearnerTeam[]): void => {
  const lastTeam = teams[teams.length - 1];
  const lastRole = lastTeam.roleB.roleName
    ? constants.learnerRole.ROLEB
    : constants.learnerRole.ROLEA;
  LocalStorageService.setTrainingTypeToLocalStorage(
    constants.trainingMode.TEAMUP,
  );
  LocalStorageService.setLastLearnerTeamToLocalStorage(lastTeam.teamName);
  LocalStorageService.setLastLearnerRoleToLocalStorage(lastRole);
};

export const createTeams = (
  connectedUsers: Ably.Types.PresenceMessage[],
): LearnerTeam[] => {
  let teams: LearnerTeam[] = [];
  if (connectedUsers.length === 0) {
    LocalStorageService.setTrainingTypeToLocalStorage(
      constants.trainingMode.TEAMUP,
    );
    LocalStorageService.setLastLearnerTeamToLocalStorage('0');
    return teams;
  }
  let myTeamName = 1;
  // This loop will create teams and will add two learners in each team.
  // Therefore it is increamenting index i by 2
  // If there remains three learner at the end then they will be in the same team
  for (let i = 0; i < connectedUsers.length; i += 2) {
    if (i + 1 < connectedUsers.length) {
      teams = addLearnerToRoleA(
        teams,
        myTeamName.toString(),
        connectedUsers[i].clientId,
      );
      teams = addLearnerToRoleB(teams, connectedUsers[i + 1].clientId);
    } else {
      teams = addLearnerToRoleA(
        teams,
        myTeamName.toString(),
        connectedUsers[i].clientId,
      );
    }
    myTeamName += 1;
  }
  setTeamUp(teams);
  return teams;
};

export const addNewLearnerInTeam = (learnerId: string): LearnerTeam => {
  const lastLearnerTeam =
    LocalStorageService.getLastLearnerTeamFromLocalStorage() ?? '';
  const lastLearnerRole =
    LocalStorageService.getLastLearnerRoleFromLocalStorage() ?? '';
  const team: LearnerTeam = {
    teamName: '',
    roleA: {
      roleName: '',
      learnerId: '',
    },
    roleB: {
      roleName: '',
      learnerId: '',
    },
  };

  if (lastLearnerRole === constants.learnerRole.ROLEA) {
    team.teamName = lastLearnerTeam;
    team.roleB = {
      roleName: constants.learnerRole.ROLEB,
      learnerId,
    };
  } else {
    team.teamName = String(parseInt(lastLearnerTeam, 10) + 1);
    team.roleA = {
      roleName: constants.learnerRole.ROLEA,
      learnerId,
    };
  }
  LocalStorageService.setLastLearnerTeamToLocalStorage(team.teamName);
  LocalStorageService.setLastLearnerRoleToLocalStorage(
    team.roleB.roleName ? team.roleB.roleName : team.roleA.roleName,
  );
  return team;
};

export const setLearnerTeamRole = (learnerTeams: LearnerTeam[]): void => {
  const { learnerId } = window.revivr;
  let role: string | undefined;
  learnerTeams.forEach((team) => {
    if (team.roleA.learnerId === learnerId) {
      role = team.roleA.roleName;
    }
    if (team.roleB.learnerId === learnerId) {
      role = team.roleB.roleName;
    }
  });
  if (role) {
    LocalStorageService.setLearnerRoleToLocalStorage(role);
  }
};

export const setLearnerTeam = (learnerTeams: LearnerTeam[]): void => {
  const { learnerId } = window.revivr;
  const newTeam = learnerTeams.find(
    (learnerTeam) =>
      learnerTeam.roleA.learnerId === learnerId ||
      learnerTeam.roleB.learnerId === learnerId,
  );
  if (newTeam?.teamName) {
    LocalStorageService.setLearnerTeamToLocalStorage(newTeam.teamName);
  }
};

export const getLearnerData = (): string => {
  const learnerId = window.revivr.learnerId ?? '';
  const learnerData: LearnerData = {
    team: LocalStorageService.getLearnerTeamFromLocalStorage() ?? '',
    role: LocalStorageService.getLearnerRoleFromLocalStorage() ?? '',
    id: learnerId,
  };
  return JSON.stringify(learnerData);
};
