import React from 'react';
import Styles from '../RaceScreen.styles';

const LoadingComponent = ({ teams, loadingText = 'Loading...' }) => {
  if (!teams || teams.length === 0) {
    return (
      <Styles.CenteredConnecting>
        <p>{loadingText}</p>
      </Styles.CenteredConnecting>
    );
  }

  return null;
};

export default LoadingComponent;
