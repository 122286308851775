/* eslint-disable */
import { BOXSHADOWS, Z_INDEXES } from '@laerdal/life-react-components';
import React from 'react';
import ReactModal from 'react-modal';

const ModalContainerStyles = {
  content: {
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '640px',
    height: '320px',
    borderRadius: '8px',
    boxSizing: 'border-box' as any,
    margin: 0,
    overflow: 'auto',
    boxShadow: BOXSHADOWS.BOXSHADOW_L3,
  },
};

//override modal classes to define the animations
const modalTransitions = `

@keyframes modalOpenAnimation {
  from{
    transform: scale(1.1, 1.1);
  }
  to {
    transform: scale(1, 1);
  }
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: ${Z_INDEXES.backdrop};
  display:flex;
  justify-content: center;
  align-items: center;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms cubic-bezier(0.22, 0.61, 0.35, 1);
}

.ReactModal__Content{
  z-index: ${Z_INDEXES.modal};
  opacity:0;
  transition: opacity 150ms cubic-bezier(0.22, 0.61, 0.35, 1);
}

.ReactModal__Content--after-open{
  opacity: 1;
  animation: modalOpenAnimation 150ms ease-in-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: opacity 120ms linear;
}

.ReactModal__Content--before-close{
  opacity: 0;
  transform: scale(1.1, 1.1);
  transition: transform 120ms ease-in-out, opacity 120ms cubic-bezier(0.55, 0.05, 0.67, 0.19);
}
`;

type ModalContainerProps = {
  id?: string;
  showModal: boolean;
  closeModal: () => void;
  children: any;
  height?: any;
  width?: any;
  overflow?: string;
  padding?: string;
  minWidth?: string;
  maxWidth?: string;
  zIndex?: number;
};

type ModalContainerState = {
  scroll: number;
};

class ModalContainer extends React.Component<
  ModalContainerProps,
  ModalContainerState
> {
  private static openInstances = 0;

  componentDidMount() {
    if (ReactModal.defaultStyles.overlay) {
      ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.5)';
    }

    //append style node to override modal transition classes
    if (!document.querySelector('[modal-custom-styling="active"]')) {
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.setAttribute('modal-custom-styling', 'active');
      head.appendChild(style);
      style.appendChild(document.createTextNode(modalTransitions));
    }

    if (this.props.showModal) {
      this.preventScroll(window.scrollY);
    }
  }

  getSnapshotBeforeUpdate(
    prevProps: Readonly<ModalContainerProps>,
    prevState: Readonly<ModalContainerState>,
  ): { scroll: number } {
    return { scroll: window.scrollY };
  }

  componentDidUpdate(
    prevProps: Readonly<ModalContainerProps>,
    prevState: Readonly<ModalContainerState>,
    snapshot?: { scroll: number },
  ) {
    if (this.props.showModal && !prevProps.showModal) {
      this.preventScroll(snapshot!.scroll);
    }
    if (!this.props.showModal && prevProps.showModal) {
      this.resetScroll();
    }
  }

  componentWillUnmount() {
    if (this.props.showModal) {
      this.resetScroll();
    }
  }

  render() {
    const {
      id,
      showModal,
      closeModal,
      children,
      height = 'auto',
      width = 'auto',
      overflow = 'visible',
      padding,
      minWidth = '',
      maxWidth = '',
      zIndex = parseInt(Z_INDEXES.modal),
    } = this.props;

    // should be at least z-index of modal and below z-index of toast
    const zIndexValue = Math.min(
      Math.max(zIndex, +Z_INDEXES.modal),
      +Z_INDEXES.toast - 1,
    );
    const stylesConfiguration = Object.assign(
      { ...ModalContainerStyles.content },
      {
        height,
        width,
        padding,
        overflow,
        minWidth,
        maxWidth,
        zIndex: zIndexValue,
      },
    );
    const styles = { content: stylesConfiguration };

    return (
      <ReactModal
        id={id}
        isOpen={showModal}
        closeTimeoutMS={120}
        onRequestClose={() => closeModal()}
        style={styles}
        appElement={document.getElementById('root') || undefined}
      >
        {children}
      </ReactModal>
    );
  }

  private preventScroll(offset: number) {
    if (!ModalContainer.openInstances) {
      this.setState({ scroll: offset });
      document.body.style.position = 'fixed';
      document.body.style.left = `0px`;
      document.body.style.right = `0px`;
      document.body.style.top = `-${offset}px`;
    }
    ModalContainer.openInstances++;
  }

  private resetScroll() {
    ModalContainer.openInstances--;
    if (!ModalContainer.openInstances && this.state) {
      //@ts-ignore
      document.body.style.position = null;
      //@ts-ignore
      document.body.style.left = null;
      //@ts-ignore
      document.body.style.top = null;
      //@ts-ignore
      document.body.style.right = null;
      this.state.scroll && window.scrollTo(0, this.state.scroll);
      this.setState({ scroll: 0 });
    }
  }
}

export default ModalContainer;
