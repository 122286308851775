export const illegalCharacters = [
  // List of characters useful for XSS and SQL injection attacks
  '<',
  '>',
  '"',
  '=',
  '/',
  ';',
  '--',
  '#',
  '(',
  ')',
  '{',
  '}',
  '[',
  ']',
  ':',
  '|',
  '$',
  '%',
  '+',
  '*',
];

/**
 * Check if the given string contains any characters
 * that can be used for XSS and SQL injection attacks.
 * Use this for user input validation.
 * @param text The text to check for illegal characters
 * @returns True if the text contains any illegal characters, false otherwise
 */
const containsIllegalCharacters = (text: string): boolean => {
  if (!text) {
    return false;
  }
  if (illegalCharacters.some((character) => text.includes(character))) {
    return true;
  }
  return false;
};

export default containsIllegalCharacters;
