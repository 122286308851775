import { FunctionComponent, useEffect, useRef, useState } from 'react';
import consoleDebug from '../../../helper/consoleDebug';
import { GameChannelStates } from '../providers/AblyRaceEvents';
import withRaceFacilitatorProvider, {
  useRaceFacilitator,
} from '../providers/RaceFacilitatorProvider';
import { Team } from '../providers/Types';
import useNavigation from './../../../hooks/useNavigation';
import GameState from './GameState';
import { AwardPodium, RaceScreen } from './components';
import { AwardPodiumTypes } from './components/AwardPodium';
import trophyUiGenerator, {
  Trophies,
} from './components/AwardPodium/trophyUiGenerator';
import { REFRESH_RATE_MS } from './constants';
import { useFacilitatorLogging } from './useFacilitatorLogging';
import { useTeams } from '../providers/useTeams';
import DebugView from './components/Debug/DebugView';

type FinishedRacingProps = {
  raceAgain: () => void;
  teams: Team[];
};

const FinishedRacingComponent: FunctionComponent<FinishedRacingProps> = ({
  teams,
  raceAgain,
}) => {
  const { clearTeams } = useTeams();
  const useNavigationHook = useNavigation();
  const { navigateToNextPage } = useNavigationHook;

  const playersSortedByPosition = [...teams]
    .sort((a, b) => {
      if (a.finalPosition === undefined) return 1; // Moves a to the end if null
      if (b.finalPosition === undefined) return -1; // Moves b to the end if null
      return a.finalPosition - b.finalPosition; // Standard ascending sort
    })
    .map((team, index) => ({
      name: team.name,
      color: team.color,
      trophy:
        index < 3
          ? trophyUiGenerator(Trophies[index].src, Trophies[index].alt) // Generate trophy UI for the top 3 teams. First place is index 0
          : null,
    })) as AwardPodiumTypes.PlayerInfo[];

  return (
    <AwardPodium
      players={playersSortedByPosition}
      continueButton={{
        text: 'Race again',
        onClick: raceAgain,
        variant: 'secondary',
      }}
      raceAgainButton={{
        text: 'Finish',
        onClick: () => {
          clearTeams();
          navigateToNextPage();
        },
        variant: 'primary',
      }}
    />
  );
};

const getSortedTeams = (teams) => {
  return teams.sort((a, b) => a.name.localeCompare(b.name));
};

const ShowComponent = ({
  currentState,
  onGameStateChange,
  raceAgain,
  onTeamCompleted,
  teams,
}: {
  currentState: GameState;
  onGameStateChange: (status: GameChannelStates) => void;
  raceAgain: () => void;
  onTeamCompleted: (
    teamNumber: number,
    completionTime: number,
    percentageCompleted: number,
  ) => void;
  teams: Team[];
}) => {
  switch (currentState) {
    case GameState.finishedRacing: {
      return <FinishedRacingComponent teams={teams} raceAgain={raceAgain} />;
    }
    case GameState.lobby:
    case GameState.countdown:
    case GameState.racing:
      return (
        <RaceScreen
          teams={getSortedTeams(teams)}
          onGameStateChange={onGameStateChange}
          onTeamCompleted={onTeamCompleted}
        />
      );
    default:
      consoleDebug('Unhandled game state: ' + currentState);
      return null;
  }
};

const RaceFacilitator = () => {
  const { getTeams, lastGameState, sendGameStates, onTeamCompleted } =
    useRaceFacilitator();
  const [teams, setTeams] = useState<Team[]>([]);
  const { initLoggedTeams, storeRaceEndToFireBase, updateTeamOnCompletion } =
    useFacilitatorLogging();

  const [currentState, setCurrentState] = useState(GameState.lobby);
  useEffect(() => {
    switch (currentState) {
      case GameState.lobby:
        setTeams((currentTeams) => clearTeamsPosition(currentTeams));
        sendGameStates(GameChannelStates.race_lobby);
        break;
      case GameState.countdown:
        sendGameStates(GameChannelStates.race_countdown);
        break;
      case GameState.racing:
        initLoggedTeams(teams);
        updateExternalTeams();
        sendGameStates(GameChannelStates.race_start);
        break;
      case GameState.finishedRacing:
        fetchTeamData();
        storeRaceEndToFireBase();
        sendGameStates(GameChannelStates.race_finished);
        break;
      default:
        consoleDebug('Unhandled game state: ' + currentState);
    }
    return () => {
      if (updateTeamsInterval.current) {
        clearInterval(updateTeamsInterval.current);
      }
    };
  }, [currentState]);

  useEffect(() => {
    switch (lastGameState) {
      case GameChannelStates.race_start:
        setCurrentState(GameState.racing);
        break;
      case GameChannelStates.race_countdown:
        setCurrentState(GameState.countdown);
        break;
      case GameChannelStates.race_finished:
        setCurrentState(GameState.finishedRacing);
        break;
      default:
        break;
    }
  }, [lastGameState]);

  const updateTeamsInterval = useRef<NodeJS.Timeout | null>(null);

  const onTeamCompletedAndUpdateLoggedTeams = (
    teamNumber: number,
    completionTime: number,
    percentageCompleted: number,
  ) => {
    onTeamCompleted(teamNumber, completionTime);
    updateTeamOnCompletion(teamNumber, completionTime, percentageCompleted);
  };

  const fetchTeamData = () => {
    const teamRecords = Object.values(getTeams());
    setTeams(() => {
      return teamRecords;
    });
  };
  const updateExternalTeams = () => {
    if (updateTeamsInterval.current) {
      // workaround  since  updateExternalTeams is called twice
      clearInterval(updateTeamsInterval.current);
    }
    updateTeamsInterval.current = setInterval(() => {
      fetchTeamData();
    }, REFRESH_RATE_MS);

    return () => {
      if (updateTeamsInterval.current) {
        clearInterval(updateTeamsInterval.current);
      }
    };
  };

  useEffect(() => {
    updateExternalTeams();
  }, []);

  const startRace = () => {
    setCurrentState(GameState.racing);
  };

  const startCountdown = () => {
    setCurrentState(GameState.countdown);
  };

  const clearTeamsPosition = (teams: Team[]) =>
    teams.map(
      (team) =>
        ({
          ...team,
          finalPosition: null,
          score: null,
        } as Team),
    );

  const raceAgain = () => {
    setCurrentState(GameState.lobby);
  };

  const onGameStateChange = (state: GameChannelStates) => {
    switch (state) {
      case GameChannelStates.race_countdown:
        startCountdown();
        break;
      case GameChannelStates.race_start:
        startRace();
        break;
      case GameChannelStates.race_lobby:
        raceAgain();
        break;
      case GameChannelStates.race_finished:
        setCurrentState(GameState.finishedRacing);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {window.config.debugMode && (
        <DebugView teams={teams} currentState={currentState} />
      )}
      <ShowComponent
        currentState={currentState}
        teams={teams}
        raceAgain={raceAgain}
        onTeamCompleted={onTeamCompletedAndUpdateLoggedTeams}
        onGameStateChange={onGameStateChange}
      />
    </div>
  );
};

export default withRaceFacilitatorProvider(RaceFacilitator);
