import isPropValid from '@emotion/is-prop-valid';
import React from 'react';
import { StyleSheetManager } from 'styled-components';
import './App.css';
import SoloApp from './SoloApp';
import GroupApp from './features/groupRevivr/GroupApp';
import {
  GlobalUnhandledErrorHandlingHook,
  useGlobalConfig,
  useNoSleep,
} from './hooks';

// Props are not forwarded in styled-components v6. This makes v6 compatible with styled-components v5
const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

const App = (): JSX.Element => {
  useGlobalConfig();
  useNoSleep();
  GlobalUnhandledErrorHandlingHook();
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {window.revivr.isGroupRevivr ? <GroupApp /> : <SoloApp />}
    </StyleSheetManager>
  );
};

export default App;
