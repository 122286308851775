/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-concat */
import { isProduction } from './EnvironmentHelper';

/**
 * Checks if the DevOps variable has been inserted during production. If it has not, it throws an error.
 * This function should always return the default value during development.
 * @param devOpsVariable The DevOps variable
 * @param defaultValue The default value to return if the DevOps variable is not valid
 * @returns devOpsVariable if DevOps has replaced it, otherwise returns the defaultValue
 */
const checkForInsertedDevOpsVariable = <T extends string | number>(
  devOpsVariable: string,
  defaultValue: T,
): T => {
  if (!isProduction) {
    return defaultValue;
  }
  if (!devOpsVariable) {
    console.error(
      `The DevOps variable is undefined or empty. Defaulting to ${defaultValue}`,
    );
    return defaultValue;
  }

  if (devOpsVariable.match(/^#\{.*$/i) || devOpsVariable.match(/^.*\}#$/i)) {
    console.error(
      `The DevOps variable ${devOpsVariable} has most likely not been replaced and is incorrect.`,
    );
    return defaultValue;
  }
  // Cast devOpsVariable to the generic T if possible
  if (typeof defaultValue === 'number' && !isNaN(+devOpsVariable)) {
    return +devOpsVariable as T;
  }
  if (typeof defaultValue === 'string') {
    return devOpsVariable as T;
  }
  if (devOpsVariable) {
    return devOpsVariable as T;
  }
  console.error('Unable to cast the DevOps variable to the appropriate type.');
  return defaultValue;
};

export default checkForInsertedDevOpsVariable;
