import { useEffect } from 'react';
import { AudioContextProps } from '../context/audio/audioContextProps';
import { AudioProps, AudioTypes } from '../pageTemplates/types/courseTypes';

const initialiseAudioAutoplay = (
  enableAudio: boolean,
  timerId: NodeJS.Timeout | null,
  audio: AudioContextProps,
  delayInSeconds?: number,
) => {
  audio?.initCurrentSection();
  if (enableAudio) {
    timerId = setTimeout(() => {
      audio?.playAudioOnPage(AudioTypes.OnLoad);
    }, delayInSeconds ?? 0);
  }
};

const cleanUpAudioAutoplay = (
  enableAudio: boolean,
  timerId: NodeJS.Timeout | null,
  audio: AudioContextProps,
  currentPath: string,
) => {
  if (enableAudio) {
    audio?.pauseFromAudioLibrary(currentPath);
    audio?.resetAudio(currentPath);
  }
  if (timerId) {
    clearTimeout(timerId);
  }
};

/**
 * Hook for autoplaying audio
 * This function contains the logic for autoplaying audio with delay if desired.
 * useAudioAutoplay contains an useEffect that autoplays the audio stored in the Sanity page.
    Custom hook to manage audio autoplay.
    @param {Object} audioProps - Properties related to audio playback.
    @param {HTMLAudioElement} audio - The audio element to be played.
    */
const useAudioAutoplay = (audioProps: AudioProps, audio: AudioContextProps) => {
  useEffect(() => {
    const timerId: NodeJS.Timeout | null = null;
    initialiseAudioAutoplay(
      audioProps?.enableAudio,
      timerId,
      audio,
      (audioProps?.audioDelay || 0) * 1000,
    );
    // This changes if it's placed inside of the cleanup function.
    // It thus has to be passed as a variable.
    const locationPathname = window.location.pathname;
    return () => {
      cleanUpAudioAutoplay(
        audioProps?.enableAudio,
        timerId,
        audio,
        locationPathname,
      );
    };
  }, [audioProps?.enableAudio]);
};

export default useAudioAutoplay;
