import { FunctionComponent, useEffect, useRef, useState } from 'react';
import LifeButton from '../../../../../components/Button';
import { useHeightViewport, useWidthViewport } from '../../../../../context';
import { useNavigation } from '../../../../../hooks';
import Countdown from '../../../assets/Countdown.json';
import finishLineImage from '../../../assets/race_end_line.png';
import { CountDownLottie } from '../../../components';
import { getTeamNumber } from '../../../helpers/teamAssetSelectors';
import { GameChannelStates } from '../../../providers/AblyRaceEvents';
import { DurationConst, REFRESH_RATE_MS } from '../../constants';
import Styles, { FINISH_LINE_WIDTH } from './RaceScreen.styles';
import RaceScreenProps, { TeamWithPosition } from './RaceScreen.types';
import LoadingComponent from './components/LoadingComponent';
import { useTeams } from '../../../providers/useTeams';

const AMBULANCE_INITIAL_POSITION = 20;
const AMBULANCE_WIDTH = 42;
const AMBULANCE_MARGIN = 32;
const AmbulanceInitialPositionWithMargin =
  AMBULANCE_INITIAL_POSITION + AMBULANCE_MARGIN;

const BUTTON_WIDTH_PX = '200px';

const COUNT_DOWN_LOTTIE_OPTIONS = {
  animationData: Countdown,
  autoplay: true,
  loop: false,
  path: '',
};

const getAmbulanceEndPosition = (
  teamWithPosition: TeamWithPosition,
  finishLinePosition: number,
): number => {
  if (teamWithPosition.hasFinishedRace) {
    return finishLinePosition + FINISH_LINE_WIDTH - AMBULANCE_WIDTH;
  }
  return teamWithPosition.futurePosition;
};

const renderAmbulanceAndPath = (
  teamWithPosition: TeamWithPosition,
  start: boolean,
  finishLinePosition: number,
) => {
  const props = {
    src: teamWithPosition.ambulanceImage,
    alt: 'ambulance',
    startPosition: teamWithPosition.currentPosition,
    endPosition: getAmbulanceEndPosition(teamWithPosition, finishLinePosition),
    animationDurationS: REFRESH_RATE_MS / 1000,
    start: start,
    leftMargin: AMBULANCE_MARGIN,
    width: AMBULANCE_WIDTH,
  };

  return (
    <>
      <Styles.RaceAmbulanceImg {...props} />
      <Styles.TeamLabel color={teamWithPosition.color}>
        {getTeamNumber(teamWithPosition.name)}
      </Styles.TeamLabel>
      <Styles.StyledPath color={teamWithPosition.color} {...props} />
    </>
  );
};
/**
 *
 * @param teams needs to be sorted based on the teamname. So that the ambulance is rendered in the correct order, and team1 is the first ambulance to be rendered
 * @param onGameStateChange callback function to be called when the game state changes
 * @param onTeamCompleted callback function to be called when a team has completed the race
 * @returns
 */
const RaceScreen: FunctionComponent<RaceScreenProps> = ({
  teams, // teams need to be sorted based on the name
  onGameStateChange,
  onTeamCompleted,
}) => {
  const { clearTeams } = useTeams();
  const { innerHeight } = useHeightViewport();
  const { innerWidth } = useWidthViewport();
  const { navigateToPreviousPage } = useNavigation();
  const [speed, setSpeed] = useState<number>(0);
  const [finishLinePosition, setFinishLinePosition] = useState<number>(0);
  const [totalDistance, setTotalDistance] = useState<number>(0);
  const [gameState, setGameState] = useState<GameChannelStates>(
    GameChannelStates.race_lobby,
  );

  const [teamsWithPosition, setTeamsWithPosition] = useState<
    TeamWithPosition[]
  >([]);

  const getTeamSpeed = (index: number) => {
    return Math.floor((speed * teams[index].score) / 100);
  };

  const getTeamCompletionStatus = (
    teamNumber: number,
    position: number,
  ): boolean => {
    if (position >= finishLinePosition - AMBULANCE_WIDTH) {
      onTeamCompleted(teamNumber, Date.now(), 100);
      return true;
    }
    return false;
  };

  const getUpdatedTeamsPosition = (
    changeInSize: number = 0,
  ): TeamWithPosition[] => {
    const update = teams.map((team, index) => {
      const existingTeam = teamsWithPosition.find((a) => a.name === team.name); // Need to find if the team is in the teamsWithPosition array

      // not all the teams are connected when this is rendered, so we need to be able to add teams that are not connected yet
      if (!existingTeam) {
        return {
          ...team,
          futurePosition: AMBULANCE_INITIAL_POSITION,
          currentPosition: AMBULANCE_INITIAL_POSITION,
          hasFinishedRace: false,
        };
      }

      if (
        existingTeam.hasFinishedRace ||
        gameState !== GameChannelStates.race_start
      ) {
        return existingTeam;
      }
      const newCurrentPosition =
        existingTeam.futurePosition +
        Math.floor(changeInSize * existingTeam.futurePosition);
      const newData: TeamWithPosition = {
        ...team,
        currentPosition: newCurrentPosition,
        futurePosition: newCurrentPosition + getTeamSpeed(index),
        hasFinishedRace: getTeamCompletionStatus(
          getTeamNumber(team.name),
          newCurrentPosition,
        ),
      };

      return newData;
    });

    // Team 2 can connect before team 1 etc. so we need to make sure that the result is sorted based on the team name
    return update.sort((a, b) => a.name.localeCompare(b.name));
  };

  useEffect(() => {
    if (teamsWithPosition.length === 0) {
      return;
    }
    let allTeamsCompletedRace = true;
    teamsWithPosition.forEach((team: TeamWithPosition) => {
      if (!team.hasFinishedRace) {
        allTeamsCompletedRace = false;
      }
    });
    if (allTeamsCompletedRace) {
      onGameStateChange(GameChannelStates.race_finished);
    }
  }, [teamsWithPosition]);

  useEffect(() => {
    setTeamsWithPosition(getUpdatedTeamsPosition());
  }, [teams]);

  const resizeTimeout = useRef<number>();
  useEffect(() => {
    const handleResize = () => {
      clearTimeout(resizeTimeout.current);
      resizeTimeout.current = window.setTimeout(() => {
        setFinishLinePosition(Math.floor(innerWidth - innerWidth * 0.1));
      }, 50);
    };
    handleResize();
  }, [innerWidth]);

  useEffect(() => {
    if (!finishLinePosition || finishLinePosition <= 0) {
      return;
    }

    const raceDistance =
      finishLinePosition - AmbulanceInitialPositionWithMargin;
    const distanceChange = (raceDistance - totalDistance) / totalDistance;
    setTeamsWithPosition(getUpdatedTeamsPosition(distanceChange));
    setTotalDistance(Math.floor(raceDistance));
    setSpeed(Math.floor(raceDistance / DurationConst));
  }, [finishLinePosition]);

  const onCountDownComplete = () => {
    updateGameState(GameChannelStates.race_start);
  };
  const onClickStartGame = () => {
    updateGameState(GameChannelStates.race_countdown);
  };
  const updateGameState = (state: GameChannelStates) => {
    setGameState(state);
    onGameStateChange(state);
  };

  const gameManuallyFinished = () => {
    const sortedTeamsByScore = teamsWithPosition.slice();
    sortedTeamsByScore.sort((a, b) => b.futurePosition - a.futurePosition);
    sortedTeamsByScore.forEach((team) => {
      if (team.hasFinishedRace) {
        return;
      }
      const percentageCompleted = Math.ceil(
        (team.currentPosition / totalDistance) * 100,
      );
      onTeamCompleted(
        getTeamNumber(team.name),
        Date.now(),
        percentageCompleted,
      );
    });
    updateGameState(GameChannelStates.race_finished);
  };

  return (
    <Styles.PageGrid height={innerHeight}>
      <LoadingComponent teams={teams} />
      {gameState === GameChannelStates.race_countdown ? (
        <Styles.CenterOverlay>
          <CountDownLottie
            countDownLottieOptions={COUNT_DOWN_LOTTIE_OPTIONS}
            onComplete={onCountDownComplete}
          />
        </Styles.CenterOverlay>
      ) : null}
      <Styles.RaceArea>
        <Styles.FinishLineImg src={finishLineImage} alt="racing finish line" />
        {teamsWithPosition.map((teamWithPosition) => (
          <Styles.RaceAmbulanceContainer key={teamWithPosition.name}>
            <Styles.RaceLane key={teamWithPosition.name} />
            {renderAmbulanceAndPath(
              teamWithPosition,
              gameState === GameChannelStates.race_start,
              finishLinePosition,
            )}
          </Styles.RaceAmbulanceContainer>
        ))}
      </Styles.RaceArea>
      <Styles.Footer>
        {gameState !== GameChannelStates.race_start ? (
          <>
            <LifeButton
              width={BUTTON_WIDTH_PX}
              key={`button_${'button.buttonType'}_${'button.text'}`}
              variant="secondary"
              disabled={teamsWithPosition.length === 0}
              onClick={() => {
                clearTeams();
                navigateToPreviousPage();
              }}
            >
              Back
            </LifeButton>
            <LifeButton
              width={BUTTON_WIDTH_PX}
              key={`button_${'button.buttonType1'}_${'button.text1'}`}
              variant="primary"
              disabled={teamsWithPosition.length === 0}
              onClick={onClickStartGame}
            >
              Start CPR Race
            </LifeButton>
          </>
        ) : (
          <LifeButton
            width={BUTTON_WIDTH_PX}
            key={`button_${'button.buttonType'}_${'button.text'}`}
            variant="secondary"
            onClick={gameManuallyFinished}
          >
            Stop
          </LifeButton>
        )}
      </Styles.Footer>
    </Styles.PageGrid>
  );
};
export default RaceScreen;
