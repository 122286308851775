import {
  ErrorMessage,
  InputLabel,
  SystemIcons,
  TextField,
} from '@laerdal/life-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import LifeButton from '../../components/Button';
import { TimeLeftWithText } from '../../components/TopBars';
import { useHeightViewport } from '../../context';
import { useFirebase } from '../../context/FirebaseProvider';
import { capitalize } from '../../features/sanity/stringHelpers';
import {
  BottomContainer,
  ButtonContainer,
  PageGridStyle,
  ProgressbarContainer,
  StyledText,
} from './InfoPageStyles';
import { Button } from './InfoPageTypes';
import { Text } from './Text';

const StyledPageGrid = styled(PageGridStyle)`
  grid-template-rows:
    minmax(32px, max-content)
    minmax(max-content, 1fr)
    minmax(0, max-content)
    16px;
`;

const Body = styled(StyledText)`
  align-self: center;
  grid-row: 2;
`;

const ValidationError = styled(ErrorMessage)`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.critical_400};
`;
interface DynamicInputPageProps {
  text?: Text;
  buttons?: Button[];
  verticalButtons?: boolean;
  bottomContent?: React.ReactNode;
  inputFieldPlaceholder?: string;
  inputFieldLabel?: string;
  inputFieldValidationMessage?: string;
  inputFieldMinimumLength?: number;
  firebaseCollectionName: string;
}

const InputBody = styled.div`
  margin-top: 50px;
`;

const DynamicInputPage: FunctionComponent<DynamicInputPageProps> = ({
  buttons,
  verticalButtons = false,
  text,
  inputFieldPlaceholder,
  inputFieldLabel = '',
  inputFieldValidationMessage = '',
  inputFieldMinimumLength,
  firebaseCollectionName,
  bottomContent,
}: DynamicInputPageProps) => {
  const { innerHeight } = useHeightViewport();
  const { storeCollection } = useFirebase();
  const [validationFails, setValidationFails] = useState(false);
  const [inputFieldValue, setInputFieldValue] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');

  const inputFieldChange = (val) => {
    setInputFieldValue(val);
  };

  const validateInputField = () => {
    if (
      inputFieldMinimumLength &&
      inputFieldMinimumLength > 0 &&
      inputFieldValue.length < inputFieldMinimumLength
    ) {
      setValidationFails(true);
      setValidationErrorMessage(inputFieldValidationMessage);
      return false;
    }
    setValidationFails(false);
    setValidationErrorMessage('');
    return true;
  };

  useEffect(() => {
    if (validationFails) {
      validateInputField();
    }
  }, [inputFieldValue]);

  return (
    <StyledPageGrid height={innerHeight}>
      <ProgressbarContainer>
        <TimeLeftWithText enabled />
      </ProgressbarContainer>

      {text && (
        <Body>
          <h1 tabIndex={-1}>
            {text.header}
            {text.header2 ? (
              <>
                <br />
                {text.header2}
              </>
            ) : null}
          </h1>
          {typeof text.body === 'string' ? (
            <p>
              {text.body}
              <strong>{text.emphasized}</strong>
            </p>
          ) : (
            text.body
          )}

          <InputBody>
            <InputLabel inputId="DemoTextField" text={inputFieldLabel} />
            <TextField
              value={inputFieldValue}
              onChange={inputFieldChange}
              id="inputField"
              placeholder={inputFieldPlaceholder}
              validationMessage={validationErrorMessage}
            />

            {validationFails && (
              <ValidationError>
                <SystemIcons.Attention size="20px" />
                <span>{validationErrorMessage}</span>
              </ValidationError>
            )}
          </InputBody>
        </Body>
      )}

      <ButtonContainer
        role="navigation"
        aria-label="Main"
        verticalButtons={verticalButtons}
      >
        {buttons
          ? buttons.map((button: Button) => {
              return (
                <LifeButton
                  disabled={validationFails}
                  key={`button_${button.buttonType}_${button.text}`}
                  variant={button.buttonType}
                  onClick={() => {
                    if (!validateInputField()) {
                      return;
                    }

                    storeCollection(capitalize(firebaseCollectionName), {
                      inputFieldValue,
                    }).finally(() => button.onClick());
                  }}
                >
                  {button.text}
                  {button.icon ? <button.icon size="18px" /> : ''}
                </LifeButton>
              );
            })
          : null}
      </ButtonContainer>

      <BottomContainer>{bottomContent}</BottomContainer>
    </StyledPageGrid>
  );
};

export default DynamicInputPage;
