import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Modal, ModalProps } from '../../components/Modals';
import SpeechBubble from '../../components/Speechbubble/Speechbubble';
import { ClassroomCodeAndUrl } from '../../components/TopBars';
import ProgressBarLargeScreens from '../../components/TopBars/ProgressBarLargeScreens/ProgressBarLargeScreens';
import { AnimationState } from '../../components/TopBars/types';
import { useAudio, useHeightViewport } from '../../context';
import { useSanityCourse } from '../../context/sanity/useSanityCourse';
import SanityModal from '../../features/sanity/sanityModal';
import { AudioTypes } from '../types/courseTypes';
import DynamicDialoguePageButtons from './components/DynamicDialoguePageButtons';
import StyledPageGrid from './components/StyledPageGrid';

import { useNavigation } from '../../hooks';
import {
  ButtonContainer,
  Img,
  MainContent,
  StyledText,
} from '../Templates/InfoPageStyles';
import mediaMedium from '../Templates/MediaMedium';
import ReplyBox from './ReplyBox';
import { DynamicDialoguePageProps } from './types/DynamicDialoguePageProps';

const ImgWithSize = styled(Img)<{ maxHeight?: string }>`
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '102px')};
  grid-column: 2 / -2;

  ${mediaMedium(css`
    grid-column: 2 / span 2;
  `)}
`;

const OperatorImg = styled(ImgWithSize)`
  align-self: center;
  justify-self: center;
`;

const ReplyBoxContainer = styled.div`
  grid-column: 2 / -2;
  grid-row: -3;
`;

/**
 * Custom behaviour component for when one would like
 * to have a reply bubble while autoplaying the page.
 * Only visible when text is added to the reply box in Sanity.
 */
/**
 * Inserts the reply box in the page
 * if the page is not autoplaying.
 */
const ReplyDialogue: FunctionComponent<{
  countDown: any;
  replyBox: any;
  buttons: any;
}> = ({ countDown, replyBox, buttons }) => {
  if (countDown && replyBox?.text) {
    return <ReplyBox text={replyBox?.text} />;
  }
  if (
    (replyBox?.text || replyBox?.button) &&
    buttons === undefined &&
    countDown === undefined
  ) {
    return <ReplyBox text={replyBox.text} button={replyBox.button} />;
  }
  return null;
};

const PauseContinueButton: FunctionComponent<{
  countDown: any;
  buttons: any;
}> = ({ countDown, buttons }) => {
  if (countDown && buttons) {
    return (
      <ButtonContainer role="navigation" aria-label="Main">
        <DynamicDialoguePageButtons buttons={buttons} />
      </ButtonContainer>
    );
  }
  return null;
};

const DynamicDialoguePage: FunctionComponent<DynamicDialoguePageProps> = ({
  text,
  operatorImage,
  countDown,
  speechBubbleContent,
  replyBox,
  modal,
  practiceAgainModal,
  playAudioOnLoad,
  continueCourseModal,
  buttons,
}: DynamicDialoguePageProps) => {
  const { innerHeight } = useHeightViewport();
  const navigationContext = useNavigation();
  const audio = useAudio();
  const pageContext = useSanityCourse();
  const [onRefreshModal, setOnRefreshModal] = useState<
    // onRefreshModal is used to show a modal when the user refreshes the page. If this value is not undefined, the audio and autonavigation is disabled
    ModalProps | undefined
  >();

  const dismissOnRefreshModal = () => {
    audio?.initCurrentSection();
    if (playAudioOnLoad) {
      audio?.playAudioOnPage(AudioTypes.OnLoad);
    }
    setOnRefreshModal(undefined);
  };
  const createOnRefreshModal = () => {
    if (
      practiceAgainModal &&
      navigationContext &&
      navigationContext.initialPage === window.location.pathname &&
      audio?.doesSectionContainAudio()
    ) {
      const newModal = SanityModal.createContinueCourseModal(
        pageContext,
        dismissOnRefreshModal,
        navigationContext,
      );
      setOnRefreshModal(newModal);
    }
  };

  useEffect(() => {
    if (
      playAudioOnLoad &&
      navigationContext &&
      navigationContext.initialPage !== window.location.pathname
    ) {
      audio?.playAudioOnPage(AudioTypes.OnLoad);
    }
    const locationPathname = window.location.pathname;

    return () => {
      if (playAudioOnLoad) {
        audio?.pauseFromAudioLibrary(locationPathname);
        audio?.resetAudio(locationPathname);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (continueCourseModal !== undefined) {
      // TODO ContinueCourseModal should be removed from template, as we are using the data from the section
      createOnRefreshModal();
    }
  }, [continueCourseModal]);

  const countDownDuration = (): number => {
    if (countDown) {
      return countDown.duration;
    }
    if (navigationContext) {
      return navigationContext.currentPageDuration();
    }
    return 10;
  };

  return (
    <StyledPageGrid height={innerHeight}>
      <ClassroomCodeAndUrl />
      {modal && (
        <Modal
          bannerImage={modal.bannerImage}
          buttons={modal.buttons}
          onClose={modal.onClose}
          text={modal.text}
        />
      )}
      {onRefreshModal && (
        <Modal
          bannerImage={onRefreshModal.bannerImage}
          buttons={onRefreshModal.buttons}
          onClose={onRefreshModal.onClose}
          text={onRefreshModal.text}
          verticalButtons
        />
      )}
      {countDown && (
        <ProgressBarLargeScreens
          animationDurationInSeconds={countDownDuration()}
          animationDelayInSeconds={countDown.delay}
          animationState={
            onRefreshModal ? AnimationState.paused : countDown?.animationState // onRefreshModal is controlling if the audio, progressbar and autonavigation is done
          }
          setAnimRef={countDown.setAnimRef}
        />
      )}

      {operatorImage && (
        <OperatorImg
          src={operatorImage.src}
          alt={operatorImage.alt ? operatorImage.alt : 'Operator'}
          maxHeight={operatorImage.height}
          onClick={operatorImage.onClick}
        />
      )}

      {text && (
        <MainContent>
          <StyledText>
            <h1 tabIndex={-1}>{text.header}</h1>
            <SpeechBubble>{speechBubbleContent}</SpeechBubble>
          </StyledText>
          <ReplyBoxContainer>
            <ReplyDialogue
              countDown={countDown}
              replyBox={replyBox}
              buttons={buttons}
            />
          </ReplyBoxContainer>
        </MainContent>
      )}
      <PauseContinueButton countDown={countDown} buttons={buttons} />
    </StyledPageGrid>
  );
};

export default DynamicDialoguePage;
