/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable max-classes-per-file */

import FFT from 'fft.js';
import GlobalVariables from './GlobalVariables';
import * as Utils from './Util';
import Result from './Result';

class Analyzer {
  private numberOfSamplesInFFTinput = 256;

  public EstimateFrequencyByUsingFFT(
    input: number[],
    framesPerSecond: number,
  ): Result {
    const frequencyResult = new Result();

    const values = new Array<number>(input.length);
    for (let i = 0; i < input.length; i += 1) {
      values[i] = input[i];
    }

    const lowerLimitHz = 0.55; // 33 rate
    const nyquistFrequency = framesPerSecond / 2.0; // fps / 2;

    // plus 3 becuase specter begins at one. Multiply by 3 because specter length is 45 samples and frequency range is 15 Hz
    const lowerLimitIndex = Math.trunc(
      lowerLimitHz * (this.numberOfSamplesInFFTinput / 2 / nyquistFrequency),
    );

    const higherLimitHz = 2.9; // = 174 rate
    const higherLimitIndex = Math.trunc(
      higherLimitHz * (this.numberOfSamplesInFFTinput / 2 / nyquistFrequency),
    );

    const frequencyMagnitudes = this.doFft(values);

    const peakIndexes = Utils.findPeakIndexes(
      frequencyMagnitudes,
      lowerLimitIndex,
      higherLimitIndex,
      0,
    );

    if (peakIndexes.length < 1) {
      return frequencyResult;
    }

    let currentMaxFrequencyMagnitudesIndex: number = peakIndexes[0];
    let currentMaxFrequencyMagnitudes: number =
      frequencyMagnitudes[currentMaxFrequencyMagnitudesIndex];

    for (let i = 1; i < peakIndexes.length; i += 1) {
      if (frequencyMagnitudes[peakIndexes[i]] > currentMaxFrequencyMagnitudes) {
        currentMaxFrequencyMagnitudesIndex = peakIndexes[i];
        currentMaxFrequencyMagnitudes =
          frequencyMagnitudes[currentMaxFrequencyMagnitudesIndex];
      }
    }

    const peakThreshold = Math.round(currentMaxFrequencyMagnitudes * 0.6);

    const peaks: number[] = [];

    for (let i = 0; i < peakIndexes.length; i += 1) {
      if (frequencyMagnitudes[peakIndexes[i]] > peakThreshold) {
        peaks.push(peakIndexes[i]);
      }
    }

    // hair test
    if (
      peaks.length >= 2 &&
      frequencyMagnitudes[currentMaxFrequencyMagnitudesIndex] > 100000
    ) {
      const peakTwoAndOneRatio =
        frequencyMagnitudes[peaks[1]] / frequencyMagnitudes[peaks[0]];

      if (peakTwoAndOneRatio === 1) {
        frequencyResult.noDistinctFrequencyFound = true;
        return frequencyResult;
      }

      if (peakTwoAndOneRatio > 0.75 && peakTwoAndOneRatio < 1.5) {
        // før 0.7 og 1.5

        const frequencyRatio = peaks[1] / peaks[0];
        if (frequencyRatio < 2.3 && frequencyRatio > 1.7) {
          frequencyResult.frequency = Utils.getFrequencyFromCurveInterpolation(
            frequencyMagnitudes,
            peaks[0],
            nyquistFrequency,
            this.numberOfSamplesInFFTinput,
          ); // peaks.get(0) * 60;
          frequencyResult.scepticalToFrequency = true;
        }
      }
    }

    if (!frequencyResult.scepticalToFrequency) {
      frequencyResult.frequency = Utils.getFrequencyFromCurveInterpolation(
        frequencyMagnitudes,
        currentMaxFrequencyMagnitudesIndex,
        nyquistFrequency,
        this.numberOfSamplesInFFTinput,
      ); // currentMaxIndex * 60;
    }

    const averageFrequencyAmplitudeRelevantArea = Utils.averageFromTo(
      frequencyMagnitudes,
      lowerLimitIndex,
      higherLimitIndex,
    );
    const diffmaxFrequencyMagnitudeAndaverageFrequencyAmplitudeRelevantArea =
      frequencyMagnitudes[currentMaxFrequencyMagnitudesIndex] /
      averageFrequencyAmplitudeRelevantArea;

    if (
      diffmaxFrequencyMagnitudeAndaverageFrequencyAmplitudeRelevantArea < 1.4
    ) {
      frequencyResult.scepticalToFrequency = true;
      frequencyResult.noDistinctFrequencyFound = true;
      return frequencyResult;
    }

    if (frequencyMagnitudes[currentMaxFrequencyMagnitudesIndex] < 80000) {
      // Satt opp for å forsøke å unngå deteksjoner når person har komprimeringspause
      frequencyResult.noDistinctFrequencyFound = true;
      frequencyResult.scepticalToFrequency = true;
      return frequencyResult;
    }

    if (
      diffmaxFrequencyMagnitudeAndaverageFrequencyAmplitudeRelevantArea < 1.6
    ) {
      frequencyResult.scepticalToFrequency = true;
      if (frequencyMagnitudes[currentMaxFrequencyMagnitudesIndex] < 300000) {
        // Satt opp for å forsøke å håndtere støy ved innblåsning
        frequencyResult.noDistinctFrequencyFound = true;
        return frequencyResult;
      }
    }

    // Henter ut sceptical frequency.
    GlobalVariables.globalScepticalFrequency =
      frequencyResult.scepticalToFrequency;

    return frequencyResult;
  }

  private hanningWindow: number[] = Utils.hanningWindow(15);

  public doFft(values: number[]): number[] {
    if (this.hanningWindow.length !== values.length) {
      this.hanningWindow = Utils.hanningWindow(values.length);
    }
    values = Utils.multiplyElementWise(values, this.hanningWindow);

    values = Utils.zeroPadToNewArray(
      values,
      this.numberOfSamplesInFFTinput + 2,
    );

    const f = new FFT(this.numberOfSamplesInFFTinput);
    const out: number[] = [];
    f.realTransform(out, values);

    const returnValue = Utils.frequencyMagnitudeFromFFT(out);
    returnValue.length = returnValue.length / 2 + 1; // HACK to match .cs code
    return returnValue;
  }
}

export default Analyzer;
