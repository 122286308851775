import {
  getCurrentUrl,
  groupPaths,
  removeProtocolFromUrl,
} from '../../../helper';
import { SanityGroupSettings } from '../../../pageTemplates/types/courseTypes';
import { LocalStorageService } from '../../../services';
import { UseSanityGroupCourse } from '../context/GroupSanityContext';

export const getLearnerJoinPath = (): string => {
  const pathPrefix = Object.keys(groupPaths).find(
    (key) => groupPaths[key] === LocalStorageService.getProjectId(),
  );
  if (pathPrefix !== undefined) {
    return `${pathPrefix}/join`;
  }
  return '/join';
};

/**
 * @returns The URL for learners stored in Sanity for the project.
 *  If a valid URL is not found in Sanity, then the default learner join URL
 *  is used instead.
 */
const LearnerURLFromSanityOrLocal = (): string => {
  const { settings } = UseSanityGroupCourse();
  const groupSettings = settings as SanityGroupSettings;
  const customURL = groupSettings.customJoinUrlAndText?.customJoinUrl ?? '';
  const getJoinPath = getLearnerJoinPath();

  if (customURL) {
    return removeProtocolFromUrl(customURL);
  }

  if (getJoinPath !== '/join') {
    return `${removeProtocolFromUrl(getCurrentUrl())}${getJoinPath}`;
  }
  return `${removeProtocolFromUrl(getCurrentUrl())}/join`;
};

export default LearnerURLFromSanityOrLocal;
