// States we send via RaceGameChannelEvents and game_message
export enum GameChannelStates {
  race_lobby = 'race_lobby',
  race_countdown = 'race_countdown',
  race_start = 'race_start',
  race_finished = 'race_finished',
}

// Events that is sent via TeamChannel and that facilitator and learner can listen to
export enum TeamChannelEvents {
  rate_update = 'rate_update',
  position_update = 'position_update',
}

// Events that is sent via GameChannel and that facilitator and learner can listen to
export enum RaceGameChannelEvents {
  game_message = 'game-message',
  learner_connected = 'learner_connected',
}

// Add other message types as needed
export type TeamChannelEventPositionMessage = {
  position: number;
  isFinalPosition: boolean;
};
