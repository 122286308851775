import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';

type ViewportWidthContextProps = {
  innerWidth: number;
};

type Props = {
  children: any;
};

const ViewportWidthContext = React.createContext<ViewportWidthContextProps>({
  innerWidth: 0,
});

export const ViewportWidthProvider: FunctionComponent<Props> = ({
  children,
}: Props) => {
  const [innerWidth, setWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <ViewportWidthContext.Provider value={{ innerWidth }}>
      {children}
    </ViewportWidthContext.Provider>
  );
};

export const useWidthViewport = (): ViewportWidthContextProps => {
  const { innerWidth } = useContext(ViewportWidthContext);
  return {
    innerWidth,
  };
};
