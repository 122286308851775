import { InputLabel, TextField } from '@laerdal/life-react-components';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import RoundCheckmark from '../../assets/images/roundCheckmark.png';
import LifeButton from '../../components/Button';
import { ErrorModal } from '../../components/Modals/Modals';
import { isEmailValid } from '../../helper';
import { useNavigation } from '../../hooks';
import { SendEmailReminderAsync } from '../../services';
import { logError } from '../../services/errorHandling';
import { Img } from '../Templates/InfoPageStyles';
import { ImageTypes } from '../Templates/InfoPageTypes';
import PageWrapper, {
  MainContent,
  MainContentWrapper,
  MainText,
  VerticalBottomContent,
} from '../Templates/SimulationPageStyles';
import { SanityImage } from '../types/sanityTypes';

const InputLabelContainer = styled.div`
  label {
    font-size: 1.2rem;
    line-height: 16px;
  }
  margin-top: 8px;
`;

const MainTextOverride = styled(MainText)`
  margin: 0px 0px 16px 0px;
`;

const MainContentOverride = styled(MainContent)`
  margin: 0px 16px 0px 16px;
`;

const ImageContainer = styled.div`
  justify-content: center;
  display: flex;
  margin: 10px;
`;

interface EmailContentProps {
  image?: SanityImage;
  placeholderText: string;
  emailLabelText: string;
  mainText?: string;
  email?: string;
  onChange: (text: string) => void;
  hasError?: boolean;
  validationMessage?: string;
}

const EmailContent: FunctionComponent<EmailContentProps> = ({
  image,
  placeholderText,
  emailLabelText,
  mainText,
  email,
  onChange,
  hasError,
  validationMessage,
}) => {
  return (
    <MainContentOverride>
      <ImageContainer>
        <Img src={image?.src} alt={image?.alt} imgType={image?.imgType} />
      </ImageContainer>
      <MainTextOverride>{mainText}</MainTextOverride>
      <InputLabelContainer>
        <InputLabel inputId="emailTextField" text={emailLabelText} />
      </InputLabelContainer>
      <TextField
        id="emailTextField"
        placeholder={placeholderText}
        value={email}
        onChange={(text) => onChange(text)}
        className={hasError ? 'error' : ''}
        validationMessage={validationMessage}
      />
    </MainContentOverride>
  );
};

interface ConfirmationContentProps {
  confirmationText?: string;
}

const ConfirmationContent: FunctionComponent<ConfirmationContentProps> = ({
  confirmationText = '',
}) => {
  return (
    <MainContentOverride>
      <ImageContainer>
        <Img src={RoundCheckmark} alt="Checkmark" imgType={ImageTypes.Icon} />
      </ImageContainer>
      <MainTextOverride>{confirmationText}</MainTextOverride>
    </MainContentOverride>
  );
};

interface SubmitButtonProps {
  isBusy: boolean;
  onClick: () => void;
  sendEmailButtonText: string;
}
const SubmitButton: FunctionComponent<SubmitButtonProps> = ({
  isBusy,
  onClick,
  sendEmailButtonText,
}) => {
  return (
    <LifeButton
      loading={isBusy}
      className=""
      style={{ width: '100%' }}
      variant="primary"
      onClick={onClick}
    >
      {sendEmailButtonText}
    </LifeButton>
  );
};

interface DynamicEmailReminderPageProps {
  emailSubjectText?: string;
  emailButtonText?: string;
  emailHeadingText?: string;
  emailBodyText?: string;
  inputLabelText?: string;
  mainText?: string;
  placeholderText?: string;
  image?: SanityImage;
  validationMessage?: string;
  confirmationText: string;
  sendEmailButtonText: string;
}

const DynamicEmailReminderPage: FunctionComponent<
  DynamicEmailReminderPageProps
> = ({
  mainText = '',
  inputLabelText = '',
  placeholderText = '',
  image,
  validationMessage = '',
  confirmationText = '',
  emailBodyText = '',
  emailSubjectText = '',
  emailHeadingText = '',
  emailButtonText = '',
  sendEmailButtonText = '',
}: DynamicEmailReminderPageProps) => {
  const [isBusy, setIsBusy] = useState(false);
  const [emailSentSuccessfully, setEmailSentSuccessfully] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorString, setErrorString] = useState('');
  const [formData, setFormData] = useState({
    email: {
      text: '',
      hasError: false,
      validationMessage: '',
    },
    receiveTrainingReminders: false,
    receiveMarketingCommunication: false,
  });
  const navigationContext = useNavigation();
  const createFieldData = (
    field: string,
    text: string,
    isValid?: (text: string) => boolean,
  ) => {
    const hasError = isValid === undefined ? false : !isValid(text);
    return {
      ...formData[field],
      text,
      hasError,
      validationMessage: hasError ? validationMessage : '',
    };
  };

  const validateFields = (): boolean => {
    setFormData({
      ...formData,
      email: createFieldData('email', formData.email.text, isEmailValid),
    });

    return isEmailValid(formData.email.text);
  };

  const submitButtonClick = async () => {
    if (!validateFields()) {
      return;
    }

    try {
      setIsBusy(true);
      await SendEmailReminderAsync(
        formData.email.text,
        emailSubjectText,
        emailHeadingText,
        emailBodyText,
        emailButtonText,
      );

      setEmailSentSuccessfully(true);
    } catch (exception: any) {
      logError(exception);
      setShowErrorModal(true);
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <>
      <PageWrapper>
        <MainContentWrapper>
          {emailSentSuccessfully ? (
            <ConfirmationContent confirmationText={confirmationText} />
          ) : (
            <EmailContent
              image={image}
              placeholderText={placeholderText}
              mainText={mainText}
              emailLabelText={inputLabelText}
              email={formData.email.text}
              hasError={formData.email.hasError}
              validationMessage={formData.email.validationMessage}
              onChange={(inputText) => {
                setFormData({
                  ...formData,
                  email: createFieldData('email', inputText),
                });
              }}
            />
          )}
        </MainContentWrapper>
        <VerticalBottomContent>
          {!emailSentSuccessfully && (
            <SubmitButton
              isBusy={isBusy}
              sendEmailButtonText={sendEmailButtonText}
              onClick={submitButtonClick}
            />
          )}
        </VerticalBottomContent>
      </PageWrapper>

      <ErrorModal
        isVisible={showErrorModal}
        toggleModal={() => setShowErrorModal(false)}
      />
    </>
  );
};

export default DynamicEmailReminderPage;
