import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ModalProps } from '../../components/Modals';
import { AnimationState } from '../../components/TopBars/types';
import WithFadeIn from '../../components/WithFadeIn';
import WithFocus from '../../components/WithFocus';
import { useAudio } from '../../context';
import { useSanityCourse } from '../../context/sanity/useSanityCourse';
import { sanityFunctions } from '../../features/sanity';
import autoNavigationHelper from '../../helper/autoNavigationHelper';
import { useNavigation } from '../../hooks';
import {
  Button,
  CountDown,
  ImageWithCustomSize,
} from '../Templates/InfoPageTypes';
import { Text } from '../Templates/Text';
import { SanityCountDown } from '../types/courseTypes';
import { SanityButton } from '../types/sanityTypes';
import DynamicDialoguePage from './DynamicDialoguePage';
import { ContinueCourseModal } from './types/ContinueCourseModal';
import { ReplyBoxProps } from './types/DynamicDialoguePageProps';

interface SanityDialogPageProps {
  text?: Text;
  operatorImage?: ImageWithCustomSize;
  countDown?: SanityCountDown;
  speechbubbleContent?: ReactNode;
  replyBox?: ReplyBoxProps;
  modal?: ModalProps;
  practiceAgainModal?: boolean;
  playAudioOnLoad?: boolean;
  continueCourseModal: ContinueCourseModal;
  buttons?: SanityButton[];
  enableAutoplay?: boolean;
}

const Template: FunctionComponent = () => {
  const navigationContext = useNavigation();
  const audio = useAudio();
  const [modal, setModal] = useState<ModalProps | undefined>();
  const animationRef = useRef<HTMLDivElement>(null);
  const [buttons, setButtons] = useState<Button[] | undefined>(undefined);
  const [anim, setAnim] = useState<AnimationState>(AnimationState.paused);

  const [countDown, setCountDown] = useState<CountDown | undefined>({
    duration: 10,
    delay: 1,
    animationState: AnimationState.paused,
    setAnimRef: animationRef,
  });

  const pageContext = useSanityCourse();

  const [currentPage, setCurrentPage] = useState<SanityDialogPageProps>({
    continueCourseModal: {
      continuePracticeButtonText: '',
      startOverButtonText: '',
      startOverUrl: '',
      headerText: '',
      body: '',
    },
    speechbubbleContent: null,
    countDown: undefined,
  });

  const showModal = (
    dialogPrimaryButton?: string,
    dialogHeaderText?: string,
    dialogBody?: ReactNode,
    imgSrc?: ImageWithCustomSize,
  ) => {
    const newModal: ModalProps = {
      onClose: () => {
        setModal(undefined);
      },
      buttons: [
        {
          text: dialogPrimaryButton !== undefined ? dialogPrimaryButton : '',
          buttonType: 'primary',
          onClick: () => {
            setModal(undefined);
          },
        },
      ],
      text: {
        header: dialogHeaderText,
        body: undefined,
      },
      bannerImage: {
        src: imgSrc?.src,
        alt: imgSrc?.alt,
        size: imgSrc?.height,
      },
    };
    setModal(newModal);
  };

  const onAnimated = () => {
    navigationContext?.navigateToNextPage();
  };

  useEffect(() => {
    const tempPage = pageContext.getCurrentPage().dialogPageTemplate;
    let someRef: HTMLDivElement | null = null;

    if (tempPage !== undefined) {
      if (animationRef && animationRef.current) {
        someRef = animationRef.current;
        someRef.addEventListener('animationend', onAnimated, {
          once: true,
        });
      }

      const page: SanityDialogPageProps = {
        operatorImage: {
          src: tempPage.mainImg?.src,
          alt: tempPage.mainImg?.alt,
          height: tempPage.mainImg?.height,
        },
        speechbubbleContent: tempPage.speechbubbleContent,
        text: {
          header: tempPage.text?.header,
        },
        replyBox: {
          text: tempPage.replyBox?.text,
          button: {
            text: tempPage.replyBox?.button,
            buttonType: 'primary',
            onClick: () => {
              navigationContext?.navigateToNextPageWithSleepCheck();
            },
          },
        },
        countDown:
          tempPage.countDown !== undefined
            ? {
                // eslint-disable-next-line no-nested-ternary
                duration:
                  autoNavigationHelper.autoPlayDuration(
                    tempPage.countDown?.duration !== undefined // sets the duration based on the sanity value
                      ? tempPage.countDown.duration
                      : 0,
                  ) ?? 0,
                delay:
                  autoNavigationHelper.autoPlayDelay(
                    tempPage.countDown?.delay,
                  ) ?? 0,
                playText: tempPage.countDown?.playText,
                pauseText: tempPage.countDown?.pauseText,
              }
            : undefined,
        continueCourseModal: {
          continuePracticeButtonText:
            tempPage.continueCourseModal?.continuePracticeButtonText,
          startOverButtonText:
            tempPage.continueCourseModal?.startOverButtonText,
          startOverUrl: tempPage.continueCourseModal?.startOverUrl,
          headerText: tempPage.continueCourseModal?.headerText,
          body: tempPage.continueCourseModal?.body,
        },

        enableAutoplay: tempPage.enableAutoplay,
      };

      setCurrentPage(page);

      if (tempPage.enableAutoplay && tempPage.countDown) {
        setButtons(
          sanityFunctions.createAutoplayButtonsFromSanityCountDown(
            tempPage.countDown,
            navigationContext,
            audio,
            anim,
            setAnim,
          ),
        );
      }
      setAnim(AnimationState.running);
    }
  }, []);

  useEffect(() => {
    let someRef: HTMLDivElement | null = null;
    if (currentPage.enableAutoplay && currentPage.countDown) {
      setButtons(
        sanityFunctions.createAutoplayButtonsFromSanityCountDown(
          currentPage.countDown,
          navigationContext,
          audio,
          anim,
          setAnim,
        ),
      );

      setCountDown({
        duration: currentPage.countDown.duration,
        delay: currentPage.countDown.delay,
        animationState: anim,
        setAnimRef: animationRef,
      });

      if (animationRef && animationRef.current) {
        someRef = animationRef.current;
        animationRef.current.addEventListener('animationend', onAnimated, {
          once: true,
        });
      }
    }
    return () => {
      if (someRef) {
        someRef.removeEventListener('animationend', onAnimated);
      }
    };
  }, [anim]);

  return (
    <DynamicDialoguePage
      countDown={currentPage.enableAutoplay ? countDown : undefined}
      operatorImage={currentPage.operatorImage}
      speechBubbleContent={currentPage.speechbubbleContent}
      text={currentPage.text}
      replyBox={currentPage.replyBox}
      modal={modal}
      practiceAgainModal
      playAudioOnLoad
      continueCourseModal={currentPage.continueCourseModal}
      buttons={buttons}
    />
  );
};

export default WithFadeIn(WithFocus(Template));
