declare global {
  interface Window {
    DD_LOGS: any;
  }
}

export class CustomError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CustomError';
  }
}
export class WithCauseError extends CustomError {
  cause: any;
  constructor(message: string, cause) {
    super(message);
    this.name = 'WithCauseError';
    this.cause = cause;
  }
}
export class PlayAudioError extends WithCauseError {
  constructor(message: string, cause) {
    super(message, cause);
    this.name = 'PlayAudioError';
  }
}
export class LoadToAudioLibraryError extends WithCauseError {
  constructor(message: string, cause) {
    super(message, cause);
    this.name = 'LoadToAudioLibraryError';
  }
}

export class PreloadImageError extends WithCauseError {
  constructor(message: string, cause) {
    super(message, cause);
    this.name = 'PreloadImageError';
  }
}

export class NoSleepError extends WithCauseError {
  constructor(message: string, cause) {
    super(message, cause);
    this.name = 'NoSleepError';
  }
}
const isError = (error: unknown): error is Error => {
  return (
    error instanceof Error ||
    (typeof error === 'object' &&
      error !== null &&
      'name' in error &&
      'message' in error)
  );
};

const containMessageString = (error: unknown): error is { message: string } => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
  );
};

export const convertErrorWithMessageStringToWarn = (
  error: unknown,
  message: string,
  warning?: string,
) => {
  if (containMessageString(error) && error.message.includes(message)) {
    console.warn(warning ?? '', error);
    window.DD_LOGS.onReady(function () {
      window.DD_LOGS.logger.warn(warning ?? '', {}, error);
    });
  } else {
    throw error;
  }
};

export const logErrorToDataDog = (
  message: string,
  messageContext: Record<string, unknown> = {},
  error?: Error | unknown,
) => {
  window.DD_LOGS?.onReady(function () {
    window.DD_LOGS.logger.error(message, messageContext, error);
  });
};
const logWarningToDataDog = (
  message: string,
  messageContext: Record<string, unknown> = {},
  error?: Error | unknown,
) => {
  window.DD_LOGS.onReady(function () {
    window.DD_LOGS.logger.warn(message, messageContext, error);
  });
};

const getErrorMessage = (error: unknown): string => {
  if (isError(error)) {
    return error.message;
  }
  return '';
};

export const logWarning = (
  message?: string,
  messageContext: Record<string, unknown> = {},
  error?: unknown,
) => {
  logWarningToDataDog(
    message ?? isError(error) ? getErrorMessage(error) : '',
    messageContext,
    error,
  );
  console.warn(message, error);
};
export const logError = (
  error: unknown,
  message?: string,
  messageContext: Record<string, unknown> = {},
) => {
  if (isError(error)) {
    if (import.meta.env.VITE_APP_DEBUG_LOG_ERRORS_TO_CONSOLE === 'true') {
      console.error(error, message, messageContext);
    }

    logErrorToDataDog(message ?? getErrorMessage(error), messageContext, error);

    // Emit a custom error event
    const customErrorEvent = new CustomEvent('customError', {
      detail: { error, message, messageContext, timestamp: new Date() },
    });

    window.dispatchEvent(customErrorEvent);
  }
};
