export const makeVimeoUrlPrivacyFriendly = (videoUrl: string): string => {
  const url = new URL(videoUrl);
  const params = url.searchParams;

  if (!params.has('dnt')) {
    if (url.search) {
      // The URL has other parameters
      params.append('dnt', '1');
    } else {
      // The URL has no parameters
      params.set('dnt', '1');
    }
  }

  return url.toString();
};
