import { TcprLinkContextProps } from '../../../../../context/TcprLinkProvider';
import {
  SanityLottieOptions,
  TrainingPageBottomPart,
} from '../../../../sanity/types';

export const getLottieOptions = (lottieOptions?: SanityLottieOptions) => {
  return {
    animationData: lottieOptions?.animationData
      ? lottieOptions?.animationData
      : undefined,
    autoplay: lottieOptions?.autoplay ?? true,
    loop: lottieOptions?.loop ?? true,
    path: !lottieOptions?.animationData ? lottieOptions?.path : undefined,
    rendererSettings: {},
  };
};

const CPR_HINT_LOW_RATE = 'Push faster';
const CPR_HINT_HIGH_RATE = 'Push slower';
const CPR_HINT_NO_RATE = 'Start pushing to move the ambulance';
const CPR_HINT_NO_FACE = 'Make sure your face is in the camera';
const CPR_HINT_GOOD_RATE = (
  <>
    You are doing great! <br />
    Continue pushing.
  </>
);
const CPR_MAX_ALLOWED_RATE = 120;
const CPR_MIN_ALLOWED_RATE = 100;

export const getCprHint = (
  receivedCprScoreAboveZero: boolean,
  tcprLinkContext: TcprLinkContextProps,
  bottomPart: TrainingPageBottomPart | undefined,
) => {
  return () => {
    if (!receivedCprScoreAboveZero || tcprLinkContext?.rate === undefined) {
      return CPR_HINT_NO_RATE;
    }
    if (tcprLinkContext.rate < 0) {
      return CPR_HINT_NO_FACE;
    }
    if (
      tcprLinkContext.rate <
      (bottomPart?.speedometer?.ranges?.accepted?.min ?? CPR_MIN_ALLOWED_RATE)
    ) {
      return CPR_HINT_LOW_RATE;
    }
    if (
      tcprLinkContext.rate <=
      (bottomPart?.speedometer?.ranges?.accepted?.max ?? CPR_MAX_ALLOWED_RATE)
    ) {
      return CPR_HINT_GOOD_RATE;
    }
    return CPR_HINT_HIGH_RATE;
  };
};
