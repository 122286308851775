import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';

type ViewportHeightContextProps = {
  innerHeight: number;
};

type Props = {
  children: any;
};

const ViewportHeightContext = React.createContext<ViewportHeightContextProps>({
  innerHeight: 0,
});

export const ViewportHeightProvider: FunctionComponent<Props> = ({
  children,
}: Props) => {
  const [innerHeight, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => {
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <ViewportHeightContext.Provider
      value={{
        innerHeight,
      }}
    >
      {children}
    </ViewportHeightContext.Provider>
  );
};

export const useHeightViewport = (): ViewportHeightContextProps => {
  const { innerHeight } = useContext(ViewportHeightContext);
  return {
    innerHeight,
  };
};
