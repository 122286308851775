/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Debug from './components/Debug/Debug';
import {
  ScormProvider,
  SoloNavigationProvider,
  useSanityCourse,
} from './context';
import RaceScreenTestPage from './features/race/RaceScreenTestPage';
import { urlChecker } from './helper';
import { pathHelper } from './helper/pathHelper';
import { TemplateList } from './pageTemplates';
import LandingPage from './pages/landingPage/LandingPage';

const AssetGenerator = React.lazy(
  () => import('./pages/AssetGenerator/AssetGenerator'),
);

const EmailReminder = React.lazy(
  () =>
    import(
      './pageTemplates/emailReminderTemplate/DynamicEmailReminderTemplate'
    ),
);
const LoginPage = React.lazy(() => import('./pages/Authentication/LoginPage'));

const CookiePolicy = React.lazy(() => import('./pages/CookiePolicy'));

const PathNotFoundPage = React.lazy(() => import('./pages/PathNotFoundPage'));

function TemplateComponent({ template }) {
  const ComponentToRender = TemplateList[template];
  return <ComponentToRender />;
}

const RoutingWrapper: FunctionComponent = () => {
  const courseContext = useSanityCourse();
  const { projectPages } = courseContext;
  const { mainPath, lastPartOfPath } = pathHelper;
  const getProjectPageRoutes = () => {
    if (!projectPages) {
      return null;
    }

    const projectPageRoutes = Object.entries(projectPages).map(
      ([key, value]) => {
        const projectPage = value;
        if (!projectPage) {
          return null;
        }
        const path = key.toLowerCase();
        return (
          <Route
            path={`${mainPath}/${path}`}
            key={path}
            element={
              <div>
                <TemplateComponent template={projectPage.template} />
              </div>
            }
          />
        );
      },
    );

    // eslint-disable-next-line react/jsx-one-expression-per-line
    return <> {projectPageRoutes} </>;
  };
  return (
    <Routes>
      <Route
        path={`${mainPath}/assetGenerator`}
        key="assetGenerator"
        element={
          <Suspense fallback={<>...</>}>
            <AssetGenerator />
          </Suspense>
        }
      />
      <Route
        path={`${mainPath}/email-reminder`}
        key="email-reminder"
        element={
          <Suspense fallback={<>...</>}>
            <EmailReminder />
          </Suspense>
        }
      />
      <Route
        path={`${mainPath}/login`}
        key="login"
        element={
          <Suspense fallback={null}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route
        path={`${mainPath}/cookiepolicy`}
        key="cookiepolicy"
        element={
          <Suspense fallback={<>...</>}>
            <CookiePolicy />
          </Suspense>
        }
      />

      {/* This is for testing of the race screen */}
      <Route path={`${mainPath}/raceScreen`} element={<RaceScreenTestPage />} />

      {getProjectPageRoutes()}
      <Route path={`${mainPath}`} key="landingPage" element={<LandingPage />} />
      {courseContext.course != null && courseContext.course.length > 0 ? (
        <>
          <Route
            path="/*"
            key="pathNotFound"
            element={
              <Suspense fallback={<>...</>}>
                <PathNotFoundPage />
              </Suspense>
            }
          />
          {courseContext.course.length > 0 &&
            courseContext.course.map((page) => {
              /* check if the template exists for each route */
              return typeof TemplateList[page.template] !== 'undefined' ? (
                <Route
                  key={page.url}
                  path={urlChecker(page.url)}
                  element={
                    <div key={page.url}>
                      <TemplateComponent template={page.template} />
                    </div>
                  }
                />
              ) : null;
            })}
        </>
      ) : null}
    </Routes>
  );
};
const Routing: FunctionComponent = () => {
  return (
    <Router>
      <SoloNavigationProvider>
        <ScormProvider>
          <Debug />
          <RoutingWrapper />
        </ScormProvider>
      </SoloNavigationProvider>
    </Router>
  );
};

export default Routing;
