import consoleDebug from '../../../helper/consoleDebug';
import { RaceGameChannelEvents, TeamChannelEvents } from './AblyRaceEvents';

/**
 * Logs the event and message published to Ably
 * For debugging purposes
 * @param event - The event that was published
 * @param message - The message that was published
 * @returns void
 */
const AblyDebugEventPublishedLogger = (
  event: RaceGameChannelEvents | TeamChannelEvents,
  message: unknown,
): void => {
  consoleDebug(`ABLY (Publish): ${event}`, message);
};

/**
 * Logs the event and message received from Ably
 * For debugging purposes
 * @param event - The event that was received
 * @param message - The message that was received
 *
 * @returns void
 */
const AblyDebugEventReceivedLogger = (
  event: RaceGameChannelEvents | TeamChannelEvents,
  message: unknown,
): void => {
  consoleDebug(`ABLY (Receive): ${event}`, message);
};

export default { AblyDebugEventPublishedLogger, AblyDebugEventReceivedLogger };
