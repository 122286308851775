/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-restricted-globals */
// SanityContext.tsx
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Favicon,
  PageWithTemplate,
  SanitySettings,
} from '../../pageTemplates/types/courseTypes';
import { Error } from '../sanity/Error';
import { SanityCourseContextProps } from '../sanity/sanityCourseContextProps';

export const SanityContext = React.createContext<SanityCourseContextProps>(
  {} as SanityCourseContextProps,
);

const handleFavicon = (faviconUrl?: string) => {
  const favicon = document.getElementById('favicon') as Favicon;

  if (favicon && faviconUrl !== undefined) {
    favicon.href = faviconUrl;
  }
};

const handleSanitySettings = (sanitySettings: SanitySettings) => {
  handleFavicon(sanitySettings.brandLogoUrl);
};

function SanityProviderMock({ children }: { children: ReactNode }) {
  const [course, setCourse] = useState<PageWithTemplate[]>([]);
  const [settings, setSettings] = useState<SanitySettings>(
    {} as SanitySettings,
  );

  const [projectPages, setProjectPages] = useState({});
  const [error, setError] = useState<Error | undefined>();
  const [supportedLanguages, setSupportedLanguages] = useState<string[]>([]);

  const loadContent = async () => {
    if (window.mock?.sanityProvider) {
      const content = window.mock?.sanityProvider;
      handleSanitySettings(content.settings);
      setSettings(content.settings);
      setProjectPages(content.projectPages);
      setCourse(content.course);
      setSupportedLanguages(content.supportedLanguages);
    }
  };

  // load data
  useEffect(() => {
    loadContent();
  }, []);

  const getCurrentPage = () => {
    const currentPath = window.location.pathname;
    const currentPageIndex = course.findIndex((a) => a.url === currentPath);

    return course[currentPageIndex];
  };

  const getProjectPage = () => {
    const currentPath = window.location.pathname;

    if (Object.keys(projectPages).length === 0) {
      return null;
    }
    const currentProjectPage = Object.entries(projectPages).find(
      (a) => a[0].toLowerCase() === currentPath.slice(1).toLowerCase(),
    );
    if (!currentProjectPage) {
      return null;
    }
    return projectPages[currentProjectPage[0]];
  };
  const value = useMemo(
    () => ({
      course,
      settings,
      error,
      projectPages,
      supportedLanguages,
      getCurrentPage,
      getProjectPage,
    }),
    [course, settings, error, projectPages],
  );
  return (
    <SanityContext.Provider value={value}>{children}</SanityContext.Provider>
  );
}

export default SanityProviderMock;
