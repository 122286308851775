import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ambulanceImage from '../assets/images/ambulance.svg';
import endWallImage from '../assets/images/endWall.svg';
import constants from '../constants/Constants';
import { useSanityCourse } from '../context/sanity/useSanityCourse';
import { AnimationState } from './TopBars/types';

interface AmbulanceProps {
  animationDurationInSeconds: number;
  animationDelayInSeconds: number;
  animationState?: AnimationState;
  start: boolean;
  onAnimationEnded?: () => void;
}

const vehicleAnimation = (width) => keyframes`
  0% {left:15px; top:0px;}
  100% {left:calc(${width}px - 40px); top:0px;}
`;

const pathAnimation = (width) => keyframes`
  0% {width:0px;}
  100% {width:calc(${width}px - 45px);}
`;

const Vehicle = styled.img<{
  animationDurationInSeconds: number;
  animationDelayInSeconds: number;
  width: number;
  start: boolean;
}>`
  width: 22px;
  height: 18px;
  margin-left: 15px;
  left: 15px;
  top: 0px;
  position: absolute;
  border: 0px;
  animation-name: ${(props) =>
    props.start ? vehicleAnimation(props.width) : ''};
  animation-duration: ${(props) => props.animationDurationInSeconds}s;
  animation-delay: ${(props) => props.animationDelayInSeconds}s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
`;
const animationStateTranslate = (animationState: AnimationState) => {
  switch (animationState) {
    case AnimationState.running:
      return 'running';
    case AnimationState.paused:
      return 'paused';
    default:
      return 'running';
  }
};

const Path = styled.div<{
  animationDurationInSeconds: number;
  animationDelayInSeconds: number;
  animationState?: AnimationState;
  width: number;
  start: boolean;
}>`
  width: 0px;
  height: 8px;
  border-radius: 24px;
  background-color: #fddee2;
  position: absolute;
  margin-left: 15px;
  left: 15px;
  top: 6px;
  animation-name: ${(props) => (props.start ? pathAnimation(props.width) : '')};
  animation-duration: ${(props) => props.animationDurationInSeconds}s;
  animation-delay: ${(props) => props.animationDelayInSeconds}s;
  ${(props) =>
    props.animationState
      ? `animation-play-state: ${animationStateTranslate(props.animationState)}`
      : ''};
  animation-fill-mode: forwards;
  animation-timing-function: linear;
`;

const Container = styled.div`
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
`;

const EndWall = styled.img`
  position: absolute;
  right: 0px;
  width: 8px;
  height: 27px;
`;

const StyledImg = styled.img`
  margin-left: -10px;
  max-height: 24px;
  height: 24px;
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AmbulanceAnimation = ({
  animationDurationInSeconds,
  animationDelayInSeconds,
  start,
  animationState,
  onAnimationEnded,
}: AmbulanceProps) => {
  const handleEndAnimation = () => {
    if (onAnimationEnded) onAnimationEnded();
  };
  const courseContext = useSanityCourse();
  const containerRef = useRef<any>();

  const [seconds, setSeconds] = useState(
    animationDurationInSeconds ?? constants.trainingTimeSec,
  );

  useEffect(() => {
    const counter = setInterval(() => {
      if (animationState === AnimationState.running) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(counter);
    };
  }, [animationState, seconds]);

  const width =
    containerRef && containerRef.current ? containerRef.current.offsetWidth : 0;
  return (
    <Container
      ref={containerRef}
      role="progressbar"
      aria-label={`ambulance coming. Time left ${seconds} seconds`}
      aria-valuemin={0}
      aria-valuemax={animationDurationInSeconds ?? constants.trainingTimeSec}
      aria-valuenow={seconds}
    >
      <StyledImg src={courseContext.settings.brandLogoUrl} alt="bhf heart" />
      <Path
        animationDurationInSeconds={animationDurationInSeconds}
        animationDelayInSeconds={animationDelayInSeconds}
        animationState={animationState}
        width={width}
        start={start}
        onAnimationEnd={handleEndAnimation}
      />
      <Vehicle
        width={width}
        animationDurationInSeconds={animationDurationInSeconds}
        animationDelayInSeconds={animationDelayInSeconds}
        start={start}
        src={ambulanceImage}
        alt="Ambulance"
      />
      <EndWall src={endWallImage} alt="End wall" />
    </Container>
  );
};

export default AmbulanceAnimation;
