import { FunctionComponent, useEffect, useState } from 'react';
import WelcomeImage from '../../assets/images/image_welcome.png';
import { WithFocus } from '../../components';
import LifeButton from '../../components/Button';
import { TimeLeftWithText } from '../../components/TopBars';
import WithFadeIn from '../../components/WithFadeIn';
import { useFirebase, useSanityCourse } from '../../context';
import { DisplayTeamName } from '../../features/groupRevivr/components';
import { useNavigation } from '../../hooks';
import { Img } from '../Templates/InfoPageStyles';
import PageWrapper, {
  BottomContentWithHeight,
  HeaderContentWrapper,
  HeaderImage,
  MainContentWrapper,
} from '../Templates/SimulationPageStyles';
import { RatingPageTemplate } from '../types/courseTypes';
import { SanityImage } from '../types/sanityTypes';
import Rating from './ConfidenceRating';

interface ImagePartProps {
  image?: SanityImage;
}
const RatingImagePart: FunctionComponent<ImagePartProps> = ({ image }) => {
  return image && image.src ? (
    <Img
      src={image.src}
      alt={image.alt || 'Rating page image'}
      imgType={image.imgType}
    />
  ) : (
    <HeaderImage
      src={WelcomeImage}
      maxWidth="100%"
      maxHeight="235px"
      alt={image?.alt || 'Woman performing compressions on man'}
    />
  );
};

type PageProps = {
  template?: RatingPageTemplate | undefined;
};

const SanityButton: FunctionComponent<{
  currentPage: RatingPageTemplate;
  confidenceRating: number;
  onSanityButtonClicked: () => void;
}> = ({ currentPage, confidenceRating, onSanityButtonClicked }) => {
  const { storeGAEvent } = useFirebase();

  return (
    <LifeButton
      style={{ width: '100%' }}
      variant={currentPage?.custombutton?.buttonType}
      onClick={() => {
        storeGAEvent({
          category: 'Confidence Rating',
          action: 'Confidence Selected',
          label: window.location.pathname,
          value: confidenceRating,
        });
        onSanityButtonClicked();
      }}
    >
      {currentPage?.custombutton?.text}
    </LifeButton>
  );
};

const DynamicRatingPage: FunctionComponent<PageProps> = ({ template }) => {
  const navigationContext = useNavigation();
  const [currentPage, setCurrentPage] = useState<RatingPageTemplate>(
    {} as RatingPageTemplate,
  );
  const [confidenceRating, setConfidenceRating] = useState(0);
  const [ratingText, setRatingText] = useState<string>('');
  const courseContext = useSanityCourse();

  const handleRating = (rating: number) => {
    setConfidenceRating(rating);
  };

  useEffect(() => {
    if (confidenceRating === 0) {
      setRatingText(currentPage.headingText);
    } else if (confidenceRating === 1) {
      setRatingText(currentPage.oneStarRatingText);
    } else if (confidenceRating === 2) {
      setRatingText(currentPage.twoStarRatingText);
    } else if (confidenceRating === 3) {
      setRatingText(currentPage.threeStarRatingText);
    } else if (confidenceRating === 4) {
      setRatingText(currentPage.fourStarRatingText);
    } else if (confidenceRating === 5) {
      setRatingText(currentPage.fiveStarRatingText);
    }
  }, [confidenceRating, currentPage]);

  useEffect(() => {
    const tempPage =
      template !== undefined
        ? template
        : courseContext.getCurrentPage().ratingPageTemplate;

    if (tempPage !== undefined) {
      setCurrentPage(tempPage);
    }
  }, []);

  const onSanityButtonClicked = () => {
    if (currentPage?.custombutton?.navigation === 'nextPage') {
      navigationContext?.navigateToNextPageWithSleepCheck();
      return;
    }
    if (currentPage?.custombutton?.navigation === 'previousPage') {
      navigationContext?.navigateToPreviousPage();
    }
    if (
      currentPage?.custombutton?.navigation === 'urlPage' &&
      currentPage?.custombutton?.url
    ) {
      navigationContext?.navigateToUrl(currentPage?.custombutton?.url);
    }
  };

  return (
    <PageWrapper>
      {navigationContext && (
        <HeaderContentWrapper>
          <DisplayTeamName enabled={currentPage.displayTeamName} />
          <TimeLeftWithText
            enabled={
              currentPage.showTimeLeftBar && !currentPage.displayTeamName
            }
          />
        </HeaderContentWrapper>
      )}
      <MainContentWrapper>
        <RatingImagePart image={currentPage.image} />
        <Rating
          altText={currentPage.ratingIconsAltText}
          mainText={ratingText}
          subText={currentPage.subheadingText}
          onRatingClick={handleRating}
          ratingIcons={currentPage.ratingIcons}
        />
      </MainContentWrapper>
      <BottomContentWithHeight role="navigation" aria-label="Main">
        {confidenceRating !== 0 && (
          <SanityButton
            currentPage={currentPage}
            confidenceRating={confidenceRating}
            onSanityButtonClicked={onSanityButtonClicked}
          />
        )}
      </BottomContentWithHeight>
    </PageWrapper>
  );
};

export default WithFadeIn(WithFocus(DynamicRatingPage));
