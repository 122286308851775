import {
  AEDDraggablePadPageTemplate,
  AedBubbleTemplate,
  AedTemplateProps,
  DynamicDialoguePageTemplateProps,
  EmailReminderTemplate,
  EmergencyCallTemplateProps,
  FrontPageTemplate,
  InputPageTemplate,
  LearnerFrontPageTemplate,
  LearnerShowMyTeamTemplate,
  QuizPageTemplate,
  RatingPageTemplate,
  SelectionTemplate,
  TextAndImageTemplate,
  TrainingTemplate,
  VideoPageTemplate,
} from '../../../pageTemplates/types/courseTypes';
import { DiplomaTemplate } from '../../../pageTemplates/types/diplomaTemplateTypes';
import { Section } from './section';
import {
  LearnerRoleSplitTemplate,
  LearnerTextAndImageTemplate,
  RaceTemplate,
} from './templates';
import {
  Audio,
  Document,
  LearnerTemplate,
  Organization,
  Slug,
  Template,
} from './types';

type NavigationTemplates = {
  aedBubbleTemplate?: AedBubbleTemplate;
  aedDraggablePadPageTemplate?: AEDDraggablePadPageTemplate;
  aedTemplate?: AedTemplateProps;
  dialogPageTemplate?: DynamicDialoguePageTemplateProps;
  diplomaTemplate?: DiplomaTemplate;
  emailReminderTemplate?: EmailReminderTemplate;
  emergencyCallTemplate?: EmergencyCallTemplateProps;
  frontPageTemplate?: FrontPageTemplate;
  inputTemplate?: InputPageTemplate;
  quizPageTemplate?: QuizPageTemplate;
  ratingPageTemplate?: RatingPageTemplate;
  selectionTemplate?: SelectionTemplate;
  textAndImageTemplate?: TextAndImageTemplate;
  trainingTemplate?: TrainingTemplate;
  videoPageTemplate?: VideoPageTemplate;
};

type LearnerNavigationTemplates = Omit<
  NavigationTemplates,
  'textAndImageTemplate'
> & {
  learnerFrontPageTemplate?: LearnerFrontPageTemplate;
  learnerRoleSplitTemplate?: LearnerRoleSplitTemplate;
  learnerShowMyTeamTemplate?: LearnerShowMyTeamTemplate;
  textAndImageTemplate: LearnerTextAndImageTemplate;
};
type FacilitatorNavigationTemplates = NavigationTemplates;

type CommonNavigationTemplates = RaceTemplate; // To add more templates do ( RaceTemplate | NewTemplate ) & WithTitle

export type LearnerNavigation = Document &
  LearnerNavigationTemplates & {
    id: string;
    organization: Organization;
    section: Section;
    sounds: Audio[];
    template: LearnerTemplate;
    timeToComplete: number;
    title: string;
  };

type FacilitatorNavigation = Document &
  FacilitatorNavigationTemplates & {
    title: string;
    organization: Organization;
    template: LearnerTemplate;
    section: Section;
  };

export type GroupNavigation = Document & {
  facilitatorNavigation: FacilitatorNavigation;
  learnerNavigation: LearnerNavigation;
  organization: Organization;
  section: Section;
  slug: Slug;
  title: string;
};
export type CommonNavigation = Document & {
  organization: Organization;
  section: Section;
  slug: Slug;
  template: CommonNavigationTemplates;
  title: Section;
};

// ts-prune-ignore-next
export type Navigation = Document & {
  title: string;
  slug: Slug;
  organization: Organization;
  template: Template;
  section: Section;
  sounds: Audio[];
  timeToComplete: number;
  skip: boolean;
  language: string;
};

type LocalizedNavigation<T extends LearnerNavigation | FacilitatorNavigation> =
  {
    translation: [
      {
        translations: [
          {
            value: T;
          },
        ];
      },
    ];
  };

type LocalizedLearnerNavigation = LocalizedNavigation<LearnerNavigation>;
type LocalizedFacilitatorNavigation =
  LocalizedNavigation<FacilitatorNavigation>;

export function isLocalizedGroupNavigation(
  groupNavigation: LocalizedGroupNavigation | GroupNavigation,
): groupNavigation is LocalizedGroupNavigation {
  return (
    'translation' in groupNavigation ||
    'translation' in groupNavigation.facilitatorNavigation ||
    'translation' in groupNavigation.learnerNavigation
  );
}

export type LocalizedGroupNavigation = {
  slug: Slug;
  learnerNavigation: LocalizedLearnerNavigation;
  facilitatorNavigation: LocalizedFacilitatorNavigation;
  translation: [
    {
      translations: [
        {
          value: {
            slug: {
              current: string;
            };
          };
        },
      ];
    },
  ];
};

export type LocalizedGroupProjectNavigation = {
  navigation: LocalizedGroupNavigation[];
};
