/* eslint-disable no-nested-ternary */
import {
  createClient,
  type ClientConfig,
  type SanityClient,
} from '@sanity/client';
import constants from '../../constants/Constants';
import { getLocaleCodeForSanity } from './localeHelper';
import {
  facilitatorGroupNavigationWithIdAndLocalizationGROQuery,
  facilitatorGroupNavigationWithIdGROQuery,
  facilitatorGroupProjectWithIdAndLocalizationGROQuery,
  facilitatorGroupProjectWithIdGROQuery,
  getGroupProjectSupportedLanguagesGROQQuery,
  getProjectSupportedLanguagesGROQQuery,
  learnerGroupNavigationWithIdAndLocalizationGROQuery,
  learnerGroupNavigationWithIdGROQuery,
  learnerGroupProjectWithIdAndLocalizationGROQuery,
  learnerGroupProjectWithIdGROQuery,
  learnerNavigationWithLearnerRoleSplitTemplate,
  navigationWithIdAndLocalizationGROQuery,
  navigationWithIdGROQuery,
  projectWithIdAndLocalizationGROQuery,
  projectWithIdGROQuery,
  settingsWithIdGROQuery,
} from './sanityGroqQueryGenerator';

const { SANITY_KEY, SANITY_PROJECT_KEY } = constants.queryKeys;

export const buildSanityClient = (clientForPreview: boolean): SanityClient => {
  const config: ClientConfig = {
    projectId: constants.sanity.PROJECT_ID, // you can find this in sanity.json
    dataset: constants.sanity.DATASET, // or the name you chose in step 1
    useCdn: clientForPreview ? false : constants.sanity.USE_CDN, // `false` if you want to ensure fresh data
    withCredentials: !!clientForPreview,
    apiVersion: 'v1', // use current date (YYYY-MM-DD) to target the latest API version
  };
  const client = createClient(config);
  return client;
};

const getProjectWithIdGROQuery = (organizationId: string) => {
  return window.revivr.isGroupRevivr
    ? window.revivr.isLearner
      ? learnerGroupProjectWithIdGROQuery(organizationId)
      : facilitatorGroupProjectWithIdGROQuery(organizationId)
    : projectWithIdGROQuery(organizationId);
};

const getProjectWithIdAndLocalizationGROQuery = (
  organizationId: string,
  language: string,
) => {
  return window.revivr.isGroupRevivr
    ? window.revivr.isLearner
      ? learnerGroupProjectWithIdAndLocalizationGROQuery(
          organizationId,
          language,
        )
      : facilitatorGroupProjectWithIdAndLocalizationGROQuery(
          organizationId,
          language,
        )
    : projectWithIdAndLocalizationGROQuery(organizationId, language);
};

const getNavigationWithIdGROQuery = (organizationId: string) => {
  return window.revivr.isGroupRevivr
    ? window.revivr.isLearner
      ? learnerGroupNavigationWithIdGROQuery(organizationId)
      : facilitatorGroupNavigationWithIdGROQuery(organizationId)
    : navigationWithIdGROQuery(organizationId);
};

const getNavigationWithIdAndLocalizationGROQuery = (
  organizationId: string,
  language: string,
) => {
  return window.revivr.isGroupRevivr
    ? window.revivr.isLearner
      ? learnerGroupNavigationWithIdAndLocalizationGROQuery(
          organizationId,
          language,
        )
      : facilitatorGroupNavigationWithIdAndLocalizationGROQuery(
          organizationId,
          language,
        )
    : navigationWithIdAndLocalizationGROQuery(organizationId, language);
};

export interface API<T> {
  fetchAsync: () => Promise<T>;
}

export type FetchProject<T> = API<T>;
export type FetchSettings<T> = API<T>;
export type FetchSupportedLanguages<T> = API<T>;

export type FetchPagesWithTemplates<T> = API<T>;

const getBaseLanguageProjectPages = async (
  currQueryStrings,
  organizationProjectId,
) => {
  const client = buildSanityClient(currQueryStrings?.sanity === 'drafts');
  const res = await client.fetch(
    getProjectWithIdGROQuery(organizationProjectId),
  );
  return res;
};

const getLocalizeFacilitatorProjectPages = (
  localizeRes,
  currQueryStrings,
  organizationId,
) => {
  if (
    localizeRes.projectPages?.facilitatorLandingPage?.translation[0]
      ?.translations[0]?.value
  ) {
    return localizeRes;
  }
  return getBaseLanguageProjectPages(currQueryStrings, organizationId);
};

const getLocalizeLearnerProjectPages = (
  localizeRes,
  currQueryStrings,
  organizationId,
) => {
  if (
    localizeRes.projectPages?.learnerLandingPage?.translation[0]
      ?.translations[0]?.value
  ) {
    return localizeRes;
  }
  return getBaseLanguageProjectPages(currQueryStrings, organizationId);
};

const getLocalizeSoloProjectPages = (
  localizeRes,
  currQueryStrings,
  organizationId,
) => {
  if (
    localizeRes.projectPages?.landingPage?.translation[0]?.translations[0]
      ?.value
  ) {
    return localizeRes;
  }
  return getBaseLanguageProjectPages(currQueryStrings, organizationId);
};

const getBaseLanguageNavigation = async (
  currQueryStrings,
  organizationProjectId,
) => {
  const client = buildSanityClient(currQueryStrings?.sanity === 'drafts');
  const res = await client.fetch(
    getNavigationWithIdGROQuery(organizationProjectId),
  );
  return res;
};

const getLocalizeFacilitatorNavigation = (
  localizeRes,
  currQueryStrings,
  organizationProjectId,
) => {
  if (
    localizeRes.navigation[0]?.facilitatorNavigation?.translation &&
    localizeRes.navigation[0]?.facilitatorNavigation?.translation[0]
      ?.translations[0]?.value
  ) {
    return localizeRes;
  }
  return getBaseLanguageNavigation(currQueryStrings, organizationProjectId);
};

const getLocalizeLearnerNavigation = (
  localizeRes,
  currQueryStrings,
  organizationProjectId,
) => {
  if (
    localizeRes.navigation[0]?.learnerNavigation?.translation &&
    localizeRes.navigation[0]?.learnerNavigation?.translation[0]
      ?.translations[0]?.value
  ) {
    return localizeRes;
  }
  return getBaseLanguageNavigation(currQueryStrings, organizationProjectId);
};

const getLocalizeSoloNavigation = (
  localizeRes,
  currQueryStrings,
  organizationProjectId,
) => {
  if (
    localizeRes.navigation[0]?.translation &&
    localizeRes.navigation[0]?.translation[0]?.translations[0]?.value
  ) {
    return localizeRes;
  }
  return getBaseLanguageNavigation(currQueryStrings, organizationProjectId);
};

const getLocalizeProjectPages = async (
  currQueryStrings,
  organizationProjectId,
) => {
  const language = getLocaleCodeForSanity();
  const client = buildSanityClient(currQueryStrings?.sanity === 'drafts');
  if (language !== constants.localeCode.EN) {
    const localizeRes = await client.fetch(
      getProjectWithIdAndLocalizationGROQuery(organizationProjectId, language),
    );

    if (window.revivr.isGroupRevivr) {
      if (window.revivr.isLearner) {
        // Learner Project Pages
        return getLocalizeLearnerProjectPages(
          localizeRes,
          currQueryStrings,
          organizationProjectId,
        );
      }
      // Facilitator Project Pages
      return getLocalizeFacilitatorProjectPages(
        localizeRes,
        currQueryStrings,
        organizationProjectId,
      );
    }
    // Solo Project Pages
    return getLocalizeSoloProjectPages(
      localizeRes,
      currQueryStrings,
      organizationProjectId,
    );
  }
  return getBaseLanguageProjectPages(currQueryStrings, organizationProjectId);
};

export const fetchProject = (
  organizationProjectId: string,
): FetchProject<any> => {
  const params = new URLSearchParams(window.location.search);
  const currQueryStrings = {
    sanity: params.get(SANITY_KEY),
    project: params.get(SANITY_PROJECT_KEY),
  };

  const client = buildSanityClient(currQueryStrings?.sanity === 'drafts');

  const fetchAsync = async () => {
    if (currQueryStrings && currQueryStrings.sanity === 'drafts') {
      const draftOrganizationProjectId = `drafts.${organizationProjectId}`;
      const draftRes = await client.fetch(
        getProjectWithIdGROQuery(draftOrganizationProjectId),
      );
      if (draftRes !== null) {
        return draftRes;
      }
    }
    return getLocalizeProjectPages(currQueryStrings, organizationProjectId);
  };

  return {
    fetchAsync,
  };
};

export const fetchLanguages = (
  organizationProjectId: string,
): FetchSupportedLanguages<any> => {
  const params = new URLSearchParams(window.location.search);
  const currQueryStrings = {
    sanity: params.get(SANITY_KEY),
    project: params.get(SANITY_PROJECT_KEY),
  };

  const client = buildSanityClient(currQueryStrings?.sanity === 'drafts');

  const fetchAsync = async () => {
    if (window.revivr.isGroupRevivr) {
      const data = await client.fetch(
        getGroupProjectSupportedLanguagesGROQQuery(organizationProjectId),
      );
      const languages =
        data?.[0]?.projectPages?.facilitatorLandingPage?.translation?.[0]?.translations?.map(
          (lang) => {
            return lang?.value?.language;
          },
        );
      return languages;
    }
    const data = await client.fetch(
      getProjectSupportedLanguagesGROQQuery(organizationProjectId),
    );
    const languages =
      data?.[0]?.projectPages?.landingPage?.translation?.[0]?.translations?.map(
        (lang) => {
          return lang?.value?.language;
        },
      );
    return languages;
  };
  return {
    fetchAsync,
  };
};

const getLocalizeNavigation = async (
  currQueryStrings,
  organizationProjectId,
) => {
  const language = getLocaleCodeForSanity();
  const client = buildSanityClient(currQueryStrings?.sanity === 'drafts');
  if (language !== constants.localeCode.EN) {
    // Get translated project pages to check if landing page exist
    const localizeProjectRes = await client.fetch(
      getProjectWithIdAndLocalizationGROQuery(organizationProjectId, language),
    );
    const localizeRes = await client.fetch(
      getNavigationWithIdAndLocalizationGROQuery(
        organizationProjectId,
        language,
      ),
    );
    if (window.revivr.isGroupRevivr) {
      if (
        window.revivr.isLearner &&
        localizeProjectRes.projectPages?.learnerLandingPage?.translation[0]
          ?.translations[0]?.value
      ) {
        return getLocalizeLearnerNavigation(
          localizeRes,
          currQueryStrings,
          organizationProjectId,
        );
      }
      // For Facilitator Navigation Pages
      if (
        !window.revivr.isLearner &&
        localizeProjectRes.projectPages?.facilitatorLandingPage?.translation[0]
          ?.translations[0]?.value
      ) {
        return getLocalizeFacilitatorNavigation(
          localizeRes,
          currQueryStrings,
          organizationProjectId,
        );
      }
    }
    // For Solo Navigation Pages
    if (
      !window.revivr.isGroupRevivr &&
      localizeProjectRes.projectPages?.landingPage?.translation[0]
        ?.translations[0]?.value
    ) {
      return getLocalizeSoloNavigation(
        localizeRes,
        currQueryStrings,
        organizationProjectId,
      );
    }
  }
  return getBaseLanguageNavigation(currQueryStrings, organizationProjectId);
};

export const fetchNavigation = (
  organizationProjectId: string,
): FetchProject<any> => {
  const params = new URLSearchParams(window.location.search);
  const currQueryStrings = {
    sanity: params.get(SANITY_KEY),
    project: params.get(SANITY_PROJECT_KEY),
  };

  const client = buildSanityClient(currQueryStrings?.sanity === 'drafts');

  const fetchAsync = async () => {
    if (currQueryStrings && currQueryStrings.sanity === 'drafts') {
      const draftOrganizationProjectId = `drafts.${organizationProjectId}`;
      const draftRes = await client.fetch(
        getNavigationWithIdGROQuery(draftOrganizationProjectId),
      );
      if (draftRes !== null) {
        return draftRes;
      }
    }
    return getLocalizeNavigation(currQueryStrings, organizationProjectId);
  };
  return {
    fetchAsync,
  };
};
const createFetcher =
  (queryFunc: (id: string | string[]) => any) =>
  (id: string | string[]): API<any> => {
    const params = new URLSearchParams(window.location.search);
    const currQueryStrings = {
      sanity: params.get(SANITY_KEY),
      project: params.get(SANITY_PROJECT_KEY),
    };
    const client = buildSanityClient(currQueryStrings?.sanity === 'drafts');
    const fetchAsync = async () => {
      const fullQuery = queryFunc(id);
      return Array.isArray(fullQuery)
        ? Promise.all(fullQuery.map((chunk) => client.fetch(chunk))).then(
            (queriedChunks) => queriedChunks.flat(),
          )
        : client.fetch(fullQuery);
    };
    return {
      fetchAsync,
    };
  };

export const fetchSettings = createFetcher(settingsWithIdGROQuery);
export const fetchLearnerTeamSplitPage = createFetcher(
  learnerNavigationWithLearnerRoleSplitTemplate,
);
