import { IconButton, SystemIcons } from '@laerdal/life-react-components';
import React, { FunctionComponent } from 'react';
import { LanguageButton } from '../../pageTemplates/Templates/InfoPageStyles';
import { HorizontalAlignment } from '../../pageTemplates/types/courseTypes';

const getGroupLanguageIconAlignment = (
  alignment: HorizontalAlignment | undefined,
): HorizontalAlignment => {
  if (alignment === 'right') {
    return 'left';
  }
  return 'right';
};

interface LanguageIconProps {
  alignment?: HorizontalAlignment | undefined;
  onClick: () => void;
}

const LanguageIcon: FunctionComponent<LanguageIconProps> = ({
  alignment,
  onClick,
}): JSX.Element => {
  return (
    <LanguageButton alignment={getGroupLanguageIconAlignment(alignment)}>
      <IconButton variant="secondary" shape="square" action={() => onClick()}>
        <SystemIcons.Language size="24px" />
      </IconButton>
    </LanguageButton>
  );
};
export default LanguageIcon;
