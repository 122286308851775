import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { AedPadsHelpModal } from '../../components/Modals/Modals';
import { TimeLeftWithText } from '../../components/TopBars';
import WithFadeIn from '../../components/WithFadeIn';
import WithFocus from '../../components/WithFocus';
import {
  FireBaseCollections,
  useFirebase,
} from '../../context/FirebaseProvider';
import { useSanityCourse } from '../../context/sanity/useSanityCourse';
import { DisplayTeamName } from '../../features/groupRevivr/components';
import { isObjectEmpty } from '../../helper';
import PageWrapper, {
  HeaderContentWrapperExtended,
} from '../Templates/SimulationPageStyles';
import { AEDDraggablePadPageTemplate } from '../types/courseTypes';
import AedHelp from './AedHelp';
import AedPadPlacement from './AedPadPlacement';
import {
  AedPadRelativeWidth,
  CorrectPlacementArea,
  ImageSize,
  PadPosition,
  PercentageString,
  getCorrectArea,
  getInitialDeltaPosition,
  getPadInitialValue,
  initializeBodyImage,
  initializePadCorrectPosition,
  initializePadPosition,
  isPadPlacementCorrect,
} from './AedPadUtil';
import AedResult from './AedResult';

type PageProps = {
  template?: AEDDraggablePadPageTemplate;
};

const DynamicAedDraggablePadPage: FunctionComponent<PageProps> = ({
  template,
}) => {
  const pageContext = useSanityCourse();
  const { storeCollection } = useFirebase();

  const bodyImage = useRef<HTMLImageElement>(null);
  const [rightPadImage, setRightPadImage] = useState<string>('');
  const [leftPadImage, setLeftPadImage] = useState<string>('');
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [bodyImageSize, setBodyImageSize] = useState<ImageSize>(
    initializeBodyImage(),
  );
  const [deltaPositionLeftPad, setDeltaPositionLeftPad] = useState<PadPosition>(
    initializePadPosition(),
  );
  const [deltaPositionRightPad, setDeltaPositionRightPad] =
    useState<PadPosition>(initializePadPosition());
  const [leftPadInitialValue, setLeftPadInitialValue] = useState<PadPosition>(
    initializePadPosition(),
  );
  const [rightPadInitialValue, setRightPadInitialValue] = useState<PadPosition>(
    initializePadPosition(),
  );
  const [padPlacementCorrect, setPadPlacementCorrect] =
    useState<boolean>(false);
  const [leftPadFinalPosition, setLeftPadFinalPosition] = useState<PadPosition>(
    initializePadPosition(),
  );
  const [rightPadFinalPosition, setRightPadFinalPosition] =
    useState<PadPosition>(initializePadPosition());
  const [leftPadCorrectPosition, setLeftPadCorrectPosition] =
    useState<CorrectPlacementArea>(initializePadCorrectPosition());
  const [rightPadCorrectPosition, setRightPadCorrectPosition] =
    useState<CorrectPlacementArea>(initializePadCorrectPosition());
  const [currentPage, setCurrentPage] = useState<AEDDraggablePadPageTemplate>(
    {} as AEDDraggablePadPageTemplate,
  );
  const [dataLoaded, setDataLoaded] = useState(false);

  const handleOnStop = () => {
    setLeftPadFinalPosition({
      x: leftPadInitialValue.x + deltaPositionLeftPad.x,
      y: leftPadInitialValue.y + deltaPositionLeftPad.y,
    });
    setRightPadFinalPosition({
      x: rightPadInitialValue.x + deltaPositionRightPad.x,
      y: rightPadInitialValue.y + deltaPositionRightPad.y,
    });
  };
  const [aedPadSize, setAedPadSize] = useState<AedPadRelativeWidth>({
    rightPadWidth: '25%',
    leftPadWidth: '20%',
  });

  const reset = () => {
    setLeftPadCorrectPosition(getCorrectArea(bodyImageSize, true, pageContext));
    setRightPadCorrectPosition(
      getCorrectArea(bodyImageSize, false, pageContext),
    );
    setLeftPadInitialValue(getPadInitialValue(bodyImageSize, true));
    setRightPadInitialValue(getPadInitialValue(bodyImageSize, false));
    setLeftPadFinalPosition(getPadInitialValue(bodyImageSize, true));
    setRightPadFinalPosition(getPadInitialValue(bodyImageSize, false));
    setDeltaPositionLeftPad(getInitialDeltaPosition());
    setDeltaPositionRightPad(getInitialDeltaPosition());
  };

  const handleImageOnLoad = () => {
    const imageWidth = bodyImage.current === null ? 0 : bodyImage.current.width;
    const imageHeight =
      bodyImage.current === null ? 0 : bodyImage.current.height;
    setBodyImageSize({
      width: imageWidth,
      height: imageHeight,
    });
  };

  useEffect(() => {
    reset();
  }, [bodyImageSize.height, bodyImageSize.width]);

  useEffect(() => {
    setPadPlacementCorrect(
      isPadPlacementCorrect(
        leftPadFinalPosition,
        rightPadFinalPosition,
        leftPadCorrectPosition,
        rightPadCorrectPosition,
        currentPage?.useOnlyOnePad,
        currentPage?.enforceStrictPadPlacement,
      ),
    );
  }, [leftPadFinalPosition, rightPadFinalPosition]);

  const handleOnDragLeftPad = (e, ui) => {
    const { x, y } = deltaPositionLeftPad;
    setDeltaPositionLeftPad({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const handleOnDragRightPad = (e, ui) => {
    const { x, y } = deltaPositionRightPad;
    setDeltaPositionRightPad({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    const tempPage =
      template !== undefined
        ? template
        : pageContext.getCurrentPage().aedDraggablePadPageTemplate;
    if (tempPage !== undefined) {
      setCurrentPage(tempPage);
      setRightPadImage(tempPage.aedRightPad?.src ?? '');
      setLeftPadImage(tempPage.aedLeftPad?.src ?? '');
      setDataLoaded(true);
    }
    setAedPadSize(() => {
      const padAreaData =
        pageContext?.getCurrentPage().aedDraggablePadPageTemplate
          ?.aedPadPlacementValues;

      let leftPadWidth: PercentageString;

      if (tempPage?.useOnlyOnePad) {
        leftPadWidth = '0%';
      } else if (padAreaData && padAreaData.leftPadSize) {
        leftPadWidth = `${padAreaData.leftPadSize * 20}%`;
      } else {
        leftPadWidth = '20%';
      }

      const rightPadWidth: PercentageString = padAreaData?.rightPadSize
        ? `${padAreaData.rightPadSize * 25}%`
        : `25%`;

      const padSizes: AedPadRelativeWidth = {
        rightPadWidth,
        leftPadWidth,
      };

      return padSizes;
    });
  }, []);

  const storePadsPlacementToFirebase = () => {
    const padsData = {
      padPlacementWithinRange: padPlacementCorrect,
      leftPadPosition: {
        x: leftPadFinalPosition.x,
        y: leftPadFinalPosition.y,
      },
      rightPadPosition: {
        x: rightPadFinalPosition.x,
        y: rightPadFinalPosition.y,
      },
      leftPadCorrectPosition: {
        x: leftPadCorrectPosition.x,
        y: leftPadCorrectPosition.y,
      },
      rightPadCorrectPosition: {
        x: rightPadCorrectPosition.x,
        y: rightPadCorrectPosition.y,
      },
    };
    storeCollection(FireBaseCollections.aedPadsPlacementData, padsData).finally(
      () => setShowResult(true),
    );
  };

  const showResultClicked = () => {
    storePadsPlacementToFirebase();
  };

  const showHelpClicked = () => {
    setShowHelpModal(true);
  };
  return isObjectEmpty(currentPage) ? null : ( // Making sure we have received some data from Sanity before we render.
    <PageWrapper>
      <HeaderContentWrapperExtended>
        <TimeLeftWithText enabled={currentPage.showTimeLeftBar} />
        <DisplayTeamName enabled={currentPage.displayTeamName} />
      </HeaderContentWrapperExtended>
      {/* Show help modal */}
      <AedPadsHelpModal
        modalData={currentPage?.aedPadHelpModal}
        isVisible={showHelpModal}
        toggleModal={() => setShowHelpModal(false)}
        whereToPlacePadsClicked={() => {
          setShowHelpModal(false);
          setShowHelp(true);
        }}
        practiseNowClicked={() => setShowHelpModal(false)}
      />
      {/* Show help screen */}
      {showHelp && (
        <AedHelp
          pageData={currentPage}
          practiseAgainClicked={() => {
            setShowHelp(false);
            setShowResult(false);
            reset();
          }}
        />
      )}
      {/* show Result screen */}
      {!showHelp && showResult && (
        <AedResult
          aedPadSize={aedPadSize}
          HelpClicked={() => {
            setShowResult(false);
            setShowHelpModal(true);
          }}
          leftPadCorrectPosition={leftPadCorrectPosition}
          leftPadPosition={leftPadFinalPosition}
          padPlacementWithinRange={padPlacementCorrect}
          pageData={currentPage}
          practiseAgainClicked={() => {
            setRightPadImage(
              pageContext.getCurrentPage().aedDraggablePadPageTemplate
                ?.aedRightPad?.src ?? '',
            );
            setShowResult(false);
            reset();
          }}
          rightPadCorrectPosition={rightPadCorrectPosition}
          rightPadImage={rightPadImage}
          rightPadPosition={rightPadFinalPosition}
        />
      )}
      {/* show Pad Placement screen */}
      {!showHelp && !showResult && (
        <AedPadPlacement
          aedPadSize={aedPadSize}
          bodyImage={{ ref: bodyImage }}
          handleImageOnLoad={handleImageOnLoad}
          handleOnDragLeftPad={handleOnDragLeftPad}
          handleOnDragRightPad={handleOnDragRightPad}
          handleOnStop={handleOnStop}
          leftPadImage={leftPadImage}
          leftPadInitialValue={leftPadInitialValue}
          pageData={currentPage}
          rightPadImage={rightPadImage}
          rightPadInitialValue={rightPadInitialValue}
          setRightPadImage={setRightPadImage}
          showHelpClicked={showHelpClicked}
          showResultClicked={showResultClicked}
        />
      )}
    </PageWrapper>
  );
};
export default WithFadeIn(WithFocus(DynamicAedDraggablePadPage));
