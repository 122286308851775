import constants, { Area, Position, Size } from '../../constants/Constants';
import { SanityCourseContextProps } from '../../context/sanity/sanityCourseContextProps';

export const calculateWidth = (): number => {
  return window.innerWidth < constants.maxPageWidthMobile
    ? window.innerWidth
    : constants.maxPageWidthMobile;
};

export const calculateHeight = (): number => {
  return Math.floor(calculateWidth() * 0.9);
};

export type PadPosition = Position;
export type CorrectPlacementArea = Area;
export type ImageSize = Size;
export type PercentageString = `${number}%`;

export type AedPadRelativeWidth = {
  leftPadWidth: PercentageString;
  rightPadWidth: PercentageString;
};

export const initializePadPosition = (): PadPosition => {
  return {
    x: 0,
    y: 0,
  };
};

export const initializeBodyImage = (): ImageSize => {
  return {
    width: 0,
    height: 0,
  };
};

export const initializePadCorrectPosition = (): CorrectPlacementArea => {
  return {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };
};

export const getCorrectArea = (
  bodyImageSize: ImageSize,
  isLeftArea: boolean,
  sanityContext?: SanityCourseContextProps,
): CorrectPlacementArea => {
  const padValues =
    sanityContext?.getCurrentPage().aedDraggablePadPageTemplate
      ?.aedPadPlacementValues;
  const leftPadDefault = constants.padAreaDefaultValues.LeftAreaDefaultValues;
  const rightPadDefault = constants.padAreaDefaultValues.RightAreaDefaultValues;

  const areaXPosition = isLeftArea
    ? padValues?.leftPadAreaXValue ?? leftPadDefault.x
    : padValues?.rightPadAreaXValue ?? rightPadDefault.x;
  const areaYPosition = isLeftArea
    ? padValues?.leftPadAreaYValue ?? leftPadDefault.y
    : padValues?.rightPadAreaYValue ?? rightPadDefault.y;
  const areaWidth = isLeftArea
    ? padValues?.leftPadAreaWidth ?? leftPadDefault.width
    : padValues?.rightPadAreaWidth ?? rightPadDefault.width;
  const areaHeight = isLeftArea
    ? padValues?.leftPadAreaHeight ?? leftPadDefault.height
    : padValues?.rightPadAreaHeight ?? rightPadDefault.height;

  return {
    x: (bodyImageSize.width * areaXPosition) / 100,
    y: (bodyImageSize.height * areaYPosition) / 100,
    width: areaWidth,
    height: areaHeight,
  };
};
export const getPadInitialValue = (
  bodyImageSize: ImageSize,
  isLeftPad: boolean,
): PadPosition => {
  const x = isLeftPad
    ? (bodyImageSize.width * 8) / 100
    : (bodyImageSize.width * 27) / 100;
  const y = isLeftPad
    ? (bodyImageSize.height * 55) / 100
    : (bodyImageSize.height * 60) / 100;
  return { x, y };
};

export const getInitialDeltaPosition = (): PadPosition => {
  return {
    x: 0,
    y: 0,
  };
};

const isInsideArea = (position: Position, area: Area): boolean => {
  return (
    position.x >= area.x &&
    position.x <= area.x + area.width &&
    position.y >= area.y &&
    position.y <= area.y + area.height
  );
};

const isOppositePadPlacementCorrect = (
  leftCorrectArea: CorrectPlacementArea,
  rightCorrectArea: CorrectPlacementArea,
  leftPadCenterPosition: PadPosition,
  rightPadCenterPosition: PadPosition,
) => {
  const leftPadInsideRightArea = isInsideArea(
    leftPadCenterPosition,
    rightCorrectArea,
  );
  const rightPadInsideLeftArea = isInsideArea(
    rightPadCenterPosition,
    leftCorrectArea,
  );

  return leftPadInsideRightArea && rightPadInsideLeftArea;
};

const isStrictPadPlacementCorrect = (
  leftCorrectArea: CorrectPlacementArea,
  rightCorrectArea: CorrectPlacementArea,
  leftPadCenterPosition: PadPosition,
  rightPadCenterPosition: PadPosition,
  useOnlyOnePad: boolean,
) => {
  const leftPadInsideLeftArea = isInsideArea(
    leftPadCenterPosition,
    leftCorrectArea,
  );
  const rightPadInsideRightArea = isInsideArea(
    rightPadCenterPosition,
    rightCorrectArea,
  );

  return (leftPadInsideLeftArea || useOnlyOnePad) && rightPadInsideRightArea;
};

const isFlexiblePadPlacementCorrect = (
  leftCorrectArea: CorrectPlacementArea,
  rightCorrectArea: CorrectPlacementArea,
  leftPadCenterPosition: PadPosition,
  rightPadCenterPosition: PadPosition,
  useOnlyOnePad: boolean,
) => {
  const isStrictPlacementCorrect = isStrictPadPlacementCorrect(
    leftCorrectArea,
    rightCorrectArea,
    leftPadCenterPosition,
    rightPadCenterPosition,
    useOnlyOnePad,
  );
  const isOppositePlacementCorrect = isOppositePadPlacementCorrect(
    leftCorrectArea,
    rightCorrectArea,
    leftPadCenterPosition,
    rightPadCenterPosition,
  );

  return isStrictPlacementCorrect || isOppositePlacementCorrect;
};

export const isPadPlacementCorrect = (
  leftPadFinalPosition: PadPosition,
  rightPadFinalPosition: PadPosition,
  leftCorrectArea: CorrectPlacementArea,
  rightCorrectArea: CorrectPlacementArea,
  useOnlyOnePad: boolean,
  enforceStrictPadPlacement: boolean,
): boolean => {
  // The height of the pads are set dynamically, so we need to get the height from the DOM for calculations.
  const leftPadWidth = document.getElementById('leftPad')?.offsetWidth ?? 109;
  const leftPadHeight = document.getElementById('leftPad')?.offsetHeight ?? 109;
  const rightPadHeight =
    document.getElementById('rightPad')?.offsetHeight ?? 79;
  const rightPadWidth = document.getElementById('rightPad')?.offsetWidth ?? 79;

  // The origin of all are the top left corner. Here we are converting the position to be in the center of the pad images.
  const leftPadCenterPosition: PadPosition = {
    x: leftPadFinalPosition.x + leftPadWidth * 0.5,
    y: leftPadFinalPosition.y + leftPadHeight * 0.5,
  };
  const rightPadCenterPosition: PadPosition = {
    x: rightPadFinalPosition.x + rightPadWidth * 0.5,
    y: rightPadFinalPosition.y + rightPadHeight * 0.5,
  };

  return enforceStrictPadPlacement
    ? isStrictPadPlacementCorrect(
        leftCorrectArea,
        rightCorrectArea,
        leftPadCenterPosition,
        rightPadCenterPosition,
        useOnlyOnePad,
      )
    : isFlexiblePadPlacementCorrect(
        leftCorrectArea,
        rightCorrectArea,
        leftPadCenterPosition,
        rightPadCenterPosition,
        useOnlyOnePad,
      );
};
