import React from 'react';
import styled, { css } from 'styled-components';
import { useSanityCourse } from '../../context';
import LearnerURLFromSanityOrLocal from '../../features/groupRevivr/helper/LearnerURLFromDevOpsOrLocal';
import mediaMedium from '../../pageTemplates/Templates/MediaMedium';
import { SanityGroupSettings } from '../../pageTemplates/types/courseTypes';
import BrandIcon from './BrandIcon';

/**
 * Container which displays the components in the header when the screen is large.
 */
const HeaderForFacilitatorLargeScreenContainer = styled.div`
  display: none;
  grid-column: 2/-2;
  grid-row: 1/2;

  ${mediaMedium(css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 6px;
    align-items: center;
  `)}
`;

const HeaderText = styled.h2`
  font-family: ${(props) => props.theme.fonts.primaryFont};
  margin-right: 24px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.theme.colors.neutral_800};
`;

const DisplayCode = ({ codeText }: { codeText: string }) => {
  return (
    <HeaderText>{`${codeText} ${window.revivr.code?.toString()}`}</HeaderText>
  );
};

const ClassroomCodeAndURL: React.FunctionComponent = (): JSX.Element => {
  const { settings } = useSanityCourse();
  const groupSettings = settings as SanityGroupSettings;
  if (window.revivr?.isLearner === true || undefined) {
    return <> </>;
  }
  const groupData = groupSettings?.customJoinUrlAndText;

  const UrlPrefixText = groupData?.customJoinUrlText ?? 'Go to:';
  const codeText = groupSettings?.joinCodeText ?? 'Code:';

  return (
    <HeaderForFacilitatorLargeScreenContainer>
      <BrandIcon />
      <HeaderText>
        {`${UrlPrefixText} `}
        <u>{LearnerURLFromSanityOrLocal()}</u>
      </HeaderText>
      <DisplayCode codeText={codeText} />
    </HeaderForFacilitatorLargeScreenContainer>
  );
};

export default ClassroomCodeAndURL;
