/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FunctionComponent, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import LaerdalFooter from '../../components/LaerdalFooter/LaerdalFooter';
import UseFocus from '../../components/UseFocus';
import WithFadeIn from '../../components/WithFadeIn';
import WithFocus from '../../components/WithFocus';
import constants from '../../constants/Constants';
import { useFirebase, useSanityCourse, useScorm, useUser } from '../../context';
import { FireBaseCollections } from '../../context/FirebaseProvider';
import { sanityTypes } from '../../features/sanity';
import { browserVersionIsMinimumSupportedVersion } from '../../helper';
import { useNavigation } from '../../hooks';
import InfoPage from '../../pageTemplates/Templates/InfoPage';
import { Button } from '../../pageTemplates/Templates/InfoPageTypes';
import ClickableCardsPage from '../../pageTemplates/clickableCardTemplate/ClickableCardsPage';
import FrontPage from '../../pageTemplates/frontPageTemplate/FrontPage';
import { PageWithTemplate } from '../../pageTemplates/types/courseTypes';
import { SanityButton } from '../../pageTemplates/types/sanityTypes';
import { LocalStorageService, isAndroidWebView } from '../../services';

const { buttonNavigationTypes } = sanityTypes;
enum ProjectPages {
  landingPage,
  browserNotSupported,
  pageForPcUsers,
  clickableCardPage,
}

const LandingPage: FunctionComponent = () => {
  const {
    navigateToDefaultAndroidBrowser,
    navigateToUrl,
    navigateToFirstPage,
  } = useNavigation();
  const [renderedPage, setRenderedPage] = useState<
    PageWithTemplate | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = useState<ProjectPages | undefined>(
    undefined,
  );
  const [specifiedUrl, setSpecifiedUrl] = useState<string | undefined>(
    undefined,
  );

  const [pageNavigated, setPageNavigated] = useState<boolean>(false);
  const userContext = useUser();
  const courseContext = useSanityCourse();
  const { storeCollection } = useFirebase();

  const { isScorm } = useScorm();

  useEffect(() => {
    UseFocus();
  }, [renderedPage]);

  useEffect(() => {
    setRenderedPage(courseContext.projectPages.landingPage);
    setCurrentPage(ProjectPages.landingPage);
    LocalStorageService.setTrainingTypeToLocalStorage(
      constants.trainingMode.SOLO,
    );
  }, []);

  const navigateToNextPageOrUrl = (url?: string) => {
    setSpecifiedUrl(url);
    if (currentPage === ProjectPages.landingPage) {
      if (isAndroidWebView()) {
        navigateToDefaultAndroidBrowser();
      } else if (
        !browserVersionIsMinimumSupportedVersion() &&
        courseContext.projectPages.browserNotSupported
      ) {
        setRenderedPage(courseContext.projectPages.browserNotSupported);
        setCurrentPage(ProjectPages.browserNotSupported);
      } else if (
        isDesktop &&
        courseContext.projectPages.pageForPcUsers &&
        !isScorm
      ) {
        setRenderedPage(courseContext.projectPages.pageForPcUsers);
        setCurrentPage(ProjectPages.pageForPcUsers);
      } else if (specifiedUrl) {
        navigateToUrl(specifiedUrl);
      } else {
        navigateToFirstPage();
      }
    } else if (specifiedUrl) {
      navigateToUrl(specifiedUrl);
    } else {
      navigateToFirstPage();
    }
  };
  const navigateToPreviousPage = () => {
    if (currentPage !== ProjectPages.landingPage) {
      setRenderedPage(courseContext.projectPages.landingPage);
      setCurrentPage(ProjectPages.landingPage);
    }
  };

  const mappedButtons = (buttons?: SanityButton[]): Button[] | undefined => {
    return buttons?.map((button) => {
      return {
        text: button.text,
        buttonType: button.buttonType,
        onClick: () => {
          if (button.navigation === buttonNavigationTypes.nextPage) {
            // only click to firebase the first time we click on next page
            setPageNavigated(true);
            if (renderedPage === courseContext.projectPages.landingPage) {
              storeCollection(
                FireBaseCollections.queryStrings,
                userContext.queryStrings,
              );
            }
            navigateToNextPageOrUrl();
          } else if (button.navigation === buttonNavigationTypes.previousPage) {
            navigateToPreviousPage();
          } else if (
            button.navigation === buttonNavigationTypes.urlPage &&
            button.url
          ) {
            navigateToNextPageOrUrl(button.url);
          }
        },
      } as Button;
    });
  };
  if (!renderedPage) {
    return null;
  }

  switch (renderedPage.template) {
    case 'textAndImageTemplate':
      return (
        <InfoPage
          image={renderedPage.textAndImageTemplate?.image}
          buttons={mappedButtons(renderedPage.textAndImageTemplate?.buttons)}
          text={renderedPage.textAndImageTemplate?.text}
          showTimeLeftBar={renderedPage.textAndImageTemplate?.showTimeLeftBar}
          verticalButtons={renderedPage.textAndImageTemplate?.verticalButtons}
        />
      );
    case 'frontPageTemplate':
      return (
        <FrontPage
          image={renderedPage.frontPageTemplate?.image}
          banner={renderedPage.frontPageTemplate?.banner}
          buttons={mappedButtons(renderedPage.frontPageTemplate?.buttons)}
          text={renderedPage.frontPageTemplate?.text}
          bottomContent={
            <LaerdalFooter
              displayVersionNumber={
                renderedPage.frontPageTemplate?.showVersionNumber
              }
            />
          }
          verticalButtons={renderedPage.frontPageTemplate?.verticalButtons}
          supportedLanguages={courseContext?.supportedLanguages}
          enableLanguageSelectionField={
            renderedPage.frontPageTemplate?.enableLanguageSelectionField
          }
          languageModelHeadingText={
            renderedPage.frontPageTemplate?.languageModal?.headingText
          }
          languageModelButtonText={
            renderedPage.frontPageTemplate?.languageModal?.buttonText
          }
          pageNavigated={pageNavigated}
        />
      );
    case 'clickableCardsTemplate':
      return (
        <ClickableCardsPage
          banner={renderedPage.clickableCardsTemplate?.banner}
          text={renderedPage.clickableCardsTemplate?.text}
          cards={renderedPage.clickableCardsTemplate?.cards}
          bottomContent={
            <LaerdalFooter
              displayVersionNumber={
                renderedPage.clickableCardsTemplate?.showVersionNumber
              }
            />
          }
        />
      );
    default:
      return null;
  }
};
export default WithFadeIn(WithFocus(LandingPage));
