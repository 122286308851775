/* eslint-disable react/self-closing-comp */
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Banner, BannerImage } from '../../components';
import LifeButton from '../../components/Button';
import { FooterText } from '../../components/LaerdalFooter/FooterStyles';
import { ModalProps } from '../../components/Modals';
import ModalForGrid from '../../components/Modals/Modal';
import { LanguageSelectionModal } from '../../components/Modals/Modals';
import QRCodeImage from '../../components/QRCodeImage/QRCodeImage';
import { LanguageIcon } from '../../components/TopBars';
import { useHeightViewport, useSanityCourse, useScorm } from '../../context';
import {
  getLanguagesList,
  updateScreenWithNewSelectedLanguage,
} from '../../features/sanity/localeHelper';
import {
  BottomContainer,
  ButtonContainer,
  Img,
  PageGridStyle,
  StyledText,
} from '../Templates/InfoPageStyles';
import { Button, Image, ImageTypes } from '../Templates/InfoPageTypes';
import { Text } from '../Templates/Text';

export interface FrontPageProps {
  banner?: Banner;
  bottomContent?: React.ReactNode;
  buttons?: Button[];
  image?: Image;
  modal?: ModalProps;
  text?: Text;
  verticalButtons?: boolean;
  supportedLanguages?: string[];
  enableLanguageSelectionField?: boolean;
  languageModelHeadingText?: string;
  languageModelButtonText?: string;
  pageNavigated?: boolean;
}

const ImagePart: FunctionComponent<{
  image: Image | undefined;
  isScorm: boolean;
}> = ({ image, isScorm }) => {
  if (image?.imgType === ImageTypes.QRCode && !isScorm) {
    return (
      <QRCodeImage url={image.src} alt={image.alt ? image.alt : 'A QR Code'} />
    );
  }
  return image ? (
    <Img
      src={image.src}
      alt={image.alt ? image.alt : 'Some image'}
      imgType={image.imgType}
    />
  ) : null;
};

const FrontPage: FunctionComponent<FrontPageProps> = ({
  banner,
  bottomContent,
  buttons,
  image,
  modal,
  text,
  verticalButtons = false,
  supportedLanguages,
  enableLanguageSelectionField,
  languageModelHeadingText,
  languageModelButtonText,
  pageNavigated,
}: FrontPageProps) => {
  const { innerHeight } = useHeightViewport();
  const { isScorm } = useScorm();

  const [showLanguageSelection, setShowLanguageSelection] = useState(false);
  const [SelectedLanguage, setSelectedLanguage] = useState<string | undefined>(
    '',
  );
  const [courseLoading, setCourseLoading] = useState<boolean>(true);
  const { testingFlag } = useFlags();
  const headerTextForABTesting = 'Learn the steps to save a life';
  const bodyTextForABTesting =
    'RevivR is the 15-minute mobile training that teaches you lifesaving skills to help save a life. All you need is a cushion.';

  const courseContext = useSanityCourse();

  useEffect(() => {
    updateScreenWithNewSelectedLanguage(SelectedLanguage);
  }, [SelectedLanguage]);

  useEffect(() => {
    if (courseContext.course && courseContext.course.length > 0) {
      setCourseLoading(false);
    }
  }, [courseContext.course]);

  return (
    <PageGridStyle height={innerHeight} imgType={image?.imgType}>
      {modal && !Array.isArray(modal) && (
        <ModalForGrid
          buttons={modal.buttons}
          onClose={modal.onClose}
          text={modal.text}
          image={modal.image}
          bannerImage={modal.bannerImage}
          verticalButtons={modal.verticalButtons}
        />
      )}

      <LanguageSelectionModal
        headerText={languageModelHeadingText}
        buttonText={languageModelButtonText}
        isVisible={showLanguageSelection}
        languageList={getLanguagesList(supportedLanguages)}
        toggleModal={(value) => {
          setShowLanguageSelection(false);
          setSelectedLanguage(value);
        }}
      />

      <>
        <BannerImage banner={banner} />
        {enableLanguageSelectionField && (
          <LanguageIcon onClick={() => setShowLanguageSelection(true)} />
        )}

        <ImagePart image={image} isScorm={isScorm} />

        {text && testingFlag && (
          <StyledText>
            <h1 tabIndex={-1}>{headerTextForABTesting}</h1>
            {bodyTextForABTesting}
          </StyledText>
        )}

        {text && !testingFlag && (
          <StyledText>
            <h1 tabIndex={-1}>{text.header}</h1>
            {typeof text.body === 'string' ? (
              <p>
                {text.body}
                <strong>{text.emphasized}</strong>
              </p>
            ) : (
              text.body
            )}
          </StyledText>
        )}

        <ButtonContainer
          role="navigation"
          aria-label="Main"
          verticalButtons={verticalButtons}
        >
          {buttons
            ? buttons.map((button: Button) => {
                return (
                  <LifeButton
                    key={`button_${button.buttonType}_${button.text}`}
                    variant={button.buttonType}
                    loading={courseLoading && pageNavigated}
                    onClick={() => {
                      button.onClick();
                    }}
                  >
                    {button.text}
                    {button.icon ? <button.icon size="18px" /> : ''}
                  </LifeButton>
                );
              })
            : null}
        </ButtonContainer>

        <BottomContainer>
          <FooterText>{text?.footerText}</FooterText>
          {bottomContent}
        </BottomContainer>
      </>
    </PageGridStyle>
  );
};

export default FrontPage;
