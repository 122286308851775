/* eslint-disable no-nested-ternary */
import {
  defaultOnMouseDownHandler,
  focusStyles,
  SystemIcons,
} from '@laerdal/life-react-components';
import { FunctionComponent, ReactNode } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useTheme } from '../../context';

export interface SubmittedAnswer {
  isSubmitted: boolean;
  isCorrect?: boolean;
}

const wiggleJiggle = keyframes`  
  10%, 90% {  
    transform: translate3d(-1px, 0, 0);  
  }   
  20%, 80% {  
    transform: translate3d(2px, 0, 0);  
  }   
  30%, 50%, 70% {  
    transform: translate3d(-4px, 0, 0);  
  }  
  40%, 60% {  
    transform: translate3d(4px, 0, 0);  
  }  
`;
const jiggleAnimation = css`
  ${wiggleJiggle} 0.82s cubic-bezier(.36,.07,.19,.97) both
`;

const StyledButton = styled.button<{
  isCorrect?: boolean;
  isAnswerSubmitted?: boolean;
  isSelected?: boolean;
}>`
  margin-bottom: 16px;
  background: ${(props) =>
    props.isCorrect
      ? props.theme.colors.green_100
      : props.theme.colors.critical_20};
  ${(props) =>
    props.isAnswerSubmitted
      ? ''
      : props.isSelected
      ? `background: ${props.theme.colors.neutral_200};`
      : `background: ${props.theme.colors.neutral_20};`}
  width: 100%;
  border-radius: 8px;
  border: none;
  display: flex;
  padding: 0;

  animation: ${(props) =>
    !props.isCorrect && props.isAnswerSubmitted ? jiggleAnimation : 'none'};

  &:focus,
  &.focus-state {
    ${() => focusStyles}
  }
`;

const QuizButtonText = styled.div<{ font?: string }>`
  width: 100%;
  min-width: min-content;
  margin-left: 28px;
  margin-right: 16px;
  align-items: center;
  align-self: center;
  display: flex;
  p {
    margin: 5px 0;
    font-family: ${(props) => props.font};
    font-size: 1.8rem;
    line-height: 140%;
    /* or 25px */

    display: flex;
    text-align: left;

    /* Brand / Black */

    color: ${(props) => props.theme.colors.neutral_800};
  }
`;
const QuizButtonIconContainer = styled.div<{
  isCorrect?: boolean;
  isAnswerSubmitted?: boolean;
  isSelected?: boolean;
}>`
  background: ${(props) =>
    props.isCorrect
      ? props.theme.colors.green_600
      : props.theme.colors.critical_400};
  ${(props) =>
    props.isAnswerSubmitted
      ? ''
      : props.isSelected
      ? `background: ${props.theme.colors.neutral_300};`
      : `background: ${props.theme.colors.neutral_200};`}
  padding: 15px;
  display: flex;
  align-self: stretch;
  border-radius: 8px 0px 0px 8px;
  svg {
    align-self: center;
  }
`;

export interface QuizButtonProps {
  children?: ReactNode;
  isSelected?: boolean;
  isSurvey?: boolean;
  submittedAnswer?: SubmittedAnswer;
  onClick?: () => void;
  isCorrect?: boolean;
  id?: string;
}
const surveyIcons = (beenAnswered: boolean, color?: string) => {
  if (!beenAnswered) {
    return <SystemIcons.CheckboxOff size="24px" color={color} />;
  }
  return <SystemIcons.CheckboxOn size="24px" color={color} />;
};

const correctIcon = (correct?: boolean, color?: string) => {
  if (correct) {
    return <SystemIcons.CheckMark size="24px" color={color} />;
  }
  return <SystemIcons.Close size="24px" color={color} />;
};
const quizIcons = (
  isSelected: boolean,
  submittedAnswer?: SubmittedAnswer,
  quizIconcolor?: string,
  correctIconColor?: string,
) => {
  if (!isSelected) {
    return <SystemIcons.RadioButtonOff size="24px" color={quizIconcolor} />;
  }
  if (submittedAnswer && submittedAnswer.isSubmitted) {
    return correctIcon(submittedAnswer.isCorrect, correctIconColor);
  }
  return <SystemIcons.RadioButtonOn size="24px" color={quizIconcolor} />;
};

const insertIcon = (
  isSelected: boolean,
  isSurvey: boolean,
  submittedAnswer?: SubmittedAnswer,
  surveyIconColor?: string,
  quizIconColor?: string,
  correctIconColor?: string,
) => {
  if (isSurvey) {
    return surveyIcons(isSelected, surveyIconColor);
  }
  return quizIcons(
    isSelected,
    submittedAnswer,
    quizIconColor,
    correctIconColor,
  );
};

const QuizButton: FunctionComponent<QuizButtonProps> = ({
  children,
  isSelected = false,
  isSurvey = false,
  onClick,
  id,
  submittedAnswer,
}) => {
  const buttonClick = () => {
    if (submittedAnswer?.isSubmitted) {
      return;
    }
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  const themeContext = useTheme();
  const colors = themeContext.getColors();
  const { secondaryFont } = themeContext.getFonts();

  return (
    <StyledButton
      isSelected={isSelected}
      isAnswerSubmitted={isSelected && submittedAnswer?.isSubmitted}
      isCorrect={submittedAnswer?.isCorrect && submittedAnswer.isSubmitted}
      type="button"
      id={id}
      aria-checked={isSelected}
      role="radio"
      onClick={buttonClick}
      onMouseDown={defaultOnMouseDownHandler}
    >
      <QuizButtonIconContainer
        isSelected={isSelected}
        isAnswerSubmitted={isSelected && submittedAnswer?.isSubmitted}
        isCorrect={submittedAnswer?.isCorrect && submittedAnswer.isSubmitted}
      >
        {insertIcon(
          isSelected,
          isSurvey,
          submittedAnswer,
          colors.neutral_800,
          colors.neutral_800,
          colors.white,
        )}
      </QuizButtonIconContainer>
      <QuizButtonText font={secondaryFont}>
        <p>{children}</p>
      </QuizButtonText>
    </StyledButton>
  );
};

export default QuizButton;
