/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  defaultOnMouseDownHandler,
  focusStyles,
} from '@laerdal/life-react-components';
import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import mediaMedium from '../Templates/MediaMedium';

interface MyState {
  blink: boolean;
  clicked: boolean;
  ariaButtonDescription: string;
  type: 'shock' | 'on';
}

interface MyProps {
  onClick?: () => void;
  blink?: boolean;
  src?: string;
  style?: CSSProperties;
  alt?: string;
  type: 'shock' | 'on';
  className?: any;
  innerWidth: number;
  id?: string;
}

const Button = styled.button<{ width?: number }>`
  border: none;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  background-color: transparent;

  &:focus,
  &.focus-state {
    ${(props) => focusStyles}
  }
`;

const ButtonImage = styled.img<{
  isVisible: boolean;
  innerWidth: number;
  type: 'shock' | 'on';
}>`
  opacity: ${(props) => (props.isVisible ? 1 : 0)};

  ${(props) =>
    props.type === 'on'
      ? `

      :hover {
        opacity: 1;
      }
      `
      : ''}
  width: 100%;
`;

const generateAriaDescription = (type) => {
  return `${type === 'shock' ? 'flashing' : ''} ${type}`;
};

export default class AEDButton extends React.Component<MyProps, MyState> {
  private wrapperDiv: React.RefObject<HTMLButtonElement>;

  private blinkingInterval;

  constructor(props) {
    super(props);
    this.wrapperDiv = React.createRef();

    this.state = {
      blink: false,
      clicked: false,
      type: props.type,
      ariaButtonDescription: generateAriaDescription(props.type),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.type !== prevState.type) {
      return {
        ariaButtonDescription: generateAriaDescription(nextProps.type),
      };
    }

    return null;
  }

  componentDidUpdate(prevProps: MyProps) {
    const { blink } = this.props;
    if (prevProps.blink !== blink) {
      this.toggleBlinking();
    }
  }

  componentWillUnmount() {
    clearInterval(this.blinkingInterval);
  }

  buttonClicked = () => {
    const { onClick } = this.props;
    if (onClick) {
      this.setState({ clicked: true });
      onClick();
    }
  };

  toggleBlinking = () => {
    if (this.blinkingInterval) {
      clearInterval(this.blinkingInterval);
    }
    const { blink } = this.props;
    if (blink) {
      this.blinkingInterval = window.setInterval(() => {
        this.setState((prevState) => {
          return {
            blink: !prevState.blink,
          };
        });
      }, 500);
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  blink() {
    this.setState({ blink: true });
    setTimeout(() => {
      this.setState({ blink: false });
    }, 500);
  }

  render() {
    const { blink, clicked, ariaButtonDescription } = this.state;
    const { src, innerWidth, style, type, className, id, alt } = this.props;
    return (
      <Button
        onMouseDown={defaultOnMouseDownHandler}
        aria-label={ariaButtonDescription}
        className={className}
        ref={this.wrapperDiv}
        style={style}
        onClick={this.buttonClicked}
        id={id || type}
      >
        <ButtonImage
          alt={alt}
          type={type}
          innerWidth={innerWidth}
          isVisible={blink || clicked}
          src={src}
        />
      </Button>
    );
  }
}

const ButtonImg = styled(AEDButton)<{
  type: 'shock' | 'on';
  innerWidth: number;
  maxHeight?: string;
}>`
  ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight};` : '')}
  grid-column: 1;
  grid-row: 1;
  align-self: flex-start;
  width: 25.2%;
  margin-left: 15.3%;
  margin-top: 58.6%;

  ${(props) =>
    props.type === 'shock'
      ? `
    margin-top: 97.6%;
    `
      : ''}
  ${(props) =>
    mediaMedium(css`
      height: auto;
      align-content: center;
      ${props.type === 'shock' ? 'margin-top: 97.2%;' : ''};
    `)}
    justify-self: center;
`;

export const AEDButtonWrapper = ({
  aedButtonImg,
  operatorImage,
  innerWidth,
  buttonRef,
}) => {
  if (aedButtonImg && operatorImage) {
    return (
      <ButtonImg
        ref={buttonRef}
        src={aedButtonImg.src}
        alt={aedButtonImg.alt ? aedButtonImg.alt : 'Aed Button'}
        innerWidth={innerWidth}
        type={aedButtonImg.buttonType}
        onClick={() => {
          if (aedButtonImg.onClick) {
            aedButtonImg.onClick();
          }
        }}
        blink={aedButtonImg.blink}
      />
    );
  }
  return null;
};
