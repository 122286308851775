import { IconProps } from '@laerdal/life-react-components';
import { AnimationState } from '../../components/TopBars/types';

export enum ButtonTypes {
  Primary = 'primary',
  Secondary = 'secondary',
}

export type Button = {
  id?: string;
  text: string;
  buttonType?: ButtonTypes | 'primary' | 'secondary'; // Kept the strings for backward compatibility..
  loading?: boolean;
  onClick: () => void;
  icon?: React.FunctionComponent<IconProps>;
};

export enum ImageTypes {
  Icon = 'ICON',
  FullWidth = 'FULLWIDTH',
  QRCode = 'QRCODE',
  imageWithCustomSize = 'IMAGEWITHCUSTOMSIZE',
  Lottie = 'LOTTIE',
  webCam = 'WEBCAM',
}
export interface Image {
  src?: string;
  alt?: string;
  imgType?: ImageTypes;
}
export interface ImageWithSize extends Image {
  size?: string;
  fullWidth?: boolean;
  onClick?: () => void;
}

export interface ImageWithCustomSize extends Image {
  height?: string;
  width?: string;
  fullWidth?: boolean;
  onClick?: () => void;
}

export interface CountDown {
  duration: number;
  delay?: number;
  animationState: AnimationState;
  setAnimRef?: React.RefObject<HTMLDivElement>;
}

export interface Video {
  ref?: React.RefObject<HTMLVideoElement>;
}
