/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-restricted-globals */
// SanityContext.tsx
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  fetchNavigation,
  fetchProject,
} from '../../features/sanity/SanityFetch';
import SanityService from '../../features/sanity/SanityService';
import consoleDebug from '../../helper/consoleDebug';
import {
  getProjectIdFromFlag,
  isLaunchDarklyEnabled,
  launchDarklyFlushEvents,
} from '../../helper/launchDarklyHelper';
import { pathHelper } from '../../helper/pathHelper';
import {
  FacilitatorPageWithTemplate,
  Favicon,
  LearnerPageWithTemplate,
  PageWithTemplate,
  ProjectPages,
  SanitySettings,
} from '../../pageTemplates/types/courseTypes';
import { logError } from '../../services/errorHandling';
import { useUser } from '../UserProvider';
import { Error } from './Error';
import { SanityCourseContextProps } from './sanityCourseContextProps';

export const CourseContext = React.createContext<SanityCourseContextProps>(
  {} as SanityCourseContextProps,
);

const handleFavicon = (faviconUrl?: string) => {
  const favicon = document.getElementById('favicon') as Favicon;

  if (favicon && faviconUrl !== undefined) {
    favicon.href = faviconUrl;
  }
};

const handleSanitySettings = (sanitySettings: SanitySettings) => {
  handleFavicon(sanitySettings.brandLogoUrl);
};

function SanityProvider({ children }: { children: ReactNode }) {
  const [course, setCourse] = useState<
    | PageWithTemplate[]
    | FacilitatorPageWithTemplate[]
    | LearnerPageWithTemplate[]
  >([]);
  const [settings, setSettings] = useState<SanitySettings>(
    {} as SanitySettings,
  );

  const userContext = useUser();
  const [projectPages, setProjectPages] = useState([] as ProjectPages);
  const [error, setError] = useState<Error | undefined>();
  const [supportedLanguages, setSupportedLanguages] = useState<string[]>([]);
  const [sanityDataLoaded, setSanityDataLoaded] = useState<boolean>(false);
  const { moduleExperimentFlag } = useFlags();
  const ldClient = useLDClient();

  const getProjectIdFromLDOrUserContext = (): string => {
    const sanityProjectId = getProjectIdFromFlag(
      moduleExperimentFlag,
      userContext.sanityProjectId,
    );
    if (sanityProjectId == moduleExperimentFlag) {
      // LaunchDarkly is changing sanity projectId used for fetching content. We also need to update the user context.
      // So other parts of the app can use the correct projectId.
      consoleDebug(
        `LaunchDarkly is changing sanity projectId from [${userContext.sanityProjectId}] to [${sanityProjectId}]`,
      );
      userContext.setProjectId(sanityProjectId);
    }
    return sanityProjectId;
  };

  const loadContent = async () => {
    try {
      const content = await SanityService.getProject(
        fetchProject(getProjectIdFromLDOrUserContext()),
      );
      handleSanitySettings(content.settings as SanitySettings);
      setSettings(content.settings);
      setProjectPages(content.projectPages);
      setSupportedLanguages(content.supportedLanguages);
    } catch (exception: unknown) {
      setError({
        errorCode: 'ERRORLOADINGCOURSE',
        errorMessage: exception,
      } as Error);
      logError(exception, undefined, { errorCode: 'ERRORLOADINGCOURSE' });
      throw exception;
    }
  };

  const loadSanityData = async () => {
    await Promise.all([loadContent(), loadCourse()]);
    setSanityDataLoaded(true);
  };

  useEffect(() => {
    if (sanityDataLoaded) {
      window.sanity.sanityContent.course = course;
    }
  }, [sanityDataLoaded, course]);

  useEffect(() => {
    if (
      !userContext.launchDarklyClientIsInitialised &&
      isLaunchDarklyEnabled()
    ) {
      return;
    }
    launchDarklyFlushEvents(ldClient, userContext);
    loadSanityData();
  }, [userContext.launchDarklyClientIsInitialised]);

  const getCurrentPage = () => {
    const currentPath = window.location.pathname;
    const currentPageIndex = course.findIndex((a) => a.url === currentPath);

    return course[currentPageIndex];
  };

  const loadCourse = async () => {
    const mappedCourse = await SanityService.getNavigationCourse(
      fetchNavigation(getProjectIdFromLDOrUserContext()),
    );
    setCourse(mappedCourse);
  };

  const getProjectPage = () => {
    const { lastPartOfPath } = pathHelper;

    if (Object.keys(projectPages).length === 0) {
      return null;
    }

    const currentProjectPage = Object.entries(projectPages).find(
      (a) => a[0].toLowerCase() === lastPartOfPath.toLowerCase(),
    );
    if (!currentProjectPage) {
      return null;
    }
    return projectPages[currentProjectPage[0]];
  };
  const value = useMemo(
    () => ({
      course,
      settings,
      error,
      projectPages,
      supportedLanguages,
      getCurrentPage,
      getProjectPage,
    }),
    [course, settings, error, projectPages],
  );
  return (
    <CourseContext.Provider value={value}>{children}</CourseContext.Provider>
  );
}

export default SanityProvider;
