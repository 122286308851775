import { useRef, useState } from 'react';
import consoleDebug from '../../helper/consoleDebug';
import RaceScreen from './facilitator/components/RaceScreen';
import { REFRESH_RATE_MS } from './facilitator/constants';
import { calculateScoreBasedOnRate } from './helpers';
import {
  getTeamAmbulanceImage,
  getTeamColor,
} from './helpers/teamAssetSelectors';
import { GameChannelStates } from './providers/AblyRaceEvents';
import { Team } from './providers/Types';

const TOTAL_TEAMS = 16;
const AMBULANCE_INITIAL_POSITION = 20;
const TEAM_SPEED = [
  110, 90, 80, 90, 80, 90, 95, 80, 90, 100, 80, 90, 130, 140, 135, 140,
];

const getTeamsInitialData = (): Team[] => {
  return Array.from({ length: TOTAL_TEAMS }).map((team: Team, index) => {
    const newData: Team = {
      name: (index + 1).toString(),
      score: AMBULANCE_INITIAL_POSITION,
      color: getTeamColor((index + 1).toString()),
      ambulanceImage: getTeamAmbulanceImage((index + 1).toString()),
      learner1: undefined,
      learner2: undefined,
    };
    return newData;
  });
};

const RaceScreenTestPage = () => {
  const timerRef = useRef<number>();
  const [durationInSeconds, setDurationInSeconds] = useState<number>(0);
  const [teams, setTeams] = useState<Team[]>(getTeamsInitialData());

  const getTeamsCprScore = (): Team[] => {
    return teams.map((team, index) => {
      const newData: Team = {
        ...team,
        score: calculateScoreBasedOnRate(TEAM_SPEED[index]),
      };
      return newData;
    });
  };

  const setTimer = () => {
    if (!timerRef.current) {
      setDurationInSeconds(Date.now());
      timerRef.current = window.setInterval(() => {
        setTeams(getTeamsCprScore());
      }, REFRESH_RATE_MS);
      consoleDebug(`TimerRef.current: ${timerRef.current}`);
    }
  };

  const stopTimer = () => {
    if (timerRef.current) {
      const timeElapsedInSeconds = (Date.now() - durationInSeconds) / 1000;
      consoleDebug('************* Stoptime called ************');
      consoleDebug(
        `TimerRef.current: ${timerRef.current}, Race duration: ${timeElapsedInSeconds}`,
      );
      clearInterval(timerRef.current);
      timerRef.current = undefined;
    }
  };

  const raceCallback = (status: GameChannelStates) => {
    if (status === GameChannelStates.race_start) {
      setTimer();
    } else {
      stopTimer();
    }
  };

  return (
    <RaceScreen
      teams={teams}
      onGameStateChange={raceCallback}
      onTeamCompleted={(team, completionTime) => {
        consoleDebug(
          `********* Team Completed: ${team} in seconds: ${
            (completionTime - durationInSeconds) / 1000
          } **********`,
        );
      }}
    />
  );
};
export default RaceScreenTestPage;
