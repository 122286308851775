import { useState } from 'react';
import { styled } from 'styled-components';
import { useSanityCourse } from '../../context';

const BlurFilter = styled.div<{
  enableFilter: boolean;
}>`
  filter: ${(props) =>
    props.enableFilter ? 'blur(2px) contrast(40%) saturate(150%)' : 'none'};
`;

const CameraFilter = ({ children }) => {
  const sanityContext = useSanityCourse();
  const [filterEnabled, setFilterEnabled] = useState(
    sanityContext.settings.enableCameraFilter,
  );

  const toggleFilter = () => {
    if (sanityContext.settings.enableCameraFilter) {
      setFilterEnabled(!filterEnabled);
    }
  };

  return (
    <BlurFilter enableFilter={filterEnabled} onClick={toggleFilter}>
      {children}
    </BlurFilter>
  );
};

export default CameraFilter;
