import React, { FunctionComponent, useEffect, useState } from 'react';
import { ModalProps } from '../../components/Modals';
import { ShareModal } from '../../components/Modals/Modals';
import WithFadeIn from '../../components/WithFadeIn';
import WithFocus from '../../components/WithFocus';
import { useCamera } from '../../context';
import { useSanityCourse } from '../../context/sanity/useSanityCourse';
import useAskForCameraPermission from '../../features/navigation/useAskForCameraPermission';
import { sanityFunctions } from '../../features/sanity';
import { createTextAndImageBody } from '../../features/sanity/SanityServiceMapper';
import {
  convertShareNotSupportedData,
  copiedShareText,
  getBodyText,
  useShowSharePopup,
} from '../../features/socialShare/socialShare';
import { useNavigation } from '../../hooks';
import { Button } from '../Templates/InfoPageTypes';
import { DiplomaTemplate } from '../types/diplomaTemplateTypes';
import {
  SanityCameraPermission,
  SanityModal,
  SanityShare,
  SanityShareNotSupported,
} from '../types/sanityTypes';
import DiplomaPageTemplate from './DiplomaTemplate';
import { DiplomaModalTypes } from './DiplomaTypes';

const DynamicDiplomaPage: FunctionComponent = () => {
  const [bodyText, setBodyText] = useState<string>('');
  const [buttons, setButtons] = useState<Button[] | undefined>(undefined);
  const [copyTextData, setCopyTextData] = useState<
    SanityShareNotSupported | undefined
  >();
  const [currentPage, setCurrentPage] = useState<DiplomaTemplate | undefined>();
  const [modal, setModal] = useState<ModalProps | undefined>();
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const cameraContext = useCamera();
  const navigationContext = useNavigation();
  const pageContext = useSanityCourse();
  const askForCameraPermission = useAskForCameraPermission();
  const showSharePopup = useShowSharePopup();

  const showModal = (
    modalType?: DiplomaModalTypes,
    sanityModal?: SanityModal,
  ) => {
    let modalData;
    switch (modalType) {
      case 'success':
        modalData = currentPage?.successModal;
        break;
      case 'error':
        modalData = currentPage?.errorModal;
        break;
      case 'default':
      default:
        modalData = sanityModal;
        break;
    }
    if (modalData === undefined) {
      setModal(undefined);
      return;
    }

    const mappedButtons = sanityFunctions.createButtonsFromSanityButtons(
      modalData.buttons,
      navigationContext,
      (internalSanityModal?: SanityModal | undefined) => {
        showModal('default', internalSanityModal);
      },
    );
    const mappedModal: ModalProps = {
      text: {
        header: modalData.text?.header,
        header2: modalData.text?.header2,
        body: createTextAndImageBody(
          modalData.text?.body,
          modalData.text?.bulletPoints,
        ),
      },
      bannerImage: modalData.bannerImage,
      buttons: mappedButtons,
      verticalButtons: modalData.verticalButtons,
      onClose: () => {
        if (modalType === 'success' && mappedButtons?.length) {
          mappedButtons[0].onClick();
        }
        showModal(undefined);
      },
    };
    setModal(mappedModal);
  };

  const showShare = (shareData: SanityShare) => {
    const webShare: any = window.navigator;
    if (webShare.share) {
      showSharePopup(shareData);
    } else {
      setCopyTextData(convertShareNotSupportedData(shareData?.copyTextModal));
      setBodyText(getBodyText(shareData?.copyTextModal?.body));
    }
  };
  useEffect(() => {
    if (copyTextData !== undefined) {
      setShowShareModal(true);
    }
  }, [copyTextData]);
  const showOpenInBrowserModal = (browserModal: SanityModal) => {
    const mappedModal: ModalProps = {
      onClose: () => {
        setModal(undefined);
      },
      text: {
        header: browserModal.text?.header,
        body: createTextAndImageBody(
          browserModal?.text?.body,
          browserModal?.text?.bulletPoints,
        ),
      },
      bannerImage: {
        src: browserModal.bannerImage?.src,
        alt: browserModal.bannerImage?.alt,
        fullWidth: true,
      },
      buttons: sanityFunctions.createButtonsFromSanityButtons(
        browserModal.buttons,
        navigationContext,
        (internalSanityModal?: SanityModal | undefined) => {
          showModal('default', internalSanityModal);
        },
      ),
      verticalButtons: true,
    };
    setModal(mappedModal);
  };
  const CopiedText = () => {
    copiedShareText(bodyText);
  };
  const showCameraPermission = (
    cameraPermission: SanityCameraPermission,
    activatedButton: Button,
    buttonArray: Button[],
  ) => {
    if (buttonArray === undefined || activatedButton === undefined) {
      return;
    }

    // show a loading icon for the button
    const mappedButtons = buttonArray.map((btn) => {
      if (btn === activatedButton) {
        btn.loading = true;
      }
      return btn;
    });

    if (mappedButtons === undefined || mappedButtons.length === 0) {
      return;
    }

    setButtons(mappedButtons);
    askForCameraPermission(
      navigationContext,
      cameraContext,
      cameraPermission,
      showOpenInBrowserModal,
    );
  };

  useEffect(() => {
    const page = pageContext.getCurrentPage();
    const tempPage = page.diplomaTemplate;

    if (tempPage === undefined) {
      return;
    }
    tempPage.documentId = page.id || '';
    setCurrentPage(tempPage);
    setButtons(
      sanityFunctions.createButtonsFromSanityButtons(
        tempPage?.buttons,
        navigationContext,
        (internalSanityModal?: SanityModal | undefined) => {
          showModal('default', internalSanityModal);
        },
        showShare,
        showCameraPermission,
      ),
    );
  }, []);

  if (currentPage === undefined) {
    return null;
  }

  return (
    <>
      <ShareModal
        copyTextPressed={CopiedText}
        isVisible={showShareModal}
        shareData={copyTextData}
        toggleModal={() => setShowShareModal(false)}
      />
      <DiplomaPageTemplate
        documentId={currentPage.documentId}
        buttons={buttons}
        diplomaData={currentPage.diplomaData}
        email={currentPage.email}
        modal={modal}
        privacyPolicyText={currentPage.privacyPolicyText}
        receiveResearchCommunication={currentPage.receiveResearchCommunication}
        showTimeLeftBar={currentPage.showTimeLeftBar}
        showModal={showModal}
        text={currentPage.text}
        verticalButtons={currentPage.verticalButtons}
      />
    </>
  );
};

export default WithFadeIn(WithFocus(DynamicDiplomaPage));
