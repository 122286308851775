import React, { FunctionComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import mediaMedium from '../../pageTemplates/Templates/MediaMedium';

const RelativeContainer = styled.div`
  position: relative;
  padding-top: 16px;
`;
const Bubble = styled.div<{ centeredText?: boolean }>`
  width: 100%;
  min-width: min-content;
  background: ${(props) => props.theme.colors.neutral_100};
  display: block;
  margin: 0 auto;
  border: 1px solid ${(props) => props.theme.colors.neutral_100};
  border-radius: 8px;
  h3 {
    ${(props) =>
      props.centeredText ? 'text-align: center;' : 'text-align: left;'}
    margin: 18px 14px;
    font-style: normal;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 120%;

    color: ${(props) => props.theme.colors.neutral_600};
    z-index: 1;
  }
  p {
    ${(props) =>
      props.centeredText ? 'text-align: center;' : 'text-align: left;'}
  }
`;
const Pointer = styled.div<{ pointerLeft?: boolean }>`
  z-index: -1;
  position: absolute;
  height: 25px;
  width: 25px;
  background: ${(props) => props.theme.colors.neutral_100};
  transform: rotate(45deg);
  border-radius: 8px 0 8px 0;
  left: calc(${(props) => (props.pointerLeft ? '20%' : '50%')} - 8px);
  top: 4px;

  ${mediaMedium(css`
    left: -8px;
    top: calc(50% - 4px);
  `)}
`;

const Margin = styled.div`
  margin: 10px;
`;

interface SpeechBubbleProps {
  textAlignCenter?: boolean;
  pointerLeft?: boolean;
  children?: ReactNode;
  tabIndex?: number;
}

/**
 * Wrapper component
 * Should input text as h3.
 */
const SpeechBubble: FunctionComponent<SpeechBubbleProps> = ({
  children,
  textAlignCenter,
  pointerLeft,
  tabIndex,
}) => {
  return (
    <RelativeContainer>
      <Bubble centeredText={textAlignCenter} tabIndex={tabIndex}>
        <Margin>{children}</Margin>
      </Bubble>
      <Pointer pointerLeft={pointerLeft} />
    </RelativeContainer>
  );
};

export default SpeechBubble;
