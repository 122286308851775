/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ModalProps } from '../../components/Modals';
import ModalForGrid from '../../components/Modals/Modal';
import Lottie, { LottieProps } from '../../components/ReactLottie';
import Speechbubble from '../../components/Speechbubble/Speechbubble';
import {
  ClassroomCodeAndUrl,
  ProgressBarWithIcon,
  TimeLeftWithText,
} from '../../components/TopBars';
import { AnimationState } from '../../components/TopBars/types';
import { useAudio, useHeightViewport, useWidthViewport } from '../../context';
import { useSanityCourse } from '../../context/sanity/useSanityCourse';
import { DisplayTeamName } from '../../features/groupRevivr/components';
import SanityModal from '../../features/sanity/sanityModal';
import autoNavigationHelper from '../../helper/autoNavigationHelper';
import { useNavigation } from '../../hooks';
import {
  FooterTextStyle,
  ImgWithSize,
  PageGridStyle,
  ProgressbarContainer,
  StyledText,
} from '../Templates/InfoPageStyles';
import { CountDown, ImageWithCustomSize } from '../Templates/InfoPageTypes';
import mediaMedium from '../Templates/MediaMedium';
import { Text } from '../Templates/Text';
import { DialogueImage } from '../dialogPageTemplate/DialogueImage';
import { DialogueText } from '../dialogPageTemplate/DialogueText';
import { ContinueCourseModal } from '../dialogPageTemplate/types/ContinueCourseModal';
import { AudioTypes } from '../types/courseTypes';
import AEDButton, { AEDButtonWrapper } from './AEDButton';

const navigationDelay = 3000;

const ImgGridContainer = styled.div<{ row?: string }>`
  grid-column: 1 / -1;
  grid-row: ${(props) => (props.row ? props.row : '2')};
  display: grid;
  gap: 1px;
  align-self: center;
  height: fit-content;
  grid-template-columns: 1fr 1fr;

  ${mediaMedium(css`
    grid-row: 2 / 3;
    grid-column: 1 / 4;
  `)}
`;
const AEDImg = styled(ImgWithSize)<{
  shockMode: boolean;
}>`
  grid-column: 1;
  align-self: flex-end;
  justify-self: center;
  grid-row: 1;
  ${(props) => (props.shockMode ? 'grid-column: 1 / -1;' : '')};

  ${mediaMedium(css`
    align-self: center;
  `)}
`;

const LottieContainer = styled.div<{}>`
  width: 100%;
  grid-column: 2;
  grid-row: 1;
  justify-self: flex-end;
  align-self: flex-end;
  ${mediaMedium(css`
    align-self: center;
  `)}
  svg {
    display: block;
  }
`;

const BottomContainer = styled.div`
  grid-column: 2 / -2;
  grid-row: -3;
`;

interface ImageProps {
  operatorImage: DialogueImage | undefined;
  lottie: LottieProps | undefined;
  innerWidth: number;
  aedButtonImg: AEDbuttonImg | undefined;
  buttonRef: React.RefObject<AEDButton>;
}

const Image = ({
  operatorImage,
  lottie,
  innerWidth,
  aedButtonImg,
  buttonRef,
}: ImageProps) => {
  if (operatorImage || lottie) {
    return (
      <ImgGridContainer>
        {operatorImage && (
          <>
            <AEDImg
              shockMode={aedButtonImg?.buttonType === 'shock'}
              src={operatorImage.src}
              alt={operatorImage.alt ? operatorImage.alt : 'AEDImg'}
              maxHeight={
                operatorImage.height ? operatorImage.height : undefined
              }
              onClick={() => {
                if (operatorImage.onClick !== undefined) {
                  operatorImage.onClick();
                }
              }}
            />
            <AEDButtonWrapper
              aedButtonImg={aedButtonImg}
              operatorImage={operatorImage}
              innerWidth={innerWidth}
              buttonRef={buttonRef}
            />
          </>
        )}
        {lottie && !operatorImage && (
          <LottieContainer id="lottieContainer">
            <Lottie options={lottie.options} />
          </LottieContainer>
        )}
        {lottie && operatorImage && (
          <LottieContainer
            role="img"
            aria-label="Perform compressions animation"
            id="lottieContainer"
          >
            <Lottie
              options={lottie.options}
              height={lottie.height}
              width={lottie.width}
            />
          </LottieContainer>
        )}
      </ImgGridContainer>
    );
  }
  return null;
};

export interface AEDbuttonImg extends ImageWithCustomSize {
  buttonType: 'shock' | 'on';
  blink?: boolean;
  display?: boolean;
  src: string;
  ref?: React.RefObject<HTMLButtonElement>;
}
type AEDTemplateProps = {
  dialogueText?: DialogueText;
  text?: Text;
  aedButtonImg?: AEDbuttonImg;
  operatorImage?: DialogueImage;
  lottie?: LottieProps;
  countDown?: CountDown;
  showTimeLeftBar?: boolean;
  displayTeamName?: boolean;
  practiceAgainModal?: boolean;
  playAudioOnLoad?: boolean;
  continueCourseModal: ContinueCourseModal;
};

interface TextPartProps {
  dialogueText: DialogueText | undefined;
  text: Text | undefined;
}

const TextPart = ({ dialogueText, text }: TextPartProps) => {
  if (dialogueText || text) {
    return (
      <StyledText>
        {dialogueText && (
          <>
            {dialogueText.header && (
              <h1 tabIndex={-1}>
                {dialogueText.header ? dialogueText.header : null}
              </h1>
            )}
            <Speechbubble
              pointerLeft
              textAlignCenter={dialogueText.textAlignCenter}
            >
              {dialogueText.text}
            </Speechbubble>
          </>
        )}
        {text && (
          <>
            <h1 tabIndex={-1}>
              {text.header}
              {text.header2 ? (
                <>
                  <br />
                  {text.header2}
                </>
              ) : null}
            </h1>
            <p>
              {text.body}
              <strong>{text.emphasized}</strong>
            </p>
            {text.footerText && (
              <FooterTextStyle>{text.footerText}</FooterTextStyle>
            )}
          </>
        )}
      </StyledText>
    );
  }
  return null;
};

const AedTemplatePage: FunctionComponent<AEDTemplateProps> = ({
  operatorImage,
  countDown,
  lottie,
  aedButtonImg,
  showTimeLeftBar,
  displayTeamName,
  practiceAgainModal,
  playAudioOnLoad,
  continueCourseModal,
  dialogueText,
  text,
}: AEDTemplateProps) => {
  const { innerHeight } = useHeightViewport();
  const { innerWidth } = useWidthViewport();
  const navigationContext = useNavigation();
  const audio = useAudio();
  const pageContext = useSanityCourse();
  const buttonPressed = useRef(false);
  const [onRefreshModal, setOnRefreshModal] = useState<
    ModalProps | undefined
  >();
  const gridPageRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<AEDButton>(null);
  const [animationState, setAnimationState] = useState<AnimationState>(
    AnimationState.paused,
  );

  const handleMouseDown = () => {
    if (aedButtonImg !== undefined && buttonRef.current) {
      buttonRef.current.blink();
    }
  };

  useEffect(() => {
    const locationPathName = window.location.pathname;
    if (gridPageRef.current) {
      gridPageRef.current.addEventListener('mousedown', handleMouseDown);
    }
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      if (!audio) {
        return;
      }
      audio.pauseFromAudioLibrary(locationPathName);
      audio.resetAudio(locationPathName);
    };
  }, []);

  const dismissOnRefreshModal = () => {
    if (audio) {
      audio.initCurrentSection();
      if (playAudioOnLoad) {
        audio.playAudioOnPage(AudioTypes.OnLoad);
      }
    }
    setAnimationState(AnimationState.running);
    setOnRefreshModal(undefined);
  };

  const createOnRefreshModal = () => {
    if (
      practiceAgainModal &&
      navigationContext &&
      navigationContext.initialPage === window.location.pathname &&
      audio?.doesSectionContainAudio()
    ) {
      const newModal = SanityModal.createContinueCourseModal(
        pageContext,
        dismissOnRefreshModal,
        navigationContext,
      );
      setOnRefreshModal(newModal);
    }
  };

  useEffect(() => {
    if (countDown) {
      setAnimationState(countDown.animationState);
    }

    if (
      playAudioOnLoad &&
      navigationContext &&
      navigationContext.initialPage !== window.location.pathname &&
      audio
    ) {
      audio.playAudioOnPage(AudioTypes.OnLoad);
    }
    const locationPatname = window.location.pathname;

    return () => {
      if (audio && playAudioOnLoad) {
        audio.pauseFromAudioLibrary(locationPatname);
        audio.resetAudio(locationPatname);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (continueCourseModal !== undefined) {
      // Learner in group should not see the refresh modal
      if (!(window.revivr.isGroupRevivr && window.revivr.isLearner)) {
        createOnRefreshModal();
      }
    }
  }, [navigationContext.initialPage]);

  useEffect(() => {
    if (aedButtonImg !== undefined && aedButtonImg.onClick === undefined) {
      aedButtonImg.onClick = () => {
        if (buttonPressed.current) {
          return;
        }
        buttonPressed.current = true;
        const navigationTimer = setTimeout(() => {
          navigationContext.navigateToNextPageWithSleepCheck();
        }, navigationDelay);
        if (!audio) {
          return;
        }
        if (!audio.isCurrentSectionLoaded()) {
          audio.initCurrentSection();
        }
        audio.onEnded(() => {
          navigationContext.navigateToNextPageWithSleepCheck();
          clearTimeout(navigationTimer);
        });
        audio.playAudioOnPage(AudioTypes.OnClick);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aedButtonImg]);

  const countDownDuration = (): number => {
    // testmode can override the duration
    if (autoNavigationHelper.isTestMode()) {
      return autoNavigationHelper.cameraTrainingDuration(10);
    }
    if (navigationContext) {
      return navigationContext.currentPageDuration();
    }
    if (countDown) {
      return countDown.duration;
    }
    return 10;
  };

  return (
    <PageGridStyle ref={gridPageRef} height={innerHeight}>
      <ClassroomCodeAndUrl />
      <DisplayTeamName enabled={displayTeamName} />
      {onRefreshModal && (
        <ModalForGrid
          bannerImage={onRefreshModal.bannerImage}
          buttons={onRefreshModal.buttons}
          onClose={onRefreshModal.onClose}
          text={onRefreshModal.text}
          verticalButtons
        />
      )}
      <ProgressbarContainer>
        {countDown ? (
          <ProgressBarWithIcon
            animationDurationInSeconds={countDownDuration()}
            animationDelayInSeconds={countDown.delay}
            animationState={animationState}
            setAnimRef={countDown.setAnimRef}
          />
        ) : (
          <TimeLeftWithText enabled={showTimeLeftBar} />
        )}
        <DisplayTeamName enabled={displayTeamName} />
      </ProgressbarContainer>
      <Image
        operatorImage={operatorImage}
        lottie={lottie}
        innerWidth={innerWidth}
        aedButtonImg={aedButtonImg}
        buttonRef={buttonRef}
      />

      <TextPart dialogueText={dialogueText} text={text} />
      <BottomContainer />
    </PageGridStyle>
  );
};

export default AedTemplatePage;
