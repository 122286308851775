export const groupTrainingUrl = {
  learner: '/join',
  facilitator: '/group',
};
const returnCurrentLastPartOfPath = (): string => {
  const path = window.location.pathname
    .split('/')
    .map((part) => (part.length === 0 ? part : `/${part}`));
  path.splice(0, 1);
  return path[path.length - 1] ?? '';
};

export const isGroupFacilitatorTrainingStartPage = (): boolean => {
  return returnCurrentLastPartOfPath() === groupTrainingUrl.facilitator;
};

export const isGroupLearnerTrainingStartPage = (): boolean => {
  return returnCurrentLastPartOfPath() === groupTrainingUrl.learner;
};

export const isSoloTrainingStartPage = (): boolean => {
  return returnCurrentLastPartOfPath() === '';
};
