import styled, { css } from 'styled-components';

namespace Styles {
  export const Image = styled.img`
    grid-column: 2 / -2;
    grid-row: 2;
    align-self: center;
    justify-self: center;
  `;
  export const CenterBottomContent = styled.div`
    grid-column: 2 / -2;
    grid-row: 3;
    justify-self: center;
    display: grid;
    justify-content: center;
    text-align: center;
  `;

  const fontStyle = css`
    font-family: Lato;
    font-weight: 700;
  `;

  export const PlacementText = styled.h1`
    ${fontStyle}
    font-size: 4.4rem;
  `;

  export const DescriptionText = styled.p`
    ${fontStyle}
    font-size: 2.6rem;
  `;
}
export default Styles;
