import React from 'react';
import { Route } from 'react-router-dom';
import { urlChecker } from '../../../helper';
import { CommonTemplateList } from './pageTemplates';

const getCommonNavigationRoute = (page) => {
  return typeof CommonTemplateList[page?.template] !== 'undefined' ? (
    <Route
      key={page.url}
      path={urlChecker(page.url)}
      element={
        <React.Fragment key={page.url}>
          {React.createElement(CommonTemplateList[page.template])}
        </React.Fragment>
      }
    />
  ) : null;
};
export default getCommonNavigationRoute;
