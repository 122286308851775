import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Constants } from '../../constants';
import mediaMedium from '../../pageTemplates/Templates/MediaMedium';

const Path = styled.div<{
  percentageCompleted: number;
}>`
  width: calc(${(props) => props.percentageCompleted}%);
  height: inherit;
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.primary_500};
  position: absolute;
  left: 0px;
  top: 0px;
`;

const BackgroundPath = styled.div`
  width: calc(100%);
  height: inherit;
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.neutral_100};
  position: absolute;
  left: 0px;
  top: 0px;
`;

const Container = styled.div`
  position: relative;
  width: calc(100%);
  height: ${Constants.progressBarHeight.SMALL}px;

  ${mediaMedium(css`
    height: ${Constants.progressBarHeight.MEDIUM}px;
  `)}
`;

interface TimeLeftBarProps {
  timeLeftInSeconds: number;
  totalTimeInSeconds: number;
}

const TimeLeftBar: FunctionComponent<TimeLeftBarProps> = ({
  timeLeftInSeconds,
  totalTimeInSeconds,
}) => {
  const percentageCompleted = Math.round(
    ((totalTimeInSeconds - timeLeftInSeconds) / totalTimeInSeconds) * 100,
  );

  return (
    <Container
      role="progressbar"
      aria-label={`Progress bar. ${percentageCompleted} % completed`}
      aria-valuenow={percentageCompleted}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <BackgroundPath />
      <Path percentageCompleted={percentageCompleted} />
    </Container>
  );
};

export default TimeLeftBar;
