import React, { FunctionComponent, useEffect, useState } from 'react';
import { WithFocus } from '../../components';
import { ModalProps } from '../../components/Modals';
import WithFadeIn from '../../components/WithFadeIn';
import { useSanityCourse } from '../../context';
import { sanityFunctions, sanityTypes } from '../../features/sanity';
import { createTextAndImageBody } from '../../features/sanity/SanityServiceMapper';
import { TrainingPageCentralPart } from '../../features/sanity/types';
import autoNavigationHelper from '../../helper/autoNavigationHelper';
import { useNavigation } from '../../hooks';
import { Button } from '../Templates/InfoPageTypes';
import { AudioProps, ResultData, TrainingTemplate } from '../types/courseTypes';
import { SanityModal } from '../types/sanityTypes';
import TrainingPage from './TrainingPage';
import TrainingResultPage from './TrainingResultPage';
import TrainingWithCounterPage from './TrainingWithCounterPage';

type ComponentProps = {
  template?: TrainingTemplate;
};

const DynamicTrainingPage: FunctionComponent<ComponentProps> = ({
  template,
}) => {
  const navigationContext = useNavigation();
  const [currentPage, setCurrentPage] = useState<TrainingTemplate>({});
  const pageContext = useSanityCourse();
  const [buttons, setButtons] = useState<Button[] | undefined>(undefined);
  const [trainingResult, setTrainingResult] = useState<ResultData | undefined>(
    undefined,
  );
  const [modal, setModal] = useState<ModalProps | undefined>();

  const showPractiseAgain = () => {
    setTrainingResult(undefined);
  };

  const showModal = (buttonModal: SanityModal | undefined) => {
    if (buttonModal === undefined) {
      setModal(undefined);
      return;
    }
    const newModal: ModalProps = {
      onClose: () => {
        setModal(undefined);
      },
      text: {
        header: buttonModal.text?.header,
        header2: buttonModal.text?.header2,
        body: createTextAndImageBody(
          buttonModal?.text?.body,
          buttonModal?.text?.bulletPoints,
        ),
      },
      bannerImage: buttonModal.bannerImage,
      buttons: sanityFunctions.createButtonsFromSanityButtons(
        buttonModal.buttons,
        navigationContext,
        showModal,
      ),
      verticalButtons: buttonModal.verticalButtons,
    };
    setModal(newModal);
  };

  const showTrainingResult = (resultData: ResultData) => {
    setButtons(
      sanityFunctions.createButtonsFromSanityButtons(
        resultData.buttons,
        navigationContext,
        showModal,
        undefined,
        undefined,
        showPractiseAgain,
      ),
    );
    setTrainingResult(resultData);
  };

  useEffect(() => {
    const tempPage =
      template !== undefined
        ? template
        : pageContext.getCurrentPage().trainingTemplate;

    if (tempPage !== undefined) {
      tempPage.trainingPage = {
        ...tempPage.trainingPage,
        duration: autoNavigationHelper.autoPlayDuration(
          tempPage.trainingPage?.duration,
        ),
      };

      setCurrentPage(tempPage);
    }
    return () => {};
  }, []);

  const introMessagesDurationInSeconds = () => {
    if (currentPage.trainingPage?.trainingIntro?.messages)
      return currentPage.trainingPage.trainingIntro.messages.reduce(
        (acc, message) => {
          return acc + (message.duration ?? 0);
        },
        0,
      );
    return 0;
  };

  const audioProps: AudioProps = {
    enableAudio: currentPage.trainingPage?.enableAudio ?? false,
    audioDelay: introMessagesDurationInSeconds(),
  };
  return (
    <>
      {trainingResult && (
        <TrainingResultPage
          heading={trainingResult.heading}
          subHeading={trainingResult.subHeading}
          iconImage={trainingResult.iconImage}
          modal={modal}
          buttons={buttons}
          footerText={currentPage.resultPages?.footerText}
        />
      )}
      {currentPage.trainingPage?.centralPart ===
        TrainingPageCentralPart.CompressionAnimation &&
        trainingResult === undefined && (
          <TrainingPage
            audioProps={audioProps}
            showTrainingResult={showTrainingResult}
            lottieOptions={currentPage.trainingPage?.lottie}
            showAmbulance={
              currentPage.trainingPage?.topPart ===
              sanityTypes.trainingPageLayout.top.ambulance
            }
            bottomPart={currentPage.trainingPage?.bottomPart}
            centralPart={currentPage.trainingPage?.centralPart}
            introMessages={currentPage.trainingPage?.trainingIntro}
            allowCameraPermissionMessage={
              currentPage.trainingPage?.allowCameraPermissionMessage
            }
            resultPages={currentPage.resultPages}
            trainingDurationInSeconds={currentPage.trainingPage?.duration}
          />
        )}
      {currentPage.trainingPage?.centralPart ===
        TrainingPageCentralPart.CountdownWithSound &&
        trainingResult === undefined && (
          <TrainingWithCounterPage
            showTrainingResult={showTrainingResult}
            introMessages={currentPage.trainingPage?.trainingIntro}
            bottomPart={currentPage.trainingPage?.bottomPart}
            trainingDurationInSeconds={currentPage.trainingPage?.duration}
            resultPages={currentPage.resultPages}
          />
        )}
    </>
  );
};

export default WithFadeIn(WithFocus(DynamicTrainingPage));
