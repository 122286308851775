import { FunctionComponent, useEffect, useState } from 'react';
import {
  FilterSkipButtonForPortrait,
  QRCodeImage,
} from '../../../../components';
import { Modal, ModalProps } from '../../../../components/Modals';
import Lottie from '../../../../components/ReactLottie';
import {
  BarWithIconWrapper,
  ClassroomCodeAndUrl,
  ProgressBar,
  TimeLeftWithText,
} from '../../../../components/TopBars';
import { AnimationState } from '../../../../components/TopBars/types';
import {
  useAudio,
  useHeightViewport,
  useSanityCourse,
  useViewport,
} from '../../../../context';
import { useNavigation } from '../../../../hooks';
import {
  PageGridStyle,
  ProgressbarContainer,
} from '../../../../pageTemplates/Templates/InfoPageStyles';
import {
  Button,
  CountDown,
  ImageTypes,
} from '../../../../pageTemplates/Templates/InfoPageTypes';
import { ContinueCourseModal } from '../../../../pageTemplates/dialogPageTemplate/types/ContinueCourseModal';
import { AudioTypes } from '../../../../pageTemplates/types/courseTypes';
import { SanityImage } from '../../../../pageTemplates/types/sanityTypes';
import SanityModal from '../../../sanity/sanityModal';
import Styles from './ImagePage.styles';

type ImagePartProps = {
  image: SanityImage;
};

const ImagePart: FunctionComponent<ImagePartProps> = ({ image }) => {
  switch (image.imgType) {
    case ImageTypes.webCam:
      return null;
    case ImageTypes.QRCode:
      return (
        <QRCodeImage
          url={image.src}
          alt={image.alt ? image.alt : 'A QR Code'}
        />
      );
    case ImageTypes.Lottie:
      if (image.lottie !== undefined) {
        return (
          <Styles.FullScreenLottie id="FullScreenLottie">
            <Lottie
              options={image.lottie.options}
              height={image.lottie.height}
              width={image.lottie.width}
            />
          </Styles.FullScreenLottie>
        );
      }
      return (
        <Styles.FullScreenImg
          src={image.src}
          alt={image.alt ? image.alt : 'Some image'}
          imgType={image.imgType}
        />
      );
    default:
      return (
        <Styles.FullScreenImg
          src={image.src}
          alt={image.alt ? image.alt : 'Some image'}
          imgType={image.imgType}
        />
      );
  }
};

export type ImagePageProps = {
  buttons: Button[];
  continueCourseModal?: ContinueCourseModal;
  countDown?: CountDown;
  enableAudio: boolean;
  image: SanityImage;
  modal?: ModalProps;
  verticalButtons: boolean;
};

const ImagePage: FunctionComponent<ImagePageProps> = ({
  buttons,
  continueCourseModal,
  countDown,
  enableAudio,
  image,
  modal,
  verticalButtons,
}: ImagePageProps) => {
  const navigationContext = useNavigation();
  const audio = useAudio();
  const pageContext = useSanityCourse();
  const [onRefreshModal, setOnRefreshModal] = useState<
    // onRefreshModal is used to show a modal when the user refreshes the page. If this value is not undefined, the audio and autonavigation is disabled
    ModalProps | undefined
  >();
  const { innerHeight } = useHeightViewport();
  const { isMediumWidth } = useViewport();

  const dismissOnRefreshModal = () => {
    if (!audio) return;

    audio.initCurrentSection();
    if (enableAudio) {
      audio.playAudioOnPage(AudioTypes.OnLoad);
    }
    setOnRefreshModal(undefined);
  };
  const createOnRefreshModal = () => {
    if (
      navigationContext &&
      navigationContext.initialPage === window.location.pathname &&
      audio?.doesSectionContainAudio()
    ) {
      const newModal = SanityModal.createContinueCourseModal(
        pageContext,
        dismissOnRefreshModal,
        navigationContext,
      );
      setOnRefreshModal(newModal);
    }
  };

  useEffect(() => {
    if (
      audio &&
      enableAudio &&
      navigationContext &&
      navigationContext.initialPage !== window.location.pathname
    ) {
      audio.playAudioOnPage(AudioTypes.OnLoad);
    }
    const locationPathname = window.location.pathname;

    return () => {
      if (enableAudio && audio) {
        audio.pauseFromAudioLibrary(locationPathname);
        audio.resetAudio(locationPathname);
      }
    };
  }, [enableAudio]);

  useEffect(() => {
    if (continueCourseModal !== undefined) {
      // Learner in group should not see the refresh modal
      if (!(window.revivr.isGroupRevivr && window.revivr.isLearner)) {
        createOnRefreshModal();
      }
    }
  }, [navigationContext.initialPage]);

  return (
    <PageGridStyle height={innerHeight} imgType={image.imgType}>
      <ClassroomCodeAndUrl />
      {modal && !Array.isArray(modal) && (
        <Modal
          buttons={modal.buttons}
          onClose={modal.onClose}
          text={modal.text}
          image={modal.image}
          bannerImage={modal.bannerImage}
          verticalButtons={modal.verticalButtons}
        />
      )}
      {onRefreshModal && (
        <Modal
          bannerImage={onRefreshModal.bannerImage}
          buttons={onRefreshModal.buttons}
          onClose={onRefreshModal.onClose}
          text={onRefreshModal.text}
          verticalButtons
        />
      )}
      <ProgressbarContainer>
        {countDown ? (
          <BarWithIconWrapper hideIcon={isMediumWidth}>
            <ProgressBar
              animationDurationInSeconds={countDown.duration}
              animationDelayInSeconds={countDown.delay}
              animationState={
                onRefreshModal
                  ? AnimationState.paused
                  : countDown.animationState
              }
              setAnimRef={countDown.setAnimRef}
            />
          </BarWithIconWrapper>
        ) : (
          <TimeLeftWithText enabled={false} />
        )}
      </ProgressbarContainer>
      <ImagePart image={image} />
      {FilterSkipButtonForPortrait(buttons, verticalButtons)}
    </PageGridStyle>
  );
};

export default ImagePage;
