import React, { FunctionComponent, useEffect, useState } from 'react';

import { WithFocus } from '../../components';
import WithFadeIn from '../../components/WithFadeIn';
import { useSanityCourse } from '../../context';
import { sanityFunctions } from '../../features/sanity';
import { useNavigation } from '../../hooks';
import DynamicInputPage from '../Templates/DynamicInputPage';
import { Button } from '../Templates/InfoPageTypes';
import { InputPageTemplate } from '../types/courseTypes';

const DynamicInputPageTemplate: FunctionComponent = () => {
  const [currentPage, setCurrentPage] = useState<InputPageTemplate>({
    firebaseStorageCollection: '',
  });
  const pageContext = useSanityCourse();
  const [buttons, setButtons] = useState<Button[] | undefined>(undefined);

  const navigationContext = useNavigation();
  useEffect(() => {
    const tempPage = pageContext.getCurrentPage().inputTemplate;
    if (tempPage !== undefined) {
      setCurrentPage(tempPage);
      setButtons(
        sanityFunctions.createButtonsFromSanityButtons(
          tempPage?.buttons,
          navigationContext,
        ),
      );
    }
  }, []);

  return (
    <DynamicInputPage
      buttons={buttons}
      text={currentPage.text}
      inputFieldPlaceholder={currentPage.inputField?.placeholder}
      inputFieldLabel={currentPage.inputField?.label}
      inputFieldValidationMessage={currentPage.inputField?.validationText}
      inputFieldMinimumLength={currentPage.inputField?.minimumCharsToValidate}
      firebaseCollectionName={currentPage.firebaseStorageCollection}
    />
  );
};

export default WithFadeIn(WithFocus(DynamicInputPageTemplate));
