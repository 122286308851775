class FpsCalculation {
  private fpsData: Array<number>;

  constructor(fpsData: Array<number>) {
    this.fpsData = fpsData.filter(
      (value) => value !== undefined && value !== null && value > 0,
    );
  }

  getAverageFps(): number {
    let averageFps = 0;
    for (let i = 0; i < this.fpsData.length; i += 1) {
      averageFps += this.fpsData[i];
    }
    return averageFps / this.fpsData.length;
  }

  getStandardDeviationFps(): number {
    let standardDeviationFps = 0;
    const averageFps = this.getAverageFps();
    const series = new Array<number>();
    for (let i = 0; i < this.fpsData.length; i += 1) {
      series[i] = (this.fpsData[i] - averageFps) ** 2;
    }
    let sum = 0;
    for (let i = 0; i < this.fpsData.length; i += 1) {
      sum += series[i];
    }
    standardDeviationFps = Math.sqrt(sum / this.fpsData.length);
    return standardDeviationFps;
  }

  getMinimumFps(): number {
    return Math.min(...this.fpsData);
  }

  getMaximumFps(): number {
    return Math.max(...this.fpsData);
  }
}
export default FpsCalculation;
