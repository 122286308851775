/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-restricted-globals */
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  CommonNavigationProvider,
  NavigationContextProps,
  useCommonNavigation,
} from '../../../../context';
import { LocalStorageService } from '../../../../services';
import { UseAbly } from '../../context/AblyContext';
import { isGroupFacilitatorTrainingStartPage } from '../../helper/GroupTrainingUrl';
import { ConnectionStates } from '../../types/AblyConnectionTypes';

export const FacilitatorNavigationContext =
  React.createContext<NavigationContextProps>({} as NavigationContextProps);

const FacilitatorNavigationProvider: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }) => {
  const commonNavigation = useCommonNavigation();
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const location = useLocation();

  const {
    connectionStatusChanged,
    connectFacilitator,
    publishNavigateToNextPageCommand,
    resetWaitingLearners,
  } = UseAbly();
  const [ablyConnectionState, setAblyConnectionState] = useState<
    string | undefined
  >(ConnectionStates.closed);

  useEffect(() => {
    setAblyConnectionState(connectionStatusChanged);
  }, [connectionStatusChanged]);

  useEffect(() => {
    if (initialRender) {
      if (!isGroupFacilitatorTrainingStartPage()) {
        const classroomCode = parseInt(
          LocalStorageService.getClassroomCodeFromLocalStorage() ?? '',
          10,
        );
        connectFacilitator(classroomCode);
      }

      setInitialRender(false);
    }

    if (ablyConnectionState === ConnectionStates.connected) {
      publishNavigateToNextPageCommand({ currentUrl: location.pathname });
    }
    resetWaitingLearners();
  }, [location]);

  return (
    <FacilitatorNavigationContext.Provider
      value={{
        ...commonNavigation,
        initialRender,
      }}
    >
      {children}
    </FacilitatorNavigationContext.Provider>
  );
};

const FacilitatorNavigationExportProvider: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }) => {
  return (
    <CommonNavigationProvider>
      <FacilitatorNavigationProvider>{children}</FacilitatorNavigationProvider>
    </CommonNavigationProvider>
  );
};

export default FacilitatorNavigationExportProvider;
