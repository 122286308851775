import checkForInsertedDevOpsVariable from './checkForInsertedDevOpsVariable';

const placeHolderPaths = {
  soloPath: `{"/test" : "0bd696dc-91f1-4cb1-9b7f-cf5cea00d8aa",
  "/japan" : "1bd52f5e-4646-4641-bb66-bc2a8d50fc8d",
  "/infant" : "2ff1d6fc-ed8e-46e4-950d-a3b6146a876d" }`,
  groupPath: `{"/grouptest" : "fa0510f4-9ce3-41ca-aae3-2e85f422dd81",
    "/school" : "0141883b-0fd5-4bbc-8be3-4c38295818f3",
    "/japangroup" : "6ff48373-7e8c-4264-bffd-de1415a3d40d" 
    }`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const soloPaths: any = JSON.parse(
  checkForInsertedDevOpsVariable(`#{soloPaths}#`, placeHolderPaths.soloPath),
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupPaths: any = JSON.parse(
  checkForInsertedDevOpsVariable(`#{groupPaths}#`, placeHolderPaths.groupPath),
);
