import { useState } from 'react';
import { FireBaseCollections, useFirebase } from '../../../context';
import FireBaseTypes from '../../../context/FirebaseProvider.types';
import { getTeamNumber } from '../helpers/teamAssetSelectors';
import { Team } from '../providers/Types';

/**
 * To make sure the right data is logged, this hook must be initialized, and the data must be updated once teams finish.
 * @returns
 */
export const useFacilitatorLogging = () => {
  const { storeCollection } = useFirebase();
  const [loggedTeams, setLoggedTeams] = useState<FireBaseTypes.RaceTeam[]>([]);

  const initLoggedTeams = (teams: Team[]) => {
    const startTime = Date.now();
    const FireBaseTeams: FireBaseTypes.RaceTeam[] = teams.map((team) => ({
      durationMs: 0,
      endTime: 0,
      learner1: { id: team.learner1.id },
      learner2: { id: team.learner2?.id ?? null },
      name: team.name,
      percentageCompleted: 0,
      startTime: startTime,
    }));
    setLoggedTeams(FireBaseTeams);
  };

  const storeRaceEndToFireBase = () => {
    const raceEndCollection: FireBaseTypes.RaceEnd = {
      numberOfTeams: loggedTeams.length,
      teams: loggedTeams,
    };
    storeCollection(FireBaseCollections.raceEnd, raceEndCollection);
  };

  const updateTeamOnCompletion = (
    teamNumber: number,
    completionTime: number,
    percentageCompleted: number,
  ) => {
    setLoggedTeams((currentTeams) => {
      const updatedTeams = currentTeams.map((teamData) => {
        if (getTeamNumber(teamData.name) === teamNumber) {
          return {
            ...teamData,
            endTime: completionTime,
            durationMs: completionTime - teamData.startTime,
            percentageCompleted: percentageCompleted,
          };
        }
        return teamData;
      });
      return updatedTeams;
    });
  };

  return {
    updateTeamOnCompletion,
    initLoggedTeams,
    storeRaceEndToFireBase,
  };
};
