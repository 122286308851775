import { collection, doc, setDoc } from 'firebase/firestore'; // This is the new way of importing
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { isMobile } from 'react-device-detect';
import { EventArgs } from 'react-ga';
import { firestore } from '../components/Firebase';
import constants from '../constants/Constants';
import SessionResult from '../features/cprAlgorithm/SessionResult';
import {
  ConnectionStates,
  ConnectorType,
} from '../features/groupRevivr/types/AblyConnectionTypes';
import { getLocaleCodeForSanity } from '../features/sanity/localeHelper';
import { SessionResultExtended } from '../helper';
import { generateSessionId } from '../hooks/useGlobalConfig';
import { LocalStorageService } from '../services';
import { logError } from '../services/errorHandling';
import { useUser } from './UserProvider';
import { useViewport } from './ViewportProvider';
import { useSanityCourse } from './sanity/useSanityCourse';

const version = import.meta.env.VITE_APP_VERSION ?? null; // Firebase doesn't like it when variables are undefined

const pascalToCamel = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

const groupType = (): 'Group Revivr' | 'Solo Revivr' => {
  return window.revivr?.isGroupRevivr ?? null ? 'Group Revivr' : 'Solo Revivr';
};
export const FireBaseCollections = {
  aedPadsPlacementData: 'AedPadsPlacementData',
  email: 'Email',
  inputField: 'InputField',
  orientationChange: 'OrientationChange',
  queryStrings: 'QueryStrings',
  quizQuestion: 'quizQuestion',
  raceEnd: 'RaceEnd',
  selectedAnswers: 'SelectedAnswers',
  sessionResults: 'SessionResults',
  urlChanges: 'UrlChanges',
};

const getUserSessionInfo = () => {
  const UserSessionInfo: {
    classId: null | number;
    learnerId: null | string;
    role: string;
    sessionType: string;
    team: string | null;
    teamRole: string | null;
  } = {
    classId: window.revivr?.code ?? null,
    learnerId: window.revivr?.learnerId ?? null,
    role:
      window.revivr?.isLearner === true ||
      window.revivr?.isLearner === undefined
        ? ConnectorType.Learner
        : ConnectorType.Facilitator,
    sessionType: groupType(),
    team: LocalStorageService.getLearnerTeamFromLocalStorage() || null,
    teamRole: LocalStorageService.getLearnerRoleFromLocalStorage() || null,
  };
  return UserSessionInfo;
};
type FireBaseContextProps = {
  getGeneralProps: () => Record<string, unknown>;
  storeCollectionBarebones: (
    collectionName: string,
    collectionObj: Record<string, unknown>,
    generalProps?: Record<string, unknown>,
  ) => Promise<boolean>;
  storeAblyConnectionEvent: (
    learnerEvent: ConnectionStates,
    learnerId: string,
  ) => Promise<void>;
  storeButtonClick: (
    buttonInnerText: string,
    buttonId: string,
  ) => Promise<boolean>;
  storeCollectionNonTrackable: (
    collectionName: string,
    collection: Record<string, unknown>,
  ) => Promise<void>;
  storeCollection: (
    collectionName: string,
    collection: Record<string, unknown>,
  ) => Promise<boolean>;
  storeGAEvent: (
    gaEvent: Record<string, unknown> | EventArgs,
  ) => Promise<boolean>;
  storeTrainingData: (
    collectionName: string,
    collection: SessionResultExtended,
    hasCameraPermission?: boolean,
  ) => Promise<boolean>;
};

const FireBaseContext = React.createContext<FireBaseContextProps>(
  {} as FireBaseContextProps,
);

/**
 * This is consuming contexts from:
 * UserProvider,
 * SanityProvider/GroupSanityProvider
 */
const FireBaseProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }) => {
  const courseContext = useSanityCourse();
  const userContext = useUser();
  const viewPortContext = useViewport();

  const getSanityCollection = () => {
    return {
      sanityOrganizationId: courseContext.settings.organizationId,
      sanityOrganizationTitle: courseContext.settings.organizationTitle,
      sanityProjectId: userContext.sanityProjectId,
      sanityTemplate: courseContext.getCurrentPage()?.template ?? null,
    };
  };

  const getDeviceOrientation = useCallback(() => {
    return viewPortContext.isLandscape
      ? constants.deviceOrientation.LANDSCAPE
      : constants.deviceOrientation.PORTRAIT;
  }, [viewPortContext.isLandscape]);

  const getSessionId = (): string | null => {
    const sessionId = LocalStorageService.getSessionId();
    if (sessionId === null || sessionId === undefined) {
      const error = new Error('SessionId is null in LocalStorage');
      logError(error);
      generateSessionId();
    }
    return LocalStorageService.getSessionId();
  };

  // This function is used to get all the flags from LaunchDarkly and store them in Firebase
  const getLaunchDarklyData = () => {
    if (!window.ldClient) {
      return null;
    }
    const flagArray = Object.entries(window.ldClient.allFlags());
    const result = flagArray.map(([key, flagValue]) => {
      return {
        name: key,
        type: typeof flagValue === 'object' ? 'JSON' : typeof flagValue,
        value:
          typeof flagValue === 'object' ? JSON.stringify(flagValue) : flagValue, // To make it easier for the data team, we stringify all JSONs
      };
    });
    return result;
  };

  const getGeneralProps = () => {
    return {
      date: new Date(),
      domain: window.location.hostname,
      isMobile,
      isScorm: LocalStorageService.getIsScormFromLocalStorage(),
      language: getLocaleCodeForSanity(),
      orientation: getDeviceOrientation(),
      page_url: window.location.pathname,
      queryStrings: { ...userContext.queryStrings },
      sanityCollection: getSanityCollection(),
      sessionId: getSessionId(),
      userSessionInfo: getUserSessionInfo(),
      version,
      launchDarklyFlags: getLaunchDarklyData(),
    };
  };
  const handleError = (error: Error, collectionName: string) => {
    console.error(`Error saving ${collectionName}`, error);
    logError(error, `Error saving ${collectionName}`, { collectionName });
  };

  const storeCollectionNonTrackable = async (
    collectionName: string,
    collectionObj: Record<string, unknown>,
  ): Promise<void> => {
    // Date without time for not recognizing data
    const date = new Date();
    const domain = window.location.hostname;
    const language = getLocaleCodeForSanity();
    const sanityCollection = getSanityCollection();
    date.setHours(0, 0, 0, 0);
    return setDoc(doc(collection(firestore, collectionName)), {
      [pascalToCamel(collectionName)]: collectionObj,
      date,
      domain,
      language,
      sanityCollection,
    });
  };

  /**
   * Stores a GAEvent to firebase, adding queryStrings.
   * @param gaEvent - (Collection/google event to store)
   * @returns promise<boolean>
   */
  const storeGAEvent = async (
    gaEvent: Record<string, unknown> | EventArgs,
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      setDoc(doc(collection(firestore, gaEvent.category as string)), {
        gaEvent: { ...gaEvent },
        ...getGeneralProps(),
      })
        .then(() => {
          resolve(true);
        })
        .catch((error: Error) => {
          handleError(error, gaEvent.category as string);
          resolve(false);
        });
    });
  };

  /**
   * Stores a Button click event to firebase.
   * @param buttonInnerText - button.innerText
   * @param buttonId - button.id
   * @returns promise<void>
   */
  const buttonClickCollectionName = 'ButtonClicks';
  const storeButtonClick = async (
    buttonInnerText: string,
    buttonId: string,
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      setDoc(doc(collection(firestore, buttonClickCollectionName)), {
        buttonInnerText,
        buttonId,
        ...getGeneralProps(),
      })
        .then(() => {
          resolve(true);
        })
        .catch((error: Error) => {
          handleError(error, buttonClickCollectionName);
          resolve(false);
        });
    });
  };

  const getDocumentData = (
    collectionObj: Record<string, unknown>,
    collectionName: string,
  ) => {
    let document = {};
    if (Object.keys(collectionObj).length === 0) {
      document = { ...getGeneralProps() };
    } else {
      document = {
        [pascalToCamel(collectionName)]: { ...collectionObj },
        ...getGeneralProps(),
      };
    }
    return document;
  };

  /**
   * This is a bare bones version of storeCollection,
   *  it does not add generalProps to the collection, which have to be added manually.
   *  The intent of this is so it will provide proper data for components that unmount on changing pages.
   * @param collectionName
   * @param collectionObj
   * @param generalProps
   * @returns true if it succeeded, false if it failed
   */
  const storeCollectionBarebones = async (
    collectionName: string,
    collectionObj: Record<string, unknown>,
    generalProps?: Record<string, unknown>,
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      setDoc(doc(collection(firestore, collectionName)), {
        [pascalToCamel(collectionName)]: { ...collectionObj },
        ...generalProps,
      })
        .then(() => {
          resolve(true);
        })
        .catch((error: Error) => {
          handleError(error, collectionName);
          resolve(false);
        });
    });
  };
  /**
   * Stores collection to firebase, adding queryStrings.
   * @param collectionObj - (Collection to store)
   * @param collectionName - (Collection name in firebase)
   * @returns promise<boolean>
   */
  const storeCollection = async (
    collectionName: string,
    collectionObj: Record<string, unknown>,
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      setDoc(
        doc(collection(firestore, collectionName)),
        getDocumentData(collectionObj, collectionName),
      )
        .then(() => {
          resolve(true);
        })
        .catch((error: Error) => {
          handleError(error, collectionName);
          resolve(false);
        });
    });
  };

  /**
   * Stores collection to firebase, adding queryStrings.
   * @param collectionObj - (Collection to store)
   * @param collectionName - (Collection name in firebase)
   * @returns promise<boolean>
   */
  const storeTrainingData = async (
    collectionName: string,
    collectionObj: SessionResultExtended,
    hasCameraPermission = false,
  ): Promise<boolean> => {
    if (hasCameraPermission === false) {
      collectionObj = new SessionResultExtended(new SessionResult(), []);
    }

    return new Promise<boolean>((resolve) => {
      setDoc(doc(collection(firestore, collectionName)), {
        [pascalToCamel(collectionName)]: { ...collectionObj.sessionResult },
        averageFps:
          hasCameraPermission === false ? 0 : collectionObj.averageFps,
        averageFpsStandardDeviation:
          hasCameraPermission === false
            ? 0
            : collectionObj.averageFpsStandardDeviation,
        maxFps: hasCameraPermission === false ? 0 : collectionObj.maxFps,
        minFps: hasCameraPermission === false ? 0 : collectionObj.minFps,
        browser: collectionObj.browser,
        hasCameraPermission,
        ...getGeneralProps(),
      })
        .then(() => {
          resolve(true);
        })
        .catch((error: Error) => {
          handleError(error, collectionName);
          resolve(false);
        });
    });
  };

  /**
   * Logging function used for when a learner connects or disconnects from a facilitator's class.
   * The logging is done by the facilitator and means both the learner's ID and facilitator's ID will be visible in the
   * logging data.
   * @param learnerEvent The connection event happening to the learner (ConnectionStates.connected .disconnected etc)
   * @param learnerId The unique ID of the learner
   */
  const storeAblyConnectionEvent = async (
    learnerEvent: ConnectionStates,
    learnerId: string,
  ): Promise<void> => {
    const connectingLearner = learnerId;
    storeCollection('AblyConnectionEvents', {
      connectionEvent: learnerEvent,
      learnerID: connectingLearner,
      urlOrigin: window.location.origin,
    });
  };

  useEffect(() => {
    if (viewPortContext.currentOrientation === '') {
      return;
    }

    storeCollection(FireBaseCollections.orientationChange, {});
  }, [getDeviceOrientation()]);

  return useMemo(
    () => (
      <FireBaseContext.Provider
        value={{
          storeAblyConnectionEvent,
          storeButtonClick,
          getGeneralProps,
          storeCollection,
          storeCollectionBarebones,
          storeCollectionNonTrackable,
          storeGAEvent,
          storeTrainingData,
        }}
      >
        {children}
      </FireBaseContext.Provider>
    ),
    [
      storeAblyConnectionEvent,
      storeButtonClick,
      getGeneralProps,
      storeCollection,
      storeCollectionBarebones,
      storeCollectionNonTrackable,
      storeGAEvent,
      storeTrainingData,
    ],
  );
};

/**
 * hook containing functions for logging to firebase
 * @returns  fireBaseContext: FireBaseContextProps
 */
export const useFirebase = (): FireBaseContextProps => {
  const fireBaseContext = useContext(FireBaseContext);
  return fireBaseContext;
};

export default FireBaseProvider;
