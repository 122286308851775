import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from 'react';
import CompressionRateElapsed from '../features/cprAlgorithm/CompressionRateElapsed';
import ScoreFilter from '../features/cprAlgorithm/ScoreFilter';
import SessionResultCalculator from '../features/cprAlgorithm/SessionResultCalculator';
import SessionResultExtended from '../helper/SessionResultExtended';
import consoleDebug from '../helper/consoleDebug';

export type TcprLinkContextProps = {
  fps: number | null;
  isLoaded: boolean;
  isRunning: boolean;
  isStarted: boolean;
  load: () => void;
  rate: number;
  score: number | null;
  sessionResult: string;
  start: () => void;
  startDebug: () => void;
  stop: () => void;
};
const TcprLinkContext = React.createContext<TcprLinkContextProps | null>(null);

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

let compressionRateElapsedData: Array<CompressionRateElapsed>;
let fpsData: Array<number>;
let scoreFilter: ScoreFilter;
let startTime: number;
const compressionRateUpdated = 'compressionRateUpdated';
const framesPerSecond = 'framesPerSecond';

let prevRate = 0;
let validPrevRate = 0;
let startTimeForInvalidRate = 0;
let currentTime = 0;
let timeDifferenceInMS = 0;
const MaxInvalidRateDurationInMS = 3000;

const TcprLinkProvider: FunctionComponent<Props> = ({ children }: Props) => {
  const [rate, setRate] = useState<number>(0);
  const [score, setScore] = useState<number | null>(null);
  const [fps, setFps] = useState<number | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [sessionResult, setSessionResult] = useState<string>('');

  const resetProcessRate = () => {
    prevRate = 0;
    validPrevRate = 0;
    startTimeForInvalidRate = 0;
    currentTime = 0;
    timeDifferenceInMS = 0;
  };

  const reset = () => {
    consoleDebug('Reset TCPR Link');
    setRate(0);
    setScore(null);
    setFps(null);
    setIsLoaded(false);
    setIsStarted(false);
    setIsRunning(false);
    setSessionResult('');
  };

  const ProcessRate = (receivedRate: number) => {
    if (receivedRate === -1 && prevRate > 0) {
      startTimeForInvalidRate = performance.now();
      validPrevRate = prevRate;
      setRate(validPrevRate);
    } else if (receivedRate === -1 && prevRate === -1) {
      currentTime = performance.now();
      timeDifferenceInMS = currentTime - startTimeForInvalidRate;
      if (timeDifferenceInMS > MaxInvalidRateDurationInMS) {
        validPrevRate = receivedRate;
      }
      setRate(validPrevRate);
    } else {
      setRate(receivedRate);
    }
    prevRate = receivedRate;
  };

  const compressionEventListener = useCallback((event: CustomEvent) => {
    const compressionRate = Number(event.detail.compressionRate);
    const milliSecondsElapsed = Date.now() - startTime;
    compressionRateElapsedData.push(
      new CompressionRateElapsed(compressionRate, milliSecondsElapsed),
    );
    ProcessRate(compressionRate);
  }, []);

  const fpsEventListener = useCallback((event: CustomEvent) => {
    const frameRate = Number(event.detail.frameRate);
    setFps(frameRate);
    fpsData.push(frameRate);
  }, []);

  const load = () => {
    reset();
    consoleDebug('Load TCPR Link');
    setIsLoaded(true);
    document.addEventListener(compressionRateUpdated, compressionEventListener);
    document.addEventListener(framesPerSecond, fpsEventListener);
  };

  const start = () => {
    consoleDebug('Start TCPR Link');
    startTime = Date.now();
    scoreFilter = new ScoreFilter(1);
    compressionRateElapsedData = new Array<CompressionRateElapsed>();
    fpsData = new Array<number>();
    setIsStarted(true);
    setIsRunning(true);
  };

  const stop = () => {
    consoleDebug('Stop TCPR Link');
    setIsStarted(false);
    setIsRunning(false);
    document.removeEventListener(
      compressionRateUpdated,
      compressionEventListener,
    );
    document.removeEventListener(framesPerSecond, fpsEventListener);
    compressionRateElapsedData.forEach((data) => {
      scoreFilter.filterAndSubmitAsync(
        data.compressionRate,
        data.milliSecondsElapsed,
      );
    });

    const result = SessionResultCalculator(scoreFilter.allCompressions);
    setScore(result.averageCompressionRateOfRatesAboveZero);
    const sessionResultExtended = new SessionResultExtended(result, fpsData);
    setSessionResult(JSON.stringify(sessionResultExtended));
    resetProcessRate();
    setIsLoaded(false); // Stop removes event listeners; So we need to reload to be able to start after a stop.
  };

  const startDebug = () => {};

  return (
    <TcprLinkContext.Provider
      value={{
        rate,
        score,
        isRunning,
        start,
        stop,
        startDebug,
        fps,
        isLoaded,
        sessionResult,
        isStarted,
        load,
      }}
    >
      {children}
    </TcprLinkContext.Provider>
  );
};

export const useTcprLink = (): TcprLinkContextProps => {
  return useContext(TcprLinkContext) as TcprLinkContextProps;
};

export default TcprLinkProvider;
