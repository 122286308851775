import { FunctionComponent } from 'react';
import Styles from './TopBar.styles';
import TopBarTypes from './TopBar.types';

const TopBar: FunctionComponent<TopBarTypes.Props> = ({ learnerTeam }) => {
  return (
    <Styles.FirstRow>
      <Styles.FullScreenWidth color={learnerTeam.color}>
        {' '}
        <p>{learnerTeam.name}</p>{' '}
      </Styles.FullScreenWidth>
    </Styles.FirstRow>
  );
};

export default TopBar;
