import {
  browserVersion,
  fullBrowserVersion,
  getUA,
  isChrome,
  isEdge,
  isFirefox,
  isIE,
  isMobileSafari,
  isOpera,
  isSafari,
  isSamsungBrowser,
} from 'react-device-detect';

const isEmailValid = (text: string): boolean => {
  // eslint-disable-next-line prefer-regex-literals
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  );

  return text.length !== 0 && pattern.test(text);
};

const containsNumber = (name: string) => {
  return /\d/.test(name);
};

export const version = parseInt(browserVersion, 10);
export const isFacebookBrowser =
  getUA.includes('FB_IAB') || getUA.includes('FBAN');

// https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
// https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
const browserVersionIsMinimumSupportedVersion = () => {
  if (isChrome) {
    return version >= 53;
  }

  if (isFirefox) {
    return version >= 36;
  }

  if (isSafari) {
    return version >= 11 && !fullBrowserVersion.includes('13.0'); // 13.0.3, 13.0.5 fails is not supported. 13.1.x is supported
  }

  if (isOpera) {
    return version >= 40;
  }

  if (isIE) {
    return false;
  }

  if (isEdge) {
    return version >= 17;
  }

  if (isMobileSafari) {
    return version >= 11;
  }

  if (isSamsungBrowser) {
    return version >= 6;
  }
  if (isFacebookBrowser) {
    // On Facebook the version is x_x_x.
    // That means that the version is NaN, and this will always be true. E.g. all versions are valid on facebook
    // Added for documentation purposes
    return true;
  }

  return true;
};

export {
  browserVersionIsMinimumSupportedVersion,
  containsNumber,
  isEmailValid,
};
