/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import constants from '../../constants/Constants';

const { pageMargin } = constants;

const maxCameraFeedHeightPx = 200;
export const PageGridStyle = styled.div<{
  height: number;
}>`
  height: ${(props) => props.height}px;
  width: 100%;
  max-width: ${constants.maxPageWidthMobile}px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: ${pageMargin.SMALL}px 1fr ${pageMargin.SMALL}px;
  grid-template-rows:
    minmax(${pageMargin.SMALL * 2}px, max-content)
    minmax(max-content, 1fr)
    ${maxCameraFeedHeightPx}px
    minmax(${pageMargin.SMALL}px, max-content);
`;

export const ProgressbarContainer = styled.div`
  grid-column: 2 / -2;
  grid-row: 1;
  align-self: end;
`;
export const CenterPart = styled.div<{
  fullWidth?: boolean;
}>`
  display: grid;
  grid-column: ${(props) => (props.fullWidth ? '1 / -1' : '2 / -2')};
  grid-row: 2;
  align-self: center;
  z-index: 1;
`;
export const BottomPart = styled.div`
  grid-column: 2 / -2;
  grid-row: -3;
  align-self: end;
`;
export const CenterOverlay = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-row: 2;
  align-content: stretch;
  z-index: 2;
`;
export const LottieContainer = styled.div<{
  alignLeft?: boolean;
  alignRight?: boolean;
}>`
  width: 100%;
  grid-column: 1 / -1;
  ${(props) => (props.alignLeft ? 'grid-column: 2;' : '')}
  ${(props) => (props.alignRight ? 'grid-column: 1;' : '')}
  align-self: flex-end;
  justify-self: ${(props) => (props.alignLeft ? 'flex-end' : 'center')};
  grid-row: 1;
  svg {
    display: block;
  }
`;
