/* eslint-disable import/prefer-default-export */
import { SystemIcons } from '@laerdal/life-react-components';
import { AnimationState } from '../../components/TopBars/types';
import constants from '../../constants/Constants';
import { AudioContextProps } from '../../context/audio/audioContextProps';
import { NavigationContextProps } from '../../context/navigation/navigationContextProps';
import {
  Button,
  ButtonTypes,
} from '../../pageTemplates/Templates/InfoPageTypes';
import type {
  PageAudio,
  SanityCountDown,
} from '../../pageTemplates/types/courseTypes';
import { AudioTypes } from '../../pageTemplates/types/courseTypes';
import {
  SanityButton,
  SanityCameraPermission,
  SanityModal,
  SanityShare,
} from '../../pageTemplates/types/sanityTypes';
import * as defaultText from './defaultText';
import { CustomButton } from './sanityTypes/customButton';
import { buttonNavigationTypes } from './types';

export { urlChecker } from '../../helper';

/**
 * @param buttons of type SanityButton[], data recieved from Sanity
 * @param navigationContext: const navigationContext = useContext(NavigationContext);
 * @param showModal :(buttonModal: SanityModal | undefined) => void, fuction that builds a modal
 * @returns Button[] to be rendered in /bhf components
 */
export const createButtonsFromSanityButtons = (
  buttons: SanityButton[] | CustomButton[] | undefined,
  navigationContext: NavigationContextProps | null,
  showModal?: (buttonModal: SanityModal | undefined) => void, 
  showShare?: (buttonModal: SanityShare | undefined) => void,
  showCameraPermission?: (
    cameraPermission: SanityCameraPermission | undefined,
    activatedButton: Button,
    allButtons: SanityButton[] | CustomButton[],
  ) => void,
  showPractiseAgain?: () => void,
): Button[] | undefined => {
  const newButtons = buttons?.map((button) => {
    return {
      text: button.text,
      buttonType: button.buttonType,
      onClick: () => {
        if (
          button.navigation === buttonNavigationTypes.practiseAgain &&
          showPractiseAgain
        ) {
          showPractiseAgain();
        }
        if (
          button.navigation === buttonNavigationTypes.socialMediaShare &&
          showShare
        ) {
          showShare(button.share);
        }
        if (button.navigation === buttonNavigationTypes.nextPage) {
          navigationContext?.navigateToNextPageWithSleepCheck();
          return;
        }
        if (button.navigation === buttonNavigationTypes.previousPage) {
          navigationContext?.navigateToPreviousPage();
        }
        if (button.navigation === buttonNavigationTypes.urlPage && button.url) {
          navigationContext?.navigateToUrl(
            button.url,
            undefined,
            button.openInNewTab,
          );
        }
        if (
          button.navigation === buttonNavigationTypes.navToModal &&
          showModal
        ) {
          showModal(button.modal);
        }
        if (
          button.navigation === buttonNavigationTypes.dismissModal &&
          showModal
        ) {
          showModal(undefined);
        }
        if (
          button.navigation === buttonNavigationTypes.cameraPermission &&
          showCameraPermission
        ) {
          showCameraPermission(button.cameraPermission, button, buttons);
        }
      },
    } as Button;
  });
  return newButtons;
};
/**
 * Function for creating buttons for autoplay cases.
 * @param countDown : SanityCountDown; data from Sanity (sanityPage.countDown)
 * @param navigationContext: const navigationContext = useContext(NavigationContext);
 * @param anim useState holding the AnimationState
 * @param setAnim useState to set the AnimationState
 * @returns Button[] to be rendered in /bhf components
 */
export const createAutoplayButtonsFromSanityCountDown = (
  countDown: SanityCountDown,
  navigationContext: NavigationContextProps | null,
  useAudio: AudioContextProps | null,
  anim: AnimationState,
  setAnim: React.Dispatch<React.SetStateAction<AnimationState>>,
): Button[] | undefined => {
  const buttonArray = [
    {
      text: anim
        ? countDown.playText || defaultText.countDown.buttonText.resume
        : countDown.pauseText || defaultText.countDown.buttonText.pause,
      buttonType: anim ? ButtonTypes.Primary : ButtonTypes.Secondary,
      icon: anim ? SystemIcons.Play : SystemIcons.Pause,
      onClick: () => {
        setAnim(anim ? AnimationState.running : AnimationState.paused);
        if (anim) {
          useAudio?.playAudioOnPage(AudioTypes.OnLoad);
        } else {
          useAudio?.pauseAudioOnPage();
        }
      },
    },
  ] as Button[];
  if (countDown?.hasBackButton) {
    // Needs to be placed to the front of the array.
    buttonArray.unshift({
      text: countDown.backText || defaultText.countDown.buttonText.back,
      buttonType: ButtonTypes.Secondary,
      onClick: () => {
        navigationContext?.navigateToPreviousPage();
      },
    });
  }
  if (countDown?.hasSkipButton) {
    buttonArray.push({
      icon: SystemIcons.SkipForward,
      id: 'skipForward',
      text: '',
      buttonType: ButtonTypes.Secondary,
      onClick: () => {
        navigationContext?.navigateToNextPageWithSleepCheck();
      },
    });
  }
  return buttonArray;
};

export const getResourceUrl = (ref: string): string => {
  // Example ref: file-207fd9951e759130053d37cf0a558ffe84ddd1c9-mp3
  // We don't need the first part, unless we're using the same function for files and images
  if (!ref) {
    return '';
  }
  const splitted = ref.split('-');
  const dataType = splitted[0];
  const filetype = splitted[splitted.length - 1];
  const filename = ref.replace(`${dataType}-`, '').replace(`-${filetype}`, '');
  return `https://cdn.sanity.io/${dataType}s/${constants.sanity.PROJECT_ID}/${constants.sanity.DATASET}/${filename}.${filetype}`;
};

// TODO type sounds
export const getAudioSource = (sounds): PageAudio[] | undefined => {
  if (!sounds) {
    return undefined;
  }
  const mappedSounds = sounds.map((s) => {
    return {
      // eslint-disable-next-line no-underscore-dangle
      src: getResourceUrl(s.sounds?.asset?._ref),
      audioType:
        s.audioType?.type !== undefined
          ? AudioTypes[s.audioType?.type]
          : undefined,
    } as PageAudio;
  });

  return mappedSounds as PageAudio[];
};
