/* eslint-disable import/no-unused-modules */

import React, { ReactNode, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getQuerystringParameter } from '../helper/UrlHelper';
import { LocalStorageService } from '../services';

type ScormContextProps = {
  isScorm: boolean;
  SendQuizAnswer: ({
    isCorrect,
    questionText,
    answerText,
  }: {
    isCorrect: boolean;
    questionText: string;
    answerText: string;
  }) => void;
};

enum ScormMessageTypes {
  completed = 'completed',
  quizAnswer = 'quizAnswer',
}

const ScormContext = React.createContext<ScormContextProps>(
  {} as ScormContextProps,
);

// set value from dev ops
let completedUrl =
  '#{SCORM_COMPLETED_URL}#'.indexOf('SCORM_COMPLETED_URL') === -1
    ? '#{SCORM_COMPLETED_URL}#'
    : '/invite'; // after second confidence rating
// Todo: it can be a problem if the url is not correct, and the user will not be able to complete the course because of that it does not existin in the navigation. Validate that url existt, if not use the last one in the navigation?
completedUrl = completedUrl[0] === '/' ? completedUrl : `/${completedUrl}`;

const isWrappedInAScormPackage = (): boolean => {
  if (getQuerystringParameter('isScorm') === 'true') {
    LocalStorageService.setIsScormFromLocalStorage('true');
    return true;
  }
  return LocalStorageService.getIsScormFromLocalStorage() === true;
};

const ScormProvider: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }) => {
  const isScorm = isWrappedInAScormPackage();
  const location = useLocation();

  const SendCompletion = () => {
    if (!isScorm) return;
    // eslint-disable-next-line no-console
    console.log('Scorm course completed');
    window.parent.postMessage(
      {
        type: ScormMessageTypes.completed,
        data: { completedUrl },
      },
      '*',
    );
  };

  const SendQuizAnswer = ({
    isCorrect,
    questionText,
    answerText,
  }: {
    isCorrect: boolean;
    questionText: string;
    answerText: string;
  }) => {
    if (!isScorm) return;
    window.parent.postMessage(
      {
        type: ScormMessageTypes.quizAnswer,
        data: {
          isCorrect,
          questionText,
          answerText,
          url: location.pathname,
        },
      },
      '*',
    );
  };

  useEffect(() => {
    if (location.pathname === completedUrl) {
      SendCompletion();
    }
  }, [location]);

  return (
    <ScormContext.Provider value={{ SendQuizAnswer, isScorm }}>
      {children}
    </ScormContext.Provider>
  );
};

// This is used to handle group and other parts of the code which do not want to add scorm provider
const mockScormContext: ScormContextProps = {
  isScorm: false,
  SendQuizAnswer: () => {},
};

export const useScorm = (): ScormContextProps => {
  const context = useContext(ScormContext);

  const isContextEmpty = (ctx) => Object.keys(ctx).length === 0; // check if provider have been added

  return isContextEmpty(context) ? mockScormContext : context;
};

export default ScormProvider;
