import { css, styled } from 'styled-components';
import {
  Img,
  LottieContainer,
} from '../../../../pageTemplates/Templates/InfoPageStyles';
import { ImageTypes } from '../../../../pageTemplates/Templates/InfoPageTypes';
import mediaMedium from '../../../../pageTemplates/Templates/MediaMedium';

const MEDIA_MARGIN_BOTTOM = '16px';
namespace Styles {
  export const FullScreenImg = styled(Img)`
    grid-row: 2 / span 2;
    align-self: center;
    margin-bottom: ${MEDIA_MARGIN_BOTTOM};
    ${mediaMedium(css<{ imgType?: ImageTypes }>`
      grid-row: 2 / 3;
      max-width: 100%;
      width: auto;
      height: auto;
      grid-column: 1 / -1;
    `)}
  `;

  export const FullScreenLottie = styled(LottieContainer)`
    grid-row: 2 / span 2;
    align-self: center;
    margin-bottom: ${MEDIA_MARGIN_BOTTOM};
    ${mediaMedium(css<{ imgType?: ImageTypes }>`
      grid-column: 1 / -1;
      grid-row: 2 / 3;
    `)}
  `;
}
export default Styles;
