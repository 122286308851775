import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { WithFadeIn, WithFocus } from '../../../../components';
import { ModalProps } from '../../../../components/Modals';
import { AnimationState } from '../../../../components/TopBars/types';
import { useAudio, useCamera, useSanityCourse } from '../../../../context';
import { useNavigation } from '../../../../hooks';
import {
  Button,
  CountDown,
} from '../../../../pageTemplates/Templates/InfoPageTypes';
import { showModal } from '../../../../pageTemplates/textAndImageTemplate/utils';
import {
  ImageTemplate,
  isFacilitatorPageWithTemplate,
} from '../../../../pageTemplates/types/courseTypes';
import {
  SanityButton,
  SanityCameraPermission,
  SanityModal
} from '../../../../pageTemplates/types/sanityTypes';
import useAskForCameraPermission from '../../../navigation/useAskForCameraPermission';
import { sanityFunctions } from '../../../sanity';
import { createTextAndImageBody } from '../../../sanity/SanityServiceMapper';
import { CustomButton } from '../../../sanity/sanityTypes/customButton';
import ImagePage from './ImagePage';


type DynamicImagePageProps = {
  template?: ImageTemplate;
};

const DynamicImagePage: FunctionComponent<DynamicImagePageProps> = ({
  template,
}) => {
  const askForCameraPermission = useAskForCameraPermission();
  const audio = useAudio();
  const cameraContext = useCamera();
  const navigationContext = useNavigation();
  const sanityCourse = useSanityCourse();
  const animationRef = useRef<HTMLDivElement>(null);
  const [anim, setAnim] = useState<AnimationState>(AnimationState.paused);
  const [buttons, setButtons] = useState<Button[] | undefined>(undefined);
  const [countDown, setCountDown] = useState<CountDown | undefined>({
    duration: 10,
    delay: 1,
    animationState: AnimationState.paused,
    setAnimRef: animationRef,
  });
  const [currentPage, setCurrentPage] = useState<ImageTemplate>(
    {} as ImageTemplate,
  );
  const [modal, setModal] = useState<ModalProps | undefined>();
  const [pageLoaded, setPageLoaded] = useState(false)

  const showModalPrefilled = (innerButtonModal: SanityModal | undefined) => {
    showModal(innerButtonModal, setModal, navigationContext);
}

  const showOpenInBrowserModal = (browserModal: SanityModal) => {
    const newModal: ModalProps = {
      onClose: () => {
        setModal(undefined);
      },
      text: {
        header: browserModal.text?.header,
        body: createTextAndImageBody(
          browserModal?.text?.body,
          browserModal?.text?.bulletPoints,
        ),
      },
      bannerImage: {
        src: browserModal.bannerImage?.src,
        alt: browserModal.bannerImage?.alt,
        fullWidth: true,
      },
      buttons: sanityFunctions.createButtonsFromSanityButtons(
        browserModal.buttons,
        navigationContext,
        showModalPrefilled,
      ),
      verticalButtons: true,
    };
    setModal(newModal);
  };

  const showCameraPermission = (
    cameraPermission: SanityCameraPermission,
    activatedButton: Button,
    allButtons: SanityButton[] | CustomButton[],
  ) => {
    if (allButtons === undefined || activatedButton === undefined) {
      return;
    }

    // show a loading icon for the button
    const newAllButtons: Button[] = allButtons.map((button) => {
      if (button === activatedButton) {
        button.loading = true;
      }
      return button;
    });

    if (newAllButtons === undefined || newAllButtons.length === 0) {
      return;
    }

    setButtons(newAllButtons);
    askForCameraPermission(
      navigationContext,
      cameraContext,
      cameraPermission,
      showOpenInBrowserModal,
    );
  };

  useEffect(() => {
    const tempPage = sanityCourse.getCurrentPage();
    if (
      isFacilitatorPageWithTemplate(tempPage) &&
      tempPage.imageTemplate !== undefined
    ) {
      const facilitatorPage = tempPage.imageTemplate;
      setCurrentPage(facilitatorPage);
      setPageLoaded(true);
      if (facilitatorPage.enableAutoplay && facilitatorPage.countDown) {
        setAutoplayButtons(facilitatorPage);
      } else {
        setNormalButtons(facilitatorPage);
      }
      setAnim(AnimationState.running);
    }
  }, []);

  useEffect(() => {
    let someRef: HTMLDivElement | null = null;
    if (currentPage.enableAutoplay && currentPage.countDown) {
      setAutoplayButtons(currentPage);

      setCountDown({
        duration: currentPage.countDown.duration,
        delay: currentPage.countDown.delay,
        animationState: anim,
        setAnimRef: animationRef,
      });
      if (animationRef?.current) {
        someRef = animationRef.current;
        animationRef.current.addEventListener('animationend', onAnimated, {
          once: true,
        });
      }
    }
    return () => {
      if (someRef) {
        someRef.removeEventListener('animationend', onAnimated);
      }
    };
  }, [anim]);

  const onAnimated = () => {
    navigationContext?.navigateToNextPage();
  };

  const setAutoplayButtons = (page: ImageTemplate) => {
    setButtons(
      sanityFunctions.createAutoplayButtonsFromSanityCountDown(
        page.countDown,
        navigationContext,
        audio,
        anim,
        setAnim,
      ),
    );
  };

  const setNormalButtons = (page: ImageTemplate) => {
    setButtons(
      sanityFunctions.createButtonsFromSanityButtons(
        page.buttons,
        navigationContext,
        showModalPrefilled,
        undefined,
        showCameraPermission,
      ),
    );
  };

  return (
    pageLoaded && <ImagePage
      buttons={buttons}
      countDown={currentPage.enableAutoplay ? countDown : undefined}
      enableAudio={currentPage.enableAudio}
      image={currentPage.image}
      modal={modal}
      verticalButtons={currentPage.verticalButtons}
    />
  );
};

export default WithFadeIn(WithFocus(DynamicImagePage));
