import { useFirebase } from '../../context';
import { CameraContextProps } from '../../context/CameraProvider';
import { NavigationContextProps } from '../../context/navigation/navigationContextProps';
import { SanityCameraPermission } from '../../pageTemplates/types/sanityTypes';
import { isAndroidWebView, isiOSWebview } from '../../services';

const permissionRequestTimeoutMs = 5 * 1000;

// Asks for camera permission, then navigates accordingly.
const useAskForCameraPermission = () => {
  const { storeGAEvent } = useFirebase();
  return async (
    navigationContext: NavigationContextProps | null,
    cameraContext: CameraContextProps | null,
    cameraPermission: SanityCameraPermission,
    showOpenInBrowserModal: (SanityModal) => void,
  ): Promise<void> => {
    const permissionRequestTimeoutTimer = setTimeout(() => {
      if (isAndroidWebView() || isiOSWebview()) {
        showOpenInBrowserModal(cameraPermission.modal);
      }
    }, permissionRequestTimeoutMs);
    const permission = await cameraContext?.checkCameraPermissionAsync();

    clearTimeout(permissionRequestTimeoutTimer);
    const gaEvent = {
      category: 'CameraPermission',
      action: permission
        ? 'Accepted camera first time'
        : 'Declined camera first time',
      label: window.location.pathname,
    };
    storeGAEvent(gaEvent);
    if (permission) {
      if (cameraPermission?.navigateOnPermission) {
        navigationContext?.navigateToUrl(cameraPermission.navigateOnPermission);
      } else {
        navigationContext?.navigateToNextPage();
      }
    } else {
      if (cameraPermission.navigateOnPermissionDenied) {
        navigationContext?.navigateToUrl(
          cameraPermission.navigateOnPermissionDenied,
        );
        return;
      }
      navigationContext?.navigateToNextPage();
    }
  };
};
export default useAskForCameraPermission;
