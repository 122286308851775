/* eslint-disable import/prefer-default-export */
export const aedDraggablePad = {
  images: {
    leftPad: {
      alt: 'Left pad. It should be placed on the left side of the chest, below the collar bone.',
    },
    rightPad: {
      alt: 'Right pad. It should be placed on the right side of the body, just below the armpit.',
    },
    leftCorrectPlacementArea: {
      alt: 'Area for correct pad placement. It is located on the left side of the chest, just below the collar bone.',
    },
    rightCorrectPlacementArea: {
      alt: 'Area for correct pad placement. It is located on the right side of the body, just below the armpit.',
    },
    womanWithCorrectPadPlacement: {
      alt: 'Image of a woman with correct pad placement. One pad is placed just below the collar bone on the left side of the body, while the other pad is placed just below the armpit on the right side of the body.',
    },
  },
};
export const countDown = {
  buttonText: {
    resume: 'Resume',
    pause: 'Pause',
    back: 'Back',
  },
};
export const quizPage = {
  buttonText: {
    continue: 'Continue',
    ok: 'OK',
    tryAgain: 'Try again',
    showAnswer: 'Show Answer',
  },
  answerLabel: {
    select: 'Select answer',
    correct: 'Correct answer',
    wrong: 'Wrong answer',
  },
};
export const aedPadButtonText = {
  padPlacement: {
    primary: 'Check answer',
    secondary: 'Help',
  },
  padCorrectPosition: {
    primary: 'Continue',
    secondary: 'Practise again',
  },
  padInCorrectPosition: {
    primary: 'Practise again',
    secondary: 'Help',
  },
  padHelp: {
    primary: 'Continue',
    secondary: 'Practise again',
  },
  padHelpModal: {
    primary: 'Show me where to place pads',
    secondary: 'Practise now',
  },
};
export const timeLeftWithText = {
  timeLeftText: 'Time left',
  min: 'min',
  mins: 'mins',
};
