import { styled } from 'styled-components';
import CONSTANTS from '../../../constants/Constants';

const { pageMargin } = CONSTANTS;

const MAX_CAMERA_FEED_HEIGHT_PX = '200px';
const BANNER_HEIGHT_PX = '80px';

namespace LearnerStyles {
  export const PageGrid = styled.div<{
    height: number;
  }>`
    height: ${(props) => props.height}px;
    width: 100%;
    max-width: ${CONSTANTS.maxPageWidthMobile}px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: ${pageMargin.SMALL}px 1fr ${pageMargin.SMALL}px;
    grid-template-rows:
      ${BANNER_HEIGHT_PX}
      minmax(max-content, 3fr)
      minmax(max-content, 2fr)
      ${MAX_CAMERA_FEED_HEIGHT_PX}
      minmax(${pageMargin.SMALL}px, max-content);
  `;
}
export default LearnerStyles;
