import { FunctionComponent } from 'react';
import { QRCodeImage } from '../../components';
import Lottie from '../../components/ReactLottie/index';
import { SanityImage } from '../types/sanityTypes';
import { Img, LottieContainer } from './InfoPageStyles';
import { ImageTypes } from './InfoPageTypes';

type ImagePartProps = {
  image?: SanityImage;
};

const ImagePart: FunctionComponent<ImagePartProps> = ({ image }) => {
  if (image === undefined) {
    return null;
  }
  if (image.imgType === ImageTypes.webCam) {
    return null;
  }
  if (image.imgType === ImageTypes.QRCode) {
    return (
      <QRCodeImage url={image.src} alt={image.alt ? image.alt : 'A QR Code'} />
    );
  }
  if (image.imgType === ImageTypes.Lottie && image.lottie !== undefined) {
    return (
      <LottieContainer id="lottieContainer">
        <Lottie
          options={image.lottie.options}
          height={image.lottie.height}
          width={image.lottie.width}
        />
      </LottieContainer>
    );
  }
  return image ? (
    <Img
      src={image.src}
      alt={image.alt ? image.alt : 'Some image'}
      imgType={image.imgType}
    />
  ) : null;
};

export default ImagePart;
