class CompressionRateElapsed {
  public compressionRate: number;

  public milliSecondsElapsed: number;

  constructor(compressionRate: number, milliSecondsElapsed: number) {
    this.compressionRate = compressionRate;
    this.milliSecondsElapsed = milliSecondsElapsed;
  }
}
export default CompressionRateElapsed;
