import templateTypes from '../../../../../pageTemplates/types/templateTypes';

const learnerTemplateTypes = {
  ...templateTypes,
  LearnerFrontPageTemplate: 'learnerFrontPageTemplate',
  LearnerRoleSplitTemplate: 'learnerRoleSplitTemplate',
  LearnerShowMyTeamTemplate: 'learnerShowMyTeamTemplate',
  LearnerRaceTemplate: 'learnerRaceTemplate',
};

export default learnerTemplateTypes;
