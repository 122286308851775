import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const IconListRow = styled.div`
  display: flex;
  margin-bottom: 25px;
  align-items: center;
`;

const IconListRowIcon = styled.img`
  width: 52px;
  height: 52px;
  margin-right: 16px;
`;

const IconListRowText = styled.p`
  &&& {
    text-align: left;
    margin: 0;
  }
`;

type Icon = {
  src: string;
  alt: string;
};

export type IconListDataMap = {
  icon: Icon;
  iconImage?: Icon;
  text?: string;
};

type IconListProps = {
  data: Array<IconListDataMap>;
};

const IconList: FunctionComponent<IconListProps> = ({ data }) => {
  return (
    <>
      {data.map(({ icon, text }) => {
        return (
          <IconListRow key={`iconListItem_${text}`}>
            <IconListRowIcon src={icon.src} alt={icon.alt} />
            <IconListRowText>{text}</IconListRowText>
          </IconListRow>
        );
      })}
    </>
  );
};

export default IconList;
