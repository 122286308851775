import AedBubbleTemplate from './aedBubbleTemplate';
import AedDraggablePadPageTemplate from './aedDraggablePadPageTemplate';
import AedTemplate from './aedTemplate';
import DialogPageTemplate from './dialogPageTemplate';
import DiplomaTemplate from './diplomaTemplate';
import EmailReminderTemplate from './emailReminderTemplate/DynamicEmailReminderTemplate';
import EmergencyCallTemplate from './emergencyCallTemplate';
import FrontPageTemplate from './frontPageTemplate';
import InputTemplate from './inputTemplate';
import QuizPageTemplate from './quizPageTemplate';
import RatingPageTemplate from './ratingPageTemplate';
import SelectionTemplate from './selectionTemplate';
import TeamUpTheLearnersTemplate from './teamUpTheLearnersTemplate/TeamUpTheLearnersPage';
import TextAndImageTemplate from './textAndImageTemplate';
import TrainingTemplate from './trainingTemplate';
import VideoPageTemplate from './videoPageTemplate';

export const TemplateList = {
  aedBubbleTemplate: AedBubbleTemplate,
  aedDraggablePadPageTemplate: AedDraggablePadPageTemplate,
  aedTemplate: AedTemplate,
  diplomaTemplate: DiplomaTemplate,
  dialogPageTemplate: DialogPageTemplate,
  emergencyCallTemplate: EmergencyCallTemplate,
  frontPageTemplate: FrontPageTemplate,
  inputTemplate: InputTemplate,
  quizPageTemplate: QuizPageTemplate,
  ratingPageTemplate: RatingPageTemplate,
  textAndImageTemplate: TextAndImageTemplate,
  teamUpTheLearnersTemplate: TeamUpTheLearnersTemplate,
  trainingTemplate: TrainingTemplate,
  videoPageTemplate: VideoPageTemplate,
  emailReminderTemplate: EmailReminderTemplate,
  selectionTemplate: SelectionTemplate,
};
