import styled, { keyframes } from 'styled-components';

export const FINISH_LINE_WIDTH = 10;

namespace Styles {
  const LANE_HEIGHT = 38;
  const LANE_MAX_NUMBER = 16;

  const FINISH_LINE_MARGIN_RIGHT_PCT = '10%';
  const FINISH_LINE_MARGIN_TOP_PX = '30px';
  const FINISH_LINE_WIDTH_PX = `${FINISH_LINE_WIDTH}px`;
  const FINISH_LINE_Z_INDEX = 5;
  export const FinishLineImg = styled.img`
    top: 0;
    right: 0;
    margin-right: ${FINISH_LINE_MARGIN_RIGHT_PCT};
    margin-top: ${FINISH_LINE_MARGIN_TOP_PX};
    position: absolute;
    height: calc(${LANE_HEIGHT * LANE_MAX_NUMBER}px);
    width: ${FINISH_LINE_WIDTH_PX};
    z-index: ${FINISH_LINE_Z_INDEX};
  `;

  const RACE_AREA_MARGIN_TOP_PX = '30px';
  export const RaceArea = styled.div`
    grid-column: 2 / -2;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: ${RACE_AREA_MARGIN_TOP_PX};
  `;

  export const CenterOverlay = styled.div`
    display: grid;
    grid-row: 1 / -1;
    grid-column: 2 / -2;
    justify-content: center;
    align-content: center;
    z-index: ${FINISH_LINE_Z_INDEX + 2};
  `;

  const RACE_FOOTER_MARGIN_PX = '20px';
  export const Footer = styled.footer`
    grid-column: 2 / -2;
    grid-row: 2;
    display: flex;
    justify-content: space-between;
    margin: ${RACE_FOOTER_MARGIN_PX};
  `;

  const FOOTER_HEIGHT_PX = '80px';
  export const PageGrid = styled.div<{
    height: number;
  }>`
    height: ${(props) => props.height}px;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 16px;
    grid-template-rows:
      minmax(max-content, 1fr)
      ${FOOTER_HEIGHT_PX};
  `;

  export const CenteredConnecting = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p {
      font-size: 2.9rem;
      font-family: 'Lato';
      font-weight: 400;
      line-height: 28.8px;
      color: ${(props) => props.theme.colors.neutral_700};
    }
  `;

  export const RaceAmbulanceContainer = styled.div`
    position: relative;
  `;

  const STYLED_LINE_HEIGHT_PX = '20px';
  export const RaceLane = styled.hr`
    background: #cccccc;
    height: ${STYLED_LINE_HEIGHT_PX};
    width: 100%;
  `;

  const AmbulanceAnimation = (startPosition: number, endPosition: number) => {
    return keyframes`
  0% {transform: translateX(${startPosition}px);}
  100% {transform: translateX(${endPosition}px);}
  `;
  };

  const AmbulancePathAnimation = (
    startPosition: number,
    endPosition: number,
  ) => {
    return keyframes`
  0% {width:${startPosition + 2}px;}
  100% {width:${endPosition + 2}px;}
`;
  };

  const STYLED_PATH_HEIGHT_PX = '20px';
  const STYLED_PATH_TOP_PX = '8px';
  export const StyledPath = styled.div<{
    animationDurationS: number;
    color: string;
    endPosition: number;
    leftMargin: number;
    start: boolean;
    startPosition: number;
  }>`
    width: ${(props) => (props.start ? '20' : props.endPosition)}px;
    height: ${STYLED_PATH_HEIGHT_PX};
    background-color: ${(props) => props.color};
    position: absolute;
    left: ${(props) => props.leftMargin}px;
    top: ${STYLED_PATH_TOP_PX};
    animation-name: ${(props) =>
      props.start
        ? AmbulancePathAnimation(props.startPosition, props.endPosition)
        : ''};
    animation-duration: ${(props) => props.animationDurationS}s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    z-index: ${FINISH_LINE_Z_INDEX - 1};
  `;

  const TEAM_LABEL_HEIGHT_PX = '20px';
  const TEAM_LABEL_TOP_PX = '8px';
  const TEAM_LABEL_WIDTH_PX = '30px';
  export const TeamLabel = styled.div<{ color: string }>`
    position: absolute;
    left: 0px;
    top: ${TEAM_LABEL_TOP_PX};
    width: ${TEAM_LABEL_WIDTH_PX};
    height: ${TEAM_LABEL_HEIGHT_PX};
    background-color: ${(props) => props.color};
    color: ${(props) => props.theme.colors.white};
    text-align: center;
    font-family: Handjet;
    font-weight: 900;
    font-size: 1.8rem;
    border-right: 2px solid #ffffff;
  `;

  const RACE_AMBULANCE_HEIGHT_PX = '34px';
  export const RaceAmbulanceImg = styled.img<{
    animationDurationS: number;
    startPosition: number;
    endPosition: number;
    start: boolean;
    leftMargin: number;
    width: number;
  }>`
    position: absolute;
    width: ${(props) => props.width}px;
    height: ${RACE_AMBULANCE_HEIGHT_PX};
    top: 0;
    left: ${(props) => props.leftMargin}px;
    transform: translateX(
      ${(props) => (props.start ? '20px' : props.endPosition + 'px')}
    );
    animation-name: ${(props) =>
      props.start
        ? AmbulanceAnimation(props.startPosition, props.endPosition)
        : ''};
    animation-duration: ${(props) => props.animationDurationS}s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    z-index: ${FINISH_LINE_Z_INDEX + 1};
  `;
}
export default Styles;
