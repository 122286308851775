/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`  
  from {  
    opacity: 0;  
  }    
  to {  
    opacity: 1;  
  }  
`;

const FadeInDiv = styled.div`
  animation: ${(props) => props.theme.fadeInDuration}s ${fadeIn} ease-out
    forwards;
`;

const withFadeIn = (WrappedComponent) => {
  return (props) => (
    <FadeInDiv>
      <WrappedComponent {...props} />
    </FadeInDiv>
  );
};

export default withFadeIn;
