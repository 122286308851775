import { ToastProvider } from '@laerdal/life-react-components';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import Routing from './Routing';
import {
  AudioProvider,
  FirebaseProvider,
  SanityProvider,
  ViewportHeightProvider,
  ViewportProvider,
  ViewportWidthProvider,
} from './context';
import CameraProvider from './context/CameraProvider';
import TcprLinkProvider from './context/TcprLinkProvider';
import ThemeProvider from './context/ThemeProvider';
import UserProvider from './context/UserProvider';
import {
  getLaunchDarklyId,
  isLaunchDarklyEnabled,
} from './helper/launchDarklyHelper';
import { LoadingContentPage } from './pages';
import GlobalStyle from './theme/globalStyles';

const SoloAppProvider = (): JSX.Element => {
  return (
    <ToastProvider>
      <CameraProvider>
        <UserProvider>
          <SanityProvider>
            <FirebaseProvider>
              <ThemeProvider>
                <AudioProvider>
                  <TcprLinkProvider>
                    <LoadingContentPage>
                      <GlobalStyle>
                        <ViewportProvider>
                          <ViewportWidthProvider>
                            <ViewportHeightProvider>
                              <Routing />
                            </ViewportHeightProvider>
                          </ViewportWidthProvider>
                        </ViewportProvider>
                      </GlobalStyle>
                    </LoadingContentPage>
                  </TcprLinkProvider>
                </AudioProvider>
              </ThemeProvider>
            </FirebaseProvider>
          </SanityProvider>
        </UserProvider>
      </CameraProvider>
    </ToastProvider>
  );
};

const SoloAppWithLaunchDarkly = (): JSX.Element => {
  return (
    <LDProvider
      clientSideID={getLaunchDarklyId()}
      context={{ kind: 'user', key: 'anonymous', anonymous: true }}
    >
      <SoloAppProvider />
    </LDProvider>
  );
};

const SoloApp = (): JSX.Element => {
  return (
    <>
      {isLaunchDarklyEnabled() ? (
        <SoloAppWithLaunchDarkly />
      ) : (
        <SoloAppProvider />
      )}
    </>
  );
};

export default SoloApp;
