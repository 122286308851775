import { FunctionComponent } from 'react';
import { useHeightViewport } from '../../../../../context';
import { useLearnerConfettiEffect } from '../../../features/confetti';
import LearnerStyles from '../../learner.styles';
import TopBar from '../TopBar';
import Styles from './ResultPage.styles';
import ResultPageProps from './ResultPage.types';

const ResultPage: FunctionComponent<ResultPageProps> = ({
  learnerTeam,
  header,
  body,
  trophy,
}) => {
  useLearnerConfettiEffect(learnerTeam.position);

  const { innerHeight } = useHeightViewport();
  return (
    <LearnerStyles.PageGrid height={innerHeight}>
      <TopBar learnerTeam={learnerTeam} />
      <Styles.Image src={trophy.src} alt={trophy.alt} />
      <Styles.CenterBottomContent>
        <Styles.PlacementText>{header}</Styles.PlacementText>
        <Styles.DescriptionText>{body}</Styles.DescriptionText>
      </Styles.CenterBottomContent>
    </LearnerStyles.PageGrid>
  );
};

export default ResultPage;
