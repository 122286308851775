/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/lines-between-class-members */
class ImageBlock {
  xPosition = 0;
  yPosition = 0;
  ranges: number[] = [];
  private sumOfChangesHistory: number[];
  lastCalculatedSumOfChangesSum = 0;

  constructor(xPosition: number, yPosition: number, numberOfRangePairs) {
    this.xPosition = xPosition;
    this.yPosition = yPosition;
    this.ranges = new Array(numberOfRangePairs * 2).fill(0);
    this.sumOfChangesHistory = [];
  }

  fillRanges(
    imageWidth: number,
    horizontalBlocksize: number,
    desiredBlocksize: number,
  ): void {
    for (
      let i = 0, verticalPositionCounter = 0;
      i < this.ranges.length;
      i += 2, verticalPositionCounter++
    ) {
      const from =
        desiredBlocksize * this.xPosition +
        verticalPositionCounter * imageWidth +
        imageWidth * desiredBlocksize * this.yPosition;
      const to = from + horizontalBlocksize - 1;
      this.ranges[i] = from;
      this.ranges[i + 1] = to;
    }
  }

  calculateSumOfChangesInBlock(
    differenceImage: number[],
    numberOfImagesInCurrentSignal: number,
  ): number {
    let sum = 0;
    for (
      let rangeCounter = 0;
      rangeCounter < this.ranges.length;
      rangeCounter += 2
    ) {
      for (
        let i: number = this.ranges[rangeCounter];
        i <= this.ranges[rangeCounter + 1];
        i++
      ) {
        sum += differenceImage[i];
      }
    }
    this.sumOfChangesHistory.push(sum);
    while (this.sumOfChangesHistory.length > numberOfImagesInCurrentSignal) {
      this.sumOfChangesHistory.shift(); // Remove first element in array
    }
    return sum;
  }

  calculateSumOfChangesSum(numberOfFramesToUse: number): void {
    this.lastCalculatedSumOfChangesSum = 0;
    const startIx = this.sumOfChangesHistory.length - 1;
    const endIx = Math.max(startIx - numberOfFramesToUse, 0);

    for (let i = startIx; i >= endIx; i--) {
      this.lastCalculatedSumOfChangesSum += Math.abs(
        this.sumOfChangesHistory[i],
      );
    }
  }

  sumOfChangesList(numberOfFrames: number): number[] {
    const result = new Array(numberOfFrames);
    result.fill(0);
    for (let i = 0; i < result.length; i++) {
      result[result.length - 1 - i] =
        this.sumOfChangesHistory[this.sumOfChangesHistory.length - 1 - i];
    }
    return result;
  }

  numberOfHistoricChangesSums = (): number => this.sumOfChangesHistory.length;
}

export default ImageBlock;
