import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ModalProps } from '../../components/Modals';
import { AnimationState } from '../../components/TopBars/types';
import WithFadeIn from '../../components/WithFadeIn';
import WithFocus from '../../components/WithFocus';
import { useAudio, useSanityCourse } from '../../context';
import { UseAbly } from '../../features/groupRevivr/context/AblyContext';
import { sanityFunctions } from '../../features/sanity';
import { useNavigation } from '../../hooks';
import InfoPage from '../Templates/InfoPage';
import { Button, CountDown } from '../Templates/InfoPageTypes';
import {
  PageWithTemplate,
  TeamUpTheLearnersTemplate,
  isFacilitatorPageWithTemplate,
} from '../types/courseTypes';

const TeamUpTheLearnersPage: FunctionComponent = () => {
  const [currentPage, setCurrentPage] = useState<TeamUpTheLearnersTemplate>({
    text: undefined,
    verticalButtons: false,
    showTimeLeftBar: true,
    enableAudio: false,
    image: undefined,
    buttons: undefined,
  });
  const pageContext = useSanityCourse();
  const navigationContext = useNavigation();
  const { teamUpLearners } = UseAbly();
  const [renderedPage, setRenderedPage] = useState<
    PageWithTemplate | undefined
  >(undefined);

  const [buttons, setButtons] = useState<Button[] | undefined>(undefined);
  const animationRef = useRef<HTMLDivElement>(null);
  const [modal, setModal] = useState<ModalProps | undefined>();
  const audio = useAudio();
  const [anim, setAnim] = useState<AnimationState>(AnimationState.paused);
  const [countDown, setCountDown] = useState<CountDown | undefined>({
    duration: 10,
    delay: 1,
    animationState: AnimationState.paused,
    setAnimRef: animationRef,
  });

  useEffect(() => {
    const tempPage = pageContext.getCurrentPage();
    if (
      isFacilitatorPageWithTemplate(tempPage) &&
      tempPage.teamUpTheLearnersTemplate !== undefined
    ) {
      const facilitatorPage = tempPage.teamUpTheLearnersTemplate;
      setCurrentPage(facilitatorPage);

      setButtons(
        sanityFunctions.createButtonsFromSanityButtons(
          facilitatorPage.buttons,
          navigationContext,
        ),
      );

      teamUpLearners();
      window.revivr.isTeamedUpSession = true;
    }
  }, []);

  return (
    <InfoPage
      buttons={buttons}
      image={currentPage.image}
      text={currentPage.text}
      showTimeLeftBar={currentPage.showTimeLeftBar}
      verticalButtons={currentPage.verticalButtons}
      enableAudio={currentPage.enableAudio}
    />
  );
};

export default WithFadeIn(WithFocus(TeamUpTheLearnersPage));
