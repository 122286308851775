/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import ModalProps from '../../components/Modals/ModalTypes';
import { AnimationState } from '../../components/TopBars/types';
import WithFadeIn from '../../components/WithFadeIn';
import WithFocus from '../../components/WithFocus';
import { useSanityCourse } from '../../context';
import autoNavigationHelper from '../../helper/autoNavigationHelper';
import { useNavigation } from '../../hooks';
import AedSpeechBubbleTemplate from '../Templates/AEDPages/AedSpeechBubbleTemplate';
import { CountDown } from '../Templates/InfoPageTypes';
import { AedBubbleTemplate } from '../types/courseTypes';

const AedBubbleTemplatePage: FunctionComponent = () => {
  const courseContext = useSanityCourse();
  const navigationContext = useNavigation();
  const [currentPage, setCurrentPage] = useState<AedBubbleTemplate>({
    image: undefined,
    dialogText: undefined,
    text: undefined,
    continueCourseModal: undefined,
  });
  const animationRef = useRef<HTMLDivElement>(null);
  const [anim, setAnim] = useState<AnimationState>(AnimationState.paused);
  const [countDown, setCountDown] = useState<CountDown>({
    duration: autoNavigationHelper.autoPlayDuration(10),
    delay: autoNavigationHelper.autoPlayDelay(1),
    animationState: AnimationState.paused,
    setAnimRef: animationRef,
  });
  const [modal, setModal] = useState<ModalProps | undefined>();

  useEffect(() => {
    const tempPage = courseContext.getCurrentPage().aedBubbleTemplate;
    if (tempPage === undefined) {
      return;
    }
    setCurrentPage(tempPage);
    setAnim(AnimationState.running);
  }, []);

  const onAnimated = () => {
    navigationContext?.navigateToNextPage();
  };

  useEffect(() => {
    let someRef: HTMLDivElement | null = null;
    setCountDown({
      ...countDown,
      animationState: anim,
      setAnimRef: animationRef,
    });
    if (animationRef && animationRef.current) {
      someRef = animationRef.current;
      someRef.addEventListener('animationend', onAnimated, {
        once: true,
      });
    }

    return () => {
      if (someRef) {
        someRef.removeEventListener('animationend', onAnimated);
      }
    };
  }, [anim]);

  return (
    <AedSpeechBubbleTemplate
      continueCourseModal={currentPage.continueCourseModal}
      countDown={countDown}
      dialogueText={currentPage.dialogText}
      lottie={currentPage.lottie}
      modal={modal}
      operatorImage={currentPage.image}
      playAudioOnLoad
      practiceAgainModal
      text={currentPage.text}
    />
  );
};

export default WithFadeIn(WithFocus(AedBubbleTemplatePage));
