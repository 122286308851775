import { css, styled } from 'styled-components';
import { ButtonContainer } from '../Templates/InfoPageStyles';
import mediaMedium from '../Templates/MediaMedium';

const StyledButtonContainer = styled(ButtonContainer)`
  ${mediaMedium(css<{ verticalButtons?: boolean }>`
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 10px;
  `)}
`;

export default {
  StyledButtonContainer,
};
