/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable max-classes-per-file */

import Compression from './Compression';
import SessionResult from './SessionResult';

const SessionResultCalculator = (
  allCompressions: Array<Array<Compression>>,
): SessionResult => {
  let secondsToFirstCompression = -1;
  const handsOffTime = 0;
  let totalCompressionTime = 0;
  let totalTimeWithUnknownRate = 0;
  let averageCompressionRateOfRatesAboveZero = 0;
  let totalNumberOfCompressions = 0;

  const finalCompressionList = new Array<Compression>();

  if (allCompressions.length > 0) {
    for (let i = 0; i <= allCompressions.length - 1; i++) {
      if (allCompressions[i].length === 1) {
        finalCompressionList.push(allCompressions[i][0]);
      } else {
        for (let j = 0; j <= allCompressions[i].length - 1; j++) {
          try {
            if (j === allCompressions[i].length - 1) {
              finalCompressionList.push(allCompressions[i][j]);
              continue;
            }
          } catch (exception) {
            console.error(exception);
          }

          try {
            const positionInFinalCompressionList =
              finalCompressionList.length - allCompressions[i].length + j + 1;
            finalCompressionList[positionInFinalCompressionList].rate =
              allCompressions[i][j].rate;
          } catch (exception) {
            console.error(exception);
          }
        }
      }
    }

    if (finalCompressionList.length > 14) {
      // This function filters peaks lasting less than two seconds, peaks caused by noise
      for (let i = 1; i <= finalCompressionList.length - 1 - 4; i++) {
        if (finalCompressionList[i].rate > 40) {
          if (finalCompressionList[i - 1].rate < 40) {
            if (
              finalCompressionList[i + 1].rate < 40 ||
              finalCompressionList[i + 2].rate < 40 ||
              finalCompressionList[i + 3].rate < 40 ||
              finalCompressionList[i + 4].rate < 40
            ) {
              finalCompressionList[i].rate = 0;
            }
          }
        }
      }

      // Help variable
      let x = 0;
      // Detect compression start in final compression list
      for (let i = 1; i <= finalCompressionList.length - 13; i++) {
        if (x === 0) {
          if (
            finalCompressionList[i].rate > 40 &&
            Math.abs(
              finalCompressionList[i + 1].rate - finalCompressionList[i].rate,
            ) < 30 &&
            Math.abs(
              finalCompressionList[i + 2].rate - finalCompressionList[i].rate,
            ) < 30 &&
            Math.abs(
              finalCompressionList[i + 3].rate - finalCompressionList[i].rate,
            ) < 30 &&
            Math.abs(
              finalCompressionList[i + 4].rate - finalCompressionList[i].rate,
            ) < 30 &&
            Math.abs(
              finalCompressionList[i + 5].rate - finalCompressionList[i].rate,
            ) < 30 &&
            Math.abs(
              finalCompressionList[i + 6].rate - finalCompressionList[i].rate,
            ) < 30 &&
            Math.abs(
              finalCompressionList[i + 7].rate - finalCompressionList[i].rate,
            ) < 30 &&
            Math.abs(
              finalCompressionList[i + 8].rate - finalCompressionList[i].rate,
            ) < 30 &&
            Math.abs(
              finalCompressionList[i + 9].rate - finalCompressionList[i].rate,
            ) < 30 &&
            Math.abs(
              finalCompressionList[i + 10].rate - finalCompressionList[i].rate,
            ) < 30 &&
            Math.abs(
              finalCompressionList[i + 11].rate - finalCompressionList[i].rate,
            ) < 30 &&
            Math.abs(
              finalCompressionList[i + 12].rate - finalCompressionList[i].rate,
            ) < 30
          ) {
            // Adding 1.5 because of delay (the 3 seconds time window needs to)
            secondsToFirstCompression =
              (finalCompressionList[i].mse - 1500) / 1000;
            x++;
          }
        }
      }

      totalNumberOfCompressions = 0;

      for (let i = 1; i <= finalCompressionList.length - 1; i++) {
        const compression = finalCompressionList[i];
        if (compression.rate > 40) {
          const mseBetweenDetections =
            compression.mse - finalCompressionList[i - 1].mse;
          totalCompressionTime += mseBetweenDetections;

          const totalNumberOfEstimatedCompressions =
            (compression.rate * mseBetweenDetections) / 60;
          totalNumberOfCompressions += totalNumberOfEstimatedCompressions;
        }
      }

      totalNumberOfCompressions /= 1000;

      totalCompressionTime /= 1000;

      totalTimeWithUnknownRate =
        finalCompressionList[finalCompressionList.length - 1].mse / 1000;
      totalTimeWithUnknownRate -= totalCompressionTime;

      averageCompressionRateOfRatesAboveZero =
        (totalNumberOfCompressions / totalCompressionTime) * 60;
    }
  }

  return new SessionResult(
    secondsToFirstCompression,
    handsOffTime,
    totalCompressionTime,
    totalTimeWithUnknownRate,
    averageCompressionRateOfRatesAboveZero,
    totalNumberOfCompressions,
  );
};

export default SessionResultCalculator;
