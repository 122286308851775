/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-restricted-globals */
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CommonNavigationProvider,
  NavigationContextProps,
  useCommonNavigation,
  useSanityCourse,
  useUser,
} from '../../../../context';
import { addProjectQueryStringToUrl } from '../../../../helper/UrlHelper';
import {
  FacilitatorPageWithTemplate,
  LearnerPageWithTemplate,
  PageWithTemplate,
} from '../../../../pageTemplates/types/courseTypes';
import { LocalStorageService } from '../../../../services';
import { UseAbly } from '../../context/AblyContext';
import { isGroupLearnerTrainingStartPage } from '../../helper/GroupTrainingUrl';
import learnerTemplateTypes from '../templates/types/learnerTemplateTypes';

export const LearnerNavigationContext =
  React.createContext<NavigationContextProps>({} as NavigationContextProps);

const LearnerNavigationProvider: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }) => {
  const commonNavigation = useCommonNavigation();
  const { navigateToNextPageCommand, connectLearner, getLearnerTeamRole } =
    UseAbly();
  const [previousPathCommand, setPreviousPathCommand] = useState('');
  const [previousPageTemplate, setPreviousPageTemplate] = useState('');
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const pageContext = useSanityCourse();
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useUser();
  const { LearnerRoleSplitTemplate, TrainingTemplate } = learnerTemplateTypes;

  const getLearnerSplitRoleTemplate = (
    currentPage:
      | PageWithTemplate
      | FacilitatorPageWithTemplate
      | LearnerPageWithTemplate,
  ): string => {
    const splitRolePage = currentPage as LearnerPageWithTemplate;
    const currentRole = `role${getLearnerTeamRole()}`;
    if (splitRolePage.learnerRoleSplitTemplate) {
      const splitRoleTemplate =
        splitRolePage.learnerRoleSplitTemplate[currentRole].template;
      return splitRoleTemplate;
    }
    return '';
  };

  const getPageTemplate = (currentPath?: string) => {
    if (!currentPath) {
      return '';
    }

    const currentPageIndex = pageContext?.course.findIndex(
      (a) => a?.url === currentPath,
    );
    if (currentPageIndex === -1) {
      return '';
    }
    const currentPage = pageContext?.course[currentPageIndex];
    if (currentPage.template !== LearnerRoleSplitTemplate) {
      return currentPage.template;
    }
    return getLearnerSplitRoleTemplate(currentPage);
  };

  useEffect(() => {
    const pageTemplate = getPageTemplate(navigateToNextPageCommand?.currentUrl);
    if (!pageTemplate) {
      return;
    }

    // if previous page template was trainingTemplate and
    // current page template is trainingTemplate then
    // do not push current page url to avoid reseting the training
    if (
      pageTemplate &&
      pageTemplate === TrainingTemplate &&
      previousPageTemplate === TrainingTemplate
    ) {
      return;
    }
    setPreviousPageTemplate(pageTemplate);

    // Makes sure the user is not sent to the same page while they are waiting.
    // This is necessary because a new navigation command is sent
    // whenever a new user joins.
    if (
      navigateToNextPageCommand &&
      navigateToNextPageCommand.currentUrl !== previousPathCommand
    ) {
      setPreviousPathCommand(navigateToNextPageCommand.currentUrl);
      navigate(
        addProjectQueryStringToUrl(
          navigateToNextPageCommand.currentUrl,
          userContext.sanityProjectId,
        ),
      );
    }
  }, [navigateToNextPageCommand]);

  useEffect(() => {
    if (initialRender) {
      if (!isGroupLearnerTrainingStartPage()) {
        const classroomcode = parseInt(
          LocalStorageService.getClassroomCodeFromLocalStorage() ?? '',
          10,
        );
        connectLearner(classroomcode);
      }
      setInitialRender(false);
    }
  }, [location]);

  return (
    <LearnerNavigationContext.Provider
      value={{
        ...commonNavigation,
        initialRender,
      }}
    >
      {children}
    </LearnerNavigationContext.Provider>
  );
};

const LearnerNavigationExportProvider: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }) => {
  return (
    <CommonNavigationProvider>
      <LearnerNavigationProvider>{children}</LearnerNavigationProvider>
    </CommonNavigationProvider>
  );
};

export default LearnerNavigationExportProvider;
