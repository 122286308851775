import constants from '../constants/Constants';

/* eslint-disable import/prefer-default-export */
export const addProjectQueryStringToUrl = (
  nextPageUrl: string,
  projectId: string,
): string => {
  // Check if it already exists in the url
  if (nextPageUrl.indexOf(constants.queryKeys.SANITY_PROJECT_KEY) > -1) {
    return nextPageUrl;
  }

  // Check if there are querystring parametrs already in place
  const querystringCombinationChar = nextPageUrl.indexOf('?') > -1 ? '&' : '?';

  const nextPageUrlQuerystring = new URLSearchParams();
  nextPageUrlQuerystring.set(constants.queryKeys.SANITY_PROJECT_KEY, projectId);
  return `${nextPageUrl}${querystringCombinationChar}${nextPageUrlQuerystring.toString()}`;
};

export const addLanguageQueryStringToUrl = (
  nextPageUrl: string,
  languageCode: string,
): string => {
  // Check if it already exists in the url
  if (
    !languageCode ||
    nextPageUrl.indexOf(constants.queryKeys.LANGUAGE_CODE_KEY) > -1
  ) {
    return nextPageUrl;
  }

  // Check if there are querystring parametrs already in place
  const querystringCombinationChar = nextPageUrl.indexOf('?') > -1 ? '&' : '?';

  const nextPageUrlQuerystring = new URLSearchParams();
  nextPageUrlQuerystring.set(
    constants.queryKeys.LANGUAGE_CODE_KEY,
    languageCode,
  );
  return `${nextPageUrl}${querystringCombinationChar}${nextPageUrlQuerystring.toString()}`;
};

/**
 *  Removes the protocol from the URL
 * (www. https:// http://) */
export const removeProtocolFromUrl = (url: string): string => {
  return url.replace(/(^\w+:|^)\/\//, '');
};

export const isValidExternalUrl = (url: string) => {
  let testUrl;
  try {
    testUrl = new URL(url);
  } catch (_) {
    return false;
  }
  return testUrl.protocol === 'http:' || testUrl.protocol === 'https:';
};

/**
 * Making sure the Url is valid external url, or a string that starts with a '/', adds one if it's missing
 *
 * @param url a string to check
 * @returns the url, if it is a valid external url, else it will return a string starting with a '/',
 */
export const urlChecker = (url: string): string => {
  if (!url) {
    return url;
  }
  if (isValidExternalUrl(url)) {
    return url;
  }
  return url.charAt(0) === '/' ? url : `/${url}`;
};

export const getQuerystringParameter = (parameter: string): string | null => {
  const params = new URLSearchParams(window.location.search);
  return params.get(parameter);
};

export const getCurrentUrl = () => {
  return window.location.origin;
};

export const isLocalhost = () => {
  const { hostname } = window.location;
  return hostname === 'localhost' || hostname === '127.0.0.1';
};
