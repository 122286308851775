import { ModalProps } from "../../components/Modals";
import { NavigationContextProps } from "../../context";
import { sanityFunctions } from "../../features/sanity";
import { createTextAndImageBody } from "../../features/sanity/SanityServiceMapper";
import { SanityModal } from "../types/sanityTypes";

export const showModal = (buttonModal: SanityModal | undefined, setModal: (value: React.SetStateAction<ModalProps>) => void, navigationContext: NavigationContextProps) => {  
    if (buttonModal === undefined) {  
      setModal(undefined);  
      return;  
    }
    const showModalPrefilled = (innerButtonModal: SanityModal | undefined) => {
        showModal(innerButtonModal, setModal, navigationContext);
    }
    const newModal: ModalProps = {  
      onClose: () => {  
        setModal(undefined);  
      },  
      text: {  
        header: buttonModal.text?.header,  
        header2: buttonModal.text?.header2,  
        body: createTextAndImageBody(  
          buttonModal?.text?.body,  
          buttonModal?.text?.bulletPoints,  
        ),  
      },  
      bannerImage: buttonModal.bannerImage,  
      buttons: sanityFunctions.createButtonsFromSanityButtons(  
        buttonModal.buttons,  
        navigationContext,  
        showModalPrefilled,  
      ),  
      verticalButtons: buttonModal.verticalButtons,  
    };  
    setModal(newModal);  
  };  

  
  