/* eslint-disable react/no-unused-prop-types */

import {
  defaultOnMouseDownHandler,
  focusStyles,
  SystemIcons,
} from '@laerdal/life-react-components';
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import asteriskSvg from '../../assets/images/asterisk.svg';
import Button from '../../components/Button';
import { Call911Modal, GenericModal } from '../../components/Modals/Modals';
import { TimeLeftWithText } from '../../components/TopBars';
import constants from '../../constants/Constants';
import { AccessibilityAnnouncer } from '../../features/accessibility';
import { DisplayTeamName } from '../../features/groupRevivr/components';
import { useNavigation } from '../../hooks';
import {
  PageGridStyle,
  ProgressbarContainer,
} from '../../pageTemplates/Templates/InfoPageStyles';
import { EmergencyCallTemplateProps } from '../../pageTemplates/types/courseTypes';
import phoneCallImage from '/images/call-icon.svg';

const ANIMATIONLENGTH = 500;

type GetDialsProps = {
  buttonClick: (val: string) => void;
  animate?: boolean;
};

const getDials = ({ buttonClick, animate }: GetDialsProps) => {
  const dials: Array<DialButtonProps> = [
    {
      onClick: buttonClick,
      text: '1',
      animate,
    },
    {
      onClick: buttonClick,
      text: '2',
      animate,
    },
    {
      onClick: buttonClick,
      text: '3',
      animate,
    },
    {
      onClick: buttonClick,
      text: '4',
      animate,
    },
    {
      onClick: buttonClick,
      text: '5',
      animate,
    },
    {
      onClick: buttonClick,
      text: '6',
      animate,
    },
    {
      onClick: buttonClick,
      text: '7',
      animate,
    },
    {
      onClick: buttonClick,
      text: '8',
      animate,
    },
    {
      onClick: buttonClick,
      text: '9',
      animate,
    },
    {
      onClick: buttonClick,
      text: '*',
      svg: asteriskSvg,
    },
    {
      onClick: buttonClick,
      text: '0',
    },
    {
      onClick: buttonClick,
      text: '#',
    },
  ];

  return dials;
};

type DialButtonProps = {
  text?: string;
  subText?: string;
  onClick?: (val: string) => void;
  animate?: boolean;
  svg?: string;
};

const MainContent = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  width: 100%;
  max-width: ${constants.maxPageWidthMobile}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const VCenterContent = styled.div`
  grid-row: 2;
  grid-column: 2 / -2;
  align-self: center;
`;
const Grid = styled(PageGridStyle)`
  grid-template-rows:
    minmax(32px, max-content)
    minmax(max-content, 1fr)
    18px;
`;

const ButtonDialContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonDialCallContainer = styled(ButtonDialContainer)`
  grid-column: 1/4;
  align-items: center;
`;

const DialAnimation = keyframes`
  100% { transform: scale(1.1) }
`;

const ButtonDialContent = styled.button<{
  animate?: boolean;
}>`
  height: 70px;
  width: 70px;
  border-radius: 35px;
  cursor: pointer;
  background: ${(props) => props.theme.colors.neutral_200};
  justify-content: center;
  align-items: center;
  display: flex;
  border: none;
  flex-direction: column;
  animation-name: ${(props) => (props.animate ? DialAnimation : '')};
  animation-duration: 0.5s;
  box-shadow: ${(props) => (props.animate ? '0px 0px 20px 2px #4cd964' : '')};
  &:focus,
  &.focus-state {
    ${() => focusStyles}
  }
`;

const ButtonDialContentCall = styled(ButtonDialContent)`
  background-image: url(${phoneCallImage});
  background-color: rgb(4, 160, 110);
  background-size: cover;
`;

const Dial = styled.main`
  margin: auto;
  display: grid;
  width: 250px;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
`;

const ButtonDialMainText = styled.p`
  font-size: 3.23418rem;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.33293px;
  color: ${(props) => props.theme.colors.neutral_800};
`;
const ButtonDialSubText = styled.p`
  font-family: SF Pro Text;
  font-weight: bold;
  font-size: 0.898383rem;
  line-height: 9px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
`;

const ButtonContainer = styled.footer`
  display: flex;
  justify-content: center;
  height: 6rem;
  min-height: 60px;
`;
const HeaderContainer = styled.header`
  display: flex;
  text-align: ${(props) => props.theme.textAlignment};
  height: 6rem;
  min-height: 60px;
  justify-content: center;
`;
const Header = styled.h1`
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 41px;
  letter-spacing: 0.370588px;
  align-self: flex-end;
  color: ${(props) => props.theme.colors.neutral_800};
  margin-bottom: 20px;
`;

const SimulationCallNumberHeaderTextContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const SimulationCallNumberHeaderText = styled.p`
  font-size: 1.4rem;
  letter-spacing: 0.370588px;
  color: ${(props) => props.theme.colors.neutral_800};
  margin-left: 5px;
`;

const SimulationCallNumberText = styled.p`
  font-weight: 500;
  font-size: 3.6rem;
  text-align: center;
  letter-spacing: 0.370588px;
  color: ${(props) => props.theme.colors.neutral_800};
`;

const BottomText = styled.p`
  margin-top: 20px;
  font-weight: 400;
  font-size: 1.7rem;
  text-align: center;
  color: ${(props) => props.theme.colors.neutral_400};
`;

const CallDialButton = ({ animate, onClick }: DialButtonProps) => {
  return (
    <ButtonDialCallContainer role="navigation" aria-label="Main">
      <ButtonDialContentCall
        id="CallDialButton"
        onMouseDown={defaultOnMouseDownHandler}
        aria-label="call dial"
        role="button"
        onClick={() => {
          if (onClick === undefined) {
            return;
          }
          onClick('');
        }}
        animate={animate}
      />
    </ButtonDialCallContainer>
  );
};
const DialButton = ({
  text,
  subText,
  onClick,
  animate,
  svg,
}: DialButtonProps) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const animateDial = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, ANIMATIONLENGTH);
  };

  useEffect(() => {
    if (animate === true) {
      animateDial();
    }
  }, [animate]);

  return (
    <ButtonDialContainer>
      <ButtonDialContent
        id={text}
        aria-label={`${text} dial  `}
        type="button"
        onMouseDown={defaultOnMouseDownHandler}
        animate={isAnimating}
        onClick={() => {
          if (onClick === undefined || text === undefined) {
            return;
          }

          onClick(text);
        }}
      >
        {svg !== undefined && (
          <img width="25" height="25" src={svg} alt="Asterisk button" />
        )}
        {svg === undefined && <ButtonDialMainText>{text}</ButtonDialMainText>}
        {!svg && subText && <ButtonDialSubText>{subText}</ButtonDialSubText>}
      </ButtonDialContent>
    </ButtonDialContainer>
  );
};

const SimulationCallNumberContainer = styled.div``;

interface SimulationCallNumberProps {
  phoneNumber: string;
  headerText: string;
}

const SimulationCallNumber = ({
  phoneNumber,
  headerText,
}: SimulationCallNumberProps) => {
  return (
    <SimulationCallNumberContainer>
      <SimulationCallNumberHeaderTextContainer>
        <SystemIcons.Information size="18px" />
        <SimulationCallNumberHeaderText>
          {headerText}
        </SimulationCallNumberHeaderText>
      </SimulationCallNumberHeaderTextContainer>
      <SimulationCallNumberText>{phoneNumber}</SimulationCallNumberText>
    </SimulationCallNumberContainer>
  );
};

const DialButtons = ({ dials, numberToAnimate }) => {
  return (
    <>
      {dials.map((dialData) => {
        const animate = numberToAnimate === dialData.text;
        return (
          <DialButton
            key={dialData.text}
            animate={animate}
            subText={dialData.subText}
            svg={dialData.svg}
            onClick={() => {
              if (
                dialData.onClick !== undefined &&
                dialData.text !== undefined
              ) {
                dialData.onClick(dialData.text);
              }
            }}
            text={dialData.text}
          />
        );
      })}
    </>
  );
};

const Page: React.FunctionComponent<EmergencyCallTemplateProps> = ({
  bottomText,
  call911ModalHeaderText,
  call911ModalBodyText,
  call911ModalImage,
  call911ModalPrimaryButton,
  call911ModalSecondaryButton,
  displayTeamName,
  headerText,
  infoHeaderText,
  numberToCall,
  practiceCallingText,
  showBottomButton,
  showBottomText,
  showCall911Modal,
  showTimeLeftBar,
  whyTrainButton,
  whyTrainModalBodyText,
  whyTrainModalButtonText,
  whyTrainModalHeaderText,
}: EmergencyCallTemplateProps) => {
  const navigationContext = useNavigation();
  const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
  const [number, setNumber] = useState<string>('');
  const [isCallButtonAnimating, setIsCallButtonAnimating] =
    useState<boolean>(false);
  const [showCallModal, setshowCallModal] = useState<boolean>(false);
  const [showAudioModal, setshowAudioModal] = useState<boolean>(false);
  const [numberToAnimate, setNumberToAnimate] = useState<string>('');
  const [accessibilityAnimationMessage, setAccessibilityAnimationMessage] =
    useState<string>('');

  const animateCallButton = () => {
    setIsCallButtonAnimating(true);
    setAccessibilityAnimationMessage(`you should click the call button.`);
    setTimeout(() => {
      setIsCallButtonAnimating(false);
      setAccessibilityAnimationMessage(``);
    }, ANIMATIONLENGTH);
  };

  const animateDialButton = () => {
    setNumberToAnimate(numberToCall[number.length]);
    setAccessibilityAnimationMessage(
      `you should dial number ${numberToCall[number.length]}.`,
    ); // The text needs to be updated to be red out!
    setTimeout(() => {
      setNumberToAnimate('');
      setAccessibilityAnimationMessage(``);
    }, ANIMATIONLENGTH);
  };

  const buttonClick = (val: string) => {
    if (number.length === 3) {
      animateCallButton();
      return;
    }

    if (val !== numberToCall[number.length]) {
      animateDialButton();
      return;
    }

    setNumber(number + val);
  };

  const callButtonClick = () => {
    if (number.length === 3) {
      if (showCall911Modal) {
        setshowAudioModal(true);
      } else {
        navigationContext?.navigateToNextPageWithSleepCheck();
      }
    } else {
      animateDialButton();
    }
  };

  window.addEventListener('resize', () => {
    setInnerHeight(window.innerHeight);
  });
  window.addEventListener('orientationchange', () => {
    setInnerHeight(window.innerHeight);
  });

  const dials = getDials({
    buttonClick,
  });

  const onKeyUpEvent = (event) => {
    if (event.key === 'Tab') return;
    const focusedElement = document?.activeElement?.tagName;
    if (event.key === 'Enter' || event.key === 'Space') {
      if (focusedElement === 'BUTTON') return; // To prevent call event on enter, if a button is selected.
      callButtonClick();
      return;
    }
    buttonClick(event.key);
  };

  useEffect(() => {
    document.addEventListener('keyup', onKeyUpEvent);
    return () => {
      document.removeEventListener('keyup', onKeyUpEvent);
    };
  }, [numberToCall, number]);

  return (
    <MainContent height={innerHeight}>
      <DisplayTeamName enabled={displayTeamName} />
      <AccessibilityAnnouncer message={accessibilityAnimationMessage} />
      <GenericModal
        isVisible={showCallModal}
        toggleModal={() => setshowCallModal(false)}
        headerText={whyTrainModalHeaderText}
        bodyText={whyTrainModalBodyText}
        buttonText={whyTrainModalButtonText}
      />

      <Call911Modal
        headerText={call911ModalHeaderText}
        bodyText={call911ModalBodyText}
        imageAlt={call911ModalImage?.alt}
        imageSource={call911ModalImage?.src}
        primaryButtonText={call911ModalPrimaryButton}
        secondaryButtonText={call911ModalSecondaryButton}
        isVisible={showAudioModal}
        toggleModal={() => {
          navigationContext?.navigateToNextPageWithSleepCheck();
        }}
        continueLaterClicked={() => {
          window.location.href = constants.endPoints.saveForLater;
        }}
      />
      <Grid height={innerHeight}>
        {number.length <= 0 && (
          <ProgressbarContainer>
            <TimeLeftWithText enabled={showTimeLeftBar} />
          </ProgressbarContainer>
        )}

        <VCenterContent>
          <HeaderContainer>
            {number.length <= 0 && <Header tabIndex={-1}>{headerText}</Header>}
            {number.length > 0 && (
              <SimulationCallNumber
                phoneNumber={number}
                headerText={infoHeaderText}
              />
            )}
          </HeaderContainer>
          <Dial>
            <DialButtons dials={dials} numberToAnimate={numberToAnimate} />
            <CallDialButton
              animate={isCallButtonAnimating}
              onClick={callButtonClick}
            />
          </Dial>

          <ButtonContainer role="presentation">
            {showBottomText && <BottomText>{bottomText}</BottomText>}
            {showBottomButton && number.length <= 0 && (
              <Button
                variant="secondary"
                onClick={() => {
                  setshowCallModal(true);
                }}
              >
                {whyTrainButton}
              </Button>
            )}
          </ButtonContainer>
        </VCenterContent>
      </Grid>
    </MainContent>
  );
};

export default Page;
