/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-restricted-globals */
import React, { ReactNode } from 'react';
import CommonNavigationProvider from './CommonNavigationProvider';
import { NavigationContextProps } from './navigationContextProps';
import useCommonNavigation from './useCommonNavigation';

export const SoloNavigationContext =
  React.createContext<NavigationContextProps>({} as NavigationContextProps);

const SoloNavigationProvider: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }) => {
  const commonNavigation = useCommonNavigation();

  return (
    <SoloNavigationContext.Provider
      value={{
        ...commonNavigation,
      }}
    >
      {children}
    </SoloNavigationContext.Provider>
  );
};

const SoloNavigationExportProvider: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }) => {
  return (
    <CommonNavigationProvider>
      <SoloNavigationProvider>{children}</SoloNavigationProvider>
    </CommonNavigationProvider>
  );
};

export default SoloNavigationExportProvider;
