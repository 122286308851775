/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect, useState } from 'react';
import ic_operator_on from '../../assets/images/Illustration 11.1-left.png';
import ic_operator from '../../assets/images/Illustration 11.6.png';
import animationData from '../../assets/lotties/AED1.json';
import { ModalProps } from '../../components/Modals';
import { LottieProps } from '../../components/ReactLottie';
import WithFadeIn from '../../components/WithFadeIn';
import WithFocus from '../../components/WithFocus';
import { useSanityCourse } from '../../context';
import { useNavigation } from '../../hooks';
import { Text } from '../Templates/Text';
import { DialogueImage } from '../dialogPageTemplate/DialogueImage';
import { DialogueText } from '../dialogPageTemplate/DialogueText';
import { ContinueCourseModal } from '../dialogPageTemplate/types/ContinueCourseModal';
import { AedTemplateProps } from '../types/courseTypes';
import { SanityImage } from '../types/sanityTypes';
import AedTemplatePage, { AEDbuttonImg } from './AedTemplatePage';

interface CurrentPageProps {
  dialogueText?: DialogueText;
  text?: Text;
  image?: SanityImage;
  aedButtonImg?: AEDbuttonImg;
  operatorImage?: DialogueImage;
  lottie?: LottieProps;
  modal?: ModalProps;
  showTimeLeftBar?: boolean;
  displayTeamName?: boolean;
  practiceAgainModal?: boolean;
  playAudioOnLoad?: boolean;
  continueCourseModal: ContinueCourseModal;
}

type PageProps = {
  template: AedTemplateProps;
};

const Template: FunctionComponent<PageProps> = ({ template }) => {
  const navigationContext = useNavigation();
  const pageContext = useSanityCourse();

  const [currentPage, setCurrentPage] = useState<CurrentPageProps>({
    continueCourseModal: {
      continuePracticeButtonText: '',
      startOverButtonText: '',
      startOverUrl: '',
      headerText: '',
      body: '',
    },

    operatorImage: {
      src: ic_operator,
      alt: 'Defibrillator illustrartion. Press the flashing orange button',
    },
    aedButtonImg: {
      src: '',
      alt: 'Turn on button',
      blink: false,
      onClick: () => {
        navigationContext?.navigateToNextPageWithSleepCheck();
      },
      buttonType: 'shock',
    },
    dialogueText: {
      text: null,
      textAlignCenter: true,
    },
  });

  const aedSettings = (
    aedState: 'shock' | 'on',
    image: SanityImage,
    shockButton: SanityImage | undefined,
    turnOnButton: SanityImage | undefined,
  ) => {
    switch (aedState) {
      case 'on':
        return {
          operatorImage: {
            src: ic_operator_on,
            alt: 'Defibrillator image. Turn on button is blinking',
          },
          aedButtonImg: {
            src: turnOnButton?.src ?? '',
            alt: turnOnButton?.alt ?? 'Turn on button',
            buttonType: 'on' as const,
            blink: false,
          },
          lottie: {
            options: {
              loop: true,
              autoplay: true,
              animationData: !image.lottie?.options?.path
                ? animationData
                : undefined,
              path: image?.lottie?.options.path ?? '',
              rendererSettings: {},
            },
          },
        };
      default:
        return {
          operatorImage: {
            src: image.src || ic_operator,
            alt: 'Defibrillator illustrartion. Press the flashing orange button',
            alignRight: true,
          },
          aedButtonImg: {
            src: shockButton?.src ?? '',
            alt: shockButton?.alt ?? 'Shock button',
            blink: true,
            onClick: () => {
              navigationContext?.navigateToNextPageWithSleepCheck();
            },
            buttonType: 'shock' as const,
          },
        };
    }
  };

  useEffect(() => {
    const tempPage = template ?? pageContext.getCurrentPage().aedTemplate;
    if (!tempPage) {
      return;
    }

    const page: CurrentPageProps = {
      ...aedSettings(
        tempPage.aedState,
        tempPage.image,
        tempPage.shockButton,
        tempPage.turnOnButton,
      ),
      displayTeamName: tempPage.displayTeamName,
      showTimeLeftBar: tempPage.showTimeLeftBar,
      continueCourseModal: {
        continuePracticeButtonText:
          tempPage.continueCourseModal?.continuePracticeButtonText,
        startOverButtonText: tempPage.continueCourseModal?.startOverButtonText,
        startOverUrl: tempPage.continueCourseModal?.startOverUrl,
        headerText: tempPage.continueCourseModal?.headerText,
        body: tempPage.continueCourseModal?.body,
      },
      dialogueText:
        tempPage.dialog?.text !== undefined
          ? {
              text: tempPage.dialog?.text,
              textAlignCenter: tempPage.dialog?.textAlignCenter,
            }
          : undefined,
      text: {
        header: tempPage.text?.header,
        body: tempPage.text?.body,
        footerText: tempPage.text?.footerText,
      },
    };
    setCurrentPage(page);
  }, []);

  return (
    <AedTemplatePage
      operatorImage={currentPage.operatorImage}
      dialogueText={currentPage.dialogueText}
      aedButtonImg={currentPage.aedButtonImg}
      text={currentPage.text}
      lottie={currentPage.lottie}
      showTimeLeftBar={currentPage.showTimeLeftBar}
      displayTeamName={currentPage.displayTeamName}
      continueCourseModal={currentPage.continueCourseModal}
      playAudioOnLoad
      practiceAgainModal
    />
  );
};

export default WithFadeIn(WithFocus(Template));
