import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import LifeButton from '../../components/Button';
import { Button } from '../Templates/InfoPageTypes';
import mediaMedium from '../Templates/MediaMedium';

const Container = styled.div`
  display: grid;
  grid-template-columns: 12px 1fr 12px max-content 12px;
  grid-template-rows:
    9px
    1fr
    9px;
  background: ${(props) => props.theme.colors.accent1_100};
  border: 1px solid ${(props) => props.theme.colors.accent1_100};
  border-radius: 8px;
`;
const ReplyText = styled.div<{ centeredText?: boolean }>`
  width: 100%;
  grid-column: 2;
  grid-row: 2;
  p {
    margin: 0;
    ${(props) =>
      props.centeredText ? 'text-align: center;' : 'text-align: left;'}
    color: ${(props) => props.theme.colors.neutral_600};
  }
  h3 {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 120%;
    ${(props) =>
      props.centeredText ? 'text-align: center;' : 'text-align: left;'}

    color: ${(props) => props.theme.colors.neutral_600};
  }
`;
const ButtonContainer = styled.div<{ showOnlyOnMobileDevices }>`
  grid-column: -3;
  grid-row: 2;
  align-self: flex-start;
  justify-self: flex-end;

  ${(props) =>
    props.showOnlyOnMobileDevices &&
    mediaMedium(css`
      display: none;
    `)}
`;
type ReplyButtonProps = {
  button?: Button;
  showOnlyOnMobileDevices?: boolean;
};

const ReplyButton: FunctionComponent<ReplyButtonProps> = ({
  button,
  showOnlyOnMobileDevices,
}) => {
  if (button) {
    return (
      <ButtonContainer
        role="navigation"
        aria-label="Main"
        showOnlyOnMobileDevices={showOnlyOnMobileDevices}
      >
        <LifeButton
          id="ReplyButton"
          key={`button_${button.buttonType}_${button.text}`}
          variant={button.buttonType}
          onClick={() => {
            button.onClick();
          }}
        >
          {button.text}
        </LifeButton>
      </ButtonContainer>
    );
  }
  return null;
};
type ReplyBoxProps = {
  button?: Button;
  text?: string | React.ReactNode;
  showOnlyOnMobileDevices?: boolean;
  textAlignCenter?: boolean;
};
/**
 * Component for replying the operator
 */
const ReplyBox: FunctionComponent<ReplyBoxProps> = ({
  text = '',
  button,
  showOnlyOnMobileDevices = false,
  textAlignCenter,
}) => {
  return (
    <Container>
      <ReplyText centeredText={textAlignCenter}>
        <p>{text}</p>
      </ReplyText>
      <ReplyButton
        button={button}
        showOnlyOnMobileDevices={showOnlyOnMobileDevices}
      />
    </Container>
  );
};

export default ReplyBox;
