import { CSSObject, RuleSet, css } from 'styled-components';
import constants from '../../constants/Constants';
import SettingsHelper from '../../helper/SettingsHelper';

const { media } = constants;

const mediaMedium = (cssString: CSSObject | RuleSet) => {
  return css`
    ${() => {
      return SettingsHelper.isFacilitator()
        ? css`
            @media (min-width: ${media.MEDIUM}px) {
              ${cssString}
            }
          `
        : '';
    }}
  `;
};

export default mediaMedium;
