import { Size } from '@laerdal/life-react-components';
import React, { FunctionComponent, useState } from 'react';
import Draggable from 'react-draggable';
import Button from '../../components/Button';
import { defaultText } from '../../features/sanity';
import usePreloadImage from '../../hooks/usePreloadImage';
import {
  DraggableArea,
  HorizontalBottomContent,
  ImageStyle,
  LeftPadImageStyle,
  MainContent,
  MainContentWrapper,
  MainTextTopMargin,
  RightPadImageStyle,
  SubText,
} from '../Templates/SimulationPageStyles';
import { AEDDraggablePadPageTemplate } from '../types/courseTypes';
import {
  AedPadRelativeWidth,
  PadPosition,
  calculateHeight,
  calculateWidth,
} from './AedPadUtil';

interface BodyImageRef {
  ref?: React.RefObject<HTMLImageElement>;
}

interface AedPadPlacementProps {
  aedPadSize: AedPadRelativeWidth;
  bodyImage: BodyImageRef;
  handleImageOnLoad: () => void;
  handleOnDragLeftPad: (e, ui) => void;
  handleOnDragRightPad: (e, ui) => void;
  handleOnStop: () => void;
  leftPadInitialValue: PadPosition;
  pageData: AEDDraggablePadPageTemplate;
  rightPadImage: string;
  leftPadImage: string;
  rightPadInitialValue: PadPosition;
  setRightPadImage: React.Dispatch<React.SetStateAction<string>>;
  showHelpClicked: () => void;
  showResultClicked: () => void;
}

const AedPadPlacement: FunctionComponent<AedPadPlacementProps> = ({
  aedPadSize,
  bodyImage,
  handleImageOnLoad,
  handleOnDragLeftPad,
  handleOnDragRightPad,
  handleOnStop,
  leftPadImage,
  leftPadInitialValue,
  pageData,
  rightPadImage,
  rightPadInitialValue,
  setRightPadImage,
  showHelpClicked,
  showResultClicked,
}: AedPadPlacementProps) => {
  const { rightPadAlternateImageGroup } = pageData;
  usePreloadImage(rightPadAlternateImageGroup?.alternateRightPadImage);
  const [initialRightPadImage, setInitialRightPadImage] =
    useState(rightPadImage);
  // Need to remember the initial image, because rightPadImage can change. (e.g. when the user drags the right pad to the alternate image area)

  const handleOnDragRightPadInternal = (e, ui) => {
    if (
      rightPadAlternateImageGroup &&
      rightPadAlternateImageGroup.enableRightPadAlternateImage
    ) {
      if (rightPadAlternateImageGroup.alternateRightPadImage === undefined) {
        console.error('alternateRightPadImage cannot be null');
        return;
      }
      const { x, y } = ui;
      const realX = rightPadInitialValue.x + x;
      const realY = rightPadInitialValue.y + y;
      const isInside =
        realX > rightPadAlternateImageGroup.leftX &&
        realX < rightPadAlternateImageGroup.rightX &&
        realY < rightPadAlternateImageGroup.bottomY &&
        realY > rightPadAlternateImageGroup.topY;
      if (isInside) {
        setRightPadImage(rightPadAlternateImageGroup.alternateRightPadImage);
      } else {
        setRightPadImage(initialRightPadImage);
      }
    }

    handleOnDragRightPad(e, ui);
  };

  return (
    <>
      <MainContentWrapper>
        <MainContent>
          <DraggableArea>
            <ImageStyle
              src={pageData.image?.src}
              width={calculateWidth()}
              height={calculateHeight()}
              ref={bodyImage.ref}
              alt="Unresponsive person"
              onLoad={handleImageOnLoad}
              onDragStart={(event) => event.preventDefault()}
            />
            <Draggable
              bounds="parent"
              onStop={handleOnStop}
              onDrag={handleOnDragLeftPad}
            >
              <LeftPadImageStyle
                id="leftPad"
                src={leftPadImage}
                leftPosition={leftPadInitialValue.x}
                topPosition={leftPadInitialValue.y}
                width={aedPadSize.leftPadWidth}
                alt={defaultText.aedDraggablePad.images.leftPad.alt}
                onDragStart={(event) => event.preventDefault()}
              />
            </Draggable>

            <Draggable
              bounds="parent"
              onStop={handleOnStop}
              onDrag={handleOnDragRightPadInternal}
            >
              <RightPadImageStyle
                id="rightPad"
                src={rightPadImage}
                leftPosition={rightPadInitialValue.x}
                topPosition={rightPadInitialValue.y}
                width={aedPadSize.rightPadWidth}
                alt={defaultText.aedDraggablePad.images.rightPad.alt}
                onDragStart={(event) => event.preventDefault()}
              />
            </Draggable>
          </DraggableArea>
          <MainTextTopMargin>
            {pageData?.aedPadPlacement?.headingText}
          </MainTextTopMargin>
          <SubText>{pageData?.aedPadPlacement?.bodyText}</SubText>
        </MainContent>
      </MainContentWrapper>
      <HorizontalBottomContent>
        <Button
          id="helpButton"
          size={window.innerWidth <= 320 ? Size.Small : Size.Medium}
          style={{ width: '100%' }}
          variant="secondary"
          onClick={() => {
            showHelpClicked();
          }}
        >
          {pageData?.aedPadPlacement?.buttons?.secondaryButtonText ??
            defaultText.aedPadButtonText.padPlacement.secondary}
        </Button>
        <Button
          id="checkAnswerButton"
          size={window.innerWidth <= 320 ? Size.Small : Size.Medium}
          style={{ width: '100%' }}
          variant="primary"
          onClick={() => {
            showResultClicked();
          }}
        >
          {pageData?.aedPadPlacement?.buttons?.primaryButtonText ??
            defaultText.aedPadButtonText.padPlacement.primary}
        </Button>
      </HorizontalBottomContent>
    </>
  );
};

export default AedPadPlacement;
