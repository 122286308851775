import { FunctionComponent } from 'react';
import {
  CardContainer,
  CardImage,
  CardMainText,
  CardSubText,
  CardTextHolder,
} from './Card.Styles';
import { CardProps } from './Card.types';

const Card: FunctionComponent<CardProps> = ({
  cardClicked,
  heading,
  iconImage,
  id,
  navigation,
  openInNewTab,
  subHeading,
  url,
}) => {
  return (
    <CardContainer
      id={id}
      data-url={url}
      data-navigation={navigation}
      data-openinnewtab={openInNewTab}
      onClick={cardClicked}
    >
      <CardTextHolder>
        <CardMainText>{heading}</CardMainText>
        <CardSubText>{subHeading}</CardSubText>
      </CardTextHolder>
      <CardImage src={iconImage.src} />
    </CardContainer>
  );
};
export default Card;
