import { useRef } from 'react';
import { Learner, Team } from './Types';
import { Constants } from '../../../constants';

export const createLearner = (
  id = '',
  name = '',
  rate = 0,
  score = 0,
): Learner => ({
  id,
  name,
  rate,
  score,
});

export const createTeam = (name: string, score = 0): Team => ({
  score,
  name,
  learner1: null,
  learner2: null,
});

type UseTeamsType = {
  getTeams: () => Record<string, Team>;
  setTeams: (newTeams: Record<string, Team>) => void;
  initiateTeams: () => Record<string, Team> | null;
  createTeam: (name: string, score?: number) => Team;
  clearTeams: () => void;
};

export const useTeams = (): UseTeamsType => {
  const teams = useRef<Record<string, Team>>({});

  const getTeams = (): Record<string, Team> => {
    const val = localStorage.getItem(Constants.queryKeys.RACE_TEAMS);
    return JSON.parse(val ?? '{}') as Record<string, Team>;
  };

  const setTeams = (newTeams: Record<string, Team>) => {
    teams.current = newTeams;
    localStorage.setItem(
      Constants.queryKeys.RACE_TEAMS,
      JSON.stringify(newTeams),
    );
  };

  const clearTeams = () => {
    localStorage.removeItem(Constants.queryKeys.RACE_TEAMS);
  };

  const initiateTeams = (): Record<string, Team> | null => {
    const savedTeams = localStorage.getItem(Constants.queryKeys.RACE_TEAMS);

    if (savedTeams) {
      const parsedTeams = JSON.parse(savedTeams);
      setTeams(parsedTeams);
      return parsedTeams;
    }

    return null;
  };

  return { getTeams, setTeams, initiateTeams, createTeam, clearTeams };
};
