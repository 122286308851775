/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, Suspense } from 'react';
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';
import Debug from '../../../components/Debug/Debug';
import { useSanityCourse } from '../../../context/sanity/useSanityCourse';
import { urlChecker } from '../../../helper';
import viablePaths from '../../../helper/viablePaths';
import { TemplateList } from '../../../pageTemplates';
import { isFacilitatorPageWithTemplate } from '../../../pageTemplates/types/courseTypes';
import getCommonNavigationRoute from '../common/getCommonNavigationRoute';
import { groupTrainingUrl } from '../helper/GroupTrainingUrl';
import { GroupTemplateList } from '../pageTemplates';
import FacilitatorNavigationProvider from './context/FacilitatorNavigationContext';
import { ConnectedLearnersTemplate } from './templates';

const FacilitatorTemplateList = {
  ...TemplateList,
  ...GroupTemplateList,
  connectedLearnersTemplate: ConnectedLearnersTemplate,
};

const FacilitatorTemplate: FunctionComponent<{ template: string }> = ({
  template,
}) => {
  const TemplateComponent = FacilitatorTemplateList[template];
  return TemplateComponent ? <TemplateComponent /> : <Outlet />;
};

const FacilitatorLandingPage = React.lazy(
  () => import('./templates/LandingPage/FacilitatorLandingPage'),
);

const PathNotFoundPage = React.lazy(
  () => import('../../../pages/PathNotFoundPage'),
);

const RoutingWrapper: FunctionComponent = () => {
  const courseContext = useSanityCourse();
  const location = useLocation();
  const mainPaths = viablePaths();
  mainPaths[0] = ''; // clearing it because it contains a slash and will cause issues
  return (
    <Routes>
      {mainPaths
        .map((path) => path + groupTrainingUrl.learner)
        .includes(window.location.pathname)}
      && (
      <Route
        path="*"
        element={
          <Suspense fallback={null}>
            <FacilitatorLandingPage />
          </Suspense>
        }
      />
      )
      {courseContext.course != null &&
        courseContext.course.length > 0 &&
        courseContext.course.map((page) => {
          /* check if the page exists or is a FacilitatorPage */
          if (page === undefined) {
            return null;
          }
          if (!isFacilitatorPageWithTemplate(page)) {
            return getCommonNavigationRoute(page);
          }
          /* check if the template exists for each route */
          return typeof FacilitatorTemplateList[page.template] !==
            'undefined' ? (
            <Route
              key={page.url}
              path={urlChecker(page.url)}
              element={
                <FacilitatorTemplate
                  key={location.pathname}
                  template={page.template}
                />
              }
            />
          ) : null;
        })}
      <Route
        path="*"
        element={
          <Suspense fallback={null}>
            <PathNotFoundPage />
          </Suspense>
        }
      />
      <Route
        path="/*"
        element={
          <Suspense fallback={null}>
            <PathNotFoundPage />
          </Suspense>
        }
      />
    </Routes>
  );
};

const FacilitatorRouting: FunctionComponent = () => {
  return (
    <Router>
      <FacilitatorNavigationProvider>
        <Debug />
        <RoutingWrapper />
      </FacilitatorNavigationProvider>
    </Router>
  );
};

export default FacilitatorRouting;
