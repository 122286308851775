import React from 'react';
import { useTcprLink } from '../../context';

interface TCPRLinkDebuggingProps {
  show?: boolean;
}
const TCPRLinkDebugging: React.FunctionComponent<TCPRLinkDebuggingProps> = ({
  show = false,
}: TCPRLinkDebuggingProps) => {
  const tcprLinkContext = useTcprLink();

  if (show === false) {
    return null;
  }
  return (
    <div>
      <button type="button" onClick={tcprLinkContext?.start}>
        Start
      </button>
      <button type="button" onClick={tcprLinkContext?.stop}>
        Stop
      </button>
      <button type="button" onClick={tcprLinkContext?.startDebug}>
        Debug
      </button>
      <p>Training Page</p>
      <p>
        Rate:
        {tcprLinkContext?.rate}
      </p>
      <p>
        Score:
        {tcprLinkContext?.score}
      </p>
      <p>
        Is running:
        {tcprLinkContext?.isRunning.toString()}
      </p>
      <p>
        Fps:
        {tcprLinkContext?.fps}
      </p>
    </div>
  );
};

export default TCPRLinkDebugging;
