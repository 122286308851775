import React from 'react';
import { MessageWithDuration } from '../../features/sanity/types';
import GridOverlay from './GridOverlay';

interface WarmupOverlayProps {
  introMessages?: MessageWithDuration[];
  isVisible?: boolean;
  wait?: number;
}

const WarmupOverlay: React.FunctionComponent<WarmupOverlayProps> = ({
  introMessages,
  isVisible = true,
  wait = 0,
}: WarmupOverlayProps) => {
  let updatedWait = wait;

  return (
    <>
      {/* Sanity */}
      {introMessages?.map((messageWithDuration) => {
        const Message = (
          <GridOverlay
            isVisible={isVisible}
            wait={updatedWait}
            duration={messageWithDuration.duration}
          >
            {messageWithDuration.message}
          </GridOverlay>
        );
        updatedWait += messageWithDuration.duration ?? 0;
        return Message;
      }) ?? null}
    </>
  );
};
interface PracticeStartsInProps {
  onTimeoutCallback: () => void;
  trainingStartsIn?: MessageWithDuration;
  wait?: number;
}

const PracticeStartsIn: React.FunctionComponent<PracticeStartsInProps> = ({
  onTimeoutCallback,
  trainingStartsIn,
  wait,
}: PracticeStartsInProps) => {
  return (
    <GridOverlay
      showCounter
      wait={wait}
      duration={trainingStartsIn?.duration ?? 5}
      onTimeoutCallback={onTimeoutCallback}
    >
      {trainingStartsIn?.message ?? 'Start practising in:'}
    </GridOverlay>
  );
};

interface TrainingStartSequenceProps {
  isLoaded: boolean;
  OverlayTimeout: () => void;
  trainingStartsIn?: MessageWithDuration;
  introMessages?: MessageWithDuration[];
}
export const TrainingStartSequence: React.FunctionComponent<
  TrainingStartSequenceProps
> = ({
  isLoaded,
  OverlayTimeout,
  trainingStartsIn,
  introMessages,
}: TrainingStartSequenceProps) => {
  return (
    <>
      <WarmupOverlay isVisible={!isLoaded} />
      {isLoaded && introMessages && (
        <>
          <WarmupOverlay introMessages={introMessages} />
          <PracticeStartsIn
            onTimeoutCallback={OverlayTimeout}
            trainingStartsIn={trainingStartsIn}
            wait={introMessages.reduce(
              (totalWait, message) => totalWait + message.duration,
              0,
            )}
          />
        </>
      )}
    </>
  );
};
