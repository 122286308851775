import React, { FunctionComponent } from 'react';
import { useViewport } from '../../../context';
import { ProgressbarContainer } from '../../../pageTemplates/Templates/InfoPageStyles';
import BarWithIconWrapper from '../BarWithIconWrapper';
import ProgressBar, { ProgressbarProps } from '../ProgressBar/ProgressBar';

// Should be renamed, and used as default when all templates are ready with Large screen design.
const ProgressBarLargeScreens: FunctionComponent<ProgressbarProps> = ({
  animationState,
  animationDurationInSeconds,
  animationDelayInSeconds,
  setAnimRef,
}) => {
  const { isMediumWidth } = useViewport();

  return (
    <ProgressbarContainer>
      <BarWithIconWrapper hideIcon={isMediumWidth}>
        <ProgressBar
          animationDurationInSeconds={animationDurationInSeconds}
          animationDelayInSeconds={animationDelayInSeconds}
          animationState={animationState}
          setAnimRef={setAnimRef}
        />
      </BarWithIconWrapper>
    </ProgressbarContainer>
  );
};

export default ProgressBarLargeScreens;
