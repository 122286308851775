/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-restricted-globals */

import React, { ReactNode, useState } from 'react';

import { NavigationContextProps } from '../navigation/navigationContextProps';
import { AedPadsData } from '../types/AedPadsData';

export const NavigationContextMock =
  React.createContext<NavigationContextProps>({} as NavigationContextProps);

const NavigationProviderMock: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }) => {
  const [initialPage, setInitialPage] = useState<string>('');
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [numbPagesLeft, setNumbPagesLeft] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [timeLeftInSeconds, setTimeLeftInSeconds] = useState(0);
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(0);
  const [previousPathCommand, setPreviousPathCommand] = useState('');
  const preloadImagesOnNextPage = () => {};
  const navigateToUrl = (
    url: string,
    force?: boolean,
    openInNewTab?: boolean,
  ) => {};
  const navigateToFirstPage = () => {};
  const navigateToNextPage = (skipRequireCameraPermission?: boolean) => {};
  const navigateToThePageAfterNext = (
    skipRequireCameraPermission?: boolean,
  ) => {};

  // Please call this navigation function when user interact (exp. user tap button)
  const navigateToNextPageWithSleepCheck = (
    skipRequireCameraPermission?: boolean,
  ) => {};

  const currentPageDuration = (): number => {
    return 0;
  };
  const navigateToPreviousPage = () => {};

  const navigateToCheckAnswerPage = (data: AedPadsData) => {};

  const navigateToAedPractisePage = () => {};

  const navigateToDefaultAndroidBrowser = () => {};

  const timeLeftInSecondsMock =
    window.mock?.navigationProvider?.timeLeftInSeconds === undefined
      ? 0
      : window.mock?.navigationProvider?.timeLeftInSeconds;
  const totalTimeInSecondsMock =
    window.mock?.navigationProvider?.totalTimeInSeconds === undefined
      ? 0
      : window.mock?.navigationProvider?.totalTimeInSeconds;
  return (
    <NavigationContextMock.Provider
      value={{
        navigateToNextPage,
        navigateToThePageAfterNext,
        navigateToPreviousPage,
        numbPagesLeft,
        navigateToUrl,
        preloadImagesOnNextPage,
        timeLeftInSeconds: timeLeftInSecondsMock,
        totalTimeInSeconds: totalTimeInSecondsMock,
        currentPageDuration,
        navigateToCheckAnswerPage,
        navigateToNextPageWithSleepCheck,
        navigateToAedPractisePage,
        navigateToDefaultAndroidBrowser,
        navigateToFirstPage,
        initialRender,
        initialPage,
      }}
    >
      {children}
    </NavigationContextMock.Provider>
  );
};

export default NavigationProviderMock;
