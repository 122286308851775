/* eslint-disable no-nested-ternary */
// Constants.ts
import { isProduction } from '../helper/EnvironmentHelper';

const getSanityProject = () => {
  if (isProduction) {
    return '#{SANITY_PROJECT_ID}#'; // production mode, use the project id from the dev ops
  }
  return import.meta.env.VITE_APP_SANITY_PROJECT_ID // dev mode, check if the project id is set in .env
    ? import.meta.env.VITE_APP_SANITY_PROJECT_ID // the project id set in .env, use it
    : '75hs4l5s'; // the project id is NOT set in .env, use the default value
};

const getSanityDataset = () => {
  if (isProduction) {
    return '#{SANITY_DATASET}#'; // production mode, use the dataset from the dev ops
  }
  return import.meta.env.VITE_APP_SANITY_DATASET // dev mode, check if the dataset is set in .env
    ? import.meta.env.VITE_APP_SANITY_DATASET // the dataset set in .env, use it
    : 'revivr-dev'; // the dataset is NOT set in .env, use the default value
};

const cameraLoadTimeout = 30;
const trainingTime = 60;
const branded = (): string => {
  if (
    !window.location.href.includes('localhost') &&
    !window.location.href.includes('ngrok')
  ) {
    return '#{Branded}#';
  }
  return 'true';
};

interface Algorithm {
  width: number;
  height: number;
}

interface Width {
  ideal: number;
}

interface Height {
  ideal: number;
}

interface MediaStream {
  width: Width;
  height: Height;
}

interface TrainingVideoFrameSize {
  width: number;
  height: number;
}

interface CameraOnboardingVideo {
  width: number;
  height: number;
}

interface Sizes {
  algorithm: Algorithm;
  mediaStream: MediaStream;
  trainingVideoFrameSize: TrainingVideoFrameSize;
  cameraOnboardingVideo: CameraOnboardingVideo;
}

interface Video {
  sizes: Sizes;
}

interface Hotjar {
  hjid: string;
  hjsv: string;
}

interface EndPoints {
  saveForLater: string;
}

interface QueryKeys {
  AUTHENTICATION_TOKEN_KEY: string;
  CLASSROOM_CODE_KEY: string;
  DISTRIBUTION_KEY: string;
  IS_SCORM_KEY: string;
  LAST_LEARNER_ROLE_KEY: string;
  LAST_LEARNER_TEAM_KEY: string;
  LEARNER_ID_KEY: string;
  LEARNER_ROLE_KEY: string;
  LEARNER_TEAM_KEY: string;
  ORGANIZATION_KEY: string;
  PORTAL_ID_KEY: string;
  PORTAL_ID_LOCAL_STORAGE_KEY: string;
  SANITY_KEY: string;
  SANITY_PROJECT_KEY: string;
  SHARE_LEVEL_KEY: string;
  START_OF_JOURNEY_KEY: string;
  TAG_KEY: string;
  TRAINING_ROLE: string;
  TRAINING_TYPE: string;
  UTM_CAMPAIGN: string;
  UTM_MEDIUM: string;
  UTM_SOURCE: string;
  LANGUAGE_CODE_KEY: string;
  RACE_TEAMS: string;
  RACE_DEBUG_LOGS: string;
}

interface ISanity {
  PROJECT_ID: string;
  DATASET: string;
  USE_CDN: boolean;
}
interface TrainingMode {
  SOLO: string;
  GROUP: string;
  FACILITATOR: string;
  LEARNER: string;
  TEAMUP: string;
}

interface LearnerRole {
  ROLEA: string;
  ROLEB: string;
}

type PadPlacementDefaultImages = {
  leftPadSrc: string;
  rightPadSrc: string;
  rightPadAlternateSrc: string;
  leftPadAreaSrc: string;
  rightPadAreaSrc: string;
  aedPersonSrc: string;
  aedPersonCorrectSrc: string;
};

interface DeviceOrientation {
  PORTRAIT: string;
  LANDSCAPE: string;
}

// Interface for defining device break points
interface Media {
  SMALL: number;
  MEDIUM: number;
  LARGE: number;
  XLARGE: number;
}

interface LocaleCode {
  EN: 'en';
  NB_NO: 'nb-NO';
  FR_FR: 'fr-FR';
  CY_GB: 'cy-GB';
  ES_ES: 'es-ES';
}

export type LocaleLanguageName = {
  ENGLISH: 'English';
  NORWEGIAN: 'Norwegian';
  FRENCH: 'French';
  WELSH: 'Welsh';
  SPANISH: 'Spanish';
};

export type Position = {
  x: number;
  y: number;
};
export type Size = {
  height: number;
  width: number;
};

export type TextAlignment = {
  LEFT: string;
  CENTER: string;
  RIGHT: string;
};

export type Area = Position & Size;

type LeftAreaDefaultValues = {
  LeftAreaDefaultValues: Area;
};
type RightAreaDefaultValues = {
  RightAreaDefaultValues: Area;
};
type PadAreaDefaultValues = LeftAreaDefaultValues & RightAreaDefaultValues;

type AedTemplateDefaultImages = {
  shockButtonSrc: string;
  turnOnButtonSrc: string;
};
type Constants = {
  aedTemplateDefaultImages: AedTemplateDefaultImages;
  algorithmSendTimeoutMs: number;
  branded: string;
  cameraLoadingTimeout: number;
  cameraLoadingTimeoutMs: number;
  deviceOrientation: DeviceOrientation;
  endPoints: EndPoints;
  headerHeight: Media;
  hotjar: Hotjar;
  isProduction: boolean;
  learnerRole: LearnerRole;
  localeCode: LocaleCode;
  maxPageWidth: number;
  maxPageWidthMobile: number;
  media: Media;
  overlayNoRateDelay: number;
  overlayWarmupDuration: number;
  aedPadPlacementDefaultImages: PadPlacementDefaultImages;
  pageMargin: Media;
  pageMarginBottom: number;
  pageMarginTop: number;
  padAreaDefaultValues: PadAreaDefaultValues;
  progressBarHeight: Media;
  queryKeys: QueryKeys;
  sanity: ISanity;
  setThirdPartyScriptsInGTM: boolean;
  textAlignment: TextAlignment;
  trainingMode: TrainingMode;
  trainingTimeMs: number;
  trainingTimeSec: number;
  video: Video;
  localeLanguageName: LocaleLanguageName;
};

const constants: Constants = {
  isProduction,
  maxPageWidth: 1600,
  maxPageWidthMobile: 414,
  pageMarginBottom: 18,
  pageMarginTop: 8,

  // Constants related to device width.
  media: {
    SMALL: 320,
    MEDIUM: 768,
    LARGE: 1280,
    XLARGE: 1600,
  },
  pageMargin: {
    SMALL: 16,
    MEDIUM: 40,
    LARGE: 40,
    XLARGE: 40,
  },
  headerHeight: {
    SMALL: 32,
    MEDIUM: 80,
    LARGE: 80,
    XLARGE: 80,
  },
  progressBarHeight: {
    SMALL: 6,
    MEDIUM: 10,
    LARGE: 10,
    XLARGE: 10,
  },

  localeCode: {
    EN: 'en',
    NB_NO: 'nb-NO',
    FR_FR: 'fr-FR',
    CY_GB: 'cy-GB',
    ES_ES: 'es-ES',
  },
  localeLanguageName: {
    ENGLISH: 'English',
    NORWEGIAN: 'Norwegian',
    FRENCH: 'French',
    WELSH: 'Welsh',
    SPANISH: 'Spanish',
  },

  // TrainingPageConstants
  cameraLoadingTimeout: cameraLoadTimeout,
  cameraLoadingTimeoutMs: cameraLoadTimeout * 1000,
  overlayNoRateDelay: 5,
  overlayWarmupDuration: 8,
  trainingTimeMs: trainingTime * 1000,
  trainingTimeSec: trainingTime,

  branded: branded(),
  algorithmSendTimeoutMs: 50,
  video: {
    sizes: {
      // Use landscape ratios
      algorithm: {
        width: 640,
        height: 480,
      },
      mediaStream: {
        width: { ideal: 176 }, // ideal is used mostly on firefox. We get an error when setting min and max, so we are only using ideal, so its up to the browser to decide what to use
        height: { ideal: 120 }, // ideal is used mostly on firefox. We get an error when setting min and max, so we are only using ideal, so its up to the browser to decide what to use
      },
      trainingVideoFrameSize: {
        width: 176,
        height: 120,
      },
      cameraOnboardingVideo: {
        width: 224,
        height: 168,
      },
    },
  },
  hotjar: {
    hjid: isProduction ? '#{hjid}#' : '2487125',
    hjsv: isProduction ? '#{hjsv}#' : '6',
  },

  // End points
  endPoints: {
    saveForLater: isProduction
      ? '#{SAVE_FOR_LATER_URL}#'
      : 'http://localhost:3000/email-reminder',
  },
  padAreaDefaultValues: {
    LeftAreaDefaultValues: {
      x: 21,
      y: 21,
      width: 132,
      height: 136,
    },
    RightAreaDefaultValues: {
      x: 53,
      y: 59,
      width: 124,
      height: 136,
    },
  },
  queryKeys: {
    AUTHENTICATION_TOKEN_KEY: 'authentication_token',
    CLASSROOM_CODE_KEY: 'classroom_code',
    DISTRIBUTION_KEY: 'dist',
    IS_SCORM_KEY: 'isScorm',
    LAST_LEARNER_ROLE_KEY: 'last_learner_role',
    LAST_LEARNER_TEAM_KEY: 'last_learner_team',
    LEARNER_ID_KEY: 'learner_id',
    LEARNER_ROLE_KEY: 'learner_role',
    LEARNER_TEAM_KEY: 'learner_team',
    ORGANIZATION_KEY: 'org',
    PORTAL_ID_KEY: 'portalRef',
    PORTAL_ID_LOCAL_STORAGE_KEY: 'portalId',
    SANITY_KEY: 'sanity',
    SANITY_PROJECT_KEY: 'projectId',
    SHARE_LEVEL_KEY: 'shar',
    START_OF_JOURNEY_KEY: 'start',
    TAG_KEY: 'tag',
    TRAINING_ROLE: 'training_role',
    TRAINING_TYPE: 'training_type',
    UTM_CAMPAIGN: 'utm_campaign',
    UTM_MEDIUM: 'utm_medium',
    UTM_SOURCE: 'utm_source',
    LANGUAGE_CODE_KEY: 'language',
    RACE_TEAMS: 'race_teams',
    RACE_DEBUG_LOGS: 'debugLogs',
  },
  sanity: {
    PROJECT_ID: getSanityProject(),
    DATASET: getSanityDataset(),
    USE_CDN: isProduction
      ? ('#{SANITY_USE_CDN}#' as unknown as boolean) // Linter wasnt happy with string here
      : false, // `false` if you want to ensure fresh data
  },
  textAlignment: {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
  },
  trainingMode: {
    SOLO: 'solo',
    GROUP: 'group',
    FACILITATOR: 'facilitator',
    LEARNER: 'learner',
    TEAMUP: 'teamUp',
  },
  deviceOrientation: {
    LANDSCAPE: 'Landscape',
    PORTRAIT: 'Portrait',
  },
  learnerRole: {
    ROLEA: 'A',
    ROLEB: 'B',
  },
  aedPadPlacementDefaultImages: {
    aedPersonCorrectSrc:
      'image-68dcda785e5232f7131bff2cf7f92016344a0ceb-746x670-png',
    aedPersonSrc: 'image-2fb1582b909fcef589561f945e6ace566034645b-750x670-png',
    leftPadAreaSrc:
      'image-faff89c6a5f660b3f6b326117b5e573f931336ba-369x381-png',
    leftPadSrc: 'image-6c21799803e3b9e40386f811dd43491b4ab276c5-228x299-png',
    rightPadAreaSrc:
      'image-ba6854134b666bb585db88ae3d531d012da169c1-348x381-png',
    rightPadAlternateSrc:
      'image-4cc2caee3ac1ff06a76fd1c5a5681be68b016b56-299x228-png',
    rightPadSrc: 'image-13ee59aeb76c39a54ca67639051454b6969ad6ce-299x228-png',
  },
  setThirdPartyScriptsInGTM:
    '#{GTM_THIRD_PARTY_SCRIPTS}#'.indexOf('true') !== -1,
  aedTemplateDefaultImages: {
    shockButtonSrc:
      'image-91fa9f177fd596fae6525379062825676b1997b8-139x139-png',
    turnOnButtonSrc:
      'image-cd91e5de7cbf35f34ba90faf3b930d2cdeda3b13-139x139-png',
  },
};

export default constants;
