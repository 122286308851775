export type AblyNavigateToNextPageCommand = {
  currentUrl: string;
};
export type AblyWaitingCommand = {
  message: string;
};
type Role = {
  roleName: string;
  learnerId: string;
};

export type LearnerTeam = {
  teamName: string;
  roleA: Role;
  roleB: Role;
};
export type AblyTeamUpCommand = {
  teamMessage: LearnerTeam[];
};

type ChannelTypes = {
  NavigationCommand: string;
  WaitingCommand: string;
  TeamUpCommand: string;
};

export const Channel = {
  NavigationCommand: 'NavigationCommand',
  WaitingCommand: 'WaitingCommand',
  TeamUpCommand: 'TeamUpCommand',
} as ChannelTypes;
