import { SystemIcons } from '@laerdal/life-react-components';
import React from 'react';
import { useViewport } from '../../context/ViewportProvider';
import {
  ButtonContainer,
  ButtonLeftContainer,
  ButtonRightContainer,
} from '../../pageTemplates/Templates/InfoPageStyles';
import { Button } from '../../pageTemplates/Templates/InfoPageTypes';
import LifeButton from '../Button';

const isAnyButtonLoading = (buttons: Button[] | undefined) => {
  if (!buttons) {
    return false;
  }
  return buttons !== undefined && !!buttons.find((btn) => btn.loading === true);
};

/**
 *
 * @param buttons - the button array used in the page
 * @param button - The current button
 * This function performs the OnClick() function if no buttons are loading
 * @returns - void
 */
const lockOnClickWhileButtonsAreLoading = (
  buttons: Button[] | undefined,
  button: Button,
): void => {
  if (!buttons) {
    return;
  }
  if (isAnyButtonLoading(buttons)) {
    return;
  }
  if (button.onClick) {
    button.onClick();
  } else {
    console.error(`${button.text} has no onClick function`);
  }
};

const instantiateButton = (
  button: Button,
  buttons: Button[],
  disablePrimaryButtons?: boolean,
) => {
  return (
    <LifeButton
      id={button.id ?? ''}
      key={
        button.icon === SystemIcons.SkipForward
          ? `button_${button.buttonType}_${'Skip'}`
          : `button_${button.buttonType}_${button.text}`
      }
      variant={button.buttonType}
      width={button.icon === SystemIcons.SkipForward ? '60px' : ''}
      disabled={button.buttonType === 'primary' && disablePrimaryButtons}
      onClick={() => {
        lockOnClickWhileButtonsAreLoading(buttons, button);
      }}
      loading={button.loading}
    >
      {button.text}
      {button.icon && <button.icon />}
    </LifeButton>
  );
};

/**
 * Because of the special way we want buttons to be placed, the button placement is handled by a switch case.
 * @param buttons - The array of buttons to be placed
 * @returns
 */
const PlaceButtons = (
  buttons: Button[] | undefined,
  disableButtons: boolean,
) => {
  if (buttons === undefined) {
    return null;
  }

  const buttonArray = buttons; // Whacky React stuff forces me to do this
  switch (buttonArray.length) {
    case 1:
      return (
        <>
          <ButtonLeftContainer />
          <ButtonRightContainer>
            {instantiateButton(buttonArray[0], buttons, disableButtons)}
          </ButtonRightContainer>
        </>
      );
      break;
    case 2:
      return (
        <>
          <ButtonLeftContainer>
            {instantiateButton(buttonArray[0], buttons, disableButtons)}
          </ButtonLeftContainer>
          <ButtonRightContainer>
            {instantiateButton(buttonArray[1], buttons, disableButtons)}
          </ButtonRightContainer>
        </>
      );
      break;
    case 3:
      return (
        <>
          <ButtonLeftContainer>
            {instantiateButton(buttonArray[0], buttons, disableButtons)}
          </ButtonLeftContainer>
          <ButtonRightContainer>
            {instantiateButton(buttonArray[1], buttons, disableButtons)}
            {instantiateButton(buttonArray[2], buttons, disableButtons)}
          </ButtonRightContainer>
        </>
      );
      break;
    case 4:
      return (
        <>
          <ButtonLeftContainer>
            {instantiateButton(buttonArray[0], buttons, disableButtons)}
            {instantiateButton(buttonArray[1], buttons, disableButtons)}
          </ButtonLeftContainer>
          <ButtonRightContainer>
            {instantiateButton(buttonArray[2], buttons, disableButtons)}
            {instantiateButton(buttonArray[3], buttons, disableButtons)}
          </ButtonRightContainer>
        </>
      );
      break;
    default:
      return (
        <>
          <ButtonLeftContainer />
          <ButtonRightContainer />
        </>
      );
      break;
  }
};

const renderButtonsWideScreen = (
  buttonArray: Button[],
  disableButtons: boolean,
) => {
  return PlaceButtons(buttonArray, disableButtons);
};

const renderButtonsForPortrait = (
  buttonArray: Button[],
  disableButtons: boolean,
) => {
  return buttonArray
    .filter((item) => item.icon !== SystemIcons.SkipForward) // Filter out the skip button
    .map((button) => instantiateButton(button, buttonArray, disableButtons));
};

/**
 * Because we don't have space for a skip button, we have to filter it when using portrait mode
 * @param buttons - the button array used in the page
 * @param verticalButtons - if we want the buttons to be vertical
 * @returns - The button container without the skip button if the page is portrait mode, otherwise the button container with all buttons
 */
const FilterSkipButtonForPortrait = (
  buttons: Button[] | undefined,
  verticalButtons?: boolean,
  disableButtons?: boolean,
) => {
  const { isMediumWidth } = useViewport();
  const buttonArray = buttons;
  if (!buttonArray) return null;
  return (
    <ButtonContainer
      role="navigation"
      aria-label="Main"
      verticalButtons={verticalButtons}
    >
      {isMediumWidth
        ? renderButtonsWideScreen(buttonArray, disableButtons ?? false)
        : renderButtonsForPortrait(buttonArray, disableButtons ?? false)}
    </ButtonContainer>
  );
};
export default FilterSkipButtonForPortrait;
