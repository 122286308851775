import { useContext } from 'react';
import { GroupCourseContext } from '../../features/groupRevivr/context/GroupSanityContext';
import { isSanityProviderMockDefined } from '../../features/mock/mockHelper';
import { SanityContext as MockCourseContext } from '../mock/SanityProviderMock';
import { CourseContext } from './SanityProvider';
import {
  SanityCourseContextProps,
  SanityGroupCourseContextProps,
} from './sanityCourseContextProps';

const contextResolver = () => {
  if (isSanityProviderMockDefined()) {
    return MockCourseContext;
  }

  return window.revivr.isGroupRevivr ? GroupCourseContext : CourseContext;
};

export const useSanityCourse = ():
  | SanityCourseContextProps
  | SanityGroupCourseContextProps => {
  const sanityCourse = useContext(contextResolver());

  return window.revivr.isGroupRevivr
    ? (sanityCourse as SanityGroupCourseContextProps)
    : (sanityCourse as SanityCourseContextProps);
};
