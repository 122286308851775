import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { CSSProp, css, keyframes } from 'styled-components';
import { useTcprLink } from '../../context';
import { UseInternal } from '../../helper';

const leftArchDeg = 40;
const rightArchDeg = 40;
const middleArchDeg = 180 - leftArchDeg - rightArchDeg;

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;

const LayoutAlign = styled.div<{ height: number }>`
  width: ${(props) => props.height * 2}px;
  height: ${(props) => props.height * 1.2}px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const OuterCircle = styled(LayoutAlign)<{ height: number }>`
  width: ${(props) => props.height * 2}px;
  height: ${(props) => props.height}px;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
`;
const InnerCircle = styled.div<{ height: number }>`
  position: absolute;
  left: 15%;
  top: 30%;
  width: 70%;
  height: 70%;
  border-top-left-radius: ${(props) => props.height * 0.8}px;
  border-top-right-radius: ${(props) => props.height * 0.8}px;
  background-color: #ffffff;
  z-index: 2;
`;
const ArchLeft = styled.div<{ selected: boolean }>`
  position: absolute;
  left: 0%;
  top: 0%;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.orange_600
      : props.theme.colors.neutral_600};
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transform: rotate(-${180 - leftArchDeg}deg);
  transform: rotate(-${180 - leftArchDeg}deg);
`;
const ArchRight = styled.div<{ selected: boolean }>`
  position: absolute;
  right: 0%;
  top: 0%;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.orange_600
      : props.theme.colors.neutral_600};
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transform: rotate(${180 - rightArchDeg}deg);
  transform: rotate(${180 - rightArchDeg}deg);
`;
const ArchCenter = styled.div<{ height: number; selected: boolean }>`
  position: absolute;
  left: ${(props) => props.height - props.height * 1.15}px;
  top: 0px;
  width: 0;
  height: 0;
  border-left: ${(props) => props.height * 1.15}px solid transparent;
  border-right: ${(props) => props.height * 1.15}px solid transparent;
  border-bottom: ${(props) =>
    `${props.height}px solid ${
      props.selected
        ? props.theme.colors.green_600
        : props.theme.colors.neutral_600
    }`};
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: 0;
`;

const ScorerBase = styled.div<{ height: number }>`
  position: absolute;
  left: ${(props) => props.height * 0.8}px;
  top: ${(props) => props.height * 0.9}px;
  width: ${(props) => props.height * 0.4}px;
  height: ${(props) => props.height * 0.4}px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.blue_600};
  z-index: 2;
`;
const ScorerBaseHole = styled.div`
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 5;
`;
const ScorerPointer = styled.div<{
  animation: CSSProp;
}>`
  position: absolute;
  top: 40%;
  left: -100%;
  width: 150%;
  height: 17%;
  background-color: ${(props) => props.theme.colors.blue_600};
  ${(props) => props.animation}
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 5%;
  border-bottom-right-radius: 5%;
  z-index: 4;
`;
const ScorerPointerBase = styled.div<{
  height: number;
  animation: CSSProp;
}>`
  position: absolute;
  top: 0%;
  left: -100%;
  width: 0;
  height: 0;
  ${(props) => props.animation}
  border-top: ${(props) => props.height * 0.2}px solid transparent;
  border-right: ${(props) => props.height * 0.6}px solid
    ${(props) => props.theme.colors.blue_600};
  border-bottom: ${(props) => props.height * 0.2}px solid transparent;
  z-index: 4;
`;
interface Range {
  min: number;
  max: number;
}
interface SelectedArch {
  left: boolean;
  right: boolean;
  center: boolean;
}
export interface SpeedometerProps {
  height?: number;
  acceptedCpr?: Range;
  cprRange?: Range;
  cpr?: number;
  isStarted?: boolean;
}

interface PointerProps {
  height: number;
  tickerPrevDeg: number;
  tickerDeg: number;
  isIdle: boolean;
}

const Pointer = ({
  tickerPrevDeg,
  tickerDeg,
  height,
  isIdle,
}: PointerProps) => {
  const tickerAnimation = keyframes`
    from {
      -webkit-transform-origin: right center;
      transform-origin: right center;
      -webkit-transform: rotate(${tickerPrevDeg}deg);
      transform: rotate(${tickerPrevDeg}deg);
    }
    to {
      -webkit-transform-origin:right center;
      transform-origin:right center;
      -webkit-transform: rotate(${tickerDeg}deg);
      transform: rotate(${tickerDeg}deg);
    }
  `;
  const animation = () => {
    return css`
      -webkit-animation: ${tickerAnimation} 0.45s both steps(100, end);
      animation: ${tickerAnimation} 0.45s both steps(100, end);
    `;
  };
  if (!isIdle)
    return (
      <ScorerBase height={height}>
        <ScorerBaseHole />
        <ScorerPointer animation={animation()} />
        <ScorerPointerBase animation={animation()} height={height} />
      </ScorerBase>
    );
  return null;
};

const Speedometer: FunctionComponent<SpeedometerProps> = ({
  height = 150,
  cpr = 110,
  acceptedCpr = { min: 100, max: 120 },
  cprRange = { min: 20, max: 200 },
  isStarted = true,
}: SpeedometerProps) => {
  const [tickerDeg, setTickerDeg] = useState<number>(0);
  const [tickerPrevDeg, setTickerPrevDeg] = useState<number>(0);
  const [isIdle, setIsIdle] = useState<boolean>(false);
  const [lastUpdateTime, setLastUpdateTime] = useState<Date | null>(null);
  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState<number>(0);
  const [selected, setSelected] = useState<SelectedArch>({
    left: false,
    right: false,
    center: false,
  });

  const tcprLinkContext = useTcprLink();

  const cprToDegrees = (cprInput: number, accept: Range, tot: Range) => {
    if (cprInput === -1 || cprInput < tot.min || cprInput > tot.max || isIdle) {
      return -1;
    }

    let slope = 0;
    let output = 0;
    if (cprInput < accept.min) {
      slope = leftArchDeg / (accept.min - 1 - tot.min);
      output = 0 + slope * (cprInput - tot.min);
      setSelected({
        left: true,
        right: false,
        center: false,
      });
      return output;
    }
    if (cprInput <= accept.max) {
      slope = middleArchDeg / (accept.max - accept.min);
      output = leftArchDeg + slope * (cprInput - accept.min);
      setSelected({
        left: false,
        right: false,
        center: true,
      });
      return output;
    }
    if (cprInput <= tot.max) {
      slope = rightArchDeg / (tot.max - (accept.max + 1));
      output = 180 - rightArchDeg + slope * (cprInput - (accept.max + 1));
      setSelected({
        left: false,
        right: true,
        center: false,
      });
      return output;
    }
    return -1;
  };

  useEffect(() => {
    const deg = cprToDegrees(cpr, acceptedCpr, cprRange);
    setTickerPrevDeg(tickerDeg);
    if (deg && deg !== -1) {
      setTickerDeg(deg);
    }

    // Ignore -1
    if (tcprLinkContext?.isRunning && cpr > 0) {
      const lastUpdate = new Date();

      setLastUpdateTime(lastUpdate);
      setTimeSinceLastUpdate(0);
    }
  }, [cpr]);

  UseInternal(() => {
    if (lastUpdateTime === null || !tcprLinkContext?.isRunning) {
      return;
    }

    const currentDate = new Date();
    const seconds = (currentDate.getTime() - lastUpdateTime.getTime()) / 1000;

    setTimeSinceLastUpdate(seconds);
  }, 1000);

  useEffect(() => {
    if (timeSinceLastUpdate > 3) {
      setIsIdle(true);

      setSelected({
        left: false,
        right: false,
        center: false,
      });
    } else if (timeSinceLastUpdate < 3 && isIdle) {
      setIsIdle(false);
    }
  }, [timeSinceLastUpdate]);

  return (
    <MainDiv id="main-div">
      <LayoutAlign height={height}>
        <OuterCircle height={height}>
          <InnerCircle height={height} />
          <ArchLeft selected={selected.left && isStarted} />
          <ArchRight selected={selected.right && isStarted} />
          <ArchCenter height={height} selected={selected.center && isStarted} />
        </OuterCircle>
        <Pointer
          isIdle={isIdle || !isStarted}
          height={height}
          tickerPrevDeg={tickerPrevDeg}
          tickerDeg={tickerDeg}
        />
      </LayoutAlign>
    </MainDiv>
  );
};

export default Speedometer;
