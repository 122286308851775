import { FunctionComponent, useEffect, useState } from 'react';
import { WithFocus } from '../../components';
import WithFadeIn from '../../components/WithFadeIn';
import { useSanityCourse } from '../../context';
import { defaultText } from '../../features/sanity';
import { useNavigation } from '../../hooks';
import { ButtonTypes, ImageTypes } from '../Templates/InfoPageTypes';
import { AnswerOption, QuizPageTemplate } from '../types/courseTypes';
import QuizPage, { QuizPageProps } from './QuizPage';

type PageProps = {
  template?: QuizPageTemplate | undefined;
};
const DynamicQuizPage: FunctionComponent<PageProps> = ({ template }) => {
  const navigationContext = useNavigation();
  // undefined the first time, then set to the current page
  const [currentPage, setCurrentPage] = useState<QuizPageTemplate | undefined>(
    undefined,
  );

  const courseContext = useSanityCourse();

  useEffect(() => {
    const tempPage =
      template !== undefined
        ? template
        : courseContext.getCurrentPage().quizPageTemplate;

    if (tempPage !== undefined) {
      setCurrentPage(tempPage);
    }
  }, []);

  if (currentPage === undefined) {
    return null;
  }

  const page: QuizPageProps = {
    answers: currentPage.answerOptions,
    buttons: [
      {
        text:
          currentPage.quizButtonText?.continueButtonText ??
          defaultText.quizPage.buttonText.continue,
        buttonType: ButtonTypes.Primary,
        onClick: () => {
          navigationContext?.navigateToNextPageWithSleepCheck();
        },
      },
    ],
    question: currentPage.question,
    image: currentPage.image,
    displayTeamName: currentPage.displayTeamName,
    answerText: currentPage.answerExplanation,
    showTimeLeftBar: currentPage.showTimeLeftBar,
    isSurvey: currentPage.isSurvey,
    quizAnswerLabel: currentPage.quizAnswerLabel,
    quizButtonText: currentPage.quizButtonText,
  };

  return (
    <QuizPage
      answers={page.answers}
      answerText={page.answerText}
      question={page.question}
      buttons={page.buttons}
      image={page.image}
      displayTeamName={currentPage.displayTeamName}
      showTimeLeftBar={page.showTimeLeftBar}
      isSurvey={currentPage.isSurvey}
      quizAnswerLabel={currentPage.quizAnswerLabel}
      quizButtonText={currentPage.quizButtonText}
    />
  );
};

export default WithFadeIn(WithFocus(DynamicQuizPage));
