/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-unused-modules */
import * as Ably from 'ably';
import checkForInsertedDevOpsVariable from '../../helper/checkForInsertedDevOpsVariable';
import uuidv4 from '../../services/GuidService';

const API_KEY = checkForInsertedDevOpsVariable(
  '#{ABLY_apikey}#',
  import.meta.env.VITE_APP_ABLY_API_KEY,
);

export const getOrCreateAblyClientId = () => {
  let clientId = localStorage.getItem('ablyClientId');
  if (!clientId) {
    clientId = uuidv4();
    localStorage.setItem('ablyClientId', clientId);
  }
  return clientId;
};

export const createRealtimeChannel = (
  clientId?: string,
): Ably.Types.RealtimePromise => {
  const ablyclient = clientId ?? getOrCreateAblyClientId();

  const options: Ably.Types.ClientOptions = {
    key: API_KEY,
    clientId: ablyclient,
  };

  const realtime = new Ably.Realtime.Promise(options);
  return realtime;
};
