import axios from 'axios';
import { isProduction } from '../helper';

const url = isProduction
  ? '#{EMAIL_SENDER_URL}#'
  : import.meta.env.VITE_APP_EMAIL_SENDER_URL;

const certificateBaseUrl = isProduction
  ? '#{CERTIFICATE_SENDER_URL}#'
  : import.meta.env.VITE_APP_CERTIFICATE_SENDER_URL;

interface Color {
  hex: string;
}

export type DiplomaTextInput = {
  id: string;
  label: string;
  value: string;
  hasError: false;
  currentErrorMessage?: string;
  validationErrorMessage: string;
  placeholder: string;
  isRequired?: boolean;
  enableEmailValidation?: boolean;
  minimumLengthValidation?: number;
  maximumLengthValidation?: number;
  isEmail: boolean;
  addFieldToForm: boolean;
};
export type DiplomaText = {
  bottomXPosition: number;
  color: Color;
  dateFormat?: string;
  fontName?: string;
  fontSize: number;
  formTextField?: DiplomaTextInput;
  text: string;
  todaysDate?: boolean;
  yPosibottomYPositiontion: number;
};

interface QrCodes {
  url: string;
  topLeftXPosition: string;
  topLeftYPosition: string;
  width: number;
  height: number;
}
export interface DiplomaData {
  diplomaTexts?: DiplomaText[];
  qrCodes?: QrCodes[];
  diplomaUrl?: string;
  dateFormat?: string;
}

const SendDiplomaAsync = async (
  documentId: string,
  diploma_data?: DiplomaData,
  company?: string,
): Promise<void> => {
  return axios.post(`${certificateBaseUrl}/Certificate/SendCompletionDiploma`, {
    documentId,
    diplomaData: diploma_data,
    company,
  });
};

const SendEmailReminderAsync = async (
  email: string,
  emailSubject: string,
  emailHeading: string,
  emailBody: string,
  emailButtonText: string,
): Promise<void> => {
  return axios.post(`${url}/SendReminderEmail`, {
    email,
    emailSubject,
    emailHeading,
    emailBody,
    emailButtonText,
  });
};

export { SendDiplomaAsync, SendEmailReminderAsync };
