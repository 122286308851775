import imageUrlBuilder from '@sanity/image-url';
import constants from '../../constants/Constants';
import { buildSanityClient } from './SanityFetch';

const sanityUrlBuilder = () => {
  const params = new URLSearchParams(window.location.search);

  const { SANITY_KEY, SANITY_PROJECT_KEY } = constants.queryKeys;
  const currQueryStrings = {
    sanity: params.get(SANITY_KEY),
    project: params.get(SANITY_PROJECT_KEY),
  };

  const client = buildSanityClient(currQueryStrings?.sanity === 'drafts');
  const builder = imageUrlBuilder(client);
  return builder;
};

export const sanityRefToUrl = (ref) => {
  if (ref === undefined) return '';
  return sanityUrlBuilder().image(ref).url();
};

const urlFor = (source) => {
  if (source === undefined || source.asset === undefined) return '';
  return sanityUrlBuilder().image(source).url();
};

export default urlFor;
