import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Constants } from '../../constants';
import { useSanityCourse } from '../../context/sanity/useSanityCourse';

const StyledImg = styled.img`
  margin-right: 10px;
  max-height: 24px;
  height: 24px;
`;
const BrandIcon: FunctionComponent = () => {
  const courseContext = useSanityCourse();

  if (Constants.branded === 'true') {
    return (
      <StyledImg
        src={courseContext.settings.brandLogoUrl}
        alt={courseContext.settings.brandLogoAlt ?? 'B H F heart'}
      />
    );
  }
  return null;
};
export default BrandIcon;
