import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  FireBaseCollections,
  useCamera,
  useFirebase,
  useTcprLink,
} from '../../../context';
import { SessionResultExtended } from '../../../helper';
import consoleDebug from '../../../helper/consoleDebug';
import {
  Ambulance,
  ThumbsUp,
  TrophyBronze,
  TrophyGold,
  TrophySilver,
} from '../assets';
import { modifySvgAndReturnReactComponent } from '../helpers/svgHelper';
import { getTeamColor } from '../helpers/teamAssetSelectors';
import { GameChannelStates } from '../providers/AblyRaceEvents';
import withRaceLearnerProvider, {
  useRaceLearner,
} from '../providers/RaceLearnerProvider';
import ControllerTypes from './Controller.types';
import Ambulance_Background from './assets/Ambulance_Background.json';
import Countdown from './assets/Countdown.json';
import { CprPage, ResultPage } from './components';
import DebugView from '../facilitator/components/Debug/DebugView';

// Will be replaced by Sanity, so not spelling out as 'real' constants
const MockData = {
  backgroundLottieOptions: {
    animationData: Ambulance_Background,
    autoplay: true,
    loop: true,
    path: '',
  },
  bottomPart: {
    left: 'speedometer',
    right: 'videoFeed',
    speedometer: {
      ranges: {
        accepted: { min: 100, max: 120 },
        bounds: { max: 200, min: 20 },
      },
    },
  },
  countDownLottieOptions: {
    animationData: Countdown,
    autoplay: true,
    loop: false,
    path: '',
  },
  podium: [
    {
      position: 1,
      header: (number: number) => 'Lifesaving Champion!',
      body: 'Congratulations! You have the power to save a life with your skills. Keep up the excellent work!',
      trophy: { src: TrophyGold, alt: 'Gold trophy for 1st place' },
    },
    {
      position: 2,
      header: (number: number) => 'CPR Master!',
      body: "You're a true lifesaver. Keep using your skills to make a difference in the world!",
      trophy: { src: TrophySilver, alt: 'Silver trophy for 2nd place' },
    },
    {
      position: 3,
      header: (number: number) => 'Resuscitation Hero!',
      body: "Keep pushing forward—you're making a real difference!",
      trophy: { src: TrophyBronze, alt: 'Bronze trophy for 3rd place' },
    },
    {
      position: 4,
      header: (number: number) => `${number ?? 'X'}th place!`,
      body: 'You are almost in the top three! Your efforts can make a life-saving difference. Keep up the great work!',
      trophy: { src: ThumbsUp, alt: 'A thumbs up for your position' },
    },
    {
      position: 6,
      header: (number: number) => `${number ?? 'X'}th place!`,
      body: "You're on the journey to saving lives. Keep pushing forward—you're on the right track!",
      trophy: { src: ThumbsUp, alt: 'A thumbs up for your position' },
    },
    {
      position: 9,
      header: (number: number) => `${number ?? 'X'}th place!`,
      body: 'Every attempt matters! Remember, even imperfect CPR is better than none. Keep going - practice makes perfect!',
      trophy: { src: ThumbsUp, alt: 'A thumbs up for your position' },
    },
  ],
};

const getPodiumData = (
  position: number,
  podiumArray: ControllerTypes.MockPodium[],
): ControllerTypes.Podium => {
  let closest = podiumArray[podiumArray.length - 1];

  for (const podium of podiumArray) {
    if (
      podium.position === position ||
      (podium.position < position && podium.position > closest.position)
    ) {
      closest = podium;
    }
  }

  // Call the header function to get the string
  const header = closest.header(position);

  // Return a PodiumWithHeaderString object
  return { ...closest, header };
};

const Controller: FunctionComponent = () => {
  const {
    connect,
    sendRate,
    gameState,
    position,
    teamName,
    cleanup,
    isDebugMode,
    learnerId,
  } = useRaceLearner();
  const tcprLinkContext = useTcprLink();
  const cameraContext = useCamera();
  const { storeTrainingData } = useFirebase();
  const [receivedRate, setReceivedRate] = useState<boolean>(false);

  useEffect(() => {
    connect();
    return () => {
      cleanup();
      consoleDebug('cleanup');
    };
  }, []);

  // Save session result to firebase when the game is finished
  useEffect(() => {
    const sessionResult = tcprLinkContext?.sessionResult;
    if (!sessionResult || sessionResult === '') {
      return;
    }
    const sessionResultParsed: SessionResultExtended =
      JSON.parse(sessionResult);

    // If session has stopped, the load will reset, and prepare the context for the next session.
    // Placed here to ensure we capture results before a reset.
    tcprLinkContext.load();
    // If for some reason we get results before the game is finished, it's probably because of a reload.
    // In that case we don't want to store it.
    if (!position.isFinal) {
      return;
    }
    storeTrainingData(
      FireBaseCollections.sessionResults,
      sessionResultParsed,
      cameraContext?.hasCameraPermission,
    );
  }, [tcprLinkContext?.sessionResult, gameState, position.isFinal]);

  useEffect(() => {
    if (
      tcprLinkContext !== null &&
      tcprLinkContext.rate >= -1 &&
      !receivedRate
    ) {
      setReceivedRate(true);
    }
    if (receivedRate && gameState === GameChannelStates.race_start) {
      sendRate(tcprLinkContext.rate);
    }
  }, [tcprLinkContext?.rate, receivedRate, gameState]);

  const StylableVehicle = useMemo(
    () => modifySvgAndReturnReactComponent(Ambulance),
    [Ambulance],
  );

  const podiumData = useMemo(
    () => getPodiumData(position.current, MockData.podium),
    [position.current, MockData.podium],
  );

  const learnerTeam = useMemo(() => {
    return {
      color: getTeamColor(teamName ?? '1'),
      name: teamName,
      position: position.current,
      isFinalPosition: position.isFinal,
    };
  }, [position.current, position.isFinal, teamName]);

  switch (gameState) {
    case GameChannelStates.race_finished:
      return (
        <>
          {isDebugMode && (
            <DebugView
              learnerTeam={learnerTeam}
              currentState={gameState}
              learnerId={learnerId}
            />
          )}
          <ResultPage
            trophy={podiumData.trophy}
            body={podiumData.body}
            header={podiumData.header}
            learnerTeam={learnerTeam}
          />
        </>
      );
    case GameChannelStates.race_countdown:
    case GameChannelStates.race_start:
      if (position.isFinal && position.current !== null) {
        return (
          <>
            {isDebugMode && (
              <DebugView
                learnerTeam={learnerTeam}
                currentState={gameState}
                learnerId={learnerId}
              />
            )}
            <ResultPage
              trophy={podiumData.trophy}
              body={podiumData.body}
              header={podiumData.header}
              learnerTeam={learnerTeam}
            />
          </>
        );
      }
      return (
        <CprPage
          backgroundLottieOptions={MockData.backgroundLottieOptions}
          bottomPart={MockData.bottomPart}
          countDownLottieOptions={MockData.countDownLottieOptions}
          learnerTeam={learnerTeam}
          Vehicle={StylableVehicle}
        />
      );
    default:
      return (
        <CprPage
          backgroundLottieOptions={MockData.backgroundLottieOptions}
          bottomPart={MockData.bottomPart}
          countDownLottieOptions={MockData.countDownLottieOptions}
          learnerTeam={learnerTeam}
          Vehicle={StylableVehicle}
        />
      );
  }
};

export default withRaceLearnerProvider(Controller);
