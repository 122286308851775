import { LDClient, LDContext } from 'launchdarkly-react-client-sdk';
import { UserContextProps } from '../context/UserProvider';
import {
  getLanguageName,
  getLocaleCodeForSanity,
} from '../features/sanity/localeHelper';
import checkForInsertedDevOpsVariable from './checkForInsertedDevOpsVariable';

const NO_EXPERIMENT_STRING = 'No experiment';

const getProjectName = (projectId: string): string => {
  switch (projectId) {
    case '893004bc-7ec2-47ff-9ea9-105f1e5f132a':
    case '2023ca7c-089a-4a20-b305-ebf852114df9':
    case '89ea30e8-2da5-4477-a6f8-72d02fe186ce':
    case '912eb420-3b8e-489d-af65-6a1989868e93':
    case '53d64c42-12aa-4814-baf1-fa188947b487':
      return 'BHF';
    case 'b0e9e948-6541-433b-8175-cc5373014ccc':
      return 'AHA-ADULT';
    case '2ff1d6fc-ed8e-46e4-950d-a3b6146a876d':
      return 'AHA-INFANT';
    case '0bd696dc-91f1-4cb1-9b7f-cf5cea00d8aa':
      return 'SWEDEN';
    case 'b83d39e8-10ae-4ed4-b408-6414e5e8f5f3':
      return 'NORWAY';
    case '1bd52f5e-4646-4641-bb66-bc2a8d50fc8d':
      return 'JAPAN';
    default:
      return 'UNKNOWN';
  }
};

const isModuleProject = (projectId: string): boolean => {
  if (
    projectId === '53d64c42-12aa-4814-baf1-fa188947b487' ||
    projectId === '2023ca7c-089a-4a20-b305-ebf852114df9' ||
    projectId === '89ea30e8-2da5-4477-a6f8-72d02fe186ce' ||
    projectId === '912eb420-3b8e-489d-af65-6a1989868e93'
  ) {
    return true;
  }
  return false;
};

export const getLaunchDarklyContext = (sanityProjectId: string): LDContext => {
  const projectName = getProjectName(sanityProjectId);
  const browserLanguage = getLanguageName(getLocaleCodeForSanity());

  const launchDarklyContext: LDContext = {
    kind: 'multi',
    user: {
      anonymous: true,
    },
    project: {
      key: sanityProjectId,
      name: projectName,
      language: browserLanguage,
    },
  };

  return launchDarklyContext;
};

export const getProjectIdFromFlag = (
  moduleExperimentFlag: string | undefined,
  projectId: string,
) => {
  if (
    isModuleProject(projectId) ||
    moduleExperimentFlag === NO_EXPERIMENT_STRING ||
    moduleExperimentFlag === undefined
  ) {
    return projectId;
  }
  return moduleExperimentFlag;
};

export const getLaunchDarklyId = () => {
  const id = checkForInsertedDevOpsVariable(
    '#{LAUNCH_DARKLY_Key}#',
    import.meta.env.VITE_APP_LAUNCH_DARKLY_KEY,
  );
  return id;
};

export const isLaunchDarklyEnabled = (): boolean => {
  const isEnabled = checkForInsertedDevOpsVariable(
    '#{LAUNCH_DARKLY_ENABLED}#',
    import.meta.env.VITE_APP_LAUNCH_DARKLY_ENABLED,
  );
  return isEnabled === 'true';
};

export const launchDarklyFlushEvents = async (
  ldClient: LDClient,
  userContext: UserContextProps,
) => {
  if (isLaunchDarklyEnabled() && userContext.launchDarklyClientIsInitialised) {
    await ldClient.flush();
  }
};
