/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import {
  MainText,
  SubText,
} from '../pageTemplates/Templates/SimulationPageStyles';

import {
  ButtonContainer,
  Img,
  PageGridStyle,
  StyledText,
} from '../pageTemplates/Templates/InfoPageStyles';

import image from '../assets/images/ic_unexpected-error.png';
import LifeButton from '../components/Button';
import { useHeightViewport } from '../context';
import { ImageTypes } from '../pageTemplates/Templates/InfoPageTypes';

type Error = {
  errorCode?: 'ERRORLOADINGCOURSE' | 'ERRORLOADINGSETTINGS';
  errorMessage?: string;
};

interface ErrorPageProps {
  error?: Error;
}

const ErrorPage: React.FunctionComponent<ErrorPageProps> = ({ error }) => {
  const { innerHeight } = useHeightViewport();

  return (
    <PageGridStyle height={innerHeight}>
      <Img src={image} alt="Error image" imgType={ImageTypes.Icon} />

      <StyledText>
        <MainText>An error occurred</MainText>
        <SubText>
          Sorry, an unexpected error occurred. Please try again reloading the
          page or returning to the first page of the training.
        </SubText>
        <SubText>{` ${error?.errorCode}`}</SubText>
        <SubText>{` ${error?.errorMessage}`}</SubText>
      </StyledText>

      <ButtonContainer role="navigation" aria-label="Main">
        <LifeButton
          onClick={() => {
            window.location.reload();
          }}
        >
          Return to training
        </LifeButton>
      </ButtonContainer>
    </PageGridStyle>
  );
};

export default ErrorPage;
