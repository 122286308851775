import React from 'react';
import LifeButton from '../../../components/Button';
import { Button } from '../../Templates/InfoPageTypes';

function DynamicDialoguePageButtons({ buttons }: { buttons: Button[] }) {
  const mappedButtons = buttons.map((button: Button) => (
    <LifeButton
      key={`button_${button.buttonType}_${button.text}`}
      variant={button.buttonType}
      onClick={() => {
        button.onClick();
      }}
    >
      {button.text}
      {button.icon ? <button.icon size="18px" /> : ''}
    </LifeButton>
  ));

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{mappedButtons}</>;
}

export default DynamicDialoguePageButtons;
