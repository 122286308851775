/* eslint-disable react/jsx-props-no-spreading */
import styled, { css } from 'styled-components';
import constants from '../../constants/Constants';
import { HorizontalAlignment } from '../types/courseTypes';
import { ImageTypes } from './InfoPageTypes';
import mediaMedium from './MediaMedium';

const { pageMargin, headerHeight } = constants;
/**
 * height: The desired height of the grid, by default it will use the entire page height.
 */
export const PageGridStyle = styled.div<{
  height?: number;
  imgType?: ImageTypes;
}>`
  height: 100vh; /* Fallback for browsers that don't support dvh */
  height: ${(props) => (props.height ? `${props.height}px` : '100dvh')};
  width: 100%;
  max-width: ${constants.maxPageWidthMobile}px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: ${pageMargin.SMALL}px 1fr ${pageMargin.SMALL}px;
  grid-template-rows:
    minmax(32px, max-content)
    ${(props) =>
      props.imgType === ImageTypes.Icon
        ? 'minmax(0, max-content)'
        : 'minmax(0, auto)'}
    ${(props) => (props.imgType === ImageTypes.Icon ? '2fr' : '1fr')}
    max-content
    minmax(16px, max-content);

  @media screen and (min-height: 670px) {
    grid-template-rows:
      minmax(32px, max-content)
      minmax(max-content, 1fr)
      ${(props) => (props.imgType === ImageTypes.Icon ? '2fr' : '1fr')}
      max-content
      minmax(16px, max-content);
  }

  ${mediaMedium(css`
    max-width: ${constants.maxPageWidth}px;
    grid-template-columns: ${pageMargin.MEDIUM}px 1fr 1fr 1fr 1fr ${pageMargin.MEDIUM}px;
    grid-template-rows:
      minmax(${headerHeight.MEDIUM}px, max-content)
      minmax(0, 1fr)
      minmax(0, max-content)
      ${pageMargin.SMALL}px;
  `)}
`;

export const Img = styled.img<{
  imgType?: ImageTypes;
}>`
  grid-column: 1 / -1;
  grid-row: 2;
  max-width: ${(props) =>
    props.imgType === ImageTypes.Icon ? '102px' : '100%'};
  max-height: 100%;

  align-self: flex-end;
  justify-self: center;
  z-index: -1;

  ${mediaMedium(css<{ imgType?: ImageTypes }>`
    grid-column: 2 / 4;
    align-self: center;

    ${(props) => (props.imgType === ImageTypes.Icon ? 'max-width: 60%' : '')};
  `)}
`;
export const ImgWithSize = styled(Img)<{
  maxHeight?: string;
}>`
  ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight};` : '')}
`;
export const LottieContainer = styled.div`
  height: 100%;
  grid-column: 1 / -1;
  align-self: flex-end;
  justify-self: center;
  grid-row: 2;
  ${mediaMedium(css`
    grid-column: 2 / 4;
    align-self: center;
  `)}
  svg {
    display: block;
  }
`;

export const VideoContainer = styled.div`
  grid-column: 2 / -2;
  grid-row: 2;
  max-width: 100%;
  max-height: 100%;
  align-self: center;
  justify-self: center;
  border-radius: 8px;

  ${mediaMedium(css`
    grid-row: 2;
    grid-column: 2 / 4;
    align-self: center;
    max-width: 100%;
  `)}
`;

export const VideoStyle = styled.video<{ isLandscape: boolean }>`
  max-width: 100%;
  max-height: 100%;
  ${(props) => `${props.isLandscape ? 'width: 100%;' : 'height: 100%;'}`}
  align-self: center;
  justify-self: center;
  border-radius: 8px;
`;

export const BannerFrontImg = styled.img<{
  imgType?: ImageTypes;
  alignment?: HorizontalAlignment;
}>`
  grid-column: 2 / -2;
  grid-row: 1;
  max-width: 100%;
  height: 52px;
  align-self: start;
  justify-self: center;
  ${(props) =>
    props.alignment
      ? css`
          justify-self: ${props.alignment};
        `
      : ''};
  margin-top: 16px;
  z-index: 2;
`;
export const BannerBackImg = styled.img`
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  width: 100%;
`;

export const StyledText = styled.main<{
  row?: number;
}>`
  grid-column: 2 / -2;
  grid-row: ${(props) => (props.row ? props.row : '3')};

  display: flex;
  flex-direction: column;
  gap: ${pageMargin.SMALL}px;

  margin: 0;
  margin-top: ${pageMargin.SMALL}px;

  text-align: ${(props) => props.theme.textAlignment};
  h1,
  h2 {
    text-align: center;
  }

  h1 {
    margin: 0;
    margin-top: 16px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: ${(props) => props.theme.colors.neutral_800};
  }
  p {
    color: ${(props) => props.theme.colors.neutral_600};
    line-height: 120%;
    margin-bottom: 12px;
    margin: 0;
  }

  ${mediaMedium(css`
    grid-column: 4 / span 2;
    grid-row: 2;
    align-self: center;
    margin: 0;
    margin-left: ${pageMargin.MEDIUM}px;
  `)}
`;

export const MainContent = styled.div<{
  row?: number;
}>`
  grid-column: 2 / -2;
  grid-row: ${(props) => (props.row ? props.row : '3')};
  display: flex;
  grid-template-columns: 1fr;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  & > *:last-child {
    margin-bottom: ${constants.pageMarginBottom}px;
  }

  ${mediaMedium(css`
    grid-column: 4 / span 2;
    grid-row: 2;
    align-self: center;
    padding-left: 50px;
    width: fit-content;
    justify-content: center;
    gap: 50px;
  `)}
`;

export const ProgressbarContainer = styled.div`
  grid-column: 2 / -2;
  grid-row: 1;
  align-self: end;

  ${mediaMedium(css`
    grid-column: 3 / -3;
    grid-row: -3;
    align-self: center;
  `)}
`;

export const ButtonLeftContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
`;

export const ButtonRightContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: flex-end;
`;

export const ButtonContainer = styled.footer<{
  verticalButtons?: boolean;
}>`
  grid-column: 2 / -2;
  grid-row: -3;
  display: flex;
  justify-content: flex-end;
  ${(props) =>
    props.verticalButtons
      ? `
  margin: 0px;
  flex-direction: column;
  grid-gap: 4px;
  button {
    margin: 0px;
  }`
      : `    
 gap:10px;`}

  ${mediaMedium(css<{ verticalButtons?: boolean }>`
    display: flex;
    flex-direction: ${(props) =>
      props.verticalButtons ? 'row-reverse' : 'row'};
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 10px;
  `)}
`;

export const BottomContainer = styled.div`
  grid-column: 2 / -2;
  grid-row: -2;
  display: grid;
`;

export const VisuallyHidden = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
`;

const ButtonWithSize = styled.button<{ maxHeight?: string }>`
  max-height: ${(props) => (props.maxHeight ? props.maxHeight + 5 : '102px')};
  border: none;
  background: transparent;
`;

export const FooterTextStyle = styled.p`
  font-size: 14px;
`;

export const LanguageButton = styled.div<{
  alignment: HorizontalAlignment | undefined;
}>`
  grid-column: 2 / -2;
  grid-row: 1;
  max-width: 100%;
  height: 52px;
  align-self: start;
  justify-self: end;
  margin-top: 10px;
  margin-right: -10px;
  z-index: 3;

  ${(props) =>
    props.alignment
      ? css`
          justify-self: ${props.alignment};
        `
      : ''};
`;
