import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Ambulance from '../../components/AmbulanceAnimation';
import { TrainingOperatorLoadSoundModal } from '../../components/Modals/Modals';
import Bubble from '../../components/Speechbubble/Speechbubble';
import constants from '../../constants/Constants';
import {
  useAudio,
  useCamera,
  useHeightViewport,
  useTcprLink,
  useViewport,
} from '../../context';
import { useFirebase } from '../../context/FirebaseProvider';
import SessionResult from '../../features/cprAlgorithm/SessionResult';
import {
  TrainingIntroMessages,
  TrainingPageBottomPart,
} from '../../features/sanity/types';
import { SessionResultExtended } from '../../helper';
import { useNavigation } from '../../hooks';
import {
  AudioTypes,
  ResultData,
  TrainingResultPages,
} from '../types/courseTypes';
import TrainingContent from './TrainingContent';
import autoNavigationHelper from '../../helper/autoNavigationHelper';

const TrainingPageWrapper = styled.div<{ height: number }>`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  max-width: ${constants.maxPageWidthMobile}px;
  height: ${(props) => props.height}px;
`;

const OverlayWrapper = styled.div`
  position: relative;
  padding: 0px;
  align-items: center;
  flex: 1;
  margin: 10px;
`;

export const MainText = styled.h1`
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.colors.neutral_800};
  margin-top: 5px;
  margin-bottom: 12px;
`;

const CounterTextStyle = styled.h1`
  font-weight: normal;
  font-size: 7.2rem;
  text-align: center;
  color: ${(props) => props.theme.colors.neutral_600};
  margin-top: 20px;
  margin-bottom: 12px;
`;

const CounterTextDescription = styled.h3`
  font-size: 2.2rem;
  color: ${(props) => props.theme.colors.neutral_600};
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const OperatorImage = styled.img`
  display: block;
  margin: auto;
  margin-top: 50px;
`;

interface TrainingWithCounterPageProps {
  bottomPart?: TrainingPageBottomPart;
  introMessages?: TrainingIntroMessages;
  resultPages?: TrainingResultPages;
  trainingDurationInSeconds?: number;
  showTrainingResult?: (resultData: ResultData) => void;
}

const TrainingWithCounterPage: React.FunctionComponent<
  TrainingWithCounterPageProps
> = ({
  bottomPart,
  introMessages,
  resultPages,
  trainingDurationInSeconds,
  showTrainingResult,
}: TrainingWithCounterPageProps) => {
  const { isLandscape } = useViewport();
  const { innerHeight } = useHeightViewport();
  const { storeTrainingData } = useFirebase();
  const ctx = useCamera();
  const navigationContext = useNavigation();
  const tcprLinkContext = useTcprLink();
  const audio = useAudio();

  const digitCounterRef = useRef<number>();
  const numberDelayRef = useRef<number>();
  const timerRef = useRef<number>();

  const [counter, setCounter] = useState<number>(0);
  const [counterText, setCounterText] = useState<string>('1');
  const [showTrainingPauseModal, setShowTrainingPauseModal] =
    useState<boolean>(false);
  const [startTraining, setStartTraining] = useState<boolean>(false);
  const [trainingStopped, setTrainingStopped] = useState<boolean>(false);

  const showBubbleText = () => {
    setCounter((prevCount) => prevCount + 1);
  };

  const AudioDigitIndexDifference = 3;
  const AudioVisualSyncDelayMs = 120;
  const CountDurationMs = 545; // To make the compression rate of 110.
  const MaxCount = 12; // 13 [0..12].length = 12 audio files. ( 3 first ones not used for operator counting)

  const incrementCounter = () => {
    if (trainingStopped) return; // To make sure we don't continue counting once the training has stopped.
    if (counter === MaxCount) {
      setCounter(AudioDigitIndexDifference);
      return;
    }
    setCounter((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    if (counter > 2) {
      if (!startTraining) {
        // For starting the training first time the counter reach 3.
        setStartTraining(true);
        tcprLinkContext?.start();
      }

      window.clearInterval(timerRef.current);
      digitCounterRef.current = window.setTimeout(
        incrementCounter,
        CountDurationMs,
      );

      // Counter text

      numberDelayRef.current = window.setTimeout(() => {
        setCounterText((counter - AudioDigitIndexDifference + 1).toString());
      }, AudioVisualSyncDelayMs);

      // Counter Audio
      audio?.playPauseResetAudioOnPage(AudioTypes.OnLoad, counter);
    } else {
      audio?.playAudioOnPage(AudioTypes.OnLoad, counter);
    }
    return () => {};
  }, [counter]);

  const audioDurationInMilliSeconds = 10000;

  const initializeAudio = () => {
    audio?.initializeTrainingOperatorAudio();
    timerRef.current = window.setInterval(
      showBubbleText,
      audioDurationInMilliSeconds,
    );
    audio?.playAudioOnPage(AudioTypes.OnLoad, counter);
    setShowTrainingPauseModal(false);
  };

  useEffect(() => {
    if (navigationContext && navigationContext.initialRender) {
      setShowTrainingPauseModal(true);
    } else {
      timerRef.current = window.setInterval(
        showBubbleText,
        audioDurationInMilliSeconds,
      );
      audio?.playAudioOnPage(AudioTypes.OnLoad, counter);
    }

    if (!ctx?.hasCameraPermission) {
      ctx?.checkCameraPermissionAsync();
    }
    const locationPathName = window.location.pathname;

    return () => {
      window.clearInterval(timerRef.current);
      window.clearInterval(digitCounterRef.current);
      audio?.pauseFromAudioLibrary(locationPathName);
      audio?.resetAudio(locationPathName);
    };
  }, []);

  useEffect(() => {
    if (ctx?.hasCameraPermission) {
      tcprLinkContext?.load();
    }
  }, [ctx?.hasCameraPermission]);

  const AmbulanceArrived = () => {
    setTrainingStopped(true);
    if (!ctx || !ctx?.hasCameraPermission || !tcprLinkContext?.isLoaded) {
      navigationContext?.navigateToUrl('/514');
    } else {
      tcprLinkContext?.stop();
    }
  };

  const SetTrainingResults = (
    sessionResultParsed: SessionResult,
    hasCameraPermission?: boolean,
  ) => {
    if (resultPages && showTrainingResult) {
      if (hasCameraPermission === false) {
        showTrainingResult(resultPages.debriefWithNoCameraAccess);
      } else if (
        sessionResultParsed.totalTimeWithUnknownRate === null ||
        sessionResultParsed.averageCompressionRateOfRatesAboveZero === null ||
        sessionResultParsed.totalTimeWithUnknownRate > 30
      ) {
        showTrainingResult(resultPages.couldNotDetect);
      } else if (
        sessionResultParsed.averageCompressionRateOfRatesAboveZero > 130 ||
        sessionResultParsed.averageCompressionRateOfRatesAboveZero < 89
      ) {
        showTrainingResult(resultPages.badRate);
      } else if (
        sessionResultParsed.averageCompressionRateOfRatesAboveZero > 120
      ) {
        showTrainingResult(resultPages.compressSlower);
      } else if (
        sessionResultParsed.averageCompressionRateOfRatesAboveZero < 100
      ) {
        showTrainingResult(resultPages.compressFaster);
      } else {
        showTrainingResult(resultPages.goodRate);
      }
    }
  };

  // Save score to firebase and navigate
  useEffect(() => {
    // checks startTraining because sessionResult have stale values from last training (causes issues when we traing again)
    if (
      !tcprLinkContext?.sessionResult ||
      tcprLinkContext?.sessionResult === '' ||
      !startTraining
    ) {
      return;
    }

    const sessionResultParsed: SessionResultExtended = JSON.parse(
      tcprLinkContext?.sessionResult,
    );
    storeTrainingData(
      'SessionResults',
      sessionResultParsed,
      ctx?.hasCameraPermission,
    ).finally(() => {
      SetTrainingResults(
        sessionResultParsed.sessionResult,
        ctx?.hasCameraPermission,
      );
    });
  }, [tcprLinkContext?.sessionResult]);

  const getTrainingDuration = (): number => {
    if (autoNavigationHelper.isTestMode()) {
      return autoNavigationHelper.cameraTrainingDuration(60);
    }
    if (trainingDurationInSeconds) {
      return trainingDurationInSeconds;
    }

    return 60;
  };
  return (
    <TrainingPageWrapper height={innerHeight}>
      <OverlayWrapper>
        <TrainingOperatorLoadSoundModal
          isVisible={showTrainingPauseModal}
          onContinue={initializeAudio}
          onRestart={() => navigationContext?.navigateToUrl('/51')}
          toggleModal={() => setShowTrainingPauseModal(false)}
        />

        <Ambulance
          animationDurationInSeconds={getTrainingDuration()}
          animationDelayInSeconds={0}
          start={startTraining}
          onAnimationEnded={AmbulanceArrived}
        />

        <OperatorImage
          src={introMessages?.emergencyOperatorImagePath}
          width={48}
          height={48}
          alt="Operator"
        />
        <MainText>{introMessages?.emergencyOperatorHeading}</MainText>
        <Bubble>
          {startTraining && (
            <>
              <CounterTextDescription>
                Keep pushing hard and fast.
              </CounterTextDescription>
              <CounterTextStyle>{counterText}</CounterTextStyle>
            </>
          )}
          {!startTraining && introMessages?.messages && (
            <h3>{introMessages?.messages[counter]?.message}</h3>
          )}
        </Bubble>
      </OverlayWrapper>
      <TrainingContent
        startTraining={startTraining}
        displayVideoFeed={bottomPart?.right === 'videoFeed'}
        feedbackTexts={bottomPart?.feedbackTexts}
        height={
          isLandscape
            ? constants.video.sizes.trainingVideoFrameSize.height
            : undefined
        }
      />
    </TrainingPageWrapper>
  );
};

export default TrainingWithCounterPage;
