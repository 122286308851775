import styled, { css } from 'styled-components';

namespace Styles {
  const SharedTypography = css`
    text-align: center;
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `;
  export const VehicleWrapper = styled.div<{
    color: string;
  }>`
    grid-row: 1;
    grid-column: 1;
    place-self: center;
    z-index: 5;
    margin-top: 16%;
    height: 42%;
    width: 42%;
    svg {
      height: 100%;
      width: 100%;
    }
    .colored-path {
      fill: ${(props) => props.color};
    }
  `;
  export const FeedbackText = styled.p`
    color: var(--Neutral-800-shade, #333);
    ${SharedTypography};
    font-size: 2.6rem;
    line-height: 120%;
  `;
}
export default Styles;
