/* eslint-disable react/self-closing-comp */
import React, { FunctionComponent } from 'react';
import { Banner, BannerImage } from '../../components';
import { FooterText } from '../../components/LaerdalFooter/FooterStyles';
import { useFirebase, useHeightViewport } from '../../context';
import { cardNavigationTypes } from '../../features/sanity/types';
import useNavigation from '../../hooks/useNavigation';
import { BottomContainer, StyledText } from '../Templates/InfoPageStyles';
import { Text } from '../Templates/Text';
import { SanityCard } from '../types/courseTypes';
import ExtendedPageGridStyle from './ClickableCardsPage.Styles';
import Card from './components/Card';
import { CardsContainer } from './components/Card.Styles';

export type ClickableCardPageProps = {
  banner?: Banner;
  bottomContent?: React.ReactNode;
  cards: SanityCard[];
  text?: Text;
};

const ClickableCardsPage: FunctionComponent<ClickableCardPageProps> = ({
  banner,
  bottomContent,
  text,
  cards,
}: ClickableCardPageProps) => {
  const { innerHeight } = useHeightViewport();
  const { navigateToUrl, navigateToNextPage } = useNavigation();
  const { storeButtonClick } = useFirebase();

  const cardClicked = (event) => {
    storeButtonClick(event.currentTarget.innerText, event.currentTarget.id);
    const navigation = event.currentTarget.getAttribute('data-navigation');
    const url = event.currentTarget.getAttribute('data-url');
    const openInNewTab =
      event.currentTarget.getAttribute('data-openinnewtab') === 'true';
    if (navigation === cardNavigationTypes.urlPage) {
      navigateToUrl(url, false, openInNewTab);
    }
    if (navigation === cardNavigationTypes.nextPage) {
      navigateToNextPage();
    }
  };

  return (
    <ExtendedPageGridStyle height={innerHeight}>
      <BannerImage banner={banner} />

      {text && (
        <StyledText row={2}>
          <h1 tabIndex={-1}>{text.header}</h1>
          {typeof text.body === 'string' ? (
            <p>
              {text.body}
              <strong>{text.emphasized}</strong>
            </p>
          ) : (
            text.body
          )}
        </StyledText>
      )}
      <CardsContainer>
        {cards
          ? cards.map((card, index) => {
              return (
                <Card
                  key={'card' + (index + 1)}
                  id={'card' + (index + 1)}
                  heading={card.heading}
                  subHeading={card.subHeading}
                  iconImage={card.iconImage}
                  cardClicked={cardClicked}
                  url={card.url}
                  navigation={card.navigation}
                  openInNewTab={card.openInNewTab}
                />
              );
            })
          : null}
      </CardsContainer>
      <BottomContainer>
        <FooterText>{text?.footerText}</FooterText>
        {bottomContent}
      </BottomContainer>
    </ExtendedPageGridStyle>
  );
};

export default ClickableCardsPage;
