import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Constants } from '../../../constants';
import mediaMedium from '../../../pageTemplates/Templates/MediaMedium';
import { AnimationState } from '../types';

export interface ProgressbarProps {
  animationDurationInSeconds: number;
  animationDelayInSeconds?: number;
  animationState: AnimationState;
  setAnimRef?: React.RefObject<HTMLDivElement>;
}

const { progressBarHeight: progressbarHeight } = Constants;

const pathAnimation = keyframes`
  0% {width:0px;}
  100% {width:calc(100%);}
`;

const animationStateTranslate = (animationState: AnimationState) => {
  switch (animationState) {
    case AnimationState.running:
      return 'running';
    case AnimationState.paused:
      return 'paused';
    default:
      return 'running';
  }
};

const Path = styled.div<{
  animationDurationInSeconds: number;
  animationDelayInSeconds: number;
  animationState: AnimationState;
}>`
  /* sizing */
  width: 0px;
  height: ${progressbarHeight.SMALL}px;

  /* styling */
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.primary_500};

  /* positioning */
  position: absolute;
  left: 0px;
  top: 0px;

  /* animation */
  animation-name: ${pathAnimation};
  animation-duration: ${(props) => props.animationDurationInSeconds}s;
  animation-delay: ${(props) => props.animationDelayInSeconds}s;
  animation-play-state: ${(props) =>
    animationStateTranslate(props.animationState)};
  animation-fill-mode: forwards;
  animation-timing-function: linear;

  ${mediaMedium(css`
    height: ${progressbarHeight.MEDIUM}px;
  `)}
`;

const BackgroundPath = styled.div`
  width: calc(100%);
  height: ${progressbarHeight.SMALL}px;
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.neutral_100};
  position: absolute;
  left: 0px;
  top: 0px;

  ${mediaMedium(css`
    height: ${progressbarHeight.MEDIUM}px;
  `)}
`;

const Container = styled.div`
  position: relative;
  width: calc(100%);
  height: ${progressbarHeight.SMALL}px;

  ${mediaMedium(css`
    height: ${progressbarHeight.MEDIUM}px;
  `)}
`;

const Progressbar: FunctionComponent<ProgressbarProps> = (
  props: ProgressbarProps,
) => {
  const {
    animationState,
    animationDurationInSeconds,
    animationDelayInSeconds,
    setAnimRef,
  } = props;
  const [seconds, setSeconds] = useState(animationDurationInSeconds);
  useEffect(() => {
    const counter = setInterval(() => {
      if (animationState === AnimationState.running) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(counter);
    };
  }, [animationState, seconds]);

  return (
    <Container
      role="progressbar"
      aria-label={`Progress bar. Time left ${seconds} seconds`}
      aria-valuemin={0}
      aria-valuemax={animationDurationInSeconds}
      aria-valuenow={seconds}
    >
      <BackgroundPath />
      <Path
        ref={setAnimRef}
        animationDurationInSeconds={animationDurationInSeconds}
        animationState={animationState}
        animationDelayInSeconds={
          animationDelayInSeconds === undefined ? 0 : animationDelayInSeconds
        }
      />
    </Container>
  );
};

export default Progressbar;
