import { PortableText, toPlainText } from '@portabletext/react';
import React from 'react';
import constants from '../../constants/Constants';
import { useFirebase } from '../../context';
import {
  SanityShare,
  SanityShareNotSupported,
} from '../../pageTemplates/types/sanityTypes';
import { LocalStorageService } from '../../services';
import { logError } from '../../services/errorHandling';

const getShareURL = () => {
  let shareLevel = 1;
  const localStorageShareLevel = LocalStorageService.getShareLevel();
  if (localStorageShareLevel != null) {
    shareLevel = parseInt(localStorageShareLevel, 10) + 1;
  }
  const { ORGANIZATION_KEY, SHARE_LEVEL_KEY, TAG_KEY, DISTRIBUTION_KEY } =
    constants.queryKeys;
  const organization = LocalStorageService.getOrganization()
    ? `&${ORGANIZATION_KEY}=${LocalStorageService.getOrganization()}`
    : '';
  const tag = LocalStorageService.getTag()
    ? `&${TAG_KEY}=${LocalStorageService.getTag()}`
    : '';
  const distribution = LocalStorageService.getDistribution()
    ? `&${DISTRIBUTION_KEY}=${LocalStorageService.getDistribution()}`
    : '';
  return `${document.location.origin}?${SHARE_LEVEL_KEY}=${shareLevel}${organization}${tag}${distribution}`;
};

export const copiedShareText = (text: string): void => {
  navigator.clipboard.writeText(text);
};

export const getBodyText = (portableText): string => {
  const plainText = `${toPlainText(portableText)}
  ${getShareURL()}`;
  return plainText;
};

const convertPortableText = (portableText) => {
  return (
    <>
      {portableText && <PortableText value={portableText} />}
      <p>{getShareURL()}</p>
    </>
  );
};

export const convertShareNotSupportedData = (
  copyTextModal?: SanityShareNotSupported,
): SanityShareNotSupported => {
  return {
    header: copyTextModal?.header,
    subHeader: copyTextModal?.subHeader,
    body: convertPortableText(copyTextModal?.body),
    copyButtonText: copyTextModal?.copyButtonText,
    copiedButtonText: copyTextModal?.copiedButtonText,
  } as SanityShareNotSupported;
};

/**
 * @returns
 * ```ts
 * const showSharePopup: (shareData: SanityShare) => void
 * ```
 * Link to {@link showSharePopup}
 */
export const useShowSharePopup = () => {
  const urlToShare = getShareURL();
  const { storeCollection } = useFirebase();
  const webShare = window.navigator;
  const showSharePopup = (shareData: SanityShare) => {
    const gaEvent = {
      category: 'SocialShare',
      action: 'ActualSharing',
      label: window.location.pathname,
    };
    if (webShare.share) {
      webShare
        .share({
          title: shareData?.title,
          text: shareData?.text,
          url: urlToShare,
        })
        .then(() => {
          storeCollection(gaEvent.category, gaEvent);
        })
        .catch((error: Error) => {
          console.error('Error on Web Share Api', error);
          logError(error, 'Error on Web Share Api', { ...gaEvent });
        });
    } else {
      gaEvent.action = 'SharingNotSupportedByBrowser';

      storeCollection(gaEvent.category, gaEvent);
    }
  };
  return showSharePopup;
};
