/* eslint-disable @typescript-eslint/lines-between-class-members */

class SessionResult {
  secondsToFirstCompression: number | null = 0;
  handsOffTime: number | null = 0;
  totalCompressionTime: number | null = 0;
  totalTimeWithHandsOffAndUnknownRate: number | null = 0;
  totalNumberOfCompressions: number | null = 0;
  averageCompressionRateOfRatesAboveZero: number | null = 0;
  totalTimeWithUnknownRate: number | null = 0;
  static TAG = 'SessionInfoResult';

  constructor(
    secondsToFirstCompression?: number | null,
    handsOffTime?: number | undefined,
    totalCompressionTime?: number | undefined,
    totalTimeWithUnknownRate?: number | undefined,
    averageCompressionRateOfRatesAboveZero?: number | undefined,
    totalNumberOfCompressions?: number | undefined,
  ) {
    this.secondsToFirstCompression =
      secondsToFirstCompression === undefined
        ? null
        : secondsToFirstCompression;
    this.handsOffTime = handsOffTime === undefined ? null : handsOffTime;
    this.totalCompressionTime =
      totalCompressionTime === undefined ? null : totalCompressionTime;
    this.totalTimeWithHandsOffAndUnknownRate =
      totalTimeWithUnknownRate === undefined || handsOffTime === undefined
        ? null
        : totalTimeWithUnknownRate + handsOffTime;
    this.totalNumberOfCompressions =
      totalNumberOfCompressions === undefined
        ? null
        : Math.round(totalNumberOfCompressions);
    this.averageCompressionRateOfRatesAboveZero =
      averageCompressionRateOfRatesAboveZero === undefined
        ? null
        : Math.round(averageCompressionRateOfRatesAboveZero);
    this.totalTimeWithUnknownRate =
      totalTimeWithUnknownRate === undefined ? null : totalTimeWithUnknownRate;
  }
}

export default SessionResult;
