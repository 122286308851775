import { Page } from '../../../pageTemplates/types/courseTypes';

// Used to find the next page to navigate to
const getNextPageIndex = (
  currentIndex: number,
  course: Page[],
  direction: 'backward' | 'forward',
): number => {
  const getIndexDirection = () => {
    if (direction === 'forward') {
      return 1;
    }

    return -1;
  };

  const tempPageIndex = currentIndex + getIndexDirection();
  return tempPageIndex;
};
export default getNextPageIndex;
