import styled, { css } from 'styled-components';

namespace Styles {
  const SharedTypography = css`
    text-align: center;
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `;

  export const FirstRow = styled.div`
    grid-column: 2 / -2;
    grid-row: 1;
    height: 100%;
  `;

  export const FullScreenWidth = styled.div<{
    color: string;
  }>`
    background: ${(props) => props.color};
    height: 100%;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--Brand-White, #fff);
      ${SharedTypography};
      font-size: 32px;
    }
  `;
}
export default Styles;
