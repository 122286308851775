/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Debug from '../../../components/Debug/Debug';
import { useSanityCourse } from '../../../context/sanity/useSanityCourse';
import { urlChecker } from '../../../helper';
import viablePaths from '../../../helper/viablePaths';
import { TemplateList } from '../../../pageTemplates';
import { isLearnerPageWithTemplate } from '../../../pageTemplates/types/courseTypes';
import getCommonNavigationRoute from '../common/getCommonNavigationRoute';
import { groupTrainingUrl } from '../helper/GroupTrainingUrl';
import { GroupTemplateList } from '../pageTemplates';
import LearnerNavigationProvider from './context/LearnerNavigationContext';
import {
  LearnerRoleSplitTemplate,
  LearnerShowMyTeamTemplate,
} from './templates';

const LearnerTemplateList = {
  ...TemplateList,
  ...GroupTemplateList,
  learnerShowMyTeamTemplate: LearnerShowMyTeamTemplate,
  learnerRoleSplitTemplate: LearnerRoleSplitTemplate,
};

const ConnectedPage = React.lazy(
  () => import('./templates/ConnectedPage/ConnectedPage'),
);

const LandingPage = React.lazy(
  () => import('./templates/LandingPage/LandingPage'),
);

const WaitingPage = React.lazy(
  () => import('./templates/WaitingPage/WaitingPage'),
);

const PathNotFoundPage = React.lazy(
  () => import('../../../pages/PathNotFoundPage'),
);

const RoutingWrapper: FunctionComponent = () => {
  const courseContext = useSanityCourse();
  const mainPaths = viablePaths();
  mainPaths[0] = ''; // clearing it because it contains a slash and will cause issues
  return (
    <Routes>
      {mainPaths
        .map((path) => path + groupTrainingUrl.learner)
        .includes(window.location.pathname)}
      && (
      <Route
        path="*"
        element={
          <Suspense fallback={null}>
            <LandingPage />
          </Suspense>
        }
      />
      )
      <Route
        path="/connected"
        element={
          <Suspense fallback={null}>
            <ConnectedPage />
          </Suspense>
        }
      />
      <Route
        path="/waiting"
        element={
          <Suspense fallback={null}>
            <WaitingPage />
          </Suspense>
        }
      />
      {courseContext.course != null &&
        courseContext.course.length > 0 &&
        courseContext.course.map((page) => {
          /* check if the page exists or is a LearnerPage */
          if (page === undefined) {
            return null;
          }
          if (!isLearnerPageWithTemplate(page)) {
            return getCommonNavigationRoute(page);
          }
          /* check if the template exists for each route */
          return typeof LearnerTemplateList[page?.template] !== 'undefined' ? (
            <Route
              key={page.url}
              path={urlChecker(page.url)}
              element={
                <React.Fragment key={page.url}>
                  {React.createElement(LearnerTemplateList[page.template])}
                </React.Fragment>
              }
            />
          ) : null;
        })}
      <Route
        path="/*"
        element={
          <Suspense fallback={null}>
            <PathNotFoundPage />
          </Suspense>
        }
      />
    </Routes>
  );
};

const LearnerRouting: FunctionComponent = () => {
  return (
    <Router>
      <LearnerNavigationProvider>
        <Debug />
        <RoutingWrapper />
      </LearnerNavigationProvider>
    </Router>
  );
};

export default LearnerRouting;
