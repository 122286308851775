const templateTypes = {
  AedBubbleTemplate: 'aedBubbleTemplate',
  AEDDraggablePadPageTemplate: 'aedDraggablePadPageTemplate',
  AedTemplate: 'aedTemplate',
  DialogPageTemplate: 'dialogPageTemplate',
  DiplomaTemplate: 'diplomaTemplate',
  EmergencyCallTemplate: 'emergencyCallTemplate',
  FrontPage: 'frontPageTemplate',
  InputTemplate: 'inputTemplate',
  QuizPage: 'quizPageTemplate',
  RatingPage: 'ratingPageTemplate',
  TextAndImage: 'textAndImageTemplate',
  TrainingTemplate: 'trainingTemplate',
  VideoPage: 'videoPageTemplate',
  EmailReminderTemplate: 'emailReminderTemplate',
};

export default templateTypes;
