import React from 'react';
import { srStyle } from './styles';

interface AccessibilityAnnouncerProps {
  message: string;
}

const AccessibilityAnnouncer: React.FunctionComponent<
  AccessibilityAnnouncerProps
> = ({ message }) => {
  return (
    <span style={srStyle} role="status" aria-live="polite" aria-atomic="true">
      {message}
    </span>
  );
};
export default AccessibilityAnnouncer;
