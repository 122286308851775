import { css, styled } from 'styled-components';

const RADIUS = '48px';
const MARGIN_MEDIUM = '10px';
const PADDING_SMALL = '5px';
const PADDING_MEDIUM = '10px';

export const CardContainer = styled.div`
  display: flex;
  width: 95%;
  height: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  border-radius: ${RADIUS};
  background-color: #ffffff;
  justify-content: space-between;
  margin-top: ${MARGIN_MEDIUM};
  margin-left: ${MARGIN_MEDIUM};
  margin-right: ${MARGIN_MEDIUM};

  ${(props) => css`
    &:hover {
      cursor: pointer;
      background-color: ${props.theme.colors.neutral_200};
    }
  `}
`;

export const CardTextHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 42px;
`;

export const CardsContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 3;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const CardMainText = styled.div`
  padding-bottom: ${PADDING_SMALL};
  padding-right: ${PADDING_MEDIUM};
  font-weight: bold;
`;

export const CardSubText = styled.div`
  padding-right: ${PADDING_MEDIUM};
  font-weight: normal;
`;

export const CardImage = styled.img`
  display: flex;
  align-self: flex-start;
  border-radius: 0 ${RADIUS} ${RADIUS} 0;
  height: 100%;
`;
