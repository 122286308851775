/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable no-plusplus */
import GlobalVariables from './GlobalVariables';
import ImageBlock from './ImageBlock';

class ImageblockManager {
  private blockSize = 0;
  private imageBlocks: ImageBlock[][] = [];
  private imageHeight = 0;
  private imageWidth = 0;
  private numberOfHorizontalBlocks = 0;
  private numberOfVerticalBlocks = 0;
  private lastHorizontalBlocksize = 0;
  private lastVerticalBlocksize = 0;
  private sumOfChangesParts: number[][];
  private timeParts: number[];

  constructor(imageWidth: number, imageHeight: number, blockSize: number) {
    this.blockSize = blockSize;
    this.imageHeight = imageHeight;
    this.imageWidth = imageWidth;
    this.sumOfChangesParts = [[]]; // new Array<Array<number>>();
    this.timeParts = [];

    this.calculateNumberOfBlocks();
    this.imageBlocks = this.make2DArray(
      this.numberOfVerticalBlocks,
      this.numberOfHorizontalBlocks,
    );

    this.createBlocks();
  }

  // eslint-disable-next-line class-methods-use-this
  private make2DArray(row: number, col: number): ImageBlock[][] {
    const arr = new Array(row);
    for (let i = 0; i < row; i++) {
      arr[i] = new Array(col);
    }
    return arr;
  }

  private createBlocks(): void {
    for (let y = 0; y < this.numberOfVerticalBlocks; y++) {
      if (this.imageBlocks.length === y) {
        this.imageBlocks.push(new Array(this.numberOfHorizontalBlocks));
      }

      for (let x = 0; x < this.numberOfHorizontalBlocks; x++) {
        let numberOfRangePairs = this.blockSize;
        if (y === this.numberOfVerticalBlocks - 1) {
          numberOfRangePairs = this.lastVerticalBlocksize;
        }

        const newBlock = new ImageBlock(x, y, numberOfRangePairs);

        const horizontalBlocksize =
          x === this.numberOfHorizontalBlocks - 1
            ? this.lastHorizontalBlocksize
            : this.blockSize;

        newBlock.fillRanges(
          this.imageWidth,
          horizontalBlocksize,
          this.blockSize,
        );

        this.imageBlocks[y][x] = newBlock;
      }
    }
  }

  private numberOfBlocksInOneDirectionAndLastBlockSize(
    lengthOfDirection: number,
  ): number[] {
    const result = new Array(2); // set to fixed size.  0 = number of blocks, 1 = last block size

    let lastBlockSize = this.blockSize;
    let tempLength = lengthOfDirection;
    let numberOfBlocks = 0;

    while (tempLength > 0) {
      if (tempLength < this.blockSize) {
        lastBlockSize = tempLength;
        tempLength = 0;
      } else {
        tempLength -= this.blockSize;
      }
      numberOfBlocks += 1;
    }

    result[0] = numberOfBlocks;
    result[1] = lastBlockSize;

    return result;
  }

  private calculateNumberOfBlocks(): void {
    let temp = this.numberOfBlocksInOneDirectionAndLastBlockSize(
      this.imageWidth,
    );
    this.numberOfHorizontalBlocks = temp[0];
    this.lastHorizontalBlocksize = temp[1];
    temp = this.numberOfBlocksInOneDirectionAndLastBlockSize(this.imageHeight);
    this.numberOfVerticalBlocks = temp[0];
    this.lastVerticalBlocksize = temp[1];
  }

  public calculateSumOfChangesSumForAllBlocks(
    numberOfFramesToUseInCalculation: number,
  ) {
    for (let y = 0; y < this.numberOfVerticalBlocks; y++) {
      for (let x = 0; x < this.numberOfHorizontalBlocks; x++) {
        this.imageBlocks[y][x].calculateSumOfChangesSum(
          numberOfFramesToUseInCalculation,
        );
      }
    }
  }

  public ImageCount(): number {
    let imageCount = 0;
    for (let i = 0; i < this.sumOfChangesParts.length; i++) {
      imageCount += this.sumOfChangesParts[i].length;
    }
    return imageCount;
  }

  public SumOfChanges(numberOfFrames: number): number[] {
    const result = new Array<number>(numberOfFrames).fill(0);

    for (let y = 0; y < this.numberOfVerticalBlocks; y++) {
      for (let x = 0; x < this.numberOfHorizontalBlocks; x++) {
        const sumOfChangesList =
          this.imageBlocks[y][x].sumOfChangesList(numberOfFrames);

        for (let i = 0; i < sumOfChangesList.length; i++) {
          result[i] += sumOfChangesList[i];
        }
      }
    }
    return result;
  }

  public calculateSumOfChangesInBlocks(
    differenceImage: number[],
    numberOfImagesInCurrentSignal: number,
  ): void {
    for (let y = 0; y < this.numberOfVerticalBlocks; y++) {
      for (let x = 0; x < this.numberOfHorizontalBlocks; x++) {
        this.imageBlocks[y][x].calculateSumOfChangesInBlock(
          differenceImage,
          numberOfImagesInCurrentSignal,
        );
      }
    }
  }
  public addToSumOfChangesPart(newSumOfChangesPart: number[]): void {
    this.sumOfChangesParts.push(newSumOfChangesPart);
    if (this.sumOfChangesParts.length > 6) {
      this.sumOfChangesParts.shift(); // remove first element
    }
  }

  public addTimePart(timePart: number): void {
    this.timeParts.push(timePart);
    if (this.timeParts.length > 6) {
      this.timeParts.shift();
    }
  }

  public totalTimeForLastImages(): number {
    let totalTime = 0;
    for (let i = 0; i < this.timeParts.length; i++) {
      totalTime += this.timeParts[i];
    }
    return totalTime;
  }

  public completeSumOfChanges(): number[] {
    // Flatten 2 dim array
    let counter = 0;
    const sumOfChanges = new Array<number>(this.ImageCount());

    this.sumOfChangesParts.forEach((changesParts) => {
      changesParts.forEach((changePart) => {
        sumOfChanges[counter] = changePart;
        counter++;
      });
    });

    return sumOfChanges;
  }

  public sumOfChangesLengthExcludingLast(): number {
    let sum = 0;
    if (this.sumOfChangesParts.length > 0) {
      for (let i = 1; i < this.sumOfChangesParts.length; i++) {
        sum += this.sumOfChangesParts[i].length;
      }
    }
    return sum;
  }

  public culateSumOfChangesSumForAllBlocks(
    numberOfFramesToUseInCalculation: number,
  ) {
    for (let y = 0; y < this.numberOfVerticalBlocks; y++) {
      for (let x = 0; x < this.numberOfHorizontalBlocks; x++) {
        this.imageBlocks[y][x].calculateSumOfChangesSum(
          numberOfFramesToUseInCalculation,
        );
      }
    }
  }

  public sumOfChanges(numberOfFrames: number): number[] {
    const result = new Array(numberOfFrames).fill(0);

    for (let y = 0; y < this.numberOfVerticalBlocks; y++) {
      for (let x = 0; x < this.numberOfHorizontalBlocks; x++) {
        const sumOfChangesList =
          this.imageBlocks[y][x].sumOfChangesList(numberOfFrames);
        for (let i = 0; i < sumOfChangesList.length; i++) {
          result[i] += sumOfChangesList[i];
        }
      }
    }
    return result;
  }

  public calculateSumOfChanges(
    differences: number[][],
    timeUsedToReceiveLastImages: number,
    imagesToCheck: number,
  ): number[] {
    this.addTimePart(timeUsedToReceiveLastImages);

    const numberOfImagesInCurrentSignal =
      this.sumOfChangesLengthExcludingLast() + imagesToCheck;
    const fps =
      numberOfImagesInCurrentSignal / (this.totalTimeForLastImages() / 1000.0);
    GlobalVariables.globalFPS = fps;

    for (let i = 0; i < imagesToCheck; i++) {
      this.calculateSumOfChangesInBlocks(
        differences[i],
        numberOfImagesInCurrentSignal,
      );
    }

    this.culateSumOfChangesSumForAllBlocks(imagesToCheck);

    let sumOfChanges = this.sumOfChanges(imagesToCheck);
    this.addToSumOfChangesPart(sumOfChanges);
    sumOfChanges = this.completeSumOfChanges();

    return sumOfChanges;
  }
}

export default ImageblockManager;
