import {
  defaultOnMouseDownHandler,
  focusStyles,
} from '@laerdal/life-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { focusOnElement } from '../../components/UseFocus';
import {
  MainTextNoVerticalMargin,
  SubTextNoVerticalMargin,
} from '../Templates/SimulationPageStyles';
import { RatingIcons, RatingIconsAltText } from '../types/courseTypes';
import FilledStar from '/images/star_filled.svg';
import OutlinedStar from '/images/star_outlined.svg';

interface RatingProps {
  altText?: RatingIconsAltText;
  mainText: string;
  subText: string;
  onRatingClick?: (rating: number) => void;
  ratingIcons?: RatingIcons;
}

const returnRatingIcon = (props: {
  isSelected: boolean;
  selectedIcon?: string;
  unSelectedIcon?: string;
}) => {
  return props.isSelected
    ? props.selectedIcon ?? FilledStar
    : props.unSelectedIcon ?? OutlinedStar;
};

const RatingButtonStyle = styled.button<{
  isSelected: boolean;
  selectedIcon?: string;
  unSelectedIcon?: string;
}>`
  width: 50px;
  height: 50px;
  margin: 5px;
  background: none;
  border: none;
  background-image: url(${(props) => returnRatingIcon(props)});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50px;
  &:focus,
  &.focus-state {
    ${() => focusStyles}
  }
`;

type RatingButtonProps = {
  role: string;
  onMouseDown: (event: React.MouseEvent<HTMLButtonElement>) => void;
  ariaLabel: string;
  ariaChecked: boolean;
  key: string;
  isSelected: boolean;
  onClick: () => void;
  selectedIcon?: string; // '?' indicates that the prop is optional
  unSelectedIcon?: string; // '?' indicates that the prop is optional
  forceRerenderTicker: number;
};

const RatingButton: FunctionComponent<RatingButtonProps> = ({
  role,
  onMouseDown,
  ariaLabel,
  ariaChecked,
  key,
  isSelected,
  onClick,
  selectedIcon,
  unSelectedIcon,
  forceRerenderTicker, // this is because isSelected is not always changing, so we force the animation on all stats
}: RatingButtonProps) => {
  const [style, setStyle] = useState({
    transform: 'scale(1)',
    transition: 'transform 200ms ease-in-out',
  });

  useEffect(() => {
    if (isSelected) {
      // Initially scale down
      setStyle({
        transform: 'scale(0.8)',
        transition: 'transform 200ms ease-in-out',
      });

      // Set a timeout to scale back up after 200ms
      const timer = setTimeout(() => {
        setStyle({
          transform: 'scale(1)',
          transition: 'transform 200ms ease-in-out',
        });
      }, 200);

      // Cleanup the timeout if the component unmounts or re-renders
      return () => clearTimeout(timer);
    }

    return () => {};
  }, [isSelected, forceRerenderTicker]);

  return (
    <RatingButtonStyle
      isSelected={isSelected}
      key={key}
      selectedIcon={selectedIcon}
      unSelectedIcon={unSelectedIcon}
      role={role}
      onMouseDown={onMouseDown}
      aria-label={ariaLabel}
      aria-checked={ariaChecked}
      onClick={onClick}
      style={style}
    />
  );
};

const RatingButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: none;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;
`;

const TextWrapper = styled.div`
  flex: 1;
  text-align: ${(props) => props.theme.textAlignment};
  h1:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ConfidenceRating: FunctionComponent<RatingProps> = ({
  altText,
  mainText,
  subText,
  onRatingClick,
  ratingIcons,
}: RatingProps) => {
  const [selectedStarIndex, setSelectedStarIndex] = useState<number>(0);
  const RatingButtonClicked = (starId: number) => {
    setSelectedStarIndex(starId);
    if (onRatingClick) onRatingClick(starId);
  };

  const getStarAriaLabel = (starId: number) => {
    return (
      Object.values(altText ?? [])?.[starId - 1] ??
      `${starId} star${starId === 1 ? '' : 's'}`
    );
  };

  const displayStars = () => {
    const stars: Array<JSX.Element> = [];
    for (let count = 1; count < 6; count += 1) {
      const ratingButton = (
        <RatingButton
          role="radio"
          onMouseDown={defaultOnMouseDownHandler}
          ariaLabel={getStarAriaLabel(count)}
          ariaChecked={count <= selectedStarIndex}
          key={`star${count.toString()}`}
          isSelected={count <= selectedStarIndex}
          onClick={() => RatingButtonClicked(count)}
          selectedIcon={ratingIcons?.selectedIcon?.src}
          unSelectedIcon={ratingIcons?.unSelectedIcon?.src}
          forceRerenderTicker={Date.now()}
        />
      );

      stars.push(ratingButton);
    }
    return stars;
  };

  useEffect(() => {
    focusOnElement();
  }, [selectedStarIndex]);

  return (
    <ComponentWrapper>
      {selectedStarIndex === 0 && (
        <TextWrapper>
          <MainTextNoVerticalMargin tabIndex={-1}>
            {mainText}
          </MainTextNoVerticalMargin>
          <SubTextNoVerticalMargin>{subText}</SubTextNoVerticalMargin>
        </TextWrapper>
      )}
      {selectedStarIndex !== 0 && (
        <TextWrapper>
          <MainTextNoVerticalMargin tabIndex={-1}>
            {mainText}
          </MainTextNoVerticalMargin>
        </TextWrapper>
      )}
      <RatingButtonContainer role="radiogroup">
        {displayStars()}
      </RatingButtonContainer>
    </ComponentWrapper>
  );
};

export default ConfidenceRating;
