import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useSanityCourse } from '../../context/sanity/useSanityCourse';
import { defaultText } from '../../features/sanity';
import { useNavigation } from '../../hooks';
import BrandIcon from './BrandIcon';
import TimeLeftBar from './TimeLeftBar';

const Gird = styled.div`
  display: grid;
  grid-template-columns: minmax(1px, max-content) 1fr minmax(1px, max-content);
  align-content: center;
  justify-content: center;
`;
const BarAligner = styled.div`
  height: max-content;
  align-self: center;
`;
const StyledText = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 140%;

  display: flex;
  align-items: center;
  text-align: right;

  color: ${(props) => props.theme.colors.neutral_600};
`;
interface TimeLeftWithTextProps {
  enabled: boolean | undefined;
}

const TimeLeftWithText: FunctionComponent<TimeLeftWithTextProps> = ({
  enabled,
}) => {
  const { timeLeftInSeconds, totalTimeInSeconds } = useNavigation();
  const courseContext = useSanityCourse();
  if (!enabled) {
    return <> </>;
  }

  const parsedIntValue = parseInt(`${timeLeftInSeconds / 60}`, 10);
  const timeLeftMinutes = parsedIntValue === 0 ? 1 : parsedIntValue;
  const min =
    courseContext.settings.timeLeftWithText?.timeLeftMinuteText ??
    defaultText.timeLeftWithText.min;
  const mins =
    courseContext.settings.timeLeftWithText?.timeLeftMinutesText ??
    defaultText.timeLeftWithText.mins;
  const minOrMins = timeLeftMinutes === 1 ? min : mins;
  const timeLeftText =
    courseContext.settings.timeLeftWithText?.timeLeftText ??
    defaultText.timeLeftWithText.timeLeftText;
  return (
    <Gird>
      <BrandIcon />
      <BarAligner>
        <TimeLeftBar
          timeLeftInSeconds={timeLeftInSeconds}
          totalTimeInSeconds={totalTimeInSeconds}
        />
      </BarAligner>
      <StyledText>{`${timeLeftText}: ${timeLeftMinutes} ${minOrMins}`}</StyledText>
    </Gird>
  );
};
export default TimeLeftWithText;
