/* eslint-disable react/self-closing-comp */
import { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Banner,
  BannerImage,
  FilterSkipButtonForPortrait,
} from '../../../../../components';
import QRCodeImage from '../../../../../components/QRCodeImage/QRCodeImage';
import WithFadeIn from '../../../../../components/WithFadeIn';
import WithFocus from '../../../../../components/WithFocus';
import { Constants } from '../../../../../constants';
import {
  useHeightViewport,
  useSanityCourse,
  useViewport,
} from '../../../../../context';
import { useNavigation } from '../../../../../hooks';
import {
  BottomContainer,
  Img,
  PageGridStyle,
  StyledText,
} from '../../../../../pageTemplates/Templates/InfoPageStyles';
import {
  Button,
  Image,
  ImageTypes,
} from '../../../../../pageTemplates/Templates/InfoPageTypes';
import mediaMedium from '../../../../../pageTemplates/Templates/MediaMedium';
import { Text } from '../../../../../pageTemplates/Templates/Text';
import {
  ConnectedLearnersTemplate,
  isFacilitatorPageWithTemplate,
} from '../../../../../pageTemplates/types/courseTypes';
import { LocalStorageService } from '../../../../../services';
import { sanityFunctions } from '../../../../sanity';
import { UseAbly } from '../../../context/AblyContext';
import LearnerURLFromSanityOrLocal, {
  getLearnerJoinPath,
} from '../../../helper/LearnerURLFromDevOpsOrLocal';

const LearnersJoinedText = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

const { CLASSROOM_CODE_KEY } = Constants.queryKeys;

interface ConnectedLearnersPageProps {
  banner?: Banner;
  buttons?: Button[];
  classroomCode?: {
    displayClassroomCode?: boolean;
    text?: string;
  };
  connectedLearners?: {
    displayConnectedLearners?: boolean;
    text?: string;
  };
  displayJoinLink?: boolean;
  image?: Image;
  text?: Text;
}

const StyledLearnersJoinedText = styled.h1`
  ${mediaMedium(css`
    font-size: 4rem;
  `)}
`;

const StyledLink = styled.p`
  && {
    color: ${(props) => props.theme.colors.neutral_800};
    text-decoration: underline;
  }
`;

const dynamicHeaderSize = (isMediumWidth: boolean) => {
  return isMediumWidth ? '4.6rem' : '2.6rem';
};

const ImagePart: FunctionComponent<{ image?: Image }> = ({ image }) => {
  if (image?.imgType === ImageTypes.QRCode) {
    const index = image?.src?.lastIndexOf('?');
    const QRlink =
      index !== undefined
        ? `${image?.src?.substring(
            0,
            index,
          )}${getLearnerJoinPath()}${image?.src?.substring(
            index,
          )}&${CLASSROOM_CODE_KEY}=${window.revivr?.code}`
        : `${image?.src}/join?${CLASSROOM_CODE_KEY}=${window.revivr?.code}`;

    return (
      <QRCodeImage
        url={`${QRlink}`}
        alt={image.alt ? image.alt : 'A QR Code for connecting to classroom'}
      />
    );
  }
  return image ? (
    <Img
      src={image.src}
      alt={image.alt ? image.alt : 'Some image'}
      imgType={image.imgType}
    />
  ) : null;
};

const ConnectedLearnersTemplateContent: FunctionComponent<
  ConnectedLearnersPageProps
> = ({
  image,
  banner,
  buttons,
  text,
  classroomCode,
  connectedLearners,
  displayJoinLink,
}: ConnectedLearnersPageProps) => {
  const { connectedUsers } = UseAbly();
  const { innerHeight } = useHeightViewport();
  const { isMediumWidth } = useViewport();

  return (
    <PageGridStyle height={innerHeight} imgType={image?.imgType}>
      <BannerImage banner={banner} />
      <ImagePart image={image} />

      <StyledText>
        {classroomCode?.displayClassroomCode && (
          <h1
            style={{
              fontSize: dynamicHeaderSize(isMediumWidth),
            }}
            tabIndex={-1}
          >
            {`${classroomCode?.text ?? ''} ${window.revivr.code}`}
          </h1>
        )}
        {displayJoinLink && (
          <StyledLink
            style={{
              fontSize: isMediumWidth ? '2.8rem' : '2rem',
            }}
          >
            {LearnerURLFromSanityOrLocal()}
          </StyledLink>
        )}
        {text && (
          <>
            <h1
              style={{
                fontSize: dynamicHeaderSize(isMediumWidth),
              }}
              tabIndex={-1}
            >
              {text.header}
              {text.header2 ? (
                <>
                  <br />
                  {text.header2}
                </>
              ) : null}
            </h1>

            {typeof text.body === 'string' ? (
              <p>
                {text.body}
                <strong>{text.emphasized}</strong>
              </p>
            ) : (
              text.body
            )}
          </>
        )}
        {connectedLearners?.displayConnectedLearners && (
          <StyledLearnersJoinedText>
            <LearnersJoinedText>
              {connectedUsers.length.toString()}
            </LearnersJoinedText>
            {` ${connectedLearners?.text ?? 'joined'}`}
          </StyledLearnersJoinedText>
        )}
      </StyledText>

      {FilterSkipButtonForPortrait(buttons)}

      <BottomContainer />
    </PageGridStyle>
  );
};

const ConnectedLearnersPage: FunctionComponent = () => {
  const [currentPage, setCurrentPage] = useState<ConnectedLearnersTemplate>({});
  const pageContext = useSanityCourse();
  const [buttons, setButtons] = useState<Button[] | undefined>(undefined);
  const navigationContext = useNavigation();

  useEffect(() => {
    const tempPage = pageContext.getCurrentPage();
    if (
      isFacilitatorPageWithTemplate(tempPage) &&
      tempPage.connectedLearnersTemplate !== undefined
    ) {
      const facilitatorPage = tempPage.connectedLearnersTemplate;
      setCurrentPage(facilitatorPage);
      setButtons(
        sanityFunctions.createButtonsFromSanityButtons(
          facilitatorPage?.buttons,
          navigationContext,
        ),
      );
    }
    if (window.revivr.code === undefined) {
      window.revivr.code = parseInt(
        LocalStorageService.getClassroomCodeFromLocalStorage() ?? '',
        10,
      );
    }
  }, []);

  return (
    <ConnectedLearnersTemplateContent
      banner={currentPage.banner}
      buttons={buttons}
      classroomCode={currentPage.classroomCode}
      connectedLearners={currentPage.connectedLearners}
      displayJoinLink={currentPage.displayJoinLink}
      image={currentPage.image}
      text={currentPage.text}
    />
  );
};

export default WithFadeIn(WithFocus(ConnectedLearnersPage));
