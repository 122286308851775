import { CountDown } from '../countDown';
import { CustomButton } from '../customButton';
import { CustomImage } from '../images';
import { Body, CustomText } from '../text';

export type DialogText = {
  text: Body;
  pointerAlignment: string;
  textAlignment: string;
};

type RangeWithError = {
  min: number;
  max: number;
  minErrorMessage: string;
  maxErrorMessage: string;
};

export type DynamicInputField = {
  alt: string;
  dataType: 'text' | 'numeric';
  errorMessage: string;
  placeholder: string;
  range: RangeWithError;
};

export type TextAndImageTemplateDialog = {
  showInitialDialog: boolean;
  initialDialog: DialogText;
  showAnswerDialog: boolean;
  answerDialog: DialogText & { button: CustomButton };
};

export type TextAndImageTemplate = {
  buttons?: CustomButton[];
  countDown?: CountDown;
  dialog?: TextAndImageTemplateDialog;
  displayTeamName?: boolean;
  enableAudio: boolean;
  enableAutoplay: boolean;
  enableDialog?: boolean;
  enableInputField: boolean;
  image?: CustomImage;
  inputField?: DynamicInputField;
  publishedAt: string;
  showTimeLeftBar?: boolean;
  text?: CustomText;
  verticalButtons?: boolean;
};

export type FacilitatorTextAndImageTemplate = TextAndImageTemplate & {
  displayCompletedLearners?: boolean;
  completedText?: string;
};
// typeChecker
export function isFacilitatorTextAndImageTemplate(
  template:
    | TextAndImageTemplate
    | FacilitatorTextAndImageTemplate
    | LearnerTextAndImageTemplate,
): template is FacilitatorTextAndImageTemplate {
  return (
    (template as FacilitatorTextAndImageTemplate).displayCompletedLearners !==
      undefined &&
    (template as LearnerTextAndImageTemplate).displayTeamName === undefined
  );
}
export type LearnerTextAndImageTemplate = TextAndImageTemplate & {
  displayTeamName?: boolean;
};
// Type checker
export function isLearnerTextAndImageTemplate(
  page:
    | TextAndImageTemplate
    | FacilitatorTextAndImageTemplate
    | LearnerTextAndImageTemplate,
): page is LearnerTextAndImageTemplate {
  return (
    (page as FacilitatorTextAndImageTemplate).displayCompletedLearners ===
      undefined &&
    (page as LearnerTextAndImageTemplate).displayTeamName !== undefined
  );
}
