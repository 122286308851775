import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ModalProps } from '../../../components/Modals';
import ModalForGrid from '../../../components/Modals/Modal';
import Lottie, { LottieProps } from '../../../components/ReactLottie/index';
import Speechbubble from '../../../components/Speechbubble/Speechbubble';
import { ClassroomCodeAndUrl } from '../../../components/TopBars';
import ProgressBarLargeScreens from '../../../components/TopBars/ProgressBarLargeScreens/ProgressBarLargeScreens';
import { AnimationState } from '../../../components/TopBars/types';
import { useAudio, useHeightViewport } from '../../../context';
import { useSanityCourse } from '../../../context/sanity/useSanityCourse';
import SanityModal from '../../../features/sanity/sanityModal';
import { DialogueImage } from '../../dialogPageTemplate/DialogueImage';
import { DialogueText } from '../../dialogPageTemplate/DialogueText';
import StyledPageGrid from '../../dialogPageTemplate/components/StyledPageGrid';
import { ContinueCourseModal } from '../../dialogPageTemplate/types/ContinueCourseModal';
import { AudioTypes } from '../../types/courseTypes';

import { useNavigation } from '../../../hooks';
import { ImgWithSize, MainContent, StyledText } from '../InfoPageStyles';
import { CountDown } from '../InfoPageTypes';
import mediaMedium from '../MediaMedium';
import { Text } from '../Text';

const ImgGridContainer = styled.div<{ row?: string }>`
  grid-column: 1 / -1;
  grid-row: ${(props) => (props.row ? props.row : '2')};
  display: grid;
  gap: 1px;
  grid-template-columns: 1fr 1fr;
  max-height: 100%;

  ${mediaMedium(css`
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    align-content: center;
  `)}
`;
const AEDImg = styled(ImgWithSize)<{
  alignLeft?: boolean;
  alignRight?: boolean;
}>`
  ${(props) => (props.alignLeft ? 'grid-column: 1;' : '')}
  ${(props) => (props.alignRight ? 'grid-column: 2;' : '')}
  align-self: flex-end;
  justify-self: center;
  grid-row: 1;
`;

const LottieContainer = styled.div<{
  alignLeft?: boolean;
  alignRight?: boolean;
}>`
  width: 100%;
  grid-column: 1 / -1;
  ${(props) => (props.alignLeft ? 'grid-column: 2;' : '')}
  ${(props) => (props.alignRight ? 'grid-column: 1;' : '')}
    align-self: flex-end;
  justify-self: ${(props) => (props.alignLeft ? 'flex-end' : 'center')};
  grid-row: 1;
  svg {
    display: block;
  }
`;

interface AedBubbleTemplateProps {
  continueCourseModal?: ContinueCourseModal;
  countDown: CountDown;
  dialogueText: DialogueText | undefined;
  lottie?: LottieProps;
  modal?: ModalProps;
  operatorImage: DialogueImage | undefined;
  playAudioOnLoad?: boolean;
  practiceAgainModal?: boolean;
  text: Text | undefined;
}

const Image: FunctionComponent<{
  operatorImage: DialogueImage | undefined;
  lottie: LottieProps | undefined;
}> = ({ operatorImage, lottie }) => {
  if (operatorImage || lottie) {
    return (
      <ImgGridContainer>
        {operatorImage && (
          <AEDImg
            src={operatorImage.src}
            alt={operatorImage.alt ? operatorImage.alt : 'AEDImg'}
            maxHeight={operatorImage.height ? operatorImage.height : undefined}
            alignRight={operatorImage.alignRight}
            alignLeft={lottie !== undefined && operatorImage !== undefined}
          />
        )}
        {lottie && !operatorImage && (
          <LottieContainer id="lottieContainer">
            <Lottie
              options={lottie.options}
              height={lottie.height !== undefined ? lottie.height : undefined}
              width={lottie.width !== undefined ? lottie.width : undefined}
            />
          </LottieContainer>
        )}
        {lottie && operatorImage && (
          <LottieContainer
            role="img"
            aria-label="Perform compressions animation"
            id="lottieContainer"
            alignRight={operatorImage.alignRight}
            alignLeft={lottie !== undefined && operatorImage !== undefined}
          >
            <Lottie
              options={lottie.options}
              height={lottie.height !== undefined ? lottie.height : undefined}
              width={lottie.width !== undefined ? lottie.width : undefined}
            />
          </LottieContainer>
        )}
      </ImgGridContainer>
    );
  }
  return null;
};

const TextPart: FunctionComponent<{
  dialogueText: DialogueText | undefined;
  text: Text | undefined;
}> = ({ dialogueText, text }) => {
  if (dialogueText || text) {
    return (
      <MainContent>
        <StyledText>
          {dialogueText && (
            <>
              {dialogueText.header && (
                <h1 tabIndex={-1}>
                  {dialogueText.header ? dialogueText.header : null}
                </h1>
              )}

              <Speechbubble
                tabIndex={-1}
                pointerLeft
                textAlignCenter={dialogueText.textAlignCenter}
              >
                {dialogueText.text}
              </Speechbubble>
            </>
          )}
          {text && (
            <>
              <h1>
                {text.header}
                {text.header2 ? (
                  <>
                    <br />
                    {text.header2}
                  </>
                ) : null}
              </h1>
              <p>
                {text.body}
                <strong>{text.emphasized}</strong>
              </p>
            </>
          )}
        </StyledText>
      </MainContent>
    );
  }
  return null;
};

const AedSpeechBubbleTemplate: FunctionComponent<AedBubbleTemplateProps> = ({
  continueCourseModal,
  countDown,
  dialogueText,
  lottie,
  modal,
  operatorImage,
  playAudioOnLoad,
  practiceAgainModal,
  text,
}: AedBubbleTemplateProps) => {
  const { innerHeight } = useHeightViewport();
  const navigationContext = useNavigation();
  const audio = useAudio();
  const [animationState, setAnimationState] = useState<AnimationState>(
    AnimationState.running,
  );
  const pageContext = useSanityCourse();
  const [onRefreshModal, setOnRefreshModal] = useState<
    ModalProps | undefined
  >();

  useEffect(() => {
    if (
      playAudioOnLoad &&
      navigationContext &&
      navigationContext.initialPage !== window.location.pathname
    ) {
      audio?.playAudioOnPage(AudioTypes.OnLoad);
    }
    const locationPatname = window.location.pathname;

    return () => {
      if (playAudioOnLoad) {
        audio?.pauseFromAudioLibrary(locationPatname);
        audio?.resetAudio(locationPatname);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dismissOnRefreshModal = () => {
    audio?.initCurrentSection();
    if (playAudioOnLoad) {
      audio?.playAudioOnPage(AudioTypes.OnLoad);
    }
    setAnimationState(AnimationState.running);
    setOnRefreshModal(undefined);
  };
  const createOnRefreshModal = () => {
    if (
      practiceAgainModal &&
      navigationContext &&
      navigationContext.initialPage === window.location.pathname &&
      audio?.doesSectionContainAudio()
    ) {
      setAnimationState(AnimationState.paused);
      const newModal = SanityModal.createContinueCourseModal(
        pageContext,
        dismissOnRefreshModal,
        navigationContext,
      );
      setOnRefreshModal(newModal);
    }
  };

  useEffect(() => {
    if (continueCourseModal !== undefined) {
      createOnRefreshModal();
    }
  }, [continueCourseModal]);

  const countDownDuration = (): number => {
    if (countDown) {
      return countDown.duration;
    }
    if (navigationContext) {
      return navigationContext.currentPageDuration();
    }
    return 10;
  };

  return (
    <StyledPageGrid height={innerHeight}>
      <ClassroomCodeAndUrl />
      {modal && (
        <ModalForGrid
          bannerImage={modal.bannerImage}
          buttons={modal.buttons}
          onClose={modal.onClose}
          text={modal.text}
        />
      )}
      {onRefreshModal && (
        <ModalForGrid
          bannerImage={onRefreshModal.bannerImage}
          buttons={onRefreshModal.buttons}
          onClose={onRefreshModal.onClose}
          text={onRefreshModal.text}
          verticalButtons
        />
      )}
      {countDown && (
        <ProgressBarLargeScreens
          animationDurationInSeconds={countDownDuration()}
          animationDelayInSeconds={countDown.delay}
          animationState={
            onRefreshModal ? AnimationState.paused : countDown?.animationState
          }
          setAnimRef={countDown.setAnimRef}
        />
      )}
      <Image operatorImage={operatorImage} lottie={lottie} />

      <TextPart dialogueText={dialogueText} text={text} />
    </StyledPageGrid>
  );
};
export default AedSpeechBubbleTemplate;
