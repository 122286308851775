import { FunctionComponent } from 'react';
import { WithFocus } from '../../../../../components';
import WithFadeIn from '../../../../../components/WithFadeIn';
import { RaceLearnerController } from '../../../../race';
import DynamicFacilitatorRacePage from '../../../../race/facilitator/DynamicFacilitatorRacePage';

type PageProps = {
  template?; // Add raceTemplate here in future
};
const DynamicRacePage: FunctionComponent<PageProps> = ({ template }) => {
  if (window.revivr.isLearner) {
    return <RaceLearnerController />;
  }
  return <DynamicFacilitatorRacePage />;
};

export default WithFadeIn(WithFocus(DynamicRacePage));
