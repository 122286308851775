import { COLORS } from '@laerdal/life-react-components';
import React, { ReactNode, useContext, useEffect } from 'react';
import constants from '../constants/Constants';
import { SanityColor, SanityFont } from '../pageTemplates/types/courseTypes';
import { useSanityCourse } from './sanity/useSanityCourse';

type Theme = {
  colors: any;
  fonts: {
    primaryFont: string;
    secondaryFont: string;
  };
  textAlignment: string;
  fadeInDuration: number;
};

type ThemeContextProps = {
  getColors: () => SanityColor;
  getFonts: () => SanityFont;
  theme: Theme;
};

const ThemeContext = React.createContext<ThemeContextProps>(
  {} as ThemeContextProps,
);

const ThemeProvider: React.FunctionComponent<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const courseContext = useSanityCourse();

  const getFonts = () => {
    const fonts = {
      primaryFont: `${
        courseContext.settings.organizationSettings?.theme?.fonts
          ?.primaryFont ?? 'sans-serif'
      }, Fallback, sans-serif`,
      secondaryFont: `${
        courseContext.settings.organizationSettings?.theme?.fonts
          ?.secondaryFont ?? 'sans-serif'
      }, Fallback, sans-serif`,
    };
    return fonts;
  };

  const getTextAlignment = () => {
    return (
      courseContext.settings.organizationSettings?.theme?.textAlignment ??
      constants.textAlignment.CENTER
    );
  };
  const getFadeInDuration = () => {
    return courseContext.settings.fadeInDuration ?? 0;
  };

  const getColors = () => {
    const colors = {
      ...COLORS,
      primary:
        courseContext.settings.organizationSettings?.theme?.colors?.primary
          ?.hex ?? '#EB2557',
      primary_500:
        courseContext.settings.organizationSettings?.theme?.colors?.primary_500
          ?.hex ?? '#EB2557',
      primary_600:
        courseContext.settings.organizationSettings?.theme?.colors?.primary_600
          ?.hex ?? '#EB2557',
      primary_700:
        courseContext.settings.organizationSettings?.theme?.colors?.primary_700
          ?.hex ?? '#B10E38',
      primary_800:
        courseContext.settings.organizationSettings?.theme?.colors?.primary_800
          ?.hex ?? '#8C0032',
      accent1_100:
        courseContext.settings.organizationSettings?.theme?.colors?.accent1_100
          ?.hex ?? '#FFEBEF',

      neutral_20:
        courseContext.settings.organizationSettings?.theme?.colors?.neutral_20
          ?.hex ?? '#FAFAFA',
      neutral_100:
        courseContext.settings.organizationSettings?.theme?.colors?.neutral_100
          ?.hex ?? '#ECEDEE',
      neutral_200:
        courseContext.settings.organizationSettings?.theme?.colors?.neutral_200
          ?.hex ?? '#DADCDE',
      neutral_300:
        courseContext.settings.organizationSettings?.theme?.colors?.neutral_300
          ?.hex ?? '#B5B8BD',
      neutral_500:
        courseContext.settings.organizationSettings?.theme?.colors?.neutral_500
          ?.hex ?? '#6C717B',
      neutral_600:
        courseContext.settings.organizationSettings?.theme?.colors?.neutral_600
          ?.hex ?? '#474E5A',
      neutral_800:
        courseContext.settings.organizationSettings?.theme?.colors?.neutral_800
          ?.hex ?? '#333333',

      orange_100:
        courseContext.settings.organizationSettings?.theme?.colors?.orange_100
          ?.hex ?? '#FFF3EB',
      orange_600:
        courseContext.settings.organizationSettings?.theme?.colors?.orange_600
          ?.hex ?? '#FF873C',
      orange:
        courseContext.settings.organizationSettings?.theme?.colors?.orange
          ?.hex ?? '#D6580A',
      green:
        courseContext.settings.organizationSettings?.theme?.colors?.green
          ?.hex ?? '#4CD964',
      green_100:
        courseContext.settings.organizationSettings?.theme?.colors?.green_100
          ?.hex ?? '#E8FBF5',
      green_600:
        courseContext.settings.organizationSettings?.theme?.colors?.green_600
          ?.hex ?? '#00A06E',
      blue_600:
        courseContext.settings.organizationSettings?.theme?.colors?.blue_600
          ?.hex ?? '#2D91FF',

      critical_20:
        courseContext.settings.organizationSettings?.theme?.colors?.critical_20
          ?.hex ?? '#FFEBEF',
      critical_400:
        courseContext.settings.organizationSettings?.theme?.colors?.critical_400
          ?.hex ?? '#EB2557',
    };
    return colors;
  };

  const theme = {
    colors: getColors(),
    fonts: getFonts(),
    textAlignment: getTextAlignment(),
    fadeInDuration: getFadeInDuration(),
  };

  useEffect(() => {
    getFonts();
    getColors();
    getFadeInDuration();
    getTextAlignment();
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        getFonts,
        getColors,
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const themeContext = useContext(ThemeContext);
  return themeContext;
};

export default ThemeProvider;
