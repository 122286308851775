import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// THIS SHOULD BE STORED IN env variables!
let firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_apiKey,
  authDomain: import.meta.env.VITE_APP_FIREBASE_authDomain,
  projectId: import.meta.env.VITE_APP_FIREBASE_projectId,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_storageBucket,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_messagingSenderId,
  appId: import.meta.env.VITE_APP_FIREBASE_appId,
};

// Used so we can change this during deployment
if (
  !window.location.href.includes('localhost') &&
  !window.location.href.includes('ngrok')
) {
  firebaseConfig = {
    apiKey: '#{FIREBASE_apiKey}#',
    authDomain: '#{FIREBASE_authDomain}#',
    projectId: '#{FIREBASE_projectId}#',
    storageBucket: '#{FIREBASE_storageBucket}#',
    messagingSenderId: '#{FIREBASE_messagingSenderId}#',
    appId: '#{FIREBASE_appId}#',
  };
}

const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);

// eslint-disable-next-line import/prefer-default-export
export { firestore };
