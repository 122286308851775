import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Constants } from '../../constants';
import BrandIcon from './BrandIcon';
import { AnimationState } from './types';

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(1px, max-content) 1fr;
  align-content: center;
  justify-content: center;
`;
const BarAligner = styled.div`
  height: max-content;
  align-self: center;
`;

interface ProgressbarProps {
  animationDurationInSeconds: number;
  animationDelayInSeconds?: number;
  animationState: AnimationState;
  setAnimRef?: React.RefObject<HTMLDivElement>;
}

const pathAnimation = keyframes`
  0% {width:0px;}
  100% {width:calc(100%);}
`;

const animationStateTranslate = (animationState: AnimationState) => {
  switch (animationState) {
    case AnimationState.running:
      return 'running';
    case AnimationState.paused:
      return 'paused';
    default:
      return 'running';
  }
};

const Path = styled.div<{
  animationDurationInSeconds: number;
  animationDelayInSeconds: number;
  animationState: AnimationState;
}>`
  width: 0px;
  height: ${Constants.progressBarHeight.SMALL}px;
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.primary_500};
  position: absolute;
  left: 0px;
  top: 0px;
  animation-name: ${pathAnimation};
  animation-duration: ${(props) => props.animationDurationInSeconds}s;
  animation-delay: ${(props) => props.animationDelayInSeconds}s;
  animation-play-state: ${(props) =>
    animationStateTranslate(props.animationState)};
  animation-fill-mode: forwards;
  animation-timing-function: linear;
`;

const BackgroundPath = styled.div`
  width: calc(100%);
  height: ${Constants.progressBarHeight.SMALL}px;
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.neutral_100};
  position: absolute;
  left: 0px;
  top: 0px;
`;

const Container = styled.div`
  position: relative;
  width: calc(100%);
  height: ${Constants.progressBarHeight.SMALL}px;
`;

const ProgressBarWithIcon: FunctionComponent<ProgressbarProps> = (
  props: ProgressbarProps,
) => {
  const {
    animationState,
    animationDurationInSeconds,
    animationDelayInSeconds,
    setAnimRef,
  } = props;
  const [seconds, setSeconds] = useState(animationDurationInSeconds);
  useEffect(() => {
    const counter = setInterval(() => {
      if (animationState === AnimationState.running) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(counter);
    };
  }, [animationState, seconds]);

  return (
    <Grid>
      <BrandIcon />
      <BarAligner>
        <Container
          role="progressbar"
          aria-label={`Progress bar. Time left ${seconds} seconds`}
          aria-valuemin={0}
          aria-valuemax={animationDurationInSeconds}
          aria-valuenow={seconds}
        >
          <BackgroundPath />
          <Path
            ref={setAnimRef}
            animationDurationInSeconds={animationDurationInSeconds}
            animationState={animationState}
            animationDelayInSeconds={
              animationDelayInSeconds === undefined
                ? 0
                : animationDelayInSeconds
            }
          />
        </Container>
      </BarAligner>
    </Grid>
  );
};

export default ProgressBarWithIcon;
