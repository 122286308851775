import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SpeedometerHints } from '../../features/sanity/types';
import Speedometer, { SpeedometerProps } from './Speedometer';

const Hint = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 140%;
  /* or 22px */

  text-align: center;

  /* Neutrals / 800 shade */
  color: #333333;
`;
const SpeedometerWrapper = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #ffffff;
  border-radius: 8px;
  min-height: ${(props) => props.height}px;
  max-width: ${(props) => props.height * 1.5}px;
`;
const PushBottom = styled.div`
  margin-top: auto;
  margin-bottom: 10px;
`;

interface SpeedometerWithHintProps extends SpeedometerProps {
  hints?: SpeedometerHints;
}

const SpeedometerWithHint: FunctionComponent<SpeedometerWithHintProps> = ({
  height = 150,
  cpr = 110,
  acceptedCpr = { min: 100, max: 120 },
  cprRange = { min: 20, max: 200 },
  hints,
}: SpeedometerWithHintProps) => {
  const [receivedCprScoreAboveZero, setReceivedCprScoreAboveZero] =
    useState(false);

  useEffect(() => {
    if (cpr > 0 && !receivedCprScoreAboveZero) {
      setReceivedCprScoreAboveZero(true);
    }
  }, [cpr]);

  const cprHint = () => {
    if (!receivedCprScoreAboveZero) {
      return '';
    }
    if (cpr < 0) {
      return hints?.cprNoDetect ?? 'Make sure your face is in the camera';
    }
    if (cpr < acceptedCpr.min) {
      return hints?.cprTooSlow ?? 'Compressing too slow';
    }
    if (cpr <= acceptedCpr.max) {
      return hints?.cprGood ?? 'Good compression rate';
    }
    return hints?.cprTooFast ?? 'Compressing too fast';
  };
  if (hints) {
    return (
      <SpeedometerWrapper height={height}>
        <Hint aria-live="polite">{cprHint()}</Hint>
        <PushBottom>
          <Speedometer
            isStarted={receivedCprScoreAboveZero}
            cpr={cpr}
            height={height / 1.8}
            acceptedCpr={acceptedCpr}
            cprRange={cprRange}
          />
        </PushBottom>
      </SpeedometerWrapper>
    );
  }

  return (
    <SpeedometerWrapper height={height}>
      <Speedometer
        isStarted={receivedCprScoreAboveZero}
        cpr={cpr}
        height={height / 1.3}
        acceptedCpr={acceptedCpr}
        cprRange={cprRange}
      />
    </SpeedometerWrapper>
  );
};

export default SpeedometerWithHint;
