import styled from 'styled-components';
import constants from '../constants/Constants';

const FooterText = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${(props) => props.theme.colors.neutral_600};
  margin-top: 12px;
  margin-bottom: ${constants.pageMargin.SMALL}px;
`;

export default FooterText;
