class Result {
  public frequency = 0;

  public noDistinctFrequencyFound = false;

  public scepticalToFrequency = false;

  constructor(frequency?: number) {
    this.frequency = frequency !== undefined ? frequency : 0;
  }
}
export default Result;
