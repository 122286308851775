import { FunctionComponent } from 'react';
import Lottie from '../../../../components/ReactLottie/index';
import * as TrainingPageStyles from '../../../../pageTemplates/trainingTemplate/StyledComponents';
import { SanityLottieOptions } from '../../../sanity/types';
import { getLottieOptions } from '../../learner/components/CprPage/helpers';
const { LottieContainer } = TrainingPageStyles;

type CountDownLottieProps = {
  countDownComplete?: boolean;
  countDownLottieOptions: SanityLottieOptions;
  onComplete?: () => void;
};

const CountDownLottie: FunctionComponent<CountDownLottieProps> = ({
  countDownComplete,
  countDownLottieOptions,
  onComplete,
}) => {
  if (countDownComplete) {
    return null;
  }
  return (
    <LottieContainer>
      <Lottie
        aria-label="Count down animation"
        eventListeners={[
          {
            eventName: 'complete',
            callback: onComplete,
          },
        ]}
        isClickToPauseDisabled
        options={getLottieOptions(countDownLottieOptions)}
      />
    </LottieContainer>
  );
};

export default CountDownLottie;
