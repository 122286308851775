import React, { FunctionComponent, useMemo } from 'react';
import LifeButton from '../../../../../components/Button';
import {
  ButtonContainer,
  PageGridStyle,
} from '../../../../../pageTemplates/Templates/InfoPageStyles';
import { Ambulance } from '../../../assets';
import { useFacilitatorConfettiEffect } from '../../../features/confetti';
import { modifySvgAndReturnReactComponent } from '../../../helpers/svgHelper';
import Styles from './AwardPodium.styles';
import Types from './AwardPodium.types';

const Podium: FunctionComponent<Types.PodiumProps> = ({
  name,
  playerColor,
}) => {
  return (
    <>
      <Styles.Podium />
      <Styles.TeamNameContainer color={playerColor ?? 'grey'}>
        <Styles.TeamName>{name}</Styles.TeamName>
      </Styles.TeamNameContainer>
    </>
  );
};

const AnimatedAmbulance: FunctionComponent<{ color: string }> = ({ color }) => {
  const AmbulanceToComponent = useMemo(
    () => modifySvgAndReturnReactComponent(Ambulance),
    [Ambulance],
  );

  return (
    <Styles.BouncingImage>
      <Styles.RotatingImage>
        <Styles.Ambulance ambulanceColor={color}>
          <AmbulanceToComponent />
        </Styles.Ambulance>
      </Styles.RotatingImage>
    </Styles.BouncingImage>
  );
};

const renderPodiums: FunctionComponent<Types.PlayerInfo[]> = (
  winnerOrderedFromLeft: Types.PlayerInfo[],
) => {
  const mappedWinners = winnerOrderedFromLeft.map((winner) => {
    const is1stPlace = winnerOrderedFromLeft.indexOf(winner) === 1;

    return (
      <Styles.PodiumGrid key={winner.name} is1stPlace={is1stPlace}>
        <AnimatedAmbulance color={winner.color} />
        {winner.trophy}
        <Podium
          key={winner.name}
          name={winner.name}
          playerColor={winner.color}
        />
      </Styles.PodiumGrid>
    );
  });

  return mappedWinners;
};

const AwardPodium: FunctionComponent<Types.AwardScreenProps> = ({
  players,
  raceAgainButton,
  continueButton,
}) => {
  useFacilitatorConfettiEffect({ players });

  if (!Array.isArray(players)) {
    return null;
  }

  const threeTopWinners = players.slice(0, 3);
  const order = [1, 0, 2]; // From left to right on the screen: 2nd 1st 3rd
  const rearrangedWinners = order
    .map((index) => {
      if (threeTopWinners[index]) {
        return threeTopWinners[index];
      }
      return null;
    })
    .filter((winner) => winner !== null);
  return (
    <PageGridStyle>
      <Styles.PodiumContainer>
        {renderPodiums(rearrangedWinners)}
      </Styles.PodiumContainer>
      <ButtonContainer>
        <LifeButton
          onClick={continueButton.onClick}
          variant={continueButton.variant}
        >
          {continueButton.text}
        </LifeButton>
        <LifeButton
          onClick={raceAgainButton.onClick}
          variant={raceAgainButton.variant}
        >
          {raceAgainButton.text}
        </LifeButton>
      </ButtonContainer>
    </PageGridStyle>
  );
};
export default AwardPodium;
