import { ModalProps } from '../../components/Modals';
import { NavigationContextProps } from '../../context/navigation/navigationContextProps';
import { SanityCourseContextProps } from '../../context/sanity/sanityCourseContextProps';
import { Button } from '../../pageTemplates/Templates/InfoPageTypes';

const createContinueCourseModal = (
  pageContext: SanityCourseContextProps,
  dismissOnRefreshModal: () => void,
  navigationContext?: NavigationContextProps | null,
): ModalProps => {
  const currentPath = window.location.pathname;
  const currentPageIndex = pageContext.course.findIndex(
    (a) => a.url === currentPath,
  );
  const page = pageContext.course[currentPageIndex];

  const ButtonArray: Button[] = [];
  if (page.section?.continueCourseModal?.continuePracticeButtonText) {
    ButtonArray.push({
      text: page.section?.continueCourseModal?.continuePracticeButtonText ?? '',
      buttonType: 'primary',
      onClick: () => {
        dismissOnRefreshModal();
      },
    });
  }
  if (page.section?.continueCourseModal?.startOverButtonText) {
    ButtonArray.push({
      text: page.section?.continueCourseModal?.startOverButtonText ?? '',
      buttonType: 'secondary',
      onClick: () => {
        if (page && page.section && page.section.continueCourseModal) {
          navigationContext?.navigateToUrl(
            page.section?.continueCourseModal?.startOverUrl,
          );
        }
      },
    });
  }

  const newModal: ModalProps = {
    onClose: () => {
      dismissOnRefreshModal();
    },
    buttons: ButtonArray,
    text: {
      header: page.section?.continueCourseModal?.headerText,
      body: page.section?.continueCourseModal?.body,
    },
  };

  return newModal;
};

export default { createContinueCourseModal };
