import React, { useEffect, useState } from 'react';

export const modifySvg = (svgContent: string): string => {
  // Create a new DOMParser
  const parser = new DOMParser();
  let svgDoc;

  try {
    // Parse the SVG content
    svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
  } catch (err) {
    console.error('Error parsing SVG:', err);
    return svgContent; // Return the original content in case of error
  }

  // Get all path elements
  const paths = svgDoc.querySelectorAll('path');

  // Loop over all paths
  paths.forEach((path) => {
    // Get the fill color
    const fill = path.getAttribute('fill');

    // If the fill color is not "white", add a class
    if (fill !== 'white') {
      path.classList.add('colored-path');
    }
  });
  return new XMLSerializer().serializeToString(svgDoc.documentElement);
};

const SvgComponent: React.FC<{ svgContent: string }> = ({ svgContent }) => (
  <div dangerouslySetInnerHTML={{ __html: svgContent }} />
);

export const modifySvgAndReturnReactComponent = (
  svgPath: string,
): React.FunctionComponent<React.SVGProps<SVGSVGElement>> => {
  const FetchableSvgComponent: React.FC = () => {
    const [svgContent, setSvgContent] = useState<string | null>(null);

    useEffect(() => {
      fetch(svgPath)
        .then((response) => response.text())
        .then((text) => {
          const modifiedSvg = modifySvg(text);
          setSvgContent(modifiedSvg);
        });
    }, [svgPath]);

    // Conditionally render SvgComponent if svgContent is not null
    return svgContent ? <SvgComponent svgContent={svgContent} /> : null;
  };
  return FetchableSvgComponent;
};
