import { isProduction } from './EnvironmentHelper';
import { groupPaths, soloPaths } from './projectPathsHelper';

const getProductionProjectId = () => {
  return window.revivr?.isGroupRevivr
    ? '#{SANITY_GROUP_PROJECT_ID}#' // use the group project id since we are in group revivr
    : '#{SANITY_BHF_PROJECT_ID}#'; // use the solo project id since we are in solo revivr
};

const getDevProjectId = () => {
  if (window.revivr?.isGroupRevivr) {
    return import.meta.env.VITE_APP_GROUP_PROJECT_ID // check if the group project id is set in .env
      ? import.meta.env.VITE_APP_GROUP_PROJECT_ID // the group project id set in .env, use it
      : 'a98d6e36-7e29-4093-a65b-d57d1ab76b90'; // the group project id is NOT set in .env, use the default value
  }
  return import.meta.env.VITE_APP_SOLO_PROJECT_ID // check if the solo project id is set in .env
    ? import.meta.env.VITE_APP_SOLO_PROJECT_ID // the solo project id set in .env, use it
    : '893004bc-7ec2-47ff-9ea9-105f1e5f132a'; // the solo project id is NOT set in .env, use the default value
};

export const determineOrganizationProjectId = (): string => {
  const currentPath = window.location.pathname
    .split('/')
    .map((part) => `/${part}`)[1];
  if (currentPath in soloPaths) {
    return soloPaths[currentPath];
  }
  if (currentPath in groupPaths) {
    return groupPaths[currentPath];
  }
  // The code below sets the project ids based on the environment. It allows us to set the project id in .env file for dev mode
  // Env values here: https://laerdal.atlassian.net/wiki/spaces/TL/pages/480805156/Various+Dev+files
  // eslint-disable-next-line no-nested-ternary
  return isProduction ? getProductionProjectId() : getDevProjectId();
};
