import React, { FunctionComponent } from 'react';
import { useSanityCourse } from '../../../../../context';
import AEDDraggablePadPageTemplate from '../../../../../pageTemplates/aedDraggablePadPageTemplate';
import AedTemplate from '../../../../../pageTemplates/aedTemplate';
import EmergencyCallTemplate from '../../../../../pageTemplates/emergencyCallTemplate';
import QuizPageTemplate from '../../../../../pageTemplates/quizPageTemplate';
import RatingPageTemplate from '../../../../../pageTemplates/ratingPageTemplate';
import TextAndImageTemplate from '../../../../../pageTemplates/textAndImageTemplate';
import TrainingTemplate from '../../../../../pageTemplates/trainingTemplate';
import { LearnerPageWithTemplate } from '../../../../../pageTemplates/types/courseTypes';
import templateTypes from '../../../../../pageTemplates/types/templateTypes';
import VideoPageTemplate from '../../../../../pageTemplates/videoPageTemplate';
import { UseAbly } from '../../../context/AblyContext';

const LearnerRoleSplitTemplate: FunctionComponent = () => {
  const { getLearnerTeamRole } = UseAbly();
  const courseContext = useSanityCourse();
  const tempPage = courseContext.getCurrentPage() as LearnerPageWithTemplate;
  const currentRole = `role${getLearnerTeamRole()}`; //  roleB or RoleA

  if (tempPage.learnerRoleSplitTemplate === undefined) {
    return <p>You need to select a template</p>;
  }

  const currentTemplate = getLearnerTeamRole()
    ? tempPage.learnerRoleSplitTemplate[currentRole].template
    : '';

  switch (currentTemplate) {
    case templateTypes.TextAndImage:
      return (
        <TextAndImageTemplate
          template={
            tempPage.learnerRoleSplitTemplate[currentRole][currentTemplate]
          }
        />
      );
    case templateTypes.AEDDraggablePadPageTemplate:
      return (
        <AEDDraggablePadPageTemplate
          template={
            tempPage.learnerRoleSplitTemplate[currentRole][currentTemplate]
          }
        />
      );
    case templateTypes.AedTemplate:
      return (
        <AedTemplate
          template={
            tempPage.learnerRoleSplitTemplate[currentRole][currentTemplate]
          }
        />
      );
    case templateTypes.QuizPage:
      return (
        <QuizPageTemplate
          template={
            tempPage.learnerRoleSplitTemplate[currentRole][currentTemplate]
          }
        />
      );
    case templateTypes.RatingPage:
      return (
        <RatingPageTemplate
          template={
            tempPage.learnerRoleSplitTemplate[currentRole][currentTemplate]
          }
        />
      );
    case templateTypes.TrainingTemplate:
      return (
        <TrainingTemplate
          template={
            tempPage.learnerRoleSplitTemplate[currentRole][currentTemplate]
          }
        />
      );
    case templateTypes.VideoPage:
      return (
        <VideoPageTemplate
          template={
            tempPage.learnerRoleSplitTemplate[currentRole][currentTemplate]
          }
        />
      );
    case templateTypes.EmergencyCallTemplate:
      return (
        <EmergencyCallTemplate
          template={
            tempPage.learnerRoleSplitTemplate[currentRole][currentTemplate]
          }
        />
      );
    default:
      return <p>Template could not be found!</p>;
  }
};

export default LearnerRoleSplitTemplate;
