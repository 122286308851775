/* eslint-disable no-restricted-globals */
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import constants from '../../constants/Constants';
import {
  addProjectQueryStringToUrl,
  isValidExternalUrl,
  urlChecker,
} from '../../helper';
import { addLanguageQueryStringToUrl } from '../../helper/UrlHelper';
import {
  FacilitatorPageWithTemplate,
  LearnerPageWithTemplate,
  PageWithTemplate,
} from '../../pageTemplates/types/courseTypes';
import { LocalStorageService } from '../../services';
import { PreloadImageError } from '../../services/errorHandling/errorHelper';
import { useCamera } from '../CameraProvider';
import { FireBaseCollections, useFirebase } from '../FirebaseProvider';
import { useUser } from '../UserProvider';
import { useAudio } from '../audio/AudioProvider';
import { useSanityCourse } from '../sanity/useSanityCourse';
import { AedPadsData } from '../types/AedPadsData';
import { getNextPageIndex } from './helper';
import { NavigationContextProps } from './navigationContextProps';

export const CommonNavigationContext =
  React.createContext<NavigationContextProps>({} as NavigationContextProps);

const CommonNavigationProvider: React.FunctionComponent<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }) => {
  const [initialPage, setInitialPage] = useState<string>('');
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [numbPagesLeft, setNumbPagesLeft] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [timeLeftInSeconds, setTimeLeftInSeconds] = useState(0);
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(0);
  const cameraContext = useCamera();
  const courseContext = useSanityCourse();
  const history = useNavigate();
  const location = useLocation();
  const audio = useAudio();
  const userContext = useUser();
  const { storeCollection } = useFirebase();

  const setPageIndexInitSounds = (value: React.SetStateAction<number>) => {
    audio?.initCurrentSection();
    setPageIndex(value);
  };

  const push = (url: string, state?: any) => {
    if (window?.revivr.isGroupRevivr && window?.revivr.isLearner) {
      history(
        addProjectQueryStringToUrl(`/waiting`, userContext.sanityProjectId),
        state,
      );
      return;
    }

    history(`${url}`, state);
  };

  const updatePageIndex = (): number => {
    if (!courseContext.course) {
      return;
    }
    const currentPath = window.location.pathname;
    const startIndex = courseContext.course.findIndex(
      (a) => a?.url === currentPath,
    );

    const pageIndexTemp = startIndex < 0 ? 0 : startIndex;
    setPageIndex(pageIndexTemp);
    setNumbPagesLeft(courseContext.course.length - pageIndexTemp - 1);
    return pageIndexTemp;
  };

  const preloadImg = (src: string) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
    });

  const preloadAllImages = (sources: string[]) =>
    Promise.all(sources.map(preloadImg)).catch((error) => {
      throw new PreloadImageError(sources.toString(), error);
    });

  const getImageSourcesFromPage = (
    abc:
      | PageWithTemplate
      | FacilitatorPageWithTemplate
      | LearnerPageWithTemplate,
  ) => {
    if (!abc) {
      return [];
    }
    const imageSources: string[] = [];
    // Loops through object entries and get all image sources if any are present.
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(abc)) {
      try {
        if (value?.image?.src) {
          imageSources.push(value?.image?.src as string);
        }
      } catch (error: unknown) {
        const typedError = error as Error;
        typedError.message = `getImageSourcesFromPage.${key}: ${typedError?.message}`;
        throw typedError;
      }
    }
    return imageSources;
  };

  const preloadImagesOnNextPage = () => {
    if (
      (!courseContext.course && numbPagesLeft < 2) ||
      pageIndex + 1 >= courseContext.course.length
    ) {
      return;
    }
    const imageSources = getImageSourcesFromPage(
      courseContext.course[pageIndex + 1],
    );
    if (imageSources?.length > 0) {
      preloadAllImages(imageSources);
    }
  };

  const updateTimeLeft = () => {
    if (!courseContext.course) {
      return;
    }
    let timeInSek = 0;
    courseContext.course.forEach((page, index) => {
      if (pageIndex < index) {
        if (!isNaN(page?.timeToComplete)) {
          timeInSek += page.timeToComplete;
        }
      }
    });

    setTimeLeftInSeconds(timeInSek);
  };
  const updateTotalTime = () => {
    if (!courseContext.course) {
      return;
    }
    let timeInSek = 0;
    courseContext.course.forEach((page) => {
      timeInSek += page?.timeToComplete || 0;
    });
    setTotalTimeInSeconds(timeInSek);
  };
  const currentPageDuration = (): number => {
    const currentPage = courseContext.course[pageIndex];
    return currentPage.timeToComplete;
  };

  useEffect(() => {
    updatePageIndex();
    updateTotalTime();
    updateTimeLeft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!courseContext.course) {
      return;
    }
    setNumbPagesLeft(courseContext.course.length - pageIndex - 1);
    updateTimeLeft();
    preloadImagesOnNextPage();
  }, [pageIndex]);

  const navigateToUrl = (
    url: string,
    force?: boolean,
    openInNewTab?: boolean,
  ) => {
    url = urlChecker(url);
    if (openInNewTab) {
      window.open(url, '_blank');
      return;
    }
    if (force) {
      push(url);
    }

    let index = courseContext.course.findIndex((page) => page.url === url);
    if (index === -1) {
      if (isValidExternalUrl(url)) {
        window.location.href = url;
      } else {
        console.error('cannot find page!');
      }
      return;
    }

    setInitialPage(''); // Clear initial url since we are redirecting to specific url
    setPageIndexInitSounds(index);
    const urlWithLanguageParam = addLanguageQueryStringToUrl(
      courseContext.course[index].url,
      userContext.localeCode,
    );
    push(
      addProjectQueryStringToUrl(
        urlWithLanguageParam,
        userContext.sanityProjectId,
      ),
    );
  };

  const firstPageUrl = courseContext.course?.[0]?.url ?? '/';

  const navigateToFirstPage = () => {
    const urlWithLanguageParam = addLanguageQueryStringToUrl(
      firstPageUrl,
      userContext.localeCode,
    );
    push(
      addProjectQueryStringToUrl(
        urlWithLanguageParam,
        userContext.sanityProjectId,
      ),
    );
  };

  const navigateToNextPage = (skipRequireCameraPermission?: boolean) => {
    let updatedPageIndex = updatePageIndex();
    const { nextPageURL } = courseContext.course[updatedPageIndex];

    if (nextPageURL) {
      navigateToUrl(nextPageURL, true);
      return;
    }
    updatedPageIndex = getNextPageIndex(
      updatedPageIndex,
      courseContext.course,
      'forward',
    );
    let nextPage = courseContext.course[updatedPageIndex];

    if (skipRequireCameraPermission && !cameraContext?.hasCameraPermission) {
      // Find next available page that do not require camera permission
      while (
        nextPage.requireCameraPermission &&
        updatedPageIndex < courseContext.course.length
      ) {
        updatedPageIndex = getNextPageIndex(
          updatedPageIndex,
          courseContext.course,
          'forward',
        );
        nextPage = courseContext.course[updatedPageIndex];
      }
    }

    setPageIndexInitSounds(updatedPageIndex);
    const urlWithLanguageParam = addLanguageQueryStringToUrl(
      nextPage.url,
      userContext.localeCode,
    );
    push(
      addProjectQueryStringToUrl(
        urlWithLanguageParam,
        userContext.sanityProjectId,
      ),
    );
  };
  const navigateToThePageAfterNext = (
    skipRequireCameraPermission?: boolean,
  ) => {
    let updatedPageIndex = updatePageIndex() + 1; // adding only +1 here since we are adding 1 in getNextPageIndex

    updatedPageIndex = getNextPageIndex(
      updatedPageIndex,
      courseContext.course,
      'forward',
    );
    let nextPage = courseContext.course[updatedPageIndex];

    if (skipRequireCameraPermission && !cameraContext?.hasCameraPermission) {
      // Find next available page that do not require camera permission
      while (
        nextPage.requireCameraPermission &&
        updatedPageIndex < courseContext.course.length
      ) {
        updatedPageIndex = getNextPageIndex(
          updatedPageIndex,
          courseContext.course,
          'forward',
        );
        nextPage = courseContext.course[updatedPageIndex];
      }
    }

    setPageIndexInitSounds(updatedPageIndex);
    const urlWithLanguageParam = addLanguageQueryStringToUrl(
      nextPage.url,
      userContext.localeCode,
    );
    push(
      addProjectQueryStringToUrl(
        urlWithLanguageParam,
        userContext.sanityProjectId,
      ),
    );
  };

  // Please call this navigation function when user interact (exp. user tap button)
  const navigateToNextPageWithSleepCheck = (
    skipRequireCameraPermission?: boolean,
  ) => {
    navigateToNextPage(skipRequireCameraPermission);
  };

  const navigateToPreviousPage = () => {
    const updatedPageIndex = updatePageIndex();

    const { previousPageURL } = courseContext.course[updatedPageIndex];
    if (previousPageURL) {
      navigateToUrl(previousPageURL);
      return;
    }

    const nextPageIndex = getNextPageIndex(
      updatedPageIndex,
      courseContext.course,
      'backward',
    );

    setPageIndexInitSounds(nextPageIndex);
    push(
      addProjectQueryStringToUrl(
        courseContext.course[nextPageIndex].url,
        userContext.sanityProjectId,
      ),
    );
  };

  useEffect(() => {
    storeCollection(FireBaseCollections.urlChanges, {});

    if (initialPage === '' && initialRender) {
      setInitialPage(location.pathname);
    }

    // when navigating away from the initial page, reset it
    if (initialPage.length > 0 && location.pathname !== initialPage) {
      setInitialPage('');
    }

    if (initialRender) {
      setInitialRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const navigateToCheckAnswerPage = (data: AedPadsData) => {
    const updatedPageIndex = updatePageIndex();
    if (updatedPageIndex !== 0) {
      const nextPageIndex = updatedPageIndex + 1;

      setPageIndexInitSounds(nextPageIndex);
      push(courseContext.course[nextPageIndex].url, {
        padPlacementWithinRange: data.padPlacementWithinRange,
        leftPadPosition: {
          x: data.leftPadPosition.x,
          y: data.leftPadPosition.y,
        },
        rightPadPosition: {
          x: data.rightPadPosition.x,
          y: data.rightPadPosition.y,
        },
        leftPadCorrectPosition: {
          x: data.leftPadCorrectPosition.x,
          y: data.leftPadCorrectPosition.y,
        },
        rightPadCorrectPosition: {
          x: data.rightPadCorrectPosition.x,
          y: data.rightPadCorrectPosition.y,
        },
      });
    }
  };

  const navigateToAedPractisePage = () => {
    const updatedPageIndex = updatePageIndex();
    if (updatedPageIndex !== 0) {
      push(courseContext.course[updatedPageIndex - 2].url);
    }
  };

  const navigateToDefaultAndroidBrowser = () => {
    const inAppTag = 'frominapp';

    const organization = LocalStorageService.getOrganization()
      ? `&${
          constants.queryKeys.ORGANIZATION_KEY
        }=${LocalStorageService.getOrganization()}`
      : '';
    const startOfJourney = LocalStorageService.getStartOfJourney()
      ? `&${
          constants.queryKeys.START_OF_JOURNEY_KEY
        }=${LocalStorageService.getStartOfJourney()}`
      : '';
    const distribution = LocalStorageService.getDistribution()
      ? `&${
          constants.queryKeys.DISTRIBUTION_KEY
        }=${LocalStorageService.getDistribution()}`
      : '';
    const UTMSource = LocalStorageService.getUTMSource()
      ? `&${
          constants.queryKeys.UTM_SOURCE
        }=${LocalStorageService.getUTMSource()}`
      : '';
    const shareLevel = LocalStorageService.getShareLevel()
      ? `&${
          constants.queryKeys.SHARE_LEVEL_KEY
        }=${LocalStorageService.getShareLevel()}`
      : '';
    window.location.href = `intent:${addProjectQueryStringToUrl(
      `${document.location.origin}${firstPageUrl}?${constants.queryKeys.TAG_KEY}=${inAppTag}${organization}${UTMSource}${startOfJourney}${distribution}${shareLevel}`,
      userContext.sanityProjectId,
    )}#Intent;end`;
  };

  return (
    <CommonNavigationContext.Provider
      value={{
        currentPageDuration,
        initialPage,
        initialRender,
        navigateToAedPractisePage,
        navigateToCheckAnswerPage,
        navigateToDefaultAndroidBrowser,
        navigateToFirstPage,
        navigateToNextPage,
        navigateToNextPageWithSleepCheck,
        navigateToPreviousPage,
        navigateToThePageAfterNext,
        navigateToUrl,
        numbPagesLeft,
        preloadImagesOnNextPage,
        timeLeftInSeconds,
        totalTimeInSeconds,
      }}
    >
      {children}
    </CommonNavigationContext.Provider>
  );
};

export default CommonNavigationProvider;
